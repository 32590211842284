import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { from, Observable } from 'rxjs';  
import { CommonlistService } from '../services/commonlist.service';
import { CryptojsService } from '../services/cryptojs.service';

@Injectable({
  providedIn: 'root'
})
export class UserdetailsService
{
  Url :string;
  CommonHeader : any;
  __APK_URL = this.CommonlistService.__APK_URL;
  constructor(private CryptojsService : CryptojsService, private http : HttpClient, private CommonlistService:CommonlistService) { }

  setWithdrawAutoStatus(__POSTDATA)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
  
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));
    
    this.Url = this.CommonlistService.__WITHDRAW_AUTO_STATUS_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  setWithdrawAuto(__POSTDATA, _STATUS, _TID, UserID)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
  
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));
    this.Url = this.CommonlistService.__WITHDRAW_AUTO_URL + "?STATUS=" + _STATUS + "&Data=" + encodeURIComponent(__HASH.toString()) + "&TID=" + _TID + "&UID=" + UserID;
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  setWithdrawRemoveProcessed(UserID, _TID)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
      
    this.Url = this.CommonlistService.__WITHDRAW_REMOVE_PROCESSED_URL + "?TID=" + _TID + "&UID=" + UserID;
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  setPendingAuto(__POSTDATA)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
  
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));
    
    this.Url = this.CommonlistService.GetManageDepositPendingList_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  savePendingAuto(status, tid)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    
    let __POSTDATA =
    {
      //'AUT' : token.split('_')[1],
      "orderid" : tid,
      "stetus" : status
    }
    
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));
    this.Url = this.CommonlistService.__PENDING_SAVE_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getWithdrawReqCompleteAction(UserID, inParentID, tid, amount, status, method, update_at, __Remark)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    
    let __POSTDATA =
    {
      'AUT' : token.split('_')[1],
      "inUserID" : UserID,
      "inParentID" : inParentID,
      "tid" : tid,
      "amount" : amount,
      "status" : status,
      "method" : method,
      "update_at" : update_at,
      "remark" : __Remark
    }
    
    
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));
    
    this.Url = this.CommonlistService.WithdrawRequestUpdateList_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getWithdrawReqListSearch(UserID, __KEY, __PS, __PN)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    
    let __POSTDATA =
    {
      'AUT' : token.split('_')[1],
      "inParentID" : UserID,
      "KEY" : __KEY,
      "PS" : __PS,
      "PN" : __PN
    }
    
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));
    
    this.Url = this.CommonlistService.GetWithdrawRequestListSearch_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getWithdrawReqList(UserID, __SDT, __EDT, __SELECT_STATUS, __PS, __PN)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    
    let __POSTDATA =
    {
      'AUT' : token.split('_')[1],
      "inParentID" : UserID,
      "status" : __SELECT_STATUS,
      "SDT" : __SDT,
      "EDT" : __EDT,
      "PS" : __PS,
      "PN" : __PN
    }
    
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));
    
    this.Url = this.CommonlistService.GetWithdrawRequestList_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getDepositReqList(UserID, __SDT, __EDT, __SELECT_STATUS, __PS, __PN)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    
    let __POSTDATA =
    {
      'AUT' : token.split('_')[1],
      "inParentID" : UserID,
      "status" : __SELECT_STATUS,
      "SDT" : __SDT,
      "EDT" : __EDT,
      "PS" : __PS,
      "PN" : __PN
    }
    // console.log(__POSTDATA)

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));
    
    this.Url = this.CommonlistService.GetDepositRequestList_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getDepositReqListSearch(UserID, __KEY, __PS, __PN)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    
    let __POSTDATA =
    {
      'AUT' : token.split('_')[1],
      "inParentID" : UserID,
      "KEY" : __KEY,
      "PS" : __PS,
      "PN" : __PN
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));
    
    this.Url = this.CommonlistService.GetDepositRequestListSearch_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getDepositPendingList(UserID, __SDT, __EDT, __SELECT_STATUS, __PS, __PN)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    
    let __POSTDATA =
    {
      'AUT' : token.split('_')[1],
      "inParentID" : UserID,
      "status" : __SELECT_STATUS,
      "SDT" : __SDT,
      "EDT" : __EDT,
      "PS" : __PS,
      "PN" : __PN
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));
    
    this.Url = this.CommonlistService.GetDepositPendingList_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getBonusList(UserID, __SDT, __EDT, __PS, __PN)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    
    let __POSTDATA =
    {
      'AUT' : token.split('_')[1],
      "inParentID" : UserID,
      // "status" : __SELECT_STATUS,
      "SDT" : __SDT,
      "EDT" : __EDT,
      "PS" : __PS,
      "PN" : __PN
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));
    
    this.Url = this.CommonlistService.GetDepositBonus + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getDepositReqCompleteAction(UserID, inParentID, tid, amount, status, method, update_at, __REMARK)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    
    let __POSTDATA =
    {
      'AUT' : token.split('_')[1],
      "inUserID" : UserID,
      "inParentID" : inParentID,
      "tid" : tid,
      "amount" : amount,
      "status" : status,
      "method" : method,
      "update_at" : update_at,
      "remark" : __REMARK
    }
    
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));
    
    this.Url = this.CommonlistService.DepositRequestUpdateList_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getTransactionsList(UserID, __SDT, __EDT, __PS, __PN)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    
    let __POSTDATA =
    {
      'AUT' : token.split('_')[1],
      "inParentID" : UserID,
      "SDT" : __SDT,
      "EDT" : __EDT,
      "PS" : __PS,
      "PN" : __PN,
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));
    
    this.Url = this.CommonlistService.GetTransactionsList_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getManualDepositList(UserID, __SDT, __EDT, __SELECT_STATUS, __PS, __PN)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    
    let __POSTDATA =
    {
      'AUT' : token.split('_')[1],
      "inParentID" : UserID,
      "status" : __SELECT_STATUS,
      "SDT" : __SDT,
      "EDT" : __EDT,
      "PS" : __PS,
      "PN" : __PN
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));
    
    this.Url = this.CommonlistService.GetManualDeposit_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getManaulTransactionsList(UserID, __SDT, __EDT, __PS, __PN)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    
    let __POSTDATA =
    {
      'AUT' : token.split('_')[1],
      "inParentID" : UserID,
      "SDT" : __SDT,
      "EDT" : __EDT,
      "PS" : __PS,
      "PN" : __PN,
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));
    
    this.Url = this.CommonlistService.GetManageDepositPendingList_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getUserDetails(UserID)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    
    let __POSTDATA =
    {
      'AUT' : token.split('_')[1],
      "ID" : UserID
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART = 
    {
      "Data" : __HASH
    }
    
    this.Url = this.CommonlistService.GetUserDetailsURL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  updateUserDetails(user)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    
    let __POSTDATA =
    {
      'AUT' : token.split('_')[1],
      "User" : user
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));
    // console.log(__POSTDATA);
    let __BODYPART = 
    {
      "Data" : __HASH
    }
    
    this.Url = this.CommonlistService.UpdateUserDetailsURL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  changePassword(UserID, NewPassword)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    
    let __POSTDATA =
    {
      'AUT' : token.split('_')[1],
      "UID" : UserID,
      "NPWD" : NewPassword
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART = 
    {
      "Data" : __HASH
    }
    
    this.Url = this.CommonlistService.ChangePasswordURL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  validateAdminPassword(_PASSWORD)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    
    let __POSTDATA =
    {
      "id" : SessionUser.UserId,
      "Password" : _PASSWORD
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));
    
    this.Url = this.CommonlistService.ADMIN_VALIDATE_ACCESS_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  changePasswordSelf(UserID, NewPassword)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    
    let __POSTDATA =
    {
      'AUT' : token.split('_')[1],
      "UID" : UserID,
      "NPWD" : NewPassword
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART = 
    {
      "Data" : __HASH
    }
    
    this.Url = this.CommonlistService.ChangePasswordSelfURL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  addNewUser(NewUser)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    
    let __POSTDATA =
    {
      'AUT' : token.split('_')[1],
      "User" : NewUser,
      "wi" : this.CommonlistService.LoginWebKey
    }
    // console.log(__POSTDATA);
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART = 
    {
      "Data" : __HASH
    }
    
    this.Url = this.CommonlistService.AddNewUserURL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getUserPartnership(__UID)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    //AUT, CSPL, CCPL, PID, TYP, FNAME, PWD, UNAME, WI, DT
    let __POSTDATA =
    {
      'AUT' : token.split('_')[1],
      "UID" : __UID
    }
    // console.log(__POSTDATA);
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART = 
    {
      "Data" : __HASH
    }
    
    this.Url = this.CommonlistService.__GetPartnership_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  AddUserReport(__CSPL, __CCPL, __PID, __TYP, __FNAME, __PWD, __UNAME, __WI, __DT)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    //AUT, CSPL, CCPL, PID, TYP, FNAME, PWD, UNAME, WI, DT
    let __POSTDATA =
    {
      'AUT' : token.split('_')[1],
      "CSPL" : __CSPL,
      "CCPL" : __CCPL,
      "PID" : __PID,
      "TYP" : __TYP,
      "FNAME" : __FNAME,
      "PWD" : __PWD,
      "UNAME" : __UNAME,
      "WI" : __WI,
      "DT" : __DT
    }
    // console.log(__POSTDATA);
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART = 
    {
      "Data" : __HASH
    }
    
    this.Url = this.CommonlistService.__ADD_USER_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }
}
