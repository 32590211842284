import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { from, Observable } from 'rxjs';  
import { CommonlistService } from '../services/commonlist.service'
import { CryptojsService } from '../services/cryptojs.service';

@Injectable({
  providedIn: 'root'
})
export class ChipsinoutService
{
  Url :string;
  CommonHeader : any; 
  constructor(private CryptojsService : CryptojsService, private http : HttpClient, private CommonlistService:CommonlistService)
  {
  }

  getTransactionBalance(UserID)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      "AUT" : token.split('_')[1],
      "ID" : UserID
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    var model = {
      'AUT' : token.split('_')[1],
      "ID" : UserID
    }

    this.Url = this.CommonlistService.BalanceTransactionUrl + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getCurrentBalance(UserID)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      "AUT" : token.split('_')[1],
      "ID" : UserID
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    var model = {
      'AUT' : token.split('_')[1],
      "ID" : UserID
    }

    this.Url = this.CommonlistService.BalanceUrl + "?Data=" + encodeURIComponent(__HASH.toString());
    // console.log(this.Url);
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getMyCurrentBalance(UserID)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      "AUT" : token.split('_')[1],
      "ID" : UserID
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    var model = {
      'AUT' : token.split('_')[1],
      "ID" : UserID
    }

    this.Url = this.CommonlistService.__MyBalanceUserUrl + "?Data=" + encodeURIComponent(__HASH.toString());

    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getCurrentBalanceAdmin(UserID)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      "AUT" : token.split('_')[1],
      "ID" : UserID
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    var model = {
      'AUT' : token.split('_')[1],
      "ID" : UserID
    }

    this.Url = this.CommonlistService.BalanceAdminUrl + "?Data=" + encodeURIComponent(__HASH.toString());
    // console.log(this.Url);
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getIpAddress(_PID)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    this.Url = this.CommonlistService.__GET_IPADDRESS_LIST_URL + "?UID=" + _PID;
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getDepositWithdraw(_PID)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      "AUT" : token.split('_')[1],
      "UID" : _PID
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.GET_DEPOSIT_WITHDRAW_BY_USER_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  addTransaction(Data)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      "AUT" : token.split('_')[1],
      "TRN" : Data
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.AddTransactionURL + "?Data=" + encodeURIComponent(__HASH.toString());
    // console.log(this.Url);
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getWithdrawBalance(UserID)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      "AUT" : token.split('_')[1],
      "UID" : UserID
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    var model = {
      'AUT' : token.split('_')[1],
      "UID" : UserID
    }

    this.Url = this.CommonlistService.GetWithdrawBalanceURL + "?Data=" + encodeURIComponent(__HASH.toString());
    // console.log(this.Url);
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }
}
