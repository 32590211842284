import { Component, OnInit } from '@angular/core';
import { SignalRService } from '../services/signal-r.service';
import { Router } from '@angular/router';
import { MatchtableService } from '../services/matchtable.service';
import { SportsshowhideService } from '../services/sportsshowhide.service';
import { CryptojsService } from '../services/cryptojs.service';
import { Subscription } from 'rxjs';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-sidemenu-mobile',
  templateUrl: './sidemenu-mobile.component.html',
  styleUrls: ['./sidemenu-mobile.component.css']
})
export class SidemenuMobileComponent implements OnInit {
  UserType_Check : any;
  UserType = 0;
  UserName : any;
  UserID : any;
  
  TotalCricketMatches: number;
  TotalTennisMatches: number;
  TotalSoccerMatches: number;
  TotalHorseRacingMatches: number;
  TotalInPlayMatches: number;
  SportsID : number;

  timer : any;
  
  CricketMatchList = [];
  InPlayMatchList = [];
  TennisMatchList = [];
  SoccerMatchList = [];
  HorseMatchList = [];


  CricketShowStatus = false;
  TennisShowStatus = false;
  SoccerShowStatus = false;
  HorseRacingShowStatus = false;

  MainInPlayMatchList: any;
  private __ALLINONESUBSCRIPTION : Subscription = new Subscription();

  constructor(private LoginService : LoginService, private CryptojsService : CryptojsService, private SportsshowhideService : SportsshowhideService, private MatchtableService : MatchtableService, private Router : Router, private SignalRService : SignalRService)
  {
    // this.__ALLINONESUBSCRIPTION.add(
    //   this.SignalRService.getOnNewMaster().subscribe(
    //   (message) =>
    //   {
    //     setTimeout(() => this.refreshMarketList(), 2000);
    //   })
    // );

    // this.__ALLINONESUBSCRIPTION.add(
    //   this.SignalRService.getOnShowHideMatch().subscribe(
    //   (message) =>
    //   {
    //     setTimeout(() => this.refreshMarketList(), 2000);
    //   })
    // );

    // this.__ALLINONESUBSCRIPTION.add(
    //   this.SignalRService.getOnShowHideSport().subscribe(
    //   (message) =>
    //   {
    //     this.setShowHideSports(message.SID, message.PID, message.STATUS);
    //   })
    // );

    this.__ALLINONESUBSCRIPTION.add(
      this.SportsshowhideService.getOnSportsShowHideShowStatus().subscribe(
      (message) =>
      {
        this.getHidedSports();
      })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.MatchtableService.getOnInPlayMatches().subscribe(
      (message) =>
      {
        this.checkInPlayMatches();
      })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.MatchtableService.getOnResetMatchList().subscribe(
      (message) =>
      {
       this.getMatchList();
      })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getActiveMatch().subscribe(
      (message) =>
      {
       this.setActiveMatch(message);
      })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.LoginService.getAdminAccess().subscribe(
      (message) =>
      {
        this.checkAccess();
      })
    );
  }

  IS_ACCESS = false;
  checkAccess()
  {
    if(sessionStorage.getItem('_ACCESS') === null)
    {
      this.IS_ACCESS = false;
    }
    else
      this.IS_ACCESS = true;
  }

  disableAccess()
  {
    sessionStorage.removeItem('_ACCESS');
    this.LoginService.changeAccess("1");
  }

  __PREV_EID = 0;
  __PREV_CID = 0;
  __PREV_SID = 0;
  __OUT = 10;
  setActiveMatch(__DATA)
  {
    for(let i = 0; i < 6; i++)
    {
      if(document.getElementById("__SIDE_TOGGLE_" + i))
      {
        document.getElementById("__SIDE_TOGGLE_" + i).classList.remove("menu-open");
      }
      if(document.getElementById("collapsedata" + i))
      {
        document.getElementById("collapsedata" + i).style.display = "none";
      }
    }
    if(document.getElementById("__ACTIVE_MATCH_" + this.__PREV_EID))
    {
      document.getElementById("__ACTIVE_MATCH_" + this.__PREV_EID).classList.remove("active-match");
    }
    if(document.getElementById("collapsedata_" + this.__PREV_CID))
    {
      document.getElementById("collapsedata_" + this.__PREV_CID).style.display = "none";
    }
    if(document.getElementById("__SIDE_TOGGLE__" + this.__PREV_CID))
    {
      document.getElementById("__SIDE_TOGGLE__" + this.__PREV_CID).classList.remove("menu-open");
    }
    
    this.__PREV_EID = __DATA.EID;
    this.__PREV_CID = __DATA.CID;
    this.__PREV_SID = __DATA.SID;
    setTimeout(() =>
    {
      if(document.getElementById("__SIDE_TOGGLE_" + __DATA.SID))
      {
        document.getElementById("__SIDE_TOGGLE_" + __DATA.SID).classList.add("menu-open");
      }
      if(document.getElementById("collapsedata" + __DATA.SID))
      {
        document.getElementById("collapsedata" + __DATA.SID).style.display = "block";
      }

      if(document.getElementById("__ACTIVE_MATCH_" + __DATA.EID))
      {
        document.getElementById("__ACTIVE_MATCH_" + __DATA.EID).classList.add("active-match");
      }

      if(document.getElementById("__SIDE_TOGGLE__" + __DATA.CID))
      {
        document.getElementById("__SIDE_TOGGLE__" + __DATA.CID).classList.add("menu-open");
      }
      if(document.getElementById("collapsedata_" + __DATA.CID))
      {
        document.getElementById("collapsedata_" + __DATA.CID).style.display = "block";
      }
    }, this.__OUT);
  }
  
  openLotusPL()
  {
    this.onSideMenuClick();
    this.MatchtableService.OnLotusCasinoCalculate(true);
  }

  getMatchList()
  {
    if(this.MatchtableService.CurrentMatchList == undefined)
    {
      setTimeout(() => {
        this.getMatchList();
      }, 100);
      return false;
    }

    this.CricketMatchList = this.createCompetition(this.MatchtableService.CricketMatchList);
    this.TennisMatchList = this.createCompetition(this.MatchtableService.TennisMatchList);
    this.SoccerMatchList = this.createCompetition(this.MatchtableService.SoccerMatchList);

    this.createTree();

    // this.CricketMatchList = this.MatchtableService.CricketMatchList;
    // this.TennisMatchList = this.MatchtableService.TennisMatchList;
    // this.SoccerMatchList = this.MatchtableService.SoccerMatchList;
    this.HorseMatchList = this.MatchtableService.HorseMatchList;

    this.TotalCricketMatches = (this.MatchtableService.CricketMatchList.length == undefined ? 0 : this.MatchtableService.CricketMatchList.length);
    this.TotalTennisMatches = this.MatchtableService.TennisMatchList.length == undefined ? 0 : this.MatchtableService.TennisMatchList.length;
    this.TotalSoccerMatches = this.MatchtableService.SoccerMatchList.length == undefined ? 0 : this.MatchtableService.SoccerMatchList.length;
    this.TotalHorseRacingMatches = this.MatchtableService.HorseMatchList.length == undefined ? 0 : this.MatchtableService.HorseMatchList.length;
    this.TotalInPlayMatches = this.MatchtableService.InPlayMatchList.length == undefined ? 0 : this.MatchtableService.InPlayMatchList.length;
    
    this.MainInPlayMatchList = this.MatchtableService.InPlayMatchList;
    
    this.reSetInPlayMatches();
  }

  createTree()
  {
    for(let i = 0; i < this.CricketMatchList.length; i++)
    {
      this.CricketMatchList[i]['eventsList'] = this.MatchtableService.CurrentMatchList.filter(({stCompititionID, stMarketName}) =>
      {
        return stCompititionID == this.CricketMatchList[i].stCompititionID && (stMarketName == "Match Odds" || stMarketName == "Winner")
      })
    }

    for(let i = 0; i < this.TennisMatchList.length; i++)
    {
      this.TennisMatchList[i]['eventsList'] = this.MatchtableService.CurrentMatchList.filter(({stCompititionID, stMarketName}) =>
      {
        return stCompititionID == this.TennisMatchList[i].stCompititionID && (stMarketName == "Match Odds" || stMarketName == "Winner")
      })
    }

    for(let i = 0; i < this.SoccerMatchList.length; i++)
    {
      this.SoccerMatchList[i]['eventsList'] = this.MatchtableService.CurrentMatchList.filter(({stCompititionID, stMarketName}) =>
      {
        return stCompititionID == this.SoccerMatchList[i].stCompititionID && (stMarketName == "Match Odds" || stMarketName == "Winner")
      })
    }
  }

  createCompetition(__JSON)
  {
    var lookup = {};
    var items = __JSON;
    var result = [];

    for (var item, i = 0; item = items[i++];)
    {
      var stCompititionID = item.stCompititionID;

      if (!(stCompititionID in lookup)) {
        lookup[stCompititionID] = 1;
        result.push(item);
      }
    }
    return result;
  }
  ngOnInit()
  {
    if(sessionStorage.getItem('user') !== null)
    {
      var Session_User = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
      this.UserType = Session_User.UserType;
      // console.log("Session_User : " + JSON.stringify(Session_User));
      this.UserID = Session_User.UserId;
      this.UserName = Session_User.UserName;
      this.UserType_Check = "CLIENT";
      
      if(this.UserType == 4)
      this.checkAccess();
    }
    
    this.TotalInPlayMatches = this.TotalCricketMatches = this.TotalTennisMatches = this.TotalSoccerMatches  = this.TotalHorseRacingMatches = 0;
    
    this.getMatchList();

    this.getHidedSports();
  }

  onDynamicClientListingClick(__PARAM)
  {
    this.onSideMenuClick();
    this.Router.navigateByUrl('/RefreshComponent', {skipLocationChange: true}).then(()=>
    this.Router.navigate(['/client-listing/m/data'], { queryParams : {params : this.CryptojsService.encryptOrderFunction(__PARAM)} }));
  }

  onDynamicClientListingRegisteredClick(__PARAM)
  {
    this.onSideMenuClick();
    this.Router.navigateByUrl('/RefreshComponent', {skipLocationChange: true}).then(()=>
    this.Router.navigate(['/client-registered/data'], { queryParams : {params : this.CryptojsService.encryptOrderFunction(__PARAM)} }));
  }

  changeNavigation(EventID, stSportsID, stMarketID)
  {
    this.onSideMenuClick();
    // console.log("EventID, stSportsID : " + EventID + " : " + stSportsID)
    this.Router.navigateByUrl('/RefreshComponent', {skipLocationChange: true}).then(()=>
    this.Router.navigate(['/Match:TableView_mobile'], {queryParams : {EventsID : EventID, SportsID : stSportsID, MarketID : stMarketID} }));
  }

  setShowHideSports(data, ParentID, Status)
  {
    switch(data)
    {
      case "4":
        this.CricketShowStatus = Status;
        break;

      case "2":
        this.TennisShowStatus = Status;
        break;

      case "1":
        this.SoccerShowStatus = Status;
        break;

      case "7":
        this.HorseRacingShowStatus = Status;
        break;
    }
    this.reSetInPlayMatches();
  }

  checkInPlayMatches()
  {
    if(this.MatchtableService.isSideBarRefresh == 1)
    {
      this.MatchtableService.isSideBarRefresh = 0;
      setTimeout(() =>
      {
        this.getMatchList();
      }, 2000);
    }
  }

  onClosingAppClick()
  {
    this.onSideMenuClick();
    this.Router.navigate(['/closingapp']);
  }
 
  hideSportsTimer : any;

  reSetInPlayMatches()
  {
    var InPlayMatchList : any;
    if(this.CricketShowStatus)
    {
      InPlayMatchList = this.MainInPlayMatchList.filter(({stSportsID}) =>
      {
        return stSportsID != null && stSportsID != "4"
      })
    }
    else
    {
      InPlayMatchList = this.MainInPlayMatchList;
    }

    if(InPlayMatchList == undefined)
      return false;
      
    if(this.TennisShowStatus)
    {
      InPlayMatchList = InPlayMatchList.filter(({stSportsID}) =>
      {
        return stSportsID != null && stSportsID != "2"
      })
    }

    if(this.SoccerShowStatus)
    {
      InPlayMatchList = InPlayMatchList.filter(({stSportsID}) =>
      {
        return stSportsID != null && stSportsID != "1"
      })
    }

    if(this.HorseRacingShowStatus)
    {
      InPlayMatchList = InPlayMatchList.filter(({stSportsID}) =>
      {
        return stSportsID != null && stSportsID != "7"
      })
    }
    this.TotalInPlayMatches = (InPlayMatchList.length == undefined ? 0 : InPlayMatchList.length);
  }

  getHidedSports()
  {
    if(this.CricketShowStatus != this.SportsshowhideService.CricketShowStatus
      || this.TennisShowStatus != this.SportsshowhideService.TennisShowStatus
      || this.SoccerShowStatus != this.SportsshowhideService.SoccerShowStatus
      || this.HorseRacingShowStatus != this.SportsshowhideService.HorseRacingShowStatus)
    {
      setTimeout(() => {
        this.reSetInPlayMatches();
      }, 50);
    }
    this.CricketShowStatus = this.SportsshowhideService.CricketShowStatus;
    this.TennisShowStatus = this.SportsshowhideService.TennisShowStatus;
    this.SoccerShowStatus = this.SportsshowhideService.SoccerShowStatus;
    this.HorseRacingShowStatus = this.SportsshowhideService.HorseRacingShowStatus;
  }

  ngAfterViewInit()
  {
    
  }

  ngOnDestroy()
  {
    this.__ALLINONESUBSCRIPTION.unsubscribe();
    
    if (this.timer)
      clearInterval(this.timer);
  }

  onSideMenuClick()
  {
    document.getElementById("main-sidebar").className = "";
    document.getElementById("main-sidebar-1").className = "skin-blue sidebar-mini";
  }

  onChangeMainMenu(WhichTab)
  {
    for(let i = 0; i < 2; i++)
    {
      if(document.getElementById("ScrollSideMenuTabs" + i))
      document.getElementById("ScrollSideMenuTabs" + i).className = "";
    }

    if(document.getElementById("ScrollSideMenuTabs" + WhichTab))
      document.getElementById("ScrollSideMenuTabs" + WhichTab).className = "activeMain";
  }

  onDynamicRouterClick(__ROUTER)
  {
    this.onSideMenuClick();
    this.Router.navigateByUrl('/RefreshComponent', {skipLocationChange: true}).then(()=>
    this.Router.navigate([__ROUTER]));
  }

  onMatchListClick(param)
  {
    this.onSideMenuClick();
    this.Router.navigateByUrl('/RefreshComponent', {skipLocationChange: true}).then(()=>
    this.Router.navigate(['/MatchView_mobile'], { queryParams : {SportsID : param} }));
  }
  
  onBet24ButtonClick(param)
  {
    this.onSideMenuClick();
    this.Router.navigateByUrl('/RefreshComponent', {skipLocationChange: true}).then(()=>
    this.Router.navigate(['/Home_mobile'], { queryParams : {selectedTab:param} }));
  }

  onResultDataClick(__ROUTER, __PARAM)
  {
    this.onSideMenuClick();
    this.Router.navigateByUrl('/RefreshComponent', {skipLocationChange: true}).then(()=>
    this.Router.navigate([__ROUTER], { queryParams : {params : this.CryptojsService.encryptOrderFunction(__PARAM)} }));
  }

  refreshMarketList()
  {
    this.getMatchList();
  }
}
