import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { from, Observable, Subject } from 'rxjs';
import { CommonlistService } from '../services/commonlist.service';
import { CryptojsService } from '../services/cryptojs.service';
import { CookieService } from 'ngx-cookie-service';
import { MatchtableService } from './matchtable.service';

declare var $: any;
@Injectable({
  providedIn: 'root'
})

export class LoginService {
  Url: string;
  CommonHeader: any;
  __BANNERS = [];
  _ = "";
  
  private Banners$: Subject<any>;
  private Masters$: Subject<any>;
  private changeDetect$: Subject<any>;
  COUNTRY_LIST = this.CommonlistService.COUNTRY_LIST;
  private enableAccess$: Subject<any>;
  constructor(private CookieService: CookieService, private MatchtableService: MatchtableService, private CryptojsService: CryptojsService, private http: HttpClient, private CommonlistService: CommonlistService) {
    this.Banners$ = new Subject<any>();
    this.changeDetect$ = new Subject<any>();
    this.Masters$ = new Subject<any>();
    this.enableAccess$ = new Subject<any>();
    this.setBannersFromApi();
  }

  changeAccess(__TRIGGER) {
    return this.enableAccess$.next(__TRIGGER);
  }

  public getAdminAccess(): Observable<any> {
    return this.enableAccess$;
  }

  onChangeDetect() {
    this.changeDetect$.next();
  }
  public getOnChangeDetect(): Observable<any> {
    return this.changeDetect$;
  }

  $Maintenance = true;
  getMaintenance() {
    let __POSTDATA =
    {
      "site_id": this.CommonlistService._MAINTENANCE_ID,
      // "maintain": this.$Maintenance
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.MAINTENANCE_GET + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  makeRandom(lengthOfCode: number) {
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  changeBanner(__FILE: File, __ID, __SID, __NAME, __NEWNAME) {
    const formData: FormData = new FormData();
    formData.append('files', __FILE, __FILE.name);

    this.Url = this.CommonlistService.__CHANGEBANNERS_URL + "?site_id=" + __SID + "&id=" + __ID + "&name=" + __NAME + "&newName=" + __NEWNAME;
    return this.http.post<any>(this.Url, formData);
  }

  setBannersFromApi() {
    this.getBannersApi().subscribe(
      data1 => {
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));
        this.__BANNERS = data;
        this.setBanners(true);
      },
      error => {
      });
  }

  setBanners(__TRIGGER) {
    return this.Banners$.next(__TRIGGER);
  }

  public getBenners(): Observable<any> {
    return this.Banners$;
  }

  getMasterSJSON(s) {
    return this.http.get<any>(this.CommonlistService.__MASTER_NEW_JSON_URL + "?sportsId=" + s + "&marketType=MATCH_ODDS");
  }

  getMasterEJSON(e) {
    return this.http.get<any>(this.CommonlistService.__MASTER_NEW_JSON_URL + "?eventId=" + e);
  }

  getMasterMJSON(e) {
    return this.http.get<any>(this.CommonlistService.__MASTER_NEW_JSON_URL + "?mid=" + e);
  }

  getBannersApi() {
    let __POSTDATA =
    {
      "site_id": this.CommonlistService.__SITE_ID,
      "id": 1,
      "enter": 1,
      "stImage": 0
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.__GETBANNERS_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  setPhonePaisaPayUser(__POSTDATA) {
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.__PAYMENT_REQUEST_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    // this.Url = this.CommonlistService.__FLOXY_PAY_USER_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  openIPintPayRequest(__POSTDATA) {
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.__IPINT_PAYMENT_REQUEST_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  setFloxyPayUser(__PID, __UID, __COUNTRY, __MAME, __EMAIL, __MOBILE, __AMOUNT, __ORDERID) {
    let __POSTDATA =
    {
      "PID": __PID,
      "UID": __UID,
      "amount": __AMOUNT,
      "name": __MAME,
      "email": __EMAIL,
      "mobile": __MOBILE,
      "country": __COUNTRY,
      "orderid": __ORDERID
    }
    // console.log("Data/FloxyGetUser", __POSTDATA);
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    // this.Url = this.CommonlistService.__PAYMENT_REQUEST_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    this.Url = this.CommonlistService.__FLOXY_PAY_USER_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  setLotusGameResult(__MID) {
    this.Url = this.CommonlistService.__LOTUSRESULT_URL + __MID;
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  withdrawBalance(__USERID, __AMOUNT, __METHOD, __CREATEDAT, __PAYFROM, __AC_NUMBER, __AC_HOLDER, __AC_IFSC, __AC_BANK, __AC_SWIFT) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      "AUT": token.split('_')[1],
      "inUserID": __USERID,
      "reqAmount": __AMOUNT,
      "paymentMethod": __METHOD,
      "created_at": __CREATEDAT,
      "mNo": __PAYFROM,
      "bank_name": __AC_BANK,
      "accountname": __AC_HOLDER,
      "accountno": __AC_NUMBER,
      "ifsc_code": __AC_IFSC,
      "swift_code": __AC_SWIFT
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.__WITHDRAW_BALANCE_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  uploadImagePayment(__FILE: File, __ID, __SID, __NAME, __NEWNAME, AccountHolder, AccountNumber, IfscCode, BankName, UpiId, Act) {
    const formData: FormData = new FormData();
    formData.append('files', __FILE, __FILE.name);

    this.Url = this.CommonlistService.__UPLOAD_FILE_BANK_URL + "?site_id=" + __SID + "&id=" + __ID + "&name=" + __NAME + "&newName=" + __NEWNAME + "&Accountholder=" + AccountHolder + "&banknumber=" + AccountNumber + "&ifsccode=" + IfscCode + "&bankname=" + BankName + "&bttype=" + UpiId + "&ac=" + Act;
    return this.http.post<any>(this.Url, formData);
  }

  getPaymentApi() {
    let __POSTDATA =
    {
      "site_id": 806
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.__GET_FILE_BANK_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }


  paymentonoff(paymentgateway, status) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      "paymentgateway": paymentgateway,
      "status": status
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.Payment_On_off_ + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }


  getpaymentgateshow() {
    this.Url = this.CommonlistService.Payment_GateWey_Show; //+ "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  withdrawBalanceApex(__USERID, __AMOUNT, __METHOD, __CREATEDAT, __PAYFROM, __AC_NUMBER, __AC_HOLDER, __AC_IFSC, __AC_BANK, __AC_SWIFT) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      "AUT": token.split('_')[1],
      "inUserID": __USERID,
      "reqAmount": __AMOUNT,
      "paymentMethod": __METHOD,
      "created_at": __CREATEDAT,
      "mNo": __PAYFROM,
      "bank_name": __AC_BANK,
      "accountname": __AC_HOLDER,
      "accountno": __AC_NUMBER,
      "ifsc_code": __AC_IFSC,
      "swift_code": __AC_SWIFT
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.__WITHDRAW_BALANCE_APEX_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  RegisterSendTNSID(__UID, __UMAME, __TNSID) {
    let __POSTDATA =
    {
      "userId": __UID,
      "txnID": __TNSID,
      "userName": __UMAME
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.__Register_PAYMENT_TNS_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  ForgotPasswordSendOTP(__MOBILENUMBER, __OTP) {
    let __POSTDATA =
    {
      "MNO": __MOBILENUMBER,
      "OTP": __OTP,
      "UNAME": "",
      "wi": this.CommonlistService.LoginWebKey
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.__FORGOTPSW_OTP_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  ForgotPasswordChange(UserID, NewPassword) {
    let __POSTDATA =
    {
      "UID": UserID,
      "NPWD": NewPassword,
      "wi": this.CommonlistService.LoginWebKey
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.__FORGOTPSW_CHANGE_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  RegisterSendOTP(__MOBILENUMBER, __OTP, __UNAME) {
    let __POSTDATA =
    {
      "MNO": __MOBILENUMBER,
      "OTP": __OTP,
      "UNAME": __UNAME,
      //"wi" : this.CommonlistService.LoginWebKey
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.__REGISTER_OTP_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  RegisterSubmitSend(__MOBILENUMBER, __USERNAME, __FULLNAME, __PASSWORD, __EMAIL, _BTAG) {
    let __POSTDATA =
    {
      "AUT": "123",
      "IP": "1",
      "NO": __MOBILENUMBER,
      "OTP": "1",
      "ID": __USERNAME,
      "FID": __FULLNAME,
      "KEY": __PASSWORD,
      "EmailID": __EMAIL,
      "WI": this.CommonlistService.LoginWebKey,
      "btag": _BTAG
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.__Register_SUBMIT_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  RegisterClient(IPADDRESS, MOBILE, USERNAME, FULLNAME, EMAIL, COUNTRY, COUNTRY_CODE, CURRENCY, BONUS, PASSWORD, REFER) {
    let __POSTDATA =
    {
      "AUT": "123",
      "IP": IPADDRESS,
      "NO": MOBILE,
      "OTP": "123",
      "ID": USERNAME,
      "FID": FULLNAME,
      "EmailID": EMAIL,
      "country": COUNTRY,
      "country_code": COUNTRY_CODE,
      "currency": CURRENCY,
      "bonus": BONUS,
      "term": 1,
      "KEY": PASSWORD,
      "refer": REFER,
      "WI": this.CommonlistService.LoginWebKey
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.REGISTER_CLIENT_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  addBalance(__USERID, __TNSID, __AMOUNT, __METHOD, __CREATEDAT, __PAYFROM) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      "inUserID": __USERID,
      "tid": __TNSID,
      "reqAmount": __AMOUNT,
      "paymentMethod": __METHOD,
      "created_at": __CREATEDAT,
      "mNo": __PAYFROM,
      "AUT": token.split('_')[1]
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.__ADD_BALANCE_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  AutoaddBalance(__USERID, __TNSID, __AMOUNT, __METHOD, __CREATEDAT, __PAYFROM) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      "inUserID": __USERID,
      "tid": __TNSID,
      "reqAmount": __AMOUNT,
      "paymentMethod": __METHOD,
      "created_at": __CREATEDAT,
      "mNo": __PAYFROM,
      "AUT": token.split('_')[1]
    }


    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.__ADD_BALANCE_URL_Auto + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  Login(model: any) {
    let _BTAG = "";
    if (this.CookieService.check('bTag')) {
      _BTAG = this.CryptojsService.decryptData(this.CookieService.get('bTag'));
    }
    let __POSTDATA =
    {
      "IP": this.CommonlistService.ipAddress,
      "DVC": "2",
      "ID": model.ID,
      "Key": model.Key,
      "WI": this.CommonlistService.LoginWebKey,
      "Ltag": _BTAG
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.LoginUrl + "?Data=" + encodeURIComponent(__HASH.toString());
    // console.log("this.Url : " + this.Url)
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  logoutAPI() {
    var SessionUser: any;
    var token: any;
    var UserID: any;
    if (sessionStorage.getItem('user') !== null) {
      SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
      token = SessionUser.Message.split('_')[1];
      UserID = SessionUser.UserId;
    }

    let __POSTDATA =
    {
      "ID": UserID,
      "AUT": token.split('_')[1]
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.LogoutURL + "?Data=" + encodeURIComponent(__HASH.toString());
    // console.log("this.Url : " + this.Url)
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getM2MForAllMaster(UserID) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      "AUT": token.split('_')[1],
      "UID": UserID
    }
    // console.log(__POSTDATA);
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.M2MLiveAllMasterUrl + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getSubMatchListAll(model: any) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      "AUT": token.split('_')[1]
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.SubMatchListAllUrl + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  // getRateFromJSON() {
  //   return this.http.get(this.CommonlistService.__LIVERATEJSON_URL);
  // }

  getRateFromJSON(MMR) {
    let _MID = "";
    if (MMR != null && MMR != "") {
      _MID = MMR
      if (sessionStorage.getItem("user") == undefined || sessionStorage.getItem("user") == null) {
        // console.log("GETRATE > NDD")
        return null;
      }
      else {
        var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
        // console.log("GETRATE > " + SessionUser.UserId)
      }
    }

    else {
      let MRKT = [];
      if (this.MatchtableService.CurrentMatchList == null || this.MatchtableService.CurrentMatchList == undefined) {
        setTimeout(() => {
          this.getRateFromJSON("");
        }, 100);
      }
      else {
        MRKT = this.MatchtableService.CurrentMatchList.filter((m) => {
          return m.stSportsID == '4' || m.stSportsID == '2' || m.stSportsID == '1'
        })
      }
      if (MRKT != null) {
        // console.log(MRKT);
        for (let m of MRKT) {
          if (_MID == "")
            _MID = m.stMarketID;
          else
            _MID += "," + m.stMarketID;
        }
      }
    }

    const headers = { 'signature': 'czPfKoYzt5iJ3RJpi81qHQ==', 'Content-Type': 'application/json' };
    const body = { "mid": _MID };
    return this.http.post<any>(this.CommonlistService.__LIVERATEJSON_URL, body, { headers });
  }

  getMasterFromJSON() {
    if (sessionStorage.getItem("user") == undefined || sessionStorage.getItem("user") == null) {
      return null;
    }

    const headers = { 'signature': 'czPfKoYzt5iJ3RJpi81qHQ==' };
    const body = {};
    return this.http.post<any>(this.CommonlistService.__MASTERREQUESTJSON_URL, body, { headers });
  }
  public getMasters(): Observable<any> {
    return this.Masters$;
  }
  setMasters(DATA) {
    return this.Masters$.next(DATA);
  }

  getMasterAllList(model: any) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    // console.log(SessionUser)
    let __POSTDATA =
    {
      'ID': SessionUser.UserId,
      "AUT": token.split('_')[1]
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.__MASTERREQUEST_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getMatchList(model: any) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'ID': SessionUser.UserId,
      "AUT": token.split('_')[1]
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.MatchListUrl + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getMatchListParticular(MarketID) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      "MID": MarketID,
      "ID": SessionUser.UserId,
      "AUT": token.split('_')[1]
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.__MasterRequestParticular_URL + '?Data=' + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getBlockedMatchList() {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'ID': SessionUser.UserId,
      "AUT": token.split('_')[1]
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.getBlockedMatchesURL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }
} 