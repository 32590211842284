import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { CommonlistService } from '../services/commonlist.service'
import { CryptojsService } from '../services/cryptojs.service';

@Injectable({
  providedIn: 'root'
})
export class ClientlistService {
  Url: string;
  CommonHeader: any;
  constructor(private CryptojsService: CryptojsService, private http: HttpClient, private CommonlistService: CommonlistService) {
  }

  getTGainersReport(__FROMDATE, __TODATE) //(__USERID, __TYPE, __PS, __PN, __ACCOUNT_TYPE)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      "sdtDate": __FROMDATE,
      "edtDate": __TODATE
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.GetTopGainers_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getTLosersReport(__FROMDATE, __TODATE) //(__USERID, __TYPE, __PS, __PN, __ACCOUNT_TYPE)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      "sdtDate": __FROMDATE,
      "edtDate": __TODATE
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.GetTopLoosers_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getClientListingByRegistered(__USERID, __TYPE, __PS, __PN, __ACCOUNT_TYPE) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      "UID": __USERID,
      "TYPE": __TYPE,
      "PS": __PS,
      "PN": __PN,
      "A_TYPE": __ACCOUNT_TYPE
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.__CLIENTLIST_REGISTERED_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getClientListingByRegisteredSearch(__USERID, __TYPE, __PS, __PN, __KEYWORD) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      "UID": __USERID,
      "WORD": __KEYWORD,
      "TYPE": __TYPE,
      "PS": __PS,
      "PN": __PN,
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.__CLIENTLIST_SEARCH_REGISTERED_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getSettlementHistory(__USERID, __SD, __ED, __PS, __PN) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      "UID": __USERID,
      "SD": __SD,
      "ED": __ED,
      "PS": __PS,
      "PN": __PN,
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.SettlementHistoryReportURL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getClientListingByTypeSearch(__USERID, __TYPE, __PS, __PN, __KEYWORD) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      "UID": __USERID,
      "WORD": __KEYWORD,
      "TYPE": __TYPE,
      "PS": __PS,
      "PN": __PN,
      "wi": this.CommonlistService.LoginWebKey
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.__CLIENTLIST_SEARCH_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getAccountInfoDetails(__USERID) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      "UID": __USERID,
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.AccountInfoReportURL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getPartnerShipDetails(__USERID) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      "UID": __USERID,
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.__PARTNERSHIP_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getClientListingByType(__USERID, __TYPE, __PS, __PN, __ACCOUNT_TYPE) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      "UID": __USERID,
      "TYPE": __TYPE,
      "PS": __PS,
      "PN": __PN,
      "A_TYPE": __ACCOUNT_TYPE
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.__CLIENTLIST_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getClientList(UserID) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      "UID": UserID
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.ClientListURL_A + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getClientListAll() {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      "UID": 1
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.ClientListURL_ALL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getOnlyClientList() {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      "UID": SessionUser.UserId
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.getOnlyClientsURL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getAdminChipsDetails(UserID) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      "UID": UserID
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.AdminChipDetails + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getMatchWiseLimits(ParentID, UserID, MarketID) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      "PID": ParentID,
      "UID": UserID,
      "MID": MarketID
    }
    // console.log(__POSTDATA);
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.MatchWiseLimitsURL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  saveMatchWiseLimits(ParentID, UserID, MarketID, MinStack, MaxStack, ClProfLimit, MaxLossLimit, GIPTrade, GIPLimit, OSLOdd, OSLStack, Delay) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      'PID': ParentID,
      'UID': UserID,
      'MID': MarketID,
      'MIN': MinStack,
      'MAX': MaxStack,
      'CPL': ClProfLimit,
      'MLL': MaxLossLimit,
      'GIP': GIPTrade,
      'GIL': GIPLimit,
      'ODD': OSLOdd,
      'STK': OSLStack,
      'DLY': Delay
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.saveMatchWiseLimitsURL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  saveShowHideMatches(UserID, Block, MarketID) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      'PID': UserID,
      'HIDE': Block,
      'MID': MarketID
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.saveShowHideMatchesURL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getHidedSports(UserID) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      "ID": UserID
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.getShowHideSportsURL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  saveShowHideSports(UserID, Block, SportsID) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      'PID': UserID,
      'HIDE': Block,
      'SID': SportsID
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.saveShowHideSportsURL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  saveLiveStreams(EventID, URL) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      "AUT": token.split('_')[1],
      'EID': EventID,
      'tvURL': URL
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.saveLiveStreamsURL + "?Data=" + encodeURIComponent(__HASH.toString());
    // console.log(this.Url);
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getLiveStreams(EventID) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      'EID': EventID
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART =
    {
      "Data": __HASH
    }

    this.Url = this.CommonlistService.getLiveStreamsURL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getLiveTVFromBetFair() {
    this.Url = this.CommonlistService.__GETALLTV_URL;
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  getOnlineUser(__USERID, __PS, __PN) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      "UID": __USERID,
      "PS": __PS,
      "PN": __PN,
    }

    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.getOnlineUser_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  AddLock_Unlock(Key, Action, __USER_ID) {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.getItem('user')));
    const token = SessionUser.Message;

    let __POSTDATA =
    {

      "key": Key,
      "Action": Action,
      "ID": __USER_ID,
    }

    // console.log(__POSTDATA)
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.CommonlistService.__LockUnlockData + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }
}
