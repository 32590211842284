import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonlistService } from '../services/commonlist.service';
import { CheckmobileService } from '../services/checkmobile.service';
import { CryptojsService } from '../services/cryptojs.service';
import { MatchtableService } from '../services/matchtable.service';
import { Subject, Observable } from 'rxjs';

declare var $: any;
declare var TRT123: any;
declare var TRT124: any;
declare var TRT125: any;
declare var TRT126: any;
declare var TRT128: any;
@Injectable({
    providedIn: 'root'
})
export class SignalRService {
    _MAINTENANCE_ID = this.CommonlistService._MAINTENANCE_ID;
    isGetLiveRate = false;
    isWindowFocus = true;
    data: any;
    SignalR_MarqueeMessage: any;
    CurrentEventID: any;
    Transport = { transport: 'longPolling' };
    WhichMessageCount = 0;

    __IS_ENTERIPL = "";
    __CASINO_VIDEO_URL = this.CommonlistService.__CASINO_VIDEO_URL;
    __EVOLUTION = TRT123;
    __EZUGI = TRT124;
    __VIVOGAMING = TRT125;
    __BETGAMES = TRT126;
    __SLOT_GAMES = TRT128;
    __SLOTS_IMAGE_URL = this.CommonlistService.__SLOTS_IMAGE_URL;
    __FRONT_TIME = 10000;
    __CURRENTODDS = [];
    __SECONDS = 5000;
    __VAR_MATCH = 0;
    __CONNECTIONCHECK: any;
    __ISINTERNET = true;
    __MAINTAINCONNECTION = 0;

    FUNCASINO_SPORTS_ID = "1501";
    LOTUS_SPORTS_ID = "1601";
    BACCARAT_SPORTS_ID = "2101";
    BETONPOKER_SPORTS_ID = "2201";
    SIXPOKER_SPORTS_ID = "2301";
    SPEEDY7_SPORTS_ID = "2401";
    WAROFBETS_SPORTS_ID = "2501";
    
    SessionUser: any;
    TempAllMatches: any;
    tempDelayCounter = 0;
    USERINTERFACE = 0;
    __USERINTERFACE = 2000;
    __CHIPS = "";

    connection = $.hubConnection(this.CommonlistService.SignalRURL);
    BCast = this.connection.createHubProxy('BcastHub');

    private onData$: Subject<any>;
    // private onDataGuest$: Subject<any>;
    private onMessage$: Subject<any>;
    private onServerMessage$: Subject<any>;
    private onNewMaster$: Subject<any>;
    private onShowHideMatch$: Subject<any>;
    private onShowHideSport$: Subject<any>;
    private onDisconnected$: Subject<any>;
    private onLogout$: Subject<any>;
    // private onHorseInfo$: Subject<any>;
    private onMatched$: Subject<any>;
    private onUnMatched$: Subject<any>;
    private onBlockStatus$: Subject<any>;
    private onBlockEventStatus$: Subject<any>;
    private onWinnerInfo$: Subject<any>;
    private onFancyGateWayMessage$: Subject<any>;
    private onCommentary$: Subject<any>;
    private onAdminMessage$: Subject<any>;
    private onInternetConnection$: Subject<any>;
    private onEnterTableView$: Subject<any>;
    private onEnterAdminPanel$: Subject<any>;
    private onHomeButtonClick$: Subject<any>;
    private onOpenAdminPopup$: Subject<any>;
    private onLotusBalanceRefresh$: Subject<any>;
    private onFrontRate$: Subject<any>;
    private isEnterDiamondCasino$: Subject<any>;
    private onMessageDiamond$: Subject<any>;
    private onMatchedDiamond$: Subject<any>;
    private ActiveMatch$: Subject<any>;
    private closeBet$: Subject<any>;
    private onChips$: Subject<any>;

    private onMaintenance$: Subject<any>;

    constructor(private MatchtableService: MatchtableService, private CryptojsService: CryptojsService, private CheckmobileService: CheckmobileService, private http: HttpClient, private CommonlistService: CommonlistService) {
        this.onInternetConnection$ = new Subject<any>();
        this.onMaintenance$ = new Subject<any>();

        this.onOpenAdminPopup$ = new Subject<any>();
        this.onData$ = new Subject<any>();
        // this.onDataGuest$ = new Subject<any>();
        this.onMessage$ = new Subject<any>();
        this.onServerMessage$ = new Subject<any>();
        this.onNewMaster$ = new Subject<any>();
        this.onShowHideMatch$ = new Subject<any>();
        this.onShowHideSport$ = new Subject<any>();
        this.onDisconnected$ = new Subject<any>();
        this.onLogout$ = new Subject<any>();
        // this.onHorseInfo$ = new Subject<any>();
        this.onMatched$ = new Subject<any>();
        this.onUnMatched$ = new Subject<any>();
        this.onBlockStatus$ = new Subject<any>();
        this.onBlockEventStatus$ = new Subject<any>();
        this.onWinnerInfo$ = new Subject<any>();
        this.onFancyGateWayMessage$ = new Subject<any>();
        this.onCommentary$ = new Subject<any>();
        this.onEnterTableView$ = new Subject<any>();
        this.onEnterAdminPanel$ = new Subject<any>();
        this.onAdminMessage$ = new Subject<any>();
        this.onHomeButtonClick$ = new Subject<any>();
        this.isEnterDiamondCasino$ = new Subject<any>();
        this.onMessageDiamond$ = new Subject<any>();
        this.onMatchedDiamond$ = new Subject<any>();
        this.onLotusBalanceRefresh$ = new Subject<any>();
        this.onFrontRate$ = new Subject<any>();
        this.onChips$ = new Subject<any>();

        this.ActiveMatch$ = new Subject<any>();
        this.closeBet$ = new Subject<any>();
    }

    maintainConnection() {
        this.__MAINTAINCONNECTION = 0;

        var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
        let SHA_256_KEY = sessionStorage.getItem("SHA_256_KEY");

        if (this.CheckmobileService.isMobileView) {
            this.connection.start(this.Transport).done(() => {
                this.BCast.invoke("ReInitializeMulti", SessionUser.UserId, SessionUser.HandshakeKey, SHA_256_KEY).done(() => {
                    // console.log("maintainConnectionMethod ReInitializeMulti Done => => => => ");
                });
            })
                .fail(() => {

                });
        }
        else {
            this.connection.start().done(() => {
                this.BCast.invoke("ReInitializeMulti", SessionUser.UserId, SessionUser.HandshakeKey, SHA_256_KEY).done(() => {
                    // console.log("maintainConnectionMethod ReInitializeMulti Done => => => => ");
                });
            })
        }
    }

    reConnectSignalR() {
        this.__MAINTAINCONNECTION++;

        if (this.__MAINTAINCONNECTION >= 60) {
            this.maintainConnection();
        }

        if (this.connection.state == 4) {
            if (sessionStorage.getItem('user') !== null)
                this.changeInternetConnectionState(false);
            if (this.CheckmobileService.isMobileView) {
                this.connection.start(this.Transport).done(() => {
                    this.isGetLiveRate = true;
                })
                    .fail(() => {

                    });
            }
            else {
                this.connection.start().done(() => {
                    this.isGetLiveRate = true;
                })
                    .fail(() => {

                    });
            }
        }
        else if (this.connection.state == 1) {
            this.changeInternetConnectionState(true);

            if (this.isGetLiveRate && sessionStorage.getItem('user') !== null) {
                this.isGetLiveRate = false;
                this.BCast = this.connection.createHubProxy('BcastHub');
                var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));

                let SHA_256_KEY = sessionStorage.getItem("SHA_256_KEY");

                this.BCast.invoke("ReInitializeMulti", SessionUser.UserId, SessionUser.HandshakeKey, SHA_256_KEY).done(() => {
                });
                this.inializeAllKeys(this.BCast);

                this.SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));

                this.tempDelayCounter = 0;

                this.tempDelayCounter = 0;
                this.getDelayLiveRate();
            }
        }
        else {
            if (sessionStorage.getItem('user') !== null)
                this.changeInternetConnectionState(false);
            this.isGetLiveRate = true;
        }
        this.__CONNECTIONCHECK = setTimeout(() => {
            // console.log('Reinitialize Start of User : ');
            this.reConnectSignalR();
        }, 1500);
    }

    public initializeSignalRConnection(): void {
        if (this.__CONNECTIONCHECK) {
            clearTimeout(this.__CONNECTIONCHECK);
            this.__CONNECTIONCHECK = null;
        }

        this.__MAINTAINCONNECTION = 0;

        var proxy = this.connection.createHubProxy('BcastHub');
        if (sessionStorage.getItem('user') !== null) {
            var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
            // console.log(this.connection)
            // console.log(sessionStorage.getItem("SHA_256_KEY"));
            let SHA_256_KEY = sessionStorage.getItem("SHA_256_KEY");
            if (this.CheckmobileService.isMobileView) {
                this.connection.start(this.Transport).done(() => {
                    proxy.invoke("initializeMulti", SessionUser.UserId, SessionUser.HandshakeKey, SHA_256_KEY).done(() => {
                    });
                    this.inializeAllKeys(proxy);
                    setTimeout(() => {
                        this.reConnectSignalR();
                    }, 1500);
                })
                    .fail(() => {
                        this.changeInternetConnectionState(false);
                        setTimeout(() => {
                            this.initializeSignalRConnection();
                            console.log('Could not connect');
                        }, 3000);
                    });
            }
            else {
                this.connection.start().done(() => {
                    proxy.invoke("initializeMulti", SessionUser.UserId, SessionUser.HandshakeKey, SHA_256_KEY).done(() => { });
                    // proxy.invoke("initializeMulti", -1, SessionUser.HandshakeKey, SHA_256_KEY).done(() =>{});
                    this.inializeAllKeys(proxy);
                    setTimeout(() => {
                        this.reConnectSignalR();
                    }, 1500);
                })
                    .fail(() => {
                        this.changeInternetConnectionState(false);
                        setTimeout(() => {
                            this.initializeSignalRConnection();
                            console.log('Could not connect');
                        }, 3000);
                    });
            }
        }

        this.connection.disconnected(() => {
            console.log('Disconnected to the Server.');
            console.log(new Date());
            if (!this.CheckmobileService.isMobileView) {
                this.offAllKey();
            }
        });
    }

    OnChips(_CHIPS) {
        this.onChips$.next(_CHIPS);
    }

    public getOnChips(): Observable<any> {
        return this.onChips$;
    }

    offAllKey() {
        this.BCast.off('onData');
        // this.BCast.off('onDataGuest');
        this.BCast.off('message');
        this.BCast.off('serverMessage');
        this.BCast.off('newMaster');
        this.BCast.off('onShowHideMatch');
        this.BCast.off('onShowHideSport');
        this.BCast.off('disconnected');
        this.BCast.off('Logout');
        // this.BCast.off('HorseInfo');
        this.BCast.off('matched');
        this.BCast.off('unmatched');
        this.BCast.off('onBlockStatus');
        this.BCast.off('onBlockEventStatus');
        this.BCast.off('WinnerInfo');
        this.BCast.off('FancyGateWayMessage');
        this.BCast.off('commentary');
        this.BCast.off('getMaintenance');
    }

    inializeAllKeys(proxy) {
        this.BCast.on('onData', (data) => {
            this.onData$.next(data);
            data = null;
        });

        // this.BCast.on("onDataGuest", (data) =>
        // {
        //     if(data.length == 0)
        //         return false;

        //     this.__CURRENTODDS = data;
        //     this.onDataGuest$.next(data);
        //     data = null;
        // });

        this.BCast.on('message', (data) => {
            this.onMessage$.next(data);
            data = null;
        });

        this.BCast.on('serverMessage', (data, interval) => {
            let TempData = {
                'message': data,
                'interval': interval
            }
            this.onServerMessage$.next(TempData);
            data = null;
        });

        this.BCast.on('newMaster', (data) => {
            this.onNewMaster$.next(data);
            data = null;
        });

        this.BCast.on('onShowHideMatch', (data, ParentID, Status) => {
            let TempData = {
                'SID': data,
                'PID': ParentID,
                'STATUS': Status
            }
            this.onShowHideMatch$.next(TempData);
            data = null;
        });

        this.BCast.on('onShowHideSport', (data, ParentID, Status) => {
            let TempData = {
                'SID': data,
                'PID': ParentID,
                'STATUS': Status
            }
            this.onShowHideSport$.next(TempData);
            data = null;
        });

        this.BCast.on('disconnected', (data) => {
            this.onDisconnected$.next(data);
            data = null;
        });

        this.BCast.on('Logout', (data) => {
            this.onLogout$.next(data);
            data = null;
        });

        // this.BCast.on('HorseInfo', (data) =>
        // {
        //     this.onHorseInfo$.next(data);
        // });

        this.BCast.on('matched', (data) => {
            this.onMatched$.next(data);
            data = null;
        });

        this.BCast.on('unmatched', (data) => {
            this.onUnMatched$.next(data);
            data = null;
        });

        this.BCast.on('onBlockStatus', (id, block) => {
            let TempData = {
                "id": id,
                "block": block
            }
            this.onBlockStatus$.next(TempData);
        });

        this.BCast.on('onBlockEventStatus', (id, block, isMatch) => {
            let TempData = {
                "id": id,
                "block": block,
                "isMatch": isMatch
            }
            this.onBlockEventStatus$.next(TempData);
        });

        this.BCast.on('WinnerInfo', (data) => {
            this.onWinnerInfo$.next(data);
            data = null;
        });

        this.BCast.on('AdminMarketMessageReceive', (MarketID, data) => {
            let TempData = {
                "MarketID": MarketID,
                "Message": data
            }
            this.onAdminMessage$.next(TempData);
            data = null;
        });

        this.BCast.on('FancyGateWayMessage', (data) => {
            this.onFancyGateWayMessage$.next(data);
            data = null;
        });

        this.BCast.on('commentary', (data) => {
            this.onCommentary$.next(data);
            data = null;
        });

        this.BCast.on('getMaintenance', (ID, MESSAGE, STATUS) => {
            let TempData = {
                'ID': ID,
                'MESSAGE': MESSAGE,
                'STATUS': STATUS
            }
            this.onMaintenance$.next(TempData);
        });
    }

    public getOnMaintenance(): Observable<any> {
        return this.onMaintenance$;
    }

    // setOnMainRates()
    // {
    //     this.BCast.on("onDataGuest", (data) =>
    //     {
    //         if(data.length == 0)
    //             return false;

    //         this.__CURRENTODDS = data;
    //         this.onDataGuest$.next(data);
    //     });
    // }

    // setOffMainRates()
    // {
    //     if(this.connection.state != 1)
    //     {
    //         setTimeout(() => {
    //             this.setOffMainRates();
    //         }, 100);
    //         return false;
    //     }
    //     this.BCast.off("onDataGuest");
    // }

    setCloseBet(__ST) {
        return this.closeBet$.next(__ST);
    }

    public getCloseBet(): Observable<any> {
        return this.closeBet$;
    }

    setActiveMatch(__EID, __SID) {
        let __TEMP = { EID: __EID, SID: __SID }
        return this.ActiveMatch$.next(__TEMP);
    }

    setActiveMatchCompetition(__EID, __SID, __CID) {
        let __TEMP = { EID: __EID, SID: __SID, CID: __CID }
        return this.ActiveMatch$.next(__TEMP);
    }

    public getActiveMatch(): Observable<any> {
        return this.ActiveMatch$;
    }

    getFrontRate() {
        return this.onFrontRate$.next(true);
    }

    public getOnFrontRate(): Observable<any> {
        return this.onFrontRate$;
    }

    changeOnLotusBalanceRefresh(__TRIGGER) {
        return this.onLotusBalanceRefresh$.next(__TRIGGER);
    }

    public getOnLotusBalanceRefresh(): Observable<any> {
        return this.onLotusBalanceRefresh$;
    }

    changeInternetConnectionState(__VALUE) {
        if (this.__ISINTERNET != __VALUE) {
            this.__ISINTERNET = __VALUE;
            this.onInternetConnection$.next(__VALUE);
        }
    }
    public getOnInternetConnectionState(): Observable<any> {
        return this.onInternetConnection$;
    }

    changeOnHomeButtonClick(__VALUE) {
        this.onHomeButtonClick$.next(__VALUE);
    }
    public getOnHomeButtonClick(): Observable<any> {
        return this.onHomeButtonClick$;
    }

    changeEnterAdminPanel(__VALUE) {
        this.onEnterAdminPanel$.next(__VALUE);
    }
    public getOnEnterAdminPanel(): Observable<any> {
        return this.onEnterAdminPanel$;
    }

    changeOnOpenAdminPopup(__VALUE) {
        this.onOpenAdminPopup$.next(__VALUE);
    }
    public getOnOpenAdminPopup(): Observable<any> {
        return this.onOpenAdminPopup$;
    }

    changeEnterTableView(__VALUE) {
        this.__VAR_MATCH = __VALUE;
        this.onEnterTableView$.next(__VALUE);
    }
    public getOnEnterTableView(): Observable<any> {
        return this.onEnterTableView$;
    }

    public getOnData(): Observable<any> {
        return this.onData$;
    }

    // public getOnDataGuest(): Observable<any>
    // {
    //     return this.onDataGuest$;
    // }

    public getOnMessage(): Observable<any> {
        return this.onMessage$;
    }

    public getOnServerMessage(): Observable<any> {
        return this.onServerMessage$;
    }

    public getOnNewMaster(): Observable<any> {
        return this.onNewMaster$;
    }

    public getOnShowHideMatch(): Observable<any> {
        return this.onShowHideMatch$;
    }

    public getOnShowHideSport(): Observable<any> {
        return this.onShowHideSport$;
    }

    public getOnDisconnected(): Observable<any> {
        return this.onDisconnected$;
    }

    public getOnLogout(): Observable<any> {
        return this.onLogout$;
    }

    // public getOnHorseInfo(): Observable<any>
    // {
    //     return this.onHorseInfo$;
    // }

    public getOnMatched(): Observable<any> {
        return this.onMatched$;
    }

    public getOnUnMatched(): Observable<any> {
        return this.onUnMatched$;
    }

    public getOnBlockStatus(): Observable<any> {
        return this.onBlockStatus$;
    }

    public getOnBlockEventStatus(): Observable<any> {
        return this.onBlockEventStatus$;
    }

    public getOnWinnerInfo(): Observable<any> {
        return this.onWinnerInfo$;
    }

    public getOnFancyGatewayMessage(): Observable<any> {
        return this.onFancyGateWayMessage$;
    }

    public getOnCommentary(): Observable<any> {
        return this.onCommentary$;
    }

    public getOnAdminMessage(): Observable<any> {
        return this.onAdminMessage$;
    }

    requestFrontRate() {
        if (sessionStorage.user === undefined)
            return false
        var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));

        if (SessionUser === undefined) {
            setTimeout(() => {
                this.requestFrontRate();
            }, 100);
            return false;
        }
        if (this.connection.state == 1) {
            this.BCast.invoke("requestDataLiveGUEST", SessionUser.UserId, "GUEST", "GUEST");
        }
        else {
            this.connection.start().done(() => {
                this.BCast.invoke("requestDataLiveGUEST", SessionUser.UserId, "GUEST", "GUEST");
            })
                .fail(function () {
                    // console.log('Failed to connect with the Server@MatchTableView.');
                });
        }
    }

    removeFrontRate() {
        if (this.connection.state == 1) {
            this.BCast.invoke("removeDataLive", new Date().getMinutes(), "GUEST");
        }
        else {
            this.connection.start().done(() => {
                this.BCast.invoke("removeDataLive", new Date().getMinutes(), "GUEST");
            })
                .fail(function () {
                    // console.log('Failed to Remove with the Server@MatchTableView.');
                });
        }
    }

    ngOnDestroy() {
        this.offAllKey();
        this.OffDiamondKeys();

        if (this.__CONNECTIONCHECK) {
            clearTimeout(this.__CONNECTIONCHECK);
            this.__CONNECTIONCHECK = 0;
        }

        if (this.__CONNECTIONCHECK_DIAMOND) {
            clearTimeout(this.__CONNECTIONCHECK_DIAMOND);
            this.__CONNECTIONCHECK_DIAMOND = 0;
        }
    }

    getDelayLiveRate() {
        if (this.MatchtableService.CurrentRequestList.length > 0) {
            if (this.CheckmobileService.isMobileView) {
                this.connection.start(this.Transport).done(() => {
                    for (let i = 0; i < this.MatchtableService.CurrentRequestList.length; i++) {
                        this.BCast.invoke("requestDataLive", this.SessionUser.UserId, this.MatchtableService.CurrentRequestList[i], this.MatchtableService.CurrentRequestListSports[i]);
                    }
                })
                    .fail(function () {
                        // console.log('Failed to connect with the Server@MatchTableView.');
                    });
            }
            else {
                this.connection.start().done(() => {
                    for (let i = 0; i < this.MatchtableService.CurrentRequestList.length; i++) {
                        this.BCast.invoke("requestDataLive", this.SessionUser.UserId, this.MatchtableService.CurrentRequestList[i], this.MatchtableService.CurrentRequestListSports[i]);
                    }
                })
                    .fail(function () {
                        // console.log('Failed to connect with the Server@MatchTableView.');
                    });
            }
        }
    }

    closePlaceBetPopupNew() {
        if (document.getElementById("__PLACEBETPOPUP"))
            $('#__PLACEBETPOPUP').modal('hide');
    }

    __CONNECTION_DIAMOND = $.hubConnection(this.CommonlistService.__DIAMOND_SIGNALR_URL);
    __BCAST_DIAMOND = this.__CONNECTION_DIAMOND.createHubProxy('BcastHub');

    __CONNECTIONCHECK_DIAMOND: any;

    __ISREINITIALIZE = false;

    reConnectDiamondSignalR() {
        if (this.__CONNECTION_DIAMOND.state == 4) {
            this.OffDiamondKeys();
            this.__ISREINITIALIZE = true;
            if (this.CheckmobileService.isMobileView) {
                this.__CONNECTION_DIAMOND.start(this.Transport).done(() => {

                })
                    .fail(() => {

                    });
            }
            else {
                this.__CONNECTION_DIAMOND.start().done(() => {

                })
                    .fail(() => {

                    });
            }
        }
        else if (this.__CONNECTION_DIAMOND.state == 1) {
            if (this.__ISREINITIALIZE && sessionStorage.getItem('user') !== null) {
                this.__ISREINITIALIZE = false;

                this.__BCAST_DIAMOND = this.__CONNECTION_DIAMOND.createHubProxy('BcastHub');
                var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));

                let SHA_256_KEY = sessionStorage.getItem("SHA_256_KEY");

                this.__BCAST_DIAMOND.invoke("ReInitializeMulti", SessionUser.UserId, SessionUser.HandshakeKey, SHA_256_KEY).done(() => {
                });
                this.initializeDiamondKeys();
            }
        }
        else {
            this.OffDiamondKeys();
            this.__ISREINITIALIZE = true;
        }
        this.__CONNECTIONCHECK_DIAMOND = setTimeout(() => {
            this.reConnectDiamondSignalR();
        }, 1500);
    }

    public initializeDiamondSignalRConnection(): void {
        if (sessionStorage.getItem('user') !== null) {
            var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));

            let SHA_256_KEY = sessionStorage.getItem("SHA_256_KEY");
            if (this.CheckmobileService.isMobileView) {
                this.__CONNECTION_DIAMOND.start(this.Transport).done(() => {
                    this.__BCAST_DIAMOND.invoke("initializeMulti", SessionUser.UserId, SessionUser.HandshakeKey, SHA_256_KEY).done(() => {
                    });
                    this.initializeDiamondKeys();
                    setTimeout(() => {
                        this.reConnectDiamondSignalR();
                    }, 1500);
                })
                    .fail(() => {
                        setTimeout(() => {
                            this.initializeDiamondSignalRConnection();
                            console.log('Casino DataCenter : Could not connect');
                        }, 3000);
                    });
            }
            else {
                this.__CONNECTION_DIAMOND.start().done(() => {
                    this.__BCAST_DIAMOND.invoke("initializeMulti", SessionUser.UserId, SessionUser.HandshakeKey, SHA_256_KEY).done(() => { });

                    this.initializeDiamondKeys();
                    setTimeout(() => {
                        this.reConnectDiamondSignalR();
                    }, 1500);
                })
                    .fail(() => {
                        setTimeout(() => {
                            this.initializeDiamondSignalRConnection();
                            console.log('Casino DataCenter : Could not connect');
                        }, 3000);
                    });
            }
        }

        this.__CONNECTION_DIAMOND.disconnected(() => {
            console.log('Casino DataCenter : Disconnected');
            if (!this.CheckmobileService.isMobileView)
                this.OffDiamondKeys();
            if (this.__CONNECTIONCHECK_DIAMOND) {
                clearTimeout(this.__CONNECTIONCHECK_DIAMOND);
                this.__CONNECTIONCHECK_DIAMOND = 0;
            }
        });
    }

    public OffDiamondKeys() {
        this.__BCAST_DIAMOND.off('message');
        this.__BCAST_DIAMOND.off('matched');
    }

    public StopDiamondConnection() {
        this.__CONNECTION_DIAMOND.stop();
    }

    changeEnterDiamondCasino(__VALUE) {
        this.isEnterDiamondCasino$.next(__VALUE);
    }
    public getOnEnterDiamondCasino(): Observable<any> {
        return this.isEnterDiamondCasino$;
    }

    initializeDiamondKeys() {
        this.__BCAST_DIAMOND.on('message', (data) => {
            this.onMessageDiamond$.next(data);
            data = null;
        });

        this.__BCAST_DIAMOND.on('matched', (data) => {
            this.onMatchedDiamond$.next(data);
            data = null;
        });
    }

    public getOnMessageDiamond(): Observable<any> {
        return this.onMessageDiamond$;
    }

    public getOnMatchedDiamond(): Observable<any> {
        return this.onMatchedDiamond$;
    }
}
