<ng-container *ngIf="isLogin">

    <body class="hold-transition skin-blue sidebar-mini" id="main-sidebar-1">
        <div class="wrapper">
            <ng-container *ngIf="!isMobileView">
                <!-- <app-headermenu></app-headermenu> -->

                <header>
                    <nav class="header-top-size">
                        <div class="header-top">
                            <ul class="m-b-0">
                                <li><a (click)="changeHeaderTabs(0, 0)" id="__TOPBARTABS_0"
                                        class="hoverPointer router-link-active">Exchange</a></li>
                                <!-- <li><a id="__TOPBARTABS_15" (click)="onFenixCasinoClick('funnclub|123')"
                                        class="hoverPointer router-link-active">FENIX CASINO</a></li> -->
                                <li><a (click)="onWorldCasinoClick('supernowa|EZ', 11)"
                                        class="hoverPointer router-link-active" id="__TOPBARTABS_1">EZUGI</a></li>
                                <!-- <li><a (click)="onDynamicRouterClick('/casino/ezugi')" id="__TOPBARTABS_1"
                                        class="hoverPointer router-link-active">EZUGI</a></li> -->
                                <!-- <li><a (click)="onDynamiRouterClick('/casino/ezugi', 1000000)" id="__TOPBARTABS_2"
                                        class="hoverPointer router-link-active">EVOLUTION</a></li> -->
                                <!-- <li><a (click)="onDynamicRouterClick('/casino/evolution')" id="__TOPBARTABS_2"
                                        class="hoverPointer router-link-active">EVOLUTION</a></li> -->


                                <!-- <li><a (click)="onWorldCasinoClick('funnclub|123')"  id="__TOPBARTABS_3" class="hoverPointer router-link-active">FENIX CASINO</a></li> -->
                                <li><a (click)="onWorldCasinoClick('supernowa|EV', 12)"
                                        class="hoverPointer router-link-active">EVOLUTION</a></li>
                                <li><a (click)="onWorldCasinoClick('supernowa|SN', 13)" id="__TOPBARTABS_0"
                                        class="hoverPointer router-link-active">SUPER NOWA</a></li>
                                <li><a (click)="onWorldCasinoClick('supernowa|QT', 14)" id="__TOPBARTABS_0"
                                        class="hoverPointer router-link-active">QTECH</a></li>

                                <!-- <li *ngIf="!LiveCasinoShowStatus"><a id="__TOPBARTABS_1"
                                        (click)="changeHeaderTabs(1, 0)" class="hoverPointer">Our Casino</a></li> -->
                                <!-- <li *ngIf="!EvolutionShowStatus"><a id="__TOPBARTABS_2" (click)="changeHeaderTabs(1, 1)"
                                        class="hoverPointer">Live Casino</a></li> -->
                                <!-- <li><a id="__TOPBARTABS_3" (click)="changeHeaderTabs(0, 1)"
                                        class="hoverPointer">Cup Winner</a></li>
                                <li><a id="__TOPBARTABS_4" (click)="onDynamicRouterClick('/mybets')"
                                        class="hoverPointer">Trade Book</a></li> -->
                            </ul>
                        </div>
                        <!-- <div class="support-us">
                            Support : <a href="https://api.whatsapp.com/send?phone=918920006513" target="_blank"><i class="fa fa fa-whatsapp m-r-5 m-l-5 color-white"></i> <u class="support-us-text">+91 89 20 006 513</u></a>
                            <a href="https://api.whatsapp.com/send?phone=917048936253" target="_blank" class="m-l-5"><i class="fa fa fa-whatsapp m-r-5 m-l-5 color-white"></i> <u class="support-us-text">+91 70 48 936 253</u></a>
                        </div> -->
                    </nav>
                </header>
                <header class="main-header">

                    <nav class="navbar navbar-static-top static-header-main">
                        <!-- <div class="sidebar-toggle" data-toggle="push-menu" role="button">
                            <span class="sr-only">Toggle navigation</span>
                        </div> -->
                        <!-- <a (click)="onHomeClick()" class="hoverPointer logo">
                            <img src="images/HOTSPORTS/header-logo.png" width="150px" alt="" />
                        </a> -->
                        <!-- <marquee id="MarqueeMessage" scrollamount="4" class="marqueeMessage fs-09vw">
                            <b>{{MarqueeMessage}}</b>
                        </marquee> -->
                        <div class="upcoming-fixure fixure-inner-class">
                            <div class="fixure-title">Upcoming Fixtures</div>
                            <div class="fixure-box-container">
                                <div class="marqueeUpcoming">
                                    <marquee class="marquee-inner">
                                        <div class="marquee-content">
                                            <div class="login-fixture">
                                                <ng-container *ngFor="let d of AllMatchList">
                                                    <div class="fixure-box">
                                                        <div>
                                                            <img *ngIf="d.stSportsID == '4'"
                                                                src="/images/cricket-sidebar.png" style="width: 1vw;">
                                                            <img *ngIf="d.stSportsID == '2'"
                                                                src="/images/tennis-sidebar.png" style="width: 1vw;">
                                                            <img *ngIf="d.stSportsID == '1'"
                                                                src="/images/football-sidebar.png" style="width: 1vw;">
                                                            {{d.stEventName}}
                                                        </div>
                                                        <div>{{d.dtStartDate | date:'dd, MMM yyyy HH:mm:ss'}}</div>
                                                    </div>
                                                </ng-container>

                                            </div>
                                        </div>
                                    </marquee>
                                </div>
                            </div>
                        </div>
                        <div class="navbar-custom-menu fs-09vw font-family-sans-serif fixure-balance">
                            <ul class="nav navbar-nav">

                                <li class="dropdown notifications-menu p-l-5"
                                    *ngIf="__ISADDBALANCE == 1 && UserType == 3">
                                    <a class="btn btn-warning balance-btn-header l-h-header" data-backdrop="static"
                                        data-keyboard="false" href="#AddBalancePopupData" data-toggle="modal"
                                        data-target="#AddBalancePopupData">
                                        Deposit
                                    </a>
                                </li>

                                <li class="dropdown notifications-menu p-l-5"
                                    *ngIf="__ISADDBALANCE == 1 && UserType == 3">
                                    <a class="btn btn-danger balance-btn-header l-h-header" data-backdrop="static"
                                        data-keyboard="false" href="#WithdrawBalancePopupData" data-toggle="modal"
                                        data-target="#WithdrawBalancePopupData">
                                        Withdrawal
                                    </a>
                                    <!-- <a class="btn btn-danger balance-btn-header l-h-header" data-backdrop="static" data-keyboard="false" href="https://api.whatsapp.com/send?phone=12622262777" >
                                        Withdrawal
                                    </a> -->
                                </li>

                                <li class="dropdown notifications-menu">
                                    <a (click)="getCurrentBalance()"
                                        class="dropdown-toggle hoverPointer padding-bal-tab l-h-header"
                                        data-toggle="dropdown">
                                        <div>
                                            <b>Bal: </b>{{CurrentMainBalance | number : '1.2-2'}} | <span
                                                class="hoverPointer"
                                                (click)="onDynamicRouterClick('/mybets')">{{CurrentExplosure | number :
                                                '1.2-2'}}</span>
                                        </div>
                                        <!-- <div class="hoverPointer" (click)="onDynamicRouterClick('/mybets')">
                                            <u><b>Exposure: </b>{{CurrentExplosure | number : '1.2-2'}}</u>
                                        </div> -->
                                    </a>
                                </li>
                                <li class="dropdown user user-menu notifications-menu">
                                    <a class="dropdown-toggle hoverPointer padding-bal-tab l-h-header"
                                        data-toggle="dropdown">
                                        <div>
                                            <span class="hidden-xs"><img src="/images/user-client-icon.png"
                                                    width="20px"> {{UserName_DB}} <i
                                                    class="fa fa-chevron-down m-l-5"></i></span>
                                        </div>
                                        <!-- <div>
                                            <b><span class="hidden-xs fs-08vw">({{UserType_Check}})</span></b>
                                        </div> -->
                                    </a>
                                    <ul class="dropdown-menu box-shadow-light" style="width: 170px;">
                                        <li class="user-body padding0">
                                            <ul class="menu max-h-full fs-09vw">
                                                <!-- <li>
                                                    <a class="padding5">
                                                        <i class="fa fa-user"></i>
                                                        <b>{{UserName_DB}}</b> <span class="fs-12">({{UserType_Check}})</span>
                                                    </a>
                                                </li>
                                                <li>
                
                                                </li> -->
                                                <!-- <li *ngIf="__ISADDBALANCE == 1 && UserType == 3">
                                                    <a class="padding5" *ngIf="__ISADDBALANCE == 1" data-backdrop="static" data-keyboard="false" href="#AddBalancePopupData" data-toggle="modal" data-target="#AddBalancePopupData">
                                                        Deposit
                                                    </a>
                                                </li>
                
                                                <li *ngIf="__ISADDBALANCE == 1 && UserType == 3">
                                                        <a class="btn btn-danger balance-btn-header l-h-header" data-backdrop="static" data-keyboard="false" href="https://api.whatsapp.com/send?phone=12622262777" >
                                                            Withdrawal
                                                        </a>
                                                    </li> -->
                                                <!-- <a class="padding5" *ngIf="__ISADDBALANCE == 1" data-backdrop="static" data-keyboard="false" href="#WithdrawBalancePopupData" data-toggle="modal" data-target="#WithdrawBalancePopupData">
                                                        Withdrawal
                                                    </a> -->


                                                <li *ngIf="__ISADDBALANCE == 1 && UserType == 3">
                                                    <a class="padding5 hoverPointer" (click)="onTransactionClick()">
                                                        Transactions
                                                    </a>
                                                </li>

                                                <li *ngIf="UserType == 3">
                                                    <a class="padding5" href="#buttonStack" (click)="getButtonStack()"
                                                        data-toggle="modal" data-target="#buttonStack">
                                                        Stack Values
                                                    </a>
                                                </li>

                                                <li>
                                                    <a class="padding5 hoverPointer"
                                                        (click)="onDynamicRouterClick('/report/account-info')">
                                                        Account Info
                                                    </a>
                                                </li>

                                                <li>
                                                    <a class="padding5 hoverPointer"
                                                        (click)="onDynamicRouterClick('/report/acStatement')">
                                                        Account Statement
                                                    </a>
                                                </li>

                                                <!-- <li>
                                                    <a class="padding5 hoverPointer"
                                                        (click)="onDynamicRouterClick('/report/simple/acStatement')">
                                                        A/C Statement (Simple)
                                                    </a>
                                                </li> -->

                                                <li>
                                                    <a class="padding5 hoverPointer"
                                                        (click)="onDynamicRouterClick('/report/ezugi/acStatement')">
                                                        Casino Statement
                                                    </a>
                                                </li>
                                                <li>
                                                    <a class="padding5 hoverPointer"
                                                        (click)="onDynamicRouterClick('/report/ezugi/acStatement-old')">
                                                        Old Casino Statement
                                                    </a>
                                                </li>

                                                <li *ngIf="__MAIN_USER_TYPE != 3">
                                                    <a class="padding5 hoverPointer"
                                                        (click)="onDynamicRouterClick('/report/chip-summary')">
                                                        Chip Summary
                                                    </a>
                                                </li>

                                                <!-- <li *ngIf="__MAIN_USER_TYPE != 3">
                                                    <a class="padding5 hoverPointer"
                                                        (click)="onDynamicRouterClick('/report/market-pl')">
                                                        Market P/L
                                                    </a>
                                                </li> -->

                                                <li>
                                                    <a class="padding5 hoverPointer"
                                                        (click)="onDynamicRouterClick('/report/pl')">
                                                        Profit Loss
                                                    </a>
                                                </li>

                                                <li>
                                                    <a class="padding5 hoverPointer"
                                                        (click)="onDynamicRouterClick('/report/bet-history')">
                                                        Bet History
                                                    </a>
                                                </li>

                                                <li>
                                                    <a class="padding5 hoverPointer"
                                                        (click)="onDynamicRouterClick('/report/live-bets')">
                                                        Casino Bet History
                                                    </a>
                                                </li>

                                                <li>
                                                    <a class="padding5 hoverPointer"
                                                        (click)="onResultDataClick('/matchresults', 0)">
                                                        Match Result
                                                    </a>
                                                </li>

                                                <li>
                                                    <a class="padding5 hoverPointer"
                                                        (click)="onResultDataClick('/matchresults', 1)">
                                                        Casino Result
                                                    </a>
                                                </li>

                                                <li>
                                                    <a class="padding5 hoverPointer"
                                                        *ngIf="__MAIN_USER_TYPE == 0 || __MAIN_USER_TYPE == 4"
                                                        data-toggle="modal" data-target="#LotusCasinoPL"
                                                        (click)="openLotusPL()">Lotus Casino P/L
                                                    </a>
                                                </li>

                                                <li>
                                                    <a class="padding5" href="#changePass" data-toggle="modal"
                                                        data-target="#changePass">
                                                        Change Password
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="user-footer padding0 fs-09vw">
                                            <ul class="menu max-h-full">
                                                <li>
                                                    <a class="padding5 hoverPointer" data-toggle="modal"
                                                        data-target="#RulesAllForNewModal">Rules</a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="user-footer padding0 fs-09vw">
                                            <ul class="menu max-h-full">
                                                <li>
                                                    <a class="padding5 hoverPointer" (click)="Logout(1)">
                                                        Signout
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </nav>

                </header>
                <app-sidemenu></app-sidemenu>
                <div class="content-wrapper static-content-wrapper" id="__CONTENTWRAPPERDESKTOP">
                    <section class="content bgMainRoute">

                        <div id="__MATCHTABLELOADER" class="overlayLoaderPopup" style="height: 0%;">
                            <div class="overlayLoaderPopup-content" style="color: #ff8282;">
                                <!-- <div class="loader" id="__MATCHTABLELOADER"></div> -->
                                <img src="/images/LoginLoaderGIF.gif" width="50px;" />
                            </div>
                        </div>
                        <div id="__MATCHTABLELOADERNew" class="overlayLoaderPopup" style="height: 0%;">
                            <div class="overlayLoaderPopup-content" style="color: #ff8282;">
                                <!-- <div class="loader" id="__MATCHTABLELOADER"></div> -->
                                <img src="/images/LoginLoaderGIF.gif" width="50px;" />
                            </div>
                        </div>
                        <div id="__REFRESHLOADER" class="overlayRefreshPopup" style="height: 0%;">
                            <div class="overlayRefreshPopup-content" style="color: #ff8282;">
                                <img src="/images/LoginLoaderGIF.gif" width="50px;" />
                            </div>
                        </div>
                        <span id="__NOTIFIER" class="default-notification" (click)="HideAllNotification()"></span>
                        <router-outlet></router-outlet>
                    </section>
                </div>
                <div class="header-bottom">
                    <marquee id="MarqueeMessage" scrollamount="4" class="marqueeMessage fs-09vw">
                        <b>{{MarqueeMessage}}</b>
                    </marquee>
                </div>
            </ng-container>
            <ng-container *ngIf="isMobileView">
                <app-headermenu-mobile></app-headermenu-mobile>
                <app-sidemenu-mobile></app-sidemenu-mobile>

                <div class="content-wrapper" id="__CONTENTWRAPPER">

                    <div id="__UPCOMING_FIXURE">
                        <div class="scrollmenuBalance" *ngIf="__ISADDBALANCE == 1 && UserType == 3">
                            <div>
                                <a data-backdrop="static" data-keyboard="false" href="#AddBalancePopupData"
                                    data-toggle="modal" data-target="#AddBalancePopupData">
                                    Deposit
                                </a>
                            </div>
                            <div>
                                <!-- <a data-backdrop="static" data-keyboard="false" href="https://api.whatsapp.com/send?phone=12622262777" >
                                        Withdrawal
                                    </a> -->

                                <a data-backdrop="static" data-keyboard="false" href="#WithdrawBalancePopupData"
                                    data-toggle="modal" data-target="#WithdrawBalancePopupData">
                                    Withdrawal
                                </a>
                            </div>
                        </div>
                        <div class="scrollmenuMainHeader">
                            <div>
                                <a (click)="changeHeaderTabs(0, 0)" id="__TOPBARTABS_0"
                                    class="hoverPointer router-link-active">
                                    Exchange
                                </a>
                            </div>
                            <!-- <div>
                                <a (click)="changeHeaderTabs(1, 0)" id="__TOPBARTABS_1">
                                    Our Casino
                                </a>
                            </div> -->
                            <div>
                                <a (click)="changeHeaderTabs(1, 1)" id="__TOPBARTABS_2">
                                    Casino
                                </a>
                            </div>
                            <div>
                                <a (click)="changeHeaderTabs(0, 1)" id="__TOPBARTABS_3">
                                    Winner Cup
                                </a>
                            </div>
                            <!-- <div *ngIf="!LiveCasinoShowStatus || !EvolutionShowStatus">
                            <a (click)="onDynamicRouterClick('/casino/casino-games')" class="blinking">
                                CASINO
                            </a>
                            </div> -->
                        </div>
                        <div class="upcoming-fixure">
                            <!-- <div class="fixure-title">Upcoming Fixtures</div> -->
                            <div class="fixure-box-container" style="width: 100%;">
                                <!-- style="width: calc(100% - 95px);"> -->
                                <div class="marqueeUpcoming">
                                    <marquee class="marquee-inner">
                                        <div class="marquee-content">
                                            <div class="login-fixture">
                                                <ng-container *ngFor="let d of AllMatchList">
                                                    <div class="fixure-box">
                                                        <div>
                                                            <img *ngIf="d.stSportsID == '4'"
                                                                src="/images/cricket-sidebar.png" style="width: 20px;">
                                                            <img *ngIf="d.stSportsID == '2'"
                                                                src="/images/tennis-sidebar.png" style="width: 20px;">
                                                            <img *ngIf="d.stSportsID == '1'"
                                                                src="/images/football-sidebar.png" style="width: 20px;">
                                                            {{d.stEventName}}
                                                        </div>
                                                        <div>{{d.dtStartDate | date:'dd, MMM yyyy HH:mm:ss'}}</div>
                                                    </div>
                                                </ng-container>

                                            </div>
                                        </div>
                                    </marquee>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section class="content padding0 m-b-20">
                        <!-- <hr> -->
                        <!-- <button (click)="reconnect()" class="btn btn-warning">Add New Match</button> -->
                        <div id="__MATCHTABLELOADER" class="overlayLoaderPopup" style="height: 0%;">
                            <div class="overlayLoaderPopup-content" style="color: #ff8282;">
                                <!-- <div class="loader" id="__MATCHTABLELOADER"></div> -->
                                <img src="/images/LoginLoaderGIF.gif" width="50px;" />
                            </div>
                        </div>
                        <div id="__MATCHTABLELOADERNew" class="overlayLoaderPopup" style="height: 0%;">
                            <div class="overlayLoaderPopup-content" style="color: #ff8282;">
                                <!-- <div class="loader" id="__MATCHTABLELOADER"></div> -->
                                <img src="/images/LoginLoaderGIF.gif" width="50px;" />
                            </div>
                        </div>
                        <div id="__REFRESHLOADER" class="overlayRefreshPopup" style="height: 0%;">
                            <div class="overlayRefreshPopup-content" style="color: #ff8282;">
                                <img src="/images/LoginLoaderGIF.gif" width="50px;" />
                            </div>
                        </div>
                        <router-outlet></router-outlet>
                        <span id="__NOTIFIER" class="default-notification" (click)="HideAllNotification()"></span>
                    </section>
                </div>
                <div class="header-bottom">
                    <marquee id="MarqueeMessage" scrollamount="4" class="marqueeMessage">
                        <b>{{MarqueeMessage}}</b>
                    </marquee>
                </div>
            </ng-container>

        </div>
        <script>
            $.widget.bridge('uibutton', $.ui.button);
        </script>
    </body>
</ng-container>

<ng-container *ngIf="!isLogin">

    <body>
        <router-outlet></router-outlet>
        <span id="__NOTIFIER" class="default-notification" (click)="HideAllNotification()"></span>
    </body>
</ng-container>
<div id="back-top-btn">
    <!-- <a class="tg" href="https://bit.ly/3thDOGF" target="_blank" [class]="_IS_CONTACT ? 'scale-one' : 'scale-zero'"><i class="fa fa-telegram color-white"></i></a>
    <a class="insta" href="https://bit.ly/37Ahgsd" target="_blank" [class]="_IS_CONTACT ? 'scale-one' : 'scale-zero'"><i class="fa fa-instagram color-white"></i></a> -->
    <!-- <a class="insta" href="https://instagram.com/hotsportsindia?r=nametag" target="_blank"><i class="fa fa-instagram color-white"></i></a> -->
    <a class="wtsp" href="https://wa.me/+916363829642" target="_blank"
        [class]="_IS_CONTACT ? 'scale-one' : 'scale-zero'"><i class="fa fa-whatsapp color-white"></i></a>
    <a class="wtsp" (click)="openContact()"><i
            [class]="_IS_CONTACT ? 'fa fa-minus color-white' : 'fa fa-whatsapp color-white'"></i></a>
</div>
<div class="modal fade" id="LotusCasinoPL" role="dialog">
    <div class="modal-dialog">

        <div class="modal-content">
            <div class="modal-header popupColor">
                <a class="close popupClose" data-dismiss="modal">&times;</a>
                <h4 class="modal-title">
                    Lotus Casino P/L
                </h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-6 padding-two">
                        <form role="form" method="post" name="frmAdd">
                            <div class="col-md-12 padding-two">
                                <div class="form-group col-sm-12 padding-zero">
                                    <label>Enter Percentage (%)</label>
                                    <input name="__PERCENTAGE" id="__PERCENTAGE" type="number"
                                        [(ngModel)]="__PERCENTAGE" class="form-control input-sm" />
                                </div>
                            </div>

                            <div class="col-md-12 padding-two">
                                <div class="form-group col-sm-12 padding-zero">
                                    <input type="button" class="btn btn-sm btn-block bg-blue" value="Calculate"
                                        (click)="CanculatePercentage()" />
                                </div>
                            </div>

                        </form>
                    </div>
                    <div class="col-sm-6 padding-two">
                        <img src="/images/LoginLoaderGIF.gif" id="__LOADERLOTUSPL" width="30px;" alt="Loader" />
                        <div class="col-md-12 padding-two">
                            <div class="form-group col-sm-12 padding-zero">
                                <label>Lotus P/L : {{__LOTUS_PL | number : '1.2-2'}}</label>
                            </div>
                        </div>
                        <div class="col-md-12 padding-two">
                            <div class="form-group col-sm-12 padding-zero">
                                <label>Percentage (%) : {{__PERCENTAGE}}</label>
                            </div>
                        </div>

                        <div class="col-md-12 padding-two">
                            <div class="form-group col-sm-12 padding-zero">
                                <label>Calculated P/L : {{__CALCULATED_PL | number : '1.2-2'}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="WaitingGameLoader" class="overlayMainLoader" style="height: 0px;">
    <div class="overlayMainLoader-content">
        <div class="col-md-12 text-center">
            <img src="/images/LoginLoaderGIF.gif" width="50px;" alt="Loader" />
        </div>
    </div>
</div>

<div id="ChangePasswordFirstPopup" class="overlayMatchedPopup" style="height: 0px;">
    <div class="overlayMatchedPopup-content">
        <div class="col-md-3 paddingZero"></div>
        <div class="col-md-6 m-l-0 p-l-0 m-r-0 p-r-0 paddingZero bgwhite">

            <div class="scrollmenuMatched">
                <a>
                    CHANGE PASSWORD
                </a>
            </div>

            <div class="modal-body" style="padding: 5px;">
                <div class="row">
                    <div class="col-sm-12 padding-two">
                        <form role="form" method="post" name="frmAdd">
                            <div class="col-md-6 padding-two">
                                <div class="form-group col-sm-12 padding-zero">
                                    <label>New Password</label>
                                    <input name="password31" id="password31" type="password" [(ngModel)]="NewPassword"
                                        class="form-control input-sm" />
                                </div>
                            </div>
                            <div class="col-md-6 padding-two">
                                <div class="form-group col-sm-12 padding-zero">
                                    <label>Confirm Password</label>
                                    <input name="password12" id="password12" type="password"
                                        [(ngModel)]="ConfirmPassword" class="form-control input-sm" />
                                </div>
                            </div>

                            <div class="col-md-12 padding-two">
                                <div class="form-group col-sm-3 padding-zero">
                                    <input type="button" class="btn btn-sm btn-block bg-blue" value="CHANGE"
                                        (click)="ChangePassword()" />
                                </div>
                            </div>

                            <div class="col-md-12 padding-two">
                                <div class="form-group col-sm-12 padding-zero">
                                    {{PasswordErrorMessage}}
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3 paddingZero"></div>
    </div>
</div>

<div id="internetConnectionPopup" class="overlayMatchedPopup" style="height: 0px;">
    <div class="overlayMatchedPopup-content" style="color: #ff8282;">
        Something went wrong!
        <br>Can not connect to the server.
        <br>Please check your internet connection or Contact admin.
    </div>
</div>

<div class="modal fade" id="AddBalancePopupData_1" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header popupColor">
                <button type="button" class="close popupClose" (click)="CloseAddBalancePopup()"
                    data-dismiss="modal">&times;</button>
                <h4 class="modal-title">
                    Deposit
                    <!-- <a *ngIf="IS_CRYPTO" class="btn btn-success" (click)="toggleCurrency()">Regular Payment</a>
                    <a *ngIf="!IS_CRYPTO" class="btn btn-warning" (click)="toggleCurrency()">Pay using Crypto Currency</a> -->
                </h4>
            </div>
            <div class="modal-body padding5">
                <div class="box-body padding0">

                    <!-- AUTO -->

                    <!-- <form *ngIf="!__IS_SEND_REQUEST">
                        <div class="box-body padding0">

                            <div *ngIf="!IS_CRYPTO" class="SportsMenuDeposit">
                                <a id="_TABS_0" href="#tab_0" data-toggle="tab" class="hoverPointer">
                                    <img src="./images/HOTSPORTS/payment/Plugin icon - 1.png" alt="deposit"/>
                                </a>
                                <a id="_TABS_1" href="#tab_1" data-toggle="tab" class="hoverPointer">
                                    <img src="./images/HOTSPORTS/payment/Plugin icon - 2.png" alt="deposit"/>
                                </a>
                                <a id="_TABS_2" href="#tab_2" data-toggle="tab" class="hoverPointer">
                                    <img src="./images/HOTSPORTS/payment/Plugin icon - 3.png" alt="deposit"/>
                                </a>
                                <a id="_TABS_3" href="#tab_3" data-toggle="tab" class="hoverPointer">
                                    <img src="./images/HOTSPORTS/payment/Plugin icon - 4.png" alt="deposit"/>
                                </a>
                                <a id="_TABS_4" href="#tab_4" data-toggle="tab" class="hoverPointer">
                                    <img src="./images/HOTSPORTS/payment/Plugin icon - 5.png" alt="deposit"/>
                                </a>
                                <a id="_TABS_5" href="#tab_5" data-toggle="tab" class="hoverPointer">
                                    <img src="./images/HOTSPORTS/payment/Plugin icon - 8.png" alt="deposit"/>
                                </a>
                            </div>
                            <div *ngIf="IS_CRYPTO" class="SportsMenuCrypto">
                                <a id="_TABS_0" href="#tab_0" data-toggle="tab" class="hoverPointer">
                                    <img src="./images/Currency/bitcoin.png" alt="deposit"/>
                                </a>
                                <a id="_TABS_1" href="#tab_1" data-toggle="tab" class="hoverPointer">
                                    <img src="./images/Currency/ethereum.png" alt="deposit"/>
                                </a>
                                <a id="_TABS_2" href="#tab_2" data-toggle="tab" class="hoverPointer">
                                    <img src="./images/Currency/bitcoincash.png" alt="deposit"/>
                                </a>
                                <a id="_TABS_3" href="#tab_3" data-toggle="tab" class="hoverPointer">
                                    <img src="./images/Currency/dogecoin.png" alt="deposit"/>
                                </a>
                                <a id="_TABS_4" href="#tab_4" data-toggle="tab" class="hoverPointer">
                                    <img src="./images/Currency/litecoin.png" alt="deposit"/>
                                </a>
                                <a id="_TABS_5" href="#tab_5" data-toggle="tab" class="hoverPointer">
                                    <img src="./images/Currency/usdt.png" alt="deposit"/>
                                </a>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="__MERCHANT_AMOUNT" class="col-sm-12 col-form-label">Amount</label>
                            <div class="col-sm-12">
                                <input type="number" class="form-control" name="__MERCHANT_AMOUNT"
                                    id="__MERCHANT_AMOUNT" [(ngModel)]="__MERCHANT_AMOUNT" placeholder="Enter Amount" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-12 w-100">
                                <button *ngIf="!IS_CRYPTO" type="submit" (click)="openFloxyPayRequest()" id="__DEPOSIT_OK" class="btn btn-warning w-100 m-r-5">
                                    ADD
                                </button>
                                <!- <button *ngIf="IS_CRYPTO" type="submit" (click)="openIPintPayRequest()" id="__DEPOSIT_OK" class="btn btn-warning w-100 m-r-5">
                                    ADD
                                </button> ->
                                <button type="submit" (click)="CloseSignupPopup()" id="__DEPOSIT_CANCEL" class="btn btn-danger w-100">
                                    Cancel
                                </button>
                                <div class="loader" style="display: none;" id="__DEPOSIT_LOADER"></div>
                            </div>
                        </div>

                    </form>
                    <form *ngIf="__IS_SEND_REQUEST">
                        <div class="form-group row">
                            <label class="col-sm-12 col-form-label">Transaction ID : {{__MERCHANT_TNS_ID}}</label>
                            <div class="col-sm-12">
                                <p>Please note Transaction ID for future use.</p>
                                <p>Please wait... We are processing...</p>
                            </div>
                        </div>

                        <div id="__PAY_LOAD_DATA">
                        </div>

                    </form> -->

                    <!-- AUTO END -->



                    <!-- MANUAL -->

                    <!-- <div class="sc-clNaTc kvbJJARegister">
                        Select payment Method from below.
                    </div>
                    <label class="sc-kasBVs iLkJYG m-b-0">
                        <div class="form-group">
                            <label class="padding20-0">
                                <input (click)="ChangePaymentMethod(2)" type="radio" value="2" name="r1"
                                    class="minimal" checked>
                                PAYTM
                            </label>
                            <label class="padding20-0">
                                <input (click)="ChangePaymentMethod(0)" type="radio" value="0" name="r1"
                                    class="minimal">
                                GOOGLE PAY
                            </label>
                            <label class="padding20-0">
                                <input (click)="ChangePaymentMethod(1)" type="radio" value="1" name="r1"
                                    class="minimal">
                                PHONEPE
                            </label>
                        </div>
                    </label> -->
                    <!-- <div class="sc-clNaTc kvbJJARegister">
                        Pay To : <b style="color: #008fc6;">{{__PAY_TO_TEXT}}</b>
                    </div> -->
                    <!-- <label class="padding20-0">
                        <p>ACCOUNT HOLDER  = <b>S R SOLUTIONS</b></p>
                        <p>𝐁𝐀𝐍𝐊 𝐀𝐂 𝐍𝐔𝐌𝐁𝐄𝐑 = <b>10100659409</b></p>
                        <p>𝐈𝐅𝐒𝐂 𝐂𝐎𝐃𝐄 = <b>IDFB0020111</b></p>
                        <p>BANK NAME : <b>IDFC FIRST BANK</b></p>
                            
                     </label> -->
                    <!-- <label class="padding20-0">
                        <p>ACCOUNT HOLDER = <b>STAR ENTERPRISES</b></p>
                        <p>𝐁𝐀𝐍𝐊 𝐀𝐂 𝐍𝐔𝐌𝐁𝐄𝐑 = <b>22200000014950</b></p>
                        <p>𝐈𝐅𝐒𝐂 𝐂𝐎𝐃𝐄 = <b>FSFB0000001</b></p>
                        <p>BANK NAME : <b>FINCARE</b></p>

                    </label> -->
                    <div class="row color-black">
                        <div class="col-md-4 col-xs-12">
                            <label>
                                <span>Pay From</span>
                                <input type="number" id="__PAY_FROM" [(ngModel)]="__PAY_FROM"
                                    placeholder="Mobile Number" name="__PAY_FROM" spellcheck="false"
                                    class="sc-jeCdPy djMQgxRegister fs-12">
                            </label>
                        </div>
                        <div class="col-md-4 col-xs-12">
                            <label>
                                <span>Amount</span>
                                <input type="number" id="__PAY_AMOUNT" [(ngModel)]="__PAY_AMOUNT"
                                    placeholder="Enter Amount" name="__PAY_AMOUNT" spellcheck="false"
                                    class="sc-jeCdPy djMQgxRegister fs-12" (blur)="checkminimumLimit(__PAY_AMOUNT)">
                            </label>
                        </div>
                        <div class="col-md-4 col-xs-12">
                            <label>
                                <span>UTR Number</span>
                                <input type="text" id="__PAY_TNS_ID" [(ngModel)]="__PAY_TNS_ID" placeholder="UTR Number"
                                    name="__PAY_TNS_ID" spellcheck="false" class="sc-jeCdPy djMQgxRegister fs-12">
                            </label>
                        </div>
                        <div class="col-md-12 col-xs-12 m-t-10 text-center">
                            <button type="submit" id="__PAY_BUTTON" class="btn btn-success"
                                (click)="AddBalanceNew()">Add Balance</button>

                            <button type="submit" id="__PAY_CLOSE" (click)="CloseAddBalancePopup()"
                                class="btn btn-danger m-l-5">Cancel</button>
                        </div>
                    </div>
                    <div class="loader" style="display: none;" id="__PAY_LOADER"></div>
                    <span class="m-t-10">
                        Note:<br>
                        1. Select option for payment from Paytm, Google pay or PhonePe.<br>
                        2. Payment transfer to mention mobile number.<br>
                        3. Enter mobile Number from which payment has been processed in the right box.<br>
                        4. Enter processed amount in right box.<br>
                        5. Enter generated transaction id in last box.<br>
                        6. Submit screenshot with trasaction id on WhatsApp number
                        <a class="sc-erNlkL jBwopw whatsappicon" target="blank" href="https://wa.me/+916363829642">
                            <i class="fa fa-whatsapp" aria-hidden="true"></i>+91 6363829642</a>
                    </span>

                    <!-- MANUAL END -->



                    <!-- APEX -->

                    <!-- <form>
                        <div class="box-body padding0">

                            <div class="SportsMenuDeposit l8Px3ez">
                                <a id="_TABS_0" href="#tab_0" data-toggle="tab" class="hoverPointer">
                                    <img src="./images/HOTSPORTS/payment/Plugin icon - 1.png" alt="deposit"/>
                                </a>
                                <a id="_TABS_1" href="#tab_1" data-toggle="tab" class="hoverPointer">
                                    <img src="./images/HOTSPORTS/payment/Plugin icon - 2.png" alt="deposit"/>
                                </a>
                                <a id="_TABS_2" href="#tab_2" data-toggle="tab" class="hoverPointer">
                                    <img src="./images/HOTSPORTS/payment/Plugin icon - 3.png" alt="deposit"/>
                                </a>
                                <a id="_TABS_3" href="#tab_3" data-toggle="tab" class="hoverPointer">
                                    <img src="./images/HOTSPORTS/payment/Plugin icon - 4.png" alt="deposit"/>
                                </a>
                                <a id="_TABS_4" href="#tab_4" data-toggle="tab" class="hoverPointer">
                                    <img src="./images/HOTSPORTS/payment/Plugin icon - 5.png" alt="deposit"/>
                                </a>
                                <a id="_TABS_5" href="#tab_5" data-toggle="tab" class="hoverPointer">
                                    <img src="./images/HOTSPORTS/payment/Plugin icon - 6.png" alt="deposit"/>
                                </a>
                                <a id="_TABS_5" href="#tab_5" data-toggle="tab" class="hoverPointer">
                                    <img src="./images/HOTSPORTS/payment/Plugin icon - 7.png" alt="deposit"/>
                                </a>
                                <a id="_TABS_5" href="#tab_5" data-toggle="tab" class="hoverPointer">
                                    <img src="./images/HOTSPORTS/payment/Plugin icon - 8.png" alt="deposit"/>
                                </a>
                            </div>
                        </div>

                        <div class="form-group row">

                            <div class="col-md-6 col-xs-12 dis-none">
                                <label style="width: 100%;">
                                    <span>Pay ID</span>
                                    <input type="text" name="PAY_ID" id="PAY_ID" [(ngModel)]="PAY_ID" autocomplete="off" spellcheck="false" class="form-control signuptextfield" readonly="readonly" tabindex="-1">
                                </label>
                            </div>

                            <div class="col-md-6 col-xs-12 dis-none">
                                <label style="width: 100%;">
                                    <span>Order ID</span>
                                    <input type="text" name="ORDER_ID" id="ORDER_ID" [(ngModel)]="ORDER_ID" autocomplete="off" spellcheck="false" class="form-control signuptextfield" readonly="readonly" tabindex="-1">
                                </label>
                            </div>

                            <div class="col-md-12 col-xs-12">
                                <label style="width: 100%;">
                                    <span>Amount</span>
                                    <input type="text" name="AMOUNT" id="AMOUNT" [(ngModel)]="AMOUNT" placeholder="Enter Amount" autocomplete="off" spellcheck="false" class="form-control signuptextfield">
                                </label>
                            </div>

                            <div class="col-md-6 col-xs-12 dis-none">
                                <label style="width: 100%;">
                                    <span>Customer Name</span>
                                    <input type="text" name="CUST_NAME" id="CUST_NAME" [(ngModel)]="CUST_NAME" placeholder="Customer Name" autocomplete="off" spellcheck="false" class="form-control signuptextfield">
                                </label>
                            </div>
                            <div class="col-md-6 col-xs-12 dis-none">
                                <label style="width: 100%;">
                                    <span>Street Address</span>
                                    <input type="text" name="CUST_STREET_ADDRESS1" id="CUST_STREET_ADDRESS1" [(ngModel)]="CUST_STREET_ADDRESS1" placeholder="Enter Street Address" autocomplete="off" spellcheck="false" class="form-control signuptextfield">
                                </label>
                            </div>
                            <div class="col-md-6 col-xs-12 dis-none">
                                <label style="width: 100%;">
                                    <span>Zip Code</span>
                                    <input type="text" name="CUST_ZIP" id="CUST_ZIP" [(ngModel)]="CUST_ZIP" placeholder="Enter Zip Code"  autocomplete="off" spellcheck="false" class="form-control signuptextfield">
                                </label>
                            </div>

                            <div class="col-md-6 col-xs-12 dis-none">
                                <label style="width: 100%;">
                                    <span>Customer Mobile</span>
                                    <input type="text" name="CUST_PHONE" id="CUST_PHONE" [(ngModel)]="CUST_PHONE" placeholder="Mobile Number"  autocomplete="off" spellcheck="false" class="form-control signuptextfield">
                                </label>
                            </div>
                            <div class="col-md-6 col-xs-12 dis-none">
                                <label style="width: 100%;">
                                    <span>Customer Email</span>
                                    <input type="text" name="CUST_EMAIL" id="CUST_EMAIL" [(ngModel)]="CUST_EMAIL" placeholder="Email ID"  autocomplete="off" spellcheck="false" class="form-control signuptextfield">
                                </label>
                            </div>

                            <div class="col-md-6 col-xs-12 hidden">
                                <label style="width: 100%;">
                                    <span>Customer ID</span>
                                    <input type="text" name="CUST_ID" id="CUST_ID" [(ngModel)]="CUST_ID" autocomplete="off" spellcheck="false" class="form-control signuptextfield" readonly="readonly" tabindex="-1">
                                </label>
                            </div>
                            <div class="col-md-6 col-xs-12 hidden">
                                <label style="width: 100%;">
                                    <span>Description</span>
                                    <input type="text" name="PRODUCT_DESC" id="PRODUCT_DESC" [(ngModel)]="PRODUCT_DESC" autocomplete="off" spellcheck="false" class="form-control signuptextfield" readonly="readonly"  tabindex="-1">
                                </label>
                            </div>
                            <div class="col-md-6 col-xs-12 hidden">
                                <label style="width: 100%;">
                                    <span>Currency Code</span>
                                    <input type="text" name="CURRENCY_CODE" id="CURRENCY_CODE" [(ngModel)]="CURRENCY_CODE" autocomplete="off" spellcheck="false" class="form-control signuptextfield" readonly="readonly"  tabindex="-1">
                                </label>
                            </div>
                            <div class="col-md-6 col-xs-12 hidden">
                                <label style="width: 100%;">
                                    <span>Return URL</span>
                                    <input type="text" name="RETURN_URL" id="RETURN_URL" [(ngModel)]="RETURN_URL" autocomplete="off" spellcheck="false" class="form-control signuptextfield" readonly="readonly"  tabindex="-1" value="https://prod.apexpay.co.in/pgui/jsp/response.jsp">
                                </label>
                            </div>
                            <div class="col-md-6 col-xs-12 hidden">
                                <label style="width: 100%;">
                                    <span>Hash</span>
                                    <input type="text" name="HASH" id="hash" [(ngModel)]="HASH" autocomplete="off" spellcheck="false" class="form-control signuptextfield11 fs-12" readonly="readonly"  tabindex="-1">
                                </label>
                            </div>

                            <div class="col-md-12 col-xs-12 m-t-10">
                                <button id="button" class="btn btn-success" type="button" id="__DEPOSIT_OK" (click)="submitFormNew()">ADD</button>

                                <button type="submit" id="__DEPOSIT_CANCEL" (click)="CloseAddBalancePopup()"
                                    class="btn btn-danger m-l-5">Cancel</button>
                            </div>

                        </div>

                    </form> -->

                    <!-- APEX END -->

                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="AddBalancePopupData" role="dialog">

    <div class="modal-dialog">

        <div class="modal-content">
            <div class="modal-header popupColor">
                <button type="button" class="close popupClose" (click)="CloseAddBalancePopup()"
                    data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Add Balance</h4>
            </div>
            <div class="modal-body padding5">

                <form>
                    <!-- <div class="sc-clNaTc kvbJJARegister">
                        Select Option
                    </div>
                    <label class="sc-kasBVs iLkJYG m-b-0">
                        <div class="form-group">
                            <label class="padding20-0" *ngIf="PaymentOn == 'on'">
                                <input id="optPayOption0" (click)="PaymentOption(0)" type="radio" value="0" name="r0"
                                    class="minimal">
                                AUTO
                            </label>
                            <label class="padding20-0" *ngIf="PaymentOn == 'off'">
                                <input id="optPayOption2" (click)="PaymentOption(2)" type="radio" value="2" name="r0"
                                    class="minimal" checked>
                                MANUAL
                            </label>
                        </div>
                    </label> -->
                    <div *ngIf="PaymentOn == 'on'">
                        <!-- <ng-container *ngIf="__SELECTED_OPTION == 0"> -->

                        <!-- <form *ngIf="!__IS_SEND_REQUEST">
                            <div class="box-body" *ngIf="!__IS_URL_LOADED">
                                <div class="box-body padding0">

                                    <div *ngIf="!IS_CRYPTO" class="SportsMenuDeposit">
                                        <a id="_TABS_0" href="#tab_0" data-toggle="tab" class="hoverPointer">
                                            <img src="./images/HOTSPORTS/payment/Plugin icon - 1.png" alt="deposit" />
                                        </a>
                                        <a id="_TABS_1" href="#tab_1" data-toggle="tab" class="hoverPointer">
                                            <img src="./images/HOTSPORTS/payment/Plugin icon - 2.png" alt="deposit" />
                                        </a>
                                        <a id="_TABS_2" href="#tab_2" data-toggle="tab" class="hoverPointer">
                                            <img src="./images/HOTSPORTS/payment/Plugin icon - 3.png" alt="deposit" />
                                        </a>
                                        <a id="_TABS_3" href="#tab_3" data-toggle="tab" class="hoverPointer">
                                            <img src="./images/HOTSPORTS/payment/Plugin icon - 4.png" alt="deposit" />
                                        </a>
                                        <a id="_TABS_4" href="#tab_4" data-toggle="tab" class="hoverPointer">
                                            <img src="./images/HOTSPORTS/payment/Plugin icon - 5.png" alt="deposit" />
                                        </a>
                                        <a id="_TABS_5" href="#tab_5" data-toggle="tab" class="hoverPointer">
                                            <img src="./images/HOTSPORTS/payment/Plugin icon - 8.png" alt="deposit" />
                                        </a>
                                    </div>
                                    <div *ngIf="IS_CRYPTO" class="SportsMenuCrypto">
                                        <a id="_TABS_0" href="#tab_0" data-toggle="tab" class="hoverPointer">
                                            <img src="./images/Currency/bitcoin.png" alt="deposit" />
                                        </a>
                                        <a id="_TABS_1" href="#tab_1" data-toggle="tab" class="hoverPointer">
                                            <img src="./images/Currency/ethereum.png" alt="deposit" />
                                        </a>
                                        <a id="_TABS_2" href="#tab_2" data-toggle="tab" class="hoverPointer">
                                            <img src="./images/Currency/bitcoincash.png" alt="deposit" />
                                        </a>
                                        <a id="_TABS_3" href="#tab_3" data-toggle="tab" class="hoverPointer">
                                            <img src="./images/Currency/dogecoin.png" alt="deposit" />
                                        </a>
                                        <a id="_TABS_4" href="#tab_4" data-toggle="tab" class="hoverPointer">
                                            <img src="./images/Currency/litecoin.png" alt="deposit" />
                                        </a>
                                        <a id="_TABS_5" href="#tab_5" data-toggle="tab" class="hoverPointer">
                                            <img src="./images/Currency/usdt.png" alt="deposit" />
                                        </a>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="__MERCHANT_AMOUNT" class="col-sm-12 col-form-label">Amount</label>
                                    <div class="col-sm-12">
                                        <input type="number" id="__PAY_AMOUNT" [(ngModel)]="__PAY_AMOUNT"
                                            placeholder="Enter Amount" name="__PAY_AMOUNT" spellcheck="false"
                                            class="form-control" (blur)="checkminimumLimit(__PAY_AMOUNT)">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-12 w-100">
                                        <button *ngIf="!IS_CRYPTO" type="submit" (click)="AutoAddBalanceNew()"
                                            id="__DEPOSIT_OK" class="btn btn-warning w-100 m-r-5">
                                            ADD
                                        </button>
                                        <button type="submit" (click)="CloseSignupPopup()" id="__DEPOSIT_CANCEL"
                                            class="btn btn-danger w-100">
                                            Cancel
                                        </button>
                                        <div class="loader" style="display: none;" id="__DEPOSIT_LOADER"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="box-body" *ngIf="__IS_URL_LOADED">
                                <div id="urlLoad"></div>
                            </div>
                        </form>
                        <form *ngIf="__IS_SEND_REQUEST">
                            <div class="form-group row">
                                <label class="col-sm-12 col-form-label">Transaction ID :
                                    {{__MERCHANT_TNS_ID}}</label>
                                <div class="col-sm-12">
                                    <p>Please note Transaction ID for future use.</p>
                                    <p>Please wait... We are processing...</p>
                                </div>
                            </div>

                            <div id="__PAY_LOAD_DATA">
                            </div>

                        </form> -->



                        <!-- </ng-container> -->
                    </div>

                    <ng-container *ngIf="__SELECTED_OPTION == 1">

                    </ng-container>
                    <div *ngIf="PaymentOn == 'off'">
                        <!-- <ng-container *ngIf="__SELECTED_OPTION == 2"> -->
                        <div class="box-body" *ngIf="!__IS_URL_LOADED">
                            <!-- <label class="padding20-0">
                              
                                <p>ACCOUNT HOLDER = <b>RATANI TRADING CO</b></p>
                                <p>𝐁𝐀𝐍𝐊 𝐀𝐂 𝐍𝐔𝐌𝐁𝐄𝐑 = <b>10128511144</b></p>
                                <p>𝐈𝐅𝐒𝐂 𝐂𝐎𝐃𝐄 = <b>IDFB0040336</b></p>
                                <p>BANK NAME : <b>IDFC BANK LTD</b></p>
                                <p>ACCOUNT TYPE : <b>CURRENT A/C</b></p>
                               
                            </label> -->
                            <label class="padding20-0">
                                <!-- <p>ACCOUNT HOLDER = <b>FINNACLE ENTERPRISES</b></p>
                                <p>𝐁𝐀𝐍𝐊 𝐀𝐂 𝐍𝐔𝐌𝐁𝐄𝐑 = <b>2302260146759551</b></p>
                                <p>𝐈𝐅𝐒𝐂 𝐂𝐎𝐃𝐄 = <b>AUBL0002601</b></p>
                                <p>BANK NAME : <b>AU BANK</b></p>
                                <p>UPI : <b>finnacleenterprises@okicici</b></p> -->
                                <p>ACCOUNT HOLDER = <b>{{__P_ACCOUNT_HOLDER}}</b></p>
                                <p>𝐁𝐀𝐍𝐊 𝐀𝐂 𝐍𝐔𝐌𝐁𝐄𝐑 = <b>{{__P_BANK_NUMBER}}</b></p>
                                <p>𝐈𝐅𝐒𝐂 𝐂𝐎𝐃𝐄 = <b>{{__P_IFSC_CODE}}</b></p>
                                <p>BANK NAME : <b>{{__P_BANK_NAME}}</b></p>
                                <p>UPI : <b>{{__P_UPI_ID}}</b></p>
                            </label>
                            <!-- <div class="row color-black">
                                <div class="col-md-12 col-xs-12 text-center">
                                    <img *ngIf="__P_QRCODE_IMAGE" src="{{__P_QRCODE_IMAGE}}" />
                                </div>
                            </div> -->
                            <div class="row color-black">
                                <div class="col-md-4 col-xs-12">
                                    <label>
                                        <span>Pay From</span>
                                        <input type="number" id="__PAY_FROM" [(ngModel)]="__PAY_FROM"
                                            placeholder="Mobile Number" name="__PAY_FROM" spellcheck="false"
                                            class="sc-jeCdPy djMQgxRegister fs-12">
                                    </label>
                                </div>
                                <div class="col-md-4 col-xs-12">
                                    <label>
                                        <span>Amount</span>
                                        <input type="number" id="__PAY_AMOUNT" [(ngModel)]="__PAY_AMOUNT"
                                            placeholder="Enter Amount" name="__PAY_AMOUNT" spellcheck="false"
                                            class="sc-jeCdPy djMQgxRegister fs-12"
                                            (blur)="checkminimumLimit(__PAY_AMOUNT)">
                                    </label>
                                </div>
                                <div class="col-md-4 col-xs-12">
                                    <label>
                                        <span>UTR Number</span>
                                        <input type="text" id="__PAY_TNS_ID" [(ngModel)]="__PAY_TNS_ID"
                                            placeholder="UTR Number" name="__PAY_TNS_ID" spellcheck="false"
                                            class="sc-jeCdPy djMQgxRegister fs-12">
                                    </label>
                                </div>
                                <div class="col-md-12 col-xs-12 m-t-10 text-center">
                                    <button type="submit" id="__PAY_BUTTON" class="btn btn-success"
                                        (click)="AddBalanceNew()">Add Balance</button>

                                    <button type="submit" id="__PAY_CLOSE" (click)="CloseAddBalancePopup()"
                                        class="btn btn-danger m-l-5">Cancel</button>
                                </div>
                            </div>
                            <div class="loader" style="display: none;" id="__PAY_LOADER"></div>
                            <span class="m-t-10">
                                Note:<br>
                                1. Select option for payment from Paytm, Google pay or PhonePe.<br>
                                2. Payment transfer to mention mobile number.<br>
                                3. Enter mobile Number from which payment has been processed in the right box.<br>
                                4. Enter processed amount in right box.<br>
                                5. Enter generated transaction id in last box.<br>
                                6. Submit screenshot with trasaction id on WhatsApp number
                                <a class="sc-erNlkL jBwopw whatsappicon" target="blank"
                                    href="https://wa.me/+916363829642">
                                    <i class="fa fa-whatsapp" aria-hidden="true"></i>+91 6363829642</a>
                            </span>
                        </div>
                        <div class="box-body" *ngIf="__IS_URL_LOADED">
                            <div id="urlLoad"></div>
                        </div>
                        <!-- </ng-container> -->
                    </div>


                </form>
            </div>

        </div>
    </div>


</div>

<div class="modal fade" id="paymentsModel" role="dialog">
    <div class="modal-dialog">

        <div class="modal-content">
            <div class="modal-header popupColor">
                <a class="close popupClose" data-dismiss="modal">&times;</a>
                <h4 class="modal-title">
                    Payments
                </h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        Your request has been processes. Your balance will be updated soon.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="WithdrawBalancePopupData" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header popupColor">
                <button type="button" class="close popupClose" (click)="CloseWithdrawBalancePopup()"
                    data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Withdraw Balance</h4>
            </div>
            <div class="modal-body" style="padding: 5px;">
                <div class="box-body">

                    <!-- MANUAL -->

                    <!-- <div class="sc-clNaTc kvbJJARegister m-b-5">
                        Select payment Method from below.
                    </div>
                    <label class="sc-kasBVs iLkJYG m-b-0">
                        <div class="form-group">
                            <label class="m-r-15">
                                <input (click)="ChangePaymentMethod(2)" type="radio" value="2" name="r1" class="minimal"
                                    checked>
                                PAYTM
                            </label>
                            <label class="m-r-15">
                                <input (click)="ChangePaymentMethod(0)" type="radio" value="0" name="r1"
                                    class="minimal">
                                GOOGLE PAY
                            </label>
                            <label class="m-r-15">
                                <input (click)="ChangePaymentMethod(1)" type="radio" value="1" name="r1"
                                    class="minimal">
                                PHONEPE
                            </label>
                            <label class="m-r-15">
                                <input (click)="ChangePaymentMethod(4)" type="radio" value="4" name="r1"
                                    class="minimal" checked>
                                Bank
                            </label>
                            <label class="m-r-15">
                                <input (click)="ChangePaymentMethod(5)" type="radio" value="4" name="r1"
                                    class="minimal">
                                BHIM UPI
                            </label>
                        </div>
                    </label> -->

                    <!-- <div class="row color-black" *ngIf="!__IS_BANKACCOUNT">
                        <div class="col-md-4 col-xs-12" *ngIf="__SELECTED_METHOD != 5">
                            <label>
                                <span>Pay To</span>
                                <input type="number" id="__PAY_FROM" [(ngModel)]="__PAY_FROM"
                                    placeholder="Mobile Number" name="__PAY_FROM" spellcheck="false"
                                    class="form-control fs-12">
                            </label>
                        </div>
                        <div class="col-md-5 col-xs-12" *ngIf="__SELECTED_METHOD == 5">
                            <label>
                                <span>BHIM UPI</span>
                                <input type="text" id="__PAY_FROM" [(ngModel)]="__PAY_FROM"
                                    placeholder="Enter Bhim Upi ID" name="__PAY_FROM" spellcheck="false"
                                    class="form-control fs-12">
                            </label>
                        </div>
                        <div class="col-md-4 col-xs-12">
                            <label>
                                <span>Amount</span>
                                <input type="number" id="__PAY_AMOUNT" [(ngModel)]="__PAY_AMOUNT"
                                    placeholder="Enter Amount" name="__PAY_AMOUNT" spellcheck="false"
                                    class="form-control fs-12">
                            </label>
                        </div>
                        <div class="col-md-12 col-xs-12 m-t-10">
                            <button type="submit" id="__WITHDRAW_BUTTON" class="btn btn-success" (click)="WithdrawBalanceClick()">Withdraw Balance</button>
                            <!- <button type="submit" id="__WITHDRAW_BUTTON" class="btn btn-success">Withdraw Balance</button> ->

                            <button type="submit" id="__WITHDRAW_CLOSE" (click)="CloseWithdrawBalancePopup()"
                                class="btn btn-danger m-l-5">Cancel</button>
                        </div>
                    </div>
                    <div class="row color-black" *ngIf="__IS_BANKACCOUNT">
                        <div class="col-md-6 col-xs-12">
                            <label>
                                <span>Mobile Number</span>
                                <input type="number" id="__PAY_FROM" [(ngModel)]="__PAY_FROM"
                                    placeholder="Mobile Number" name="__PAY_FROM" spellcheck="false"
                                    class="form-control fs-12">
                            </label>
                        </div>
                        <div class="col-md-6 col-xs-12">
                            <label>
                                <span>Amount</span>
                                <input type="number" id="__PAY_AMOUNT" [(ngModel)]="__PAY_AMOUNT"
                                    placeholder="Enter Amount" name="__PAY_AMOUNT" spellcheck="false"
                                    class="form-control fs-12">
                            </label>
                        </div>
                        <div class="col-md-6 col-xs-12">
                            <label>
                                <span>Bank Name</span>
                                <input type="text" id="__AC_BANK" [(ngModel)]="__AC_BANK" placeholder="Enter Bank Name"
                                    name="__AC_BANK" spellcheck="false" class="form-control fs-12">
                            </label>
                        </div>
                        <div class="col-md-6 col-xs-12">
                            <label>
                                <span>A/c No.</span>
                                <input type="text" id="__AC_NUMBER" [(ngModel)]="__AC_NUMBER"
                                    placeholder="Account Number" name="__AC_NUMBER" spellcheck="false"
                                    class="form-control fs-12">
                            </label>
                        </div>
                        <div class="col-md-6 col-xs-12">
                            <label>
                                <span>A/C Holder Name</span>
                                <input type="text" id="__AC_HOLDER" [(ngModel)]="__AC_HOLDER"
                                    placeholder="Enter A/C Holder Name" name="__AC_HOLDER" spellcheck="false"
                                    class="form-control fs-12">
                            </label>
                        </div>
                        <div class="col-md-6 col-xs-12">
                            <label>
                                <span>IFSC Code</span>
                                <input type="text" id="__AC_IFSC" [(ngModel)]="__AC_IFSC" placeholder="Enter IFSC Code"
                                    name="__AC_IFSC" spellcheck="false" class="form-control fs-12">
                            </label>
                        </div>
                        <!- <div class="col-md-6 col-xs-12">
                            <label>
                                <span>SWIFT Code <span class="fs-10" style="color: #7b7b7b;">(Optional)</span></span>
                                <input type="text" id="__AC_SWIFT" [(ngModel)]="__AC_SWIFT"
                                    placeholder="Enter SWIFT Code" name="__AC_SWIFT" spellcheck="false"
                                    class="form-control fs-12">
                            </label>
                        </div> ->
                        <div class="col-md-12 col-xs-12 m-t-10">
                            <button type="submit" id="__WITHDRAW_BUTTON" class="btn btn-success" (click)="WithdrawBalanceClick()">Withdraw Balance</button>
                            <!- <button type="submit" id="__WITHDRAW_BUTTON" class="btn btn-success">Withdraw Balance</button> ->

                            <button type="submit" id="__WITHDRAW_CLOSE" (click)="CloseWithdrawBalancePopup()"
                                class="btn btn-danger m-l-5">Cancel</button>
                        </div>
                    </div>
                    <div class="loader" style="display: none;" id="__WITHDRAW_LOADER"></div>
                    <span class="m-t-10">
                        Note:<br>
                        <!- 1. Minimum Account Balance to Withdrawal is 1500.<br> ->
                        1. Minimum Withdraw is 100<br>
                        <!- 3. Minimum Balance to Maintain 1000<br> ->
                        <!- 2. Select Paymethod from Paytm, Google pay, PhonePe, Bank or Bhim Upi.<br> ->
                        2. Bonus cannot be withdrawn.<br>
                        3. Enter mobile Number on which payment will be processed in Pay To box.<br>
                        4. Enter processed amount figure in Amount box.<br>
                        <!- 7. Enter generated transaction id in last box.<br> ->
                    </span> -->

                    <!-- MANUAL END -->



                    <!-- APEX -->

                    <div class="sc-clNaTc kvbJJARegister m-b-5">
                        Select payment Method from below.
                    </div>
                    <label class="sc-kasBVs iLkJYG m-b-0">
                        <div class="form-group">
                            <!-- <label class="m-r-15">
                                <input (click)="ChangePaymentMethod(2)" type="radio" value="2" name="r1" class="minimal"
                                    checked>
                                PAYTM
                            </label>
                            <label class="m-r-15">
                                <input (click)="ChangePaymentMethod(0)" type="radio" value="0" name="r1"
                                    class="minimal">
                                GOOGLE PAY
                            </label>
                            <label class="m-r-15">
                                <input (click)="ChangePaymentMethod(1)" type="radio" value="1" name="r1"
                                    class="minimal">
                                PHONEPE
                            </label> -->
                            <label class="m-r-15">
                                <input (click)="ChangePaymentMethod(4)" type="radio" value="4" name="r1" class="minimal"
                                    checked>
                                Bank
                            </label>
                            <!-- <label class="m-r-15">
                                <input (click)="ChangePaymentMethod(5)" type="radio" value="4" name="r1"
                                    class="minimal">
                                BHIM UPI
                            </label> -->
                            <!-- <label class="m-r-15">
                                <input (click)="ChangePaymentMethod(6)" type="radio" value="6" name="r1"
                                    class="minimal">
                                WhatsApp <i class="fa fa fa-whatsapp m-l-5" style="color: #02ae02;"></i> 
                            </label> -->
                        </div>
                    </label>

                    <ng-container *ngIf="__SELECTED_METHOD == 6">
                        <div class="row color-black">
                            <div class="col-md-12 col-xs-12">
                                Send Details on WhatsApp : <a href="https://api.whatsapp.com/send?phone=919297740000"
                                    target="_blank"><i class="fa fa fa-whatsapp m-r-5 m-l-5"
                                        style="color: #02ae02;"></i> <u class="support-us-text">+91 92 97 740
                                        000</u></a>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="__SELECTED_METHOD != 6">
                        <div class="row color-black" *ngIf="!__IS_BANKACCOUNT">
                            <div class="col-md-4 col-xs-12" *ngIf="__SELECTED_METHOD != 5">
                                <label>
                                    <span>Pay To</span>
                                    <input type="number" id="__PAY_FROM" [(ngModel)]="__PAY_FROM"
                                        placeholder="Mobile Number" name="__PAY_FROM" autocomplete="off"
                                        spellcheck="false" class="form-control fs-12">
                                </label>
                            </div>
                            <div class="col-md-5 col-xs-12" *ngIf="__SELECTED_METHOD == 5">
                                <label>
                                    <span>BHIM UPI</span>
                                    <input type="text" id="__PAY_FROM" [(ngModel)]="__PAY_FROM"
                                        placeholder="Enter Bhim Upi ID" name="__PAY_FROM" autocomplete="off"
                                        spellcheck="false" class="form-control fs-12">
                                </label>
                            </div>
                            <div class="col-md-4 col-xs-12">
                                <label>
                                    <span>Amount</span>
                                    <input type="number" id="__PAY_AMOUNT" [(ngModel)]="__PAY_AMOUNT"
                                        placeholder="Enter Amount" name="__PAY_AMOUNT" autocomplete="off"
                                        spellcheck="false" class="form-control fs-12">
                                </label>
                            </div>
                            <div class="col-md-12 col-xs-12 m-t-10">
                                <button type="submit" id="__WITHDRAW_BUTTON" class="btn btn-success"
                                    (click)="WithdrawBalanceApexClick()">Withdraw Balance</button>
                                <button type="submit" id="__WITHDRAW_CLOSE" (click)="CloseWithdrawBalancePopup()"
                                    class="btn btn-danger m-l-5">Cancel</button>
                            </div>
                        </div>
                        <div class="row color-black" *ngIf="__IS_BANKACCOUNT">
                            <div class="col-md-6 col-xs-12">
                                <label>
                                    <span>Mobile Number</span>
                                    <input type="number" id="__PAY_FROM" [(ngModel)]="__PAY_FROM"
                                        placeholder="Mobile Number" name="__PAY_FROM" autocomplete="off"
                                        spellcheck="false" class="form-control fs-12">
                                </label>
                            </div>
                            <div class="col-md-6 col-xs-12">
                                <label>
                                    <span>Amount</span>
                                    <input type="number" id="__PAY_AMOUNT" [(ngModel)]="__PAY_AMOUNT"
                                        placeholder="Enter Amount" name="__PAY_AMOUNT" autocomplete="off"
                                        spellcheck="false" class="form-control fs-12">
                                </label>
                            </div>
                            <div class="col-md-6 col-xs-12">
                                <label>
                                    <span>Bank Name</span>
                                    <input type="text" id="__AC_BANK" [(ngModel)]="__AC_BANK"
                                        placeholder="Enter Bank Name" name="__AC_BANK" autocomplete="off"
                                        spellcheck="false" class="form-control fs-12">
                                </label>
                            </div>
                            <div class="col-md-6 col-xs-12">
                                <label>
                                    <span>A/c No.</span>
                                    <input type="text" id="__AC_NUMBER" [(ngModel)]="__AC_NUMBER"
                                        placeholder="Account Number" name="__AC_NUMBER" autocomplete="off"
                                        spellcheck="false" class="form-control fs-12">
                                </label>
                            </div>
                            <div class="col-md-6 col-xs-12">
                                <label>
                                    <span>A/C Holder Name</span>
                                    <input type="text" id="__AC_HOLDER" [(ngModel)]="__AC_HOLDER"
                                        placeholder="Enter A/C Holder Name" name="__AC_HOLDER" autocomplete="off"
                                        spellcheck="false" class="form-control fs-12">
                                </label>
                            </div>
                            <div class="col-md-6 col-xs-12">
                                <label>
                                    <span>IFSC Code</span>
                                    <input type="text" id="__AC_IFSC" [(ngModel)]="__AC_IFSC"
                                        placeholder="Enter IFSC Code" name="__AC_IFSC" autocomplete="off"
                                        spellcheck="false" class="form-control fs-12">
                                </label>
                            </div>
                            <div class="col-md-6 col-xs-12">
                                <label>
                                    <span>SWIFT Code <span class="fs-10"
                                            style="color: #7b7b7b;">(Optional)</span></span>
                                    <input type="text" id="__AC_SWIFT" [(ngModel)]="__AC_SWIFT"
                                        placeholder="Enter SWIFT Code" name="__AC_SWIFT" autocomplete="off"
                                        spellcheck="false" class="form-control fs-12">
                                </label>
                            </div>
                            <div class="col-md-12 col-xs-12 m-t-10">
                                <button type="submit" id="__WITHDRAW_BUTTON" class="btn btn-success"
                                    (click)="WithdrawBalanceClick()">Withdraw Balance</button>

                                <button type="submit" id="__WITHDRAW_CLOSE" (click)="CloseWithdrawBalancePopup()"
                                    class="btn btn-danger m-l-5">Cancel</button>
                            </div>
                        </div>
                    </ng-container>

                    <div class="loader" style="display: none;" id="__WITHDRAW_LOADER"></div>

                    <!-- APEX END -->
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="buttonStack" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header popupColor">
                <button type="button" class="close popupClose" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Change Button Stack Values</h4>
            </div>
            <div class="modal-body" style="padding: 5px;">
                <div class="box-body">
                    <form name="form" (ngSubmit)="SaveStacks()" #f="ngForm">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th style="width: 10%;">ID</th>
                                    <th style="width: 45%;">BUTTON VALUE</th>
                                    <th style="width: 45%;">BUTTON NAME</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>{{button0}}</td>
                                    <td>
                                        <input type="text" name="button0" [(ngModel)]="button0" class="form-control">
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>{{button1}}</td>
                                    <td>
                                        <input type="text" name="button1" [(ngModel)]="button1" class="form-control">
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>{{button2}}</td>
                                    <td>
                                        <input type="text" name="button2" [(ngModel)]="button2" class="form-control">
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>{{button3}}</td>
                                    <td>
                                        <input type="text" name="button3" [(ngModel)]="button3" class="form-control">
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>{{button4}}</td>
                                    <td>
                                        <input type="text" name="button4" [(ngModel)]="button4" class="form-control">
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>{{button5}}</td>
                                    <td>
                                        <input type="text" name="button5" [(ngModel)]="button5" class="form-control">
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3">
                                        <button class="btn btn-warning">Save Values</button>
                                        <!-- {{ButtonValues}} -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="changePass" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header popupColor">
                <button type="button" class="close popupClose" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Change Password</h4>
            </div>
            <div class="modal-body" style="padding: 5px;">
                <div class="row">
                    <div class="col-sm-12 padding-two">
                        <form role="form" method="post" name="frmAdd">
                            <div class="col-md-6 padding-two">
                                <div class="form-group col-sm-12 padding-zero">
                                    <label>New Password</label>
                                    <input name="password71" id="password71" type="password" [(ngModel)]="NewPassword"
                                        class="form-control input-sm" />
                                </div>
                            </div>
                            <div class="col-md-6 padding-two">
                                <div class="form-group col-sm-12 padding-zero">
                                    <label>Confirm Password</label>
                                    <input name="password82" id="password82" type="password"
                                        [(ngModel)]="ConfirmPassword" class="form-control input-sm" />
                                </div>
                            </div>

                            <div class="col-md-12 padding-two">
                                <div class="form-group col-sm-3 padding-zero">
                                    <input type="button" class="btn btn-sm btn-block bg-blue" value="CHANGE"
                                        (click)="ChangePassword()" />
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="maintenanceModal" role="dialog" style="background-image: url(./images/MainBG1.jpg);">
    <div class="modal-dialog">

        <div class="modal-content">
            <div class="modal-header popupColor">
                <h4 class="modal-title">
                    <img src="./images/HomeLogo.png" width="190px" class="m-r-5"> Maintenance
                </h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12" id="maintenanceModalData">

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="RulesAllInOneModal" role="dialog">
    <div class="modal-dialog">

        <div class="modal-content">
            <div class="modal-header popupColor">
                <a class="close popupClose" data-dismiss="modal">&times;</a>
                <h4 class="modal-title">
                    Rules
                </h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="rulesbox">
                            <h5 class="" style="color: rgb(0, 0, 0);">
                                PLEASE READ OUR RULES BEFORE PLACING BET ** ONCE
                                OUR EXCHANGE GIVE USERNAME AND PASSWORD, IT IS YOUR RESPONSIBILITY TO CHANGE THE
                                PASSWORD.
                            </h5>
                            <h5 class="rulestitle"> 1) SCORECARD RULES: - </h5>
                            <ul class="rulesul">
                                <li>Live scores and other data on this site is sourced from third party feeds and may be
                                    subject to time delays and/or be inaccurate. If you rely on this data to place bets,
                                    you do so at your own risk. Our exchange does not accept responsibility for loss
                                    suffered as a result of reliance on this data.</li>
                                <li>If Any Dispute in Fancy Results,then the Company Decision Will be Final. </li>
                            </ul>
                            <h5 class="rulestitle">2) MATCH ODDS RULES: - </h5>
                            <ul class="rulesul">
                                <li>Cricket: We will follow betfair rusults. </li>
                                <li>Soccer: We will follow betfair rusults.</li>
                                <li>Tennis: We will follow betfair rusults. </li>
                                <li>IF Any Client Found Hedghing Or Doing Manupulation in Any Match Odds, then company
                                    has the right to Void the Bets. </li>
                            </ul>
                            <h5 class="rulestitle">3) BOOK-MAKER MARKET RULES: - </h5>
                            <ul class="rulesul">
                                <li>Due to any reason any team will be getting advantage or disadvantage we are not
                                    concerned. </li>
                                <li>Wrong team selection by our mistake all back and lays bets will be deleted. </li>
                                <li>wrong rate bets also voided.</li>
                            </ul>
                            <h5 class="rulestitle"> 4)FANCY:- - </h5>
                            <ul class="rulesul">
                                <li>Advance fancy:- Advance Session or Player Runs and all Fancy Bets are only valid for
                                    20 Over/50 over full match each side. (Please Note this condition is applied only in
                                    case of Advance Fancy Bets only). </li>
                                <li>All advance fancy bets market will be suspended 60 mins prior to match and will be
                                    settled.</li>
                                <li>Advance session and lambi 1st inning valid only </li>
                                <li>Total match playing over adv :- We Will Settle this Market after Whole Match gets
                                    Completed </li>
                                <li>Criteria :- We Will Count Only Round- Off Over For Both the Innings While Settling
                                    (For Ex :- If 1st Batting team gets all out at 17.3 , 18.4 or 19.5 we Will Count
                                    Such Overs as 17, 18 and 19 Respectively and if Match gets Ended at 17.2 , 18.3 or
                                    19.3 Overs then we will Count that as 17 , 18 and 19 Over Respectively... and this
                                    Will Remain Same For Both the Innings .. </li>
                                <li>In Case Of Rain or if Over gets Reduced then this Market will get Voided.</li>
                                <li>3 wkt or more by bowler in match adv:- </li>
                                <li>We Will Settle this Market after Whole Match gets Completed . </li>
                                <li>In Case Of Rain or if Over Gets Reduced then this Market Will get Voided. </li>
                                <li>Once all session/fancy bets are completed and settled there will be no reversal even
                                    if the Match is Tied or is Abandoned. </li>
                                <li>Under the rules of Session/Fancy Bets if a market gets Suspended for any reason
                                    whatsoever and does not resume then all previous Bets will remain Valid and become
                                    HAAR/JEET bets.</li>
                                <li>Incomplete Session/Fancy Bet will be cancelled but Complete Session will be settled.
                                </li>
                                <li>In the case of Running Match getting Cancelled/ No Result/ Abandoned but the session
                                    is complete it will still be settled. Player runs / fall of wicket/Wicket lost to
                                    ball/How many ball face by player will be also settled at the figures where match
                                    gets stopped due to rain for the inning (D/L) , cancelled , abandoned , no result.
                                </li>
                                <li>If a player gets Retired Hurt and one ball is completed after you place your bets
                                    then all the betting till then is and will remain valid.</li>
                                <li>Should a Technical Glitch in Software occur, we will not be held responsible for any
                                    losses.</li>
                                <li>Should there be a power failure or a problem with the Internet connection at our end
                                    and session/fancy market does not get suspended then our decision on the outcome is
                                    final.</li>
                                <li>All decisions relating to settlement of wrong market being offered will be taken by
                                    management. Management will consider all actual facts and decision taken will be
                                    full in final.</li>
                                <li>Any bets which are deemed of being suspicious, including bets which have been placed
                                    from the stadium or from a source at the stadium maybe void at anytime. The decision
                                    of whether to void the particular bet in question or to void the entire market will
                                    remain at the discretion of Company. The final decision of whether bets are
                                    suspicious will be taken by Company and that decision will be full and final</li>
                                <li>Any sort of cheating bet , any sort of Matching (Passing of funds), Court Siding
                                    (Ghaobaazi on commentary), Sharpening, Commission making is not allowed in Company,
                                    If any company User is caught in any of such act then all the funds belonging that
                                    account would be seized and confiscated. No argument or claim in that context would
                                    be entertained and the decision made by company management will stand as final
                                    authority.</li>
                                <li>If any case wrong rate has been given in fancy ,that particular bets will be
                                    cancelled (Wrong Commentary).</li>
                                <li>In case customer make bets in wrong fancy we are not liable to delete, no changes
                                    will be made and bets will be considered as confirm bet.</li>
                                <li>any query regarding result or rate has to be contacted within 7 days from the
                                    event,query after 7 days from the event will not be considered as valid.</li>
                                <li>The Rules Which we have added Regarding Cheating/Hedghing/manupulation of Odds are
                                    ONLY for the Ones With Wrong Intensions Not For Genuine Clients.</li>
                                <li>Penalty Runs - Any Penalty Runs Awarded in the Match (In Any Running Fancy or ADV
                                    Fancy) Will Not be Counted While Settling in our Exchange.</li>
                                <li>Player Boundaries Fancy:- We will only consider Direct Fours and Sixes hit by BAT.
                                </li>
                                <li>Bowler run session rule:- If Bowler Bowl 1.1 over,then valid( For Bowler 2 over runs
                                    session ) If Bowler Bowl 2.1 over,then valid( For Bowler 3 over runs session ) If
                                    Bowler Bowl 3.1 over,then valid( For Bowler 4 over runs session ) If Bowler Bowl 4.1
                                    over,then valid( For Bowler 5 over runs session ) If Bowler Bowl 9.1 over,then
                                    valid( For Bowler 10 over runs session)</li>
                                <li>Any cricket event which is being held behind closed doors in that if any players
                                    found to be taking advantage of groundline in fancy bets in such cases bets can be
                                    void after the market ends . Company decision to be final.</li>
                                <li>Company reserves the right to suspend/void any id/bets if the same is found to be
                                    illegitimate. For example incase of vpn/robot-use/multiple entry from same IP/
                                    multiple bets at same time (Punching) and others. Note : only winning bets will be
                                    voided, For example: If we find such entries (above mentioned) from any id and their
                                    bets are (200000 lay in a 6 over session for the rate 40 and 200000 back for the
                                    rate of 48) and the actual score is 38, bets of 40 lay will be voided and the bets
                                    for 48 back will be considered valid.</li>
                                <li>Odd-even rules:- Incompleted games will be deleted. Over reduced or abandoned all
                                    bets will be deleted.</li>
                                <li>In any circumstances company decision will be final.</li>
                                <li>Our Exchange Is Trusted and Fair. </li>
                            </ul>
                            <h5 class="rulestitle">5) IPL 2021 RULES:- - </h5>
                            <ul class="rulesul">
                                <li>If Over Reduced in Match, we will not count the actual scores of the Over Reduced
                                    Matches instead we will count the Market’s Average Scores.</li>
                                <li>If Match is Abandoned, we will not count the actual scores of the Abandoned Matches
                                    instead we will count the Market’s Average Scores. NOTE:-These rules are for the
                                    following Markets of ENTIRE IPL 2021 (60 Matches):- </li>
                                <li>Total Fours: - Average 26 Fours will be given if the match is abandoned or over
                                    reduced.</li>
                                <li>Total Sixes: - Average 13 Sixes will be given if the match is abandoned or over
                                    reduced. </li>
                                <li>Total Wide: - Average 8 Wide’s will be given if the match is abandoned or over
                                    reduced.</li>
                                <li>Total Extras: - Average 14 Extras will be given if the match is abandoned or over
                                    reduced.</li>
                                <li>Total No Ball: - Average 1 No Ball will be given if the match is abandoned or over
                                    reduced.</li>
                                <li>Total Duck: - Average 1 Duck will be given if the match is abandoned or over
                                    reduced.</li>
                                <li>Total Fifties: - Average 2 Fifties will be given if the match is abandoned or over
                                    reduced.</li>
                                <li>Total Century: -Average 0 Century will be given if the match is abandoned or over
                                    reduced. </li>
                                <li>Total Run Out: - Average 1 Run Out will be given if the match is abandoned or over
                                    reduced. </li>
                                <li>Total Caught out: - Average 8 Caught Out will be given if the match is abandoned or
                                    over reduced.</li>
                                <li>Total Maiden Over: - Average 0 Maiden Over will be given if the match is abandoned
                                    or over reduced. </li>
                                <li>Total LBW: - Average 1 LBW will be given if the match is abandoned or over reduced.
                                </li>
                                <li>Total Bowled: - Average 2 Bowled will be given if the match is abandoned or over
                                    reduced.</li>
                                <li>In case IPL Matches Gets Stopped or Interrupted Due to "COVID-19" or Any "ACT OF
                                    GOD" Reasons, Under 45 Matches then All IPL 2021 Tournament Fancy Markets will be
                                    Voided, But if 45 or Above Matches are Being Played then we will Settle all the
                                    Fancy Markets and Also If there are NON Played Matches Above 45 then we will settle
                                    the markets as per our Markets Average.</li>
                                <li>If IPL 2021 gets stopped due to “Covid-19” or Any “Act of God” reason then We will
                                    wait till 45 days from the day IPL gets stopped , and if IPL Matches gets Resumed
                                    before 45th day then we will continue as Usual Score Countation but if IPL 2021 does
                                    not resumes until 45th day then we will settle the markets according to our above
                                    mentioned Markets Averages and In case of Void, We will Void the under 45 matches on
                                    45th day from the day IPL 2021 stopped.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="RulesAllForNewModal" role="dialog">
    <div class="modal-dialog">

        <div class="modal-content">
            <div class="modal-header popupColor">
                <a class="close popupClose" data-dismiss="modal">&times;</a>
                <h4 class="modal-title">
                    Terms and Conditions
                </h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="rulesbox">
                            <h5 class="" style="color: rgb(0, 0, 0);">
                                PLEASE GO THROUGH THE BELOW GIVEN RULES:
                            </h5>
                            <h5 class="rulestitle"> 1) SIGNUP FREE COINS: - </h5>
                            <ul class="rulesul">
                                <!-- <li>Each customer will get Rs 100 FREE COINS on Signup. </li> -->
                                <!-- <li>The same can be used only to play. Withdrawal is not allowed on FREE COIN WINNINGS. </li> -->
                            </ul>

                            <ul class="rulesul">
                                <li>FREE COINS cannot be withdrawn for CASH. </li>
                            </ul>

                            <ul class="rulesul">
                                <li>In case of misuse of FREE BALANCE SCHEME by taking multiple IDs, no withdrawal will
                                    be allowed or entertained. </li>
                            </ul>

                            <h5 class="rulestitle"> DEPOSIT BONUS: - </h5>
                            <ul class="rulesul">
                                <!-- <li>Clients will get 100% BONUS on their FIRST DEPOSIT. </li> -->
                                <!-- <li>Mamixum Bonus per client is Rs 1000. </li> -->
                            </ul>

                            <ul class="rulesul">
                                <!-- <li>FREE BONUS cannot be withdrawn for CASH.</li> -->
                                <li>In case of misuse of FREE BALANCE SCHEME by taking multiple IDs, all the winnings
                                    will be voided. </li>
                                <li>In case of repeat offenders, all money in the account will be forfeited. </li>
                                <li>THE DECISION OF THE COMPANY WILL BE FINAL. </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="_ENABLE_ACCESS" role="dialog">
    <div class="modal-dialog">

        <div class="modal-content">
            <div class="modal-header popupColor">
                <button type="button" class="close popupClose" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Enable Access</h4>
            </div>
            <div class="modal-body" style="padding: 5px;">
                <div class="row">
                    <div class="col-sm-12 padding-two">
                        <form role="form" method="post" name="frmAdd">
                            <div class="col-md-6 padding-two">
                                <div class="form-group col-sm-12 padding-zero">
                                    <label>Profile Password</label>
                                    <input name="_ACCESS_PASSWORD" id="_ACCESS_PASSWORD" type="password"
                                        [(ngModel)]="_ACCESS_PASSWORD" class="form-control input-sm" />
                                </div>
                            </div>

                            <div class="col-md-12 padding-two">
                                <div class="form-group col-sm-3 padding-zero">
                                    <input type="button" class="btn btn-warning" value="Validate"
                                        (click)="EnableAdminAccess()" />
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="autoUpdateModel" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-bg text-white p-3 px-md-5 border-white">
            <div class="modal-bg-color"></div>
            <div class="modal-header border-0 pos-relative">
                <!-- <button type="button" class="close popupClose" style="color: rgb(255, 255, 255)!important;"
                    data-dismiss="modal">&times;</button> -->
                <h4 class="modal-title">New Update</h4>
            </div>
            <div class="modal-body fs-12">
                <div class="row">
                    <div class="col-sm-12 padding-two">

                        <p>A New version of site is available. Load New Version.</p>

                        <div class="form-group row m-t-20 m-b-0">
                            <div class="col-sm-12 w-100">
                                <button type="submit" class="btn btn-primary btnLogin" (click)="reloadForUpdate()">
                                    OK
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>