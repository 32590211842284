import { Injectable } from '@angular/core';
import { HostListener } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class CheckmobileService
{

  isMobileView = false;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?)
  {
    // this.screenHeight = window.innerHeight;
    // this.screenWidth = window.innerWidth;
    // console.log(window.innerWidth, window.innerHeight);

    if(window.innerWidth < 768)
    {
      this.isMobileView = true; 
    }
    else
    {
      this.isMobileView = false; 
    }
  }
  constructor()
  {
    this.getScreenSize();
  }
}
