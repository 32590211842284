import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonlistService } from '../services/commonlist.service';
import { Matchlisthome } from '../classes/matchlisthome';
import { CryptojsService } from '../services/cryptojs.service';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MatchtableService {
  Url: string;
  CommonHeader: any;
  SessionUser: any;

  __SELECTED_SPORTSID = -1;
  CurrentRequestList = [];
  CurrentRequestListSports = [];
  MatchesList = false;

  FavouriteMatchList: Array<Matchlisthome> = [];

  CricketMatchList: Array<Matchlisthome> = [];
  isCricketFancy = 0;
  isCricketLine = 0;

  TennisMatchList: Array<Matchlisthome> = [];
  isTennisFancy = 0;
  isTennisLine = 0;

  SoccerMatchList: Array<Matchlisthome> = [];
  isSoccerFancy = 0;
  isSoccerLine = 0;

  HorseMatchList: Array<Matchlisthome> = [];
  isHorseFancy = 0;
  isHorseLine = 0;

  InPlayMatchList: Array<Matchlisthome> = [];
  isInPlayFancy = 0;
  isInPlayLine = 0;

  CurrentReqList: any;

  isMainHomeRefresh = 0;
  isMainHomeRefreshMobile = 0;
  isMatchViewRefresh = 0;
  isMatchViewRefreshMobile = 0;
  isSideBarRefresh = 0;
  isSideBarRefreshMobile = 0;

  checkInPlayTimer: any;

  private onInPlatMatches$: Subject<any>;
  private onResetMatchList$: Subject<any>;

  private onReqList$: Subject<any>;

  private onLotusCasinoClick$: Subject<any>;

  CurrentMatchList: any;

  constructor(private $LYDRJ: CryptojsService, private http: HttpClient, private $const: CommonlistService) {
    this.onInPlatMatches$ = new Subject<any>();
    this.onResetMatchList$ = new Subject<any>();

    this.onLotusCasinoClick$ = new Subject<any>();

    this.onReqList$ = new Subject<any>();
  }

  checkNewMatch(__MARKETID, __DATA) {
    var Temp = 0;
    if (__DATA.length > 0) {
      let Check = __DATA.filter(({ stMarketID }) => {
        return stMarketID == __MARKETID
      })
      if (Check.length > 0)
        Temp = 1;
    }
    return Temp;
  }

  checkMaster(__MARKETID) {
    var LocalData: any;
    if (this.$const.__SITE_ID == 0) LocalData = JSON.parse(this.$LYDRJ.decryptData(sessionStorage.getItem("CUStakes")));
    /*else */LocalData = this.CurrentMatchList;

    var Temp = 0;
    if (LocalData.length > 0) {
      let Check = LocalData.filter(({ stMarketID }) => {
        return stMarketID == __MARKETID
      })
      if (Check.length > 0)
        Temp = 1;
    }
    return Temp;
  }

  checkInPlayMatchFunction() {
    this.checkInPlayTimer = setInterval(() => {
      let i = 0;
      if (this.CurrentMatchList === null)
        return false;

      var LocalData: any;
      if (this.$const.__SITE_ID == 0) LocalData = JSON.parse(this.$LYDRJ.decryptData(sessionStorage.getItem("CUStakes")));
      /*else */LocalData = this.CurrentMatchList;

      let checkStatus = false;
      LocalData = LocalData.filter(function (el) {
        return el != null;
      });

      for (let result of LocalData) {
        if (LocalData[i].btMarketStatus != 1 && LocalData[i].stMarketID.split(".")[0] == "1") {
          var now = new Date();
          var MatchDate;

          if (LocalData[i].stSportsID == "7" || LocalData[i].stSportsID == "4339")
            MatchDate = new Date(LocalData[i].dtStartDate).setMinutes(new Date(LocalData[i].dtStartDate).getMinutes() - 5);
          else
            MatchDate = new Date(LocalData[i].dtStartDate);

          let checkTemp = now;
          let checkTemp1 = MatchDate;

          if (LocalData.length > 0 && checkTemp1 <= checkTemp) {
            LocalData[i].btMarketStatus = 1;
            if (this.$const.__SITE_ID == 0) sessionStorage.setItem("CUStakes", this.$LYDRJ.encryptData(JSON.stringify(LocalData)));
            /*else */this.CurrentMatchList = LocalData;
            this.addNewMatch(LocalData[i].stSportsID);
            checkStatus = true;
          }
        }
        i++;
      }
      if (checkStatus) {
        this.setInPlayMatches();
        this.isMainHomeRefresh = 1;
        this.isMainHomeRefreshMobile = 1;
        this.isMatchViewRefresh = 1;
        this.isMatchViewRefreshMobile = 1;
        this.isSideBarRefresh = 1;
        this.isSideBarRefreshMobile = 1;
        this.onInPlatMatches$.next(checkStatus);
      }
    }, 15000);
  }

  public getOnInPlayMatches(): Observable<any> {
    return this.onInPlatMatches$;
  }

  setInPlayMatches() {
    if (this.$const.__SITE_ID == 0) this.CurrentMatchList = JSON.parse(this.$LYDRJ.decryptData(sessionStorage.getItem("CUStakes")));
    // this.InPlayMatchList = this.searchInPlay();
  }

  destroyInPlayMatchFunction() {
    if (this.checkInPlayTimer)
      clearInterval(this.checkInPlayTimer);
  }

  searchInPlay() {
    // if (this.CurrentMatchList == null)
    //   return false;
    // return this.CurrentMatchList.filter(({ stMarketID, stSportsID, btMarketStatus, stMarketName }) => {
    //   return stMarketID != null && stMarketID.split(".")[0] == "1" && stSportsID != null && btMarketStatus == "1" && (((stSportsID != "7" && stSportsID != "4339") && (stMarketName == 'Winner' || stMarketName == 'Match Odds')) || (stSportsID == "7" || stSportsID == "4339"))
    // })
  }

  public getOnRequestList(): Observable<any> {
    return this.onReqList$;
  }

  getRequestList(__LIST) {
    let _MID = "";
    setTimeout(() => {
      this.CurrentReqList = _MID;
      return this.onReqList$.next(_MID);
    }, 500);
  }

  filterMasterList(checkMrktID) {
    let data: any;
    if (this.$const.__SITE_ID == 0) data = JSON.parse(this.$LYDRJ.decryptData(sessionStorage.getItem("CUStakes")));
    /*else */data = this.CurrentMatchList;
    if (data == null)
      return [];

    if (data.length == 0)
      return [];

    return data.filter(({ stMarketID }) => {
      return stMarketID == checkMrktID
    })
  }

  addNewMatch(data) {
    // console.log("addNewMatch : " + data)
    if (this.$const.__SITE_ID == 0) this.CurrentMatchList = JSON.parse(this.$LYDRJ.decryptData(sessionStorage.getItem("CUStakes")));
    this.CurrentMatchList = this.CurrentMatchList.filter(function (el) {
      return el != null;
    });

    if (this.$const.__SITE_ID == 0) sessionStorage.setItem("CUStakes", this.$LYDRJ.encryptData(JSON.stringify(this.CurrentMatchList)));
    switch (data) {
      case "4":
        this.CricketMatchList = [];
        this.isCricketFancy = 0;
        this.isCricketLine = 0;
        this.CricketMatchList = this.search("4");
        this.setInPlayMatches();
        break;

      case "2":
        this.TennisMatchList = [];
        this.TennisMatchList = this.search("2");
        this.setInPlayMatches();
        break;

      case "1":
        this.SoccerMatchList = [];
        this.SoccerMatchList = this.search("1");
        this.setInPlayMatches();
        break;

      case "7":
        this.HorseMatchList = [];
        this.HorseMatchList = this.search("7");
        this.setInPlayMatches();
        break;
    }
  }

  resetFavourites() {
    if (sessionStorage.getItem("favouriteMatches") === null)
      return false;

    let Matches = JSON.parse(this.$LYDRJ.decryptData(sessionStorage.getItem("favouriteMatches")));

    let i = 0;
    for (let data of Matches) {
      let __CHECK = this.filterMasterList(data.stMarketID);

      if (__CHECK.length == 0) {
        this.deleteExtraMatches(Matches, i);
        break;
      }
      i++;
    }
  }

  deleteExtraMatches(Matches, __INDEX) {
    if (Matches[__INDEX] != undefined)
      delete Matches[__INDEX];

    var filtered = Matches.filter(function (el) {
      return el != null;
    });

    sessionStorage.setItem("favouriteMatches", this.$LYDRJ.encryptData(JSON.stringify(filtered)));
    this.resetFavourites();
  }

  resetMatchList() {
    let i = 0;

    var LocalData: any;
    if (this.$const.__SITE_ID == 0) LocalData = JSON.parse(this.$LYDRJ.decryptData(sessionStorage.getItem("CUStakes")));
    /*else */LocalData = this.CurrentMatchList;
    LocalData = LocalData.filter(function (el) {
      return el != null;
    });

    let checkStatus = false;
    for (let result of LocalData) {
      if (LocalData[i].btMarketStatus != 1 && LocalData[i].stMarketID.split(".")[0] == "1") {
        var now = new Date();
        var MatchDate;

        if (LocalData[i].stSportsID == "7" || LocalData[i].stSportsID == "4339")
          MatchDate = new Date(LocalData[i].dtStartDate).setMinutes(new Date(LocalData[i].dtStartDate).getMinutes() - 10);
        else
          MatchDate = new Date(LocalData[i].dtStartDate);

        let checkTemp = now;
        let checkTemp1 = MatchDate;

        if (LocalData.length > 0 && checkTemp1 <= checkTemp) {
          LocalData[i].btMarketStatus = 1;
          if (this.$const.__SITE_ID == 0) sessionStorage.setItem("CUStakes", this.$LYDRJ.encryptData(JSON.stringify(LocalData)));
          /*else */this.CurrentMatchList = LocalData;
          this.addNewMatch(LocalData[i].stSportsID);
          checkStatus = true;
        }
      }
      i++;
    }
    if (checkStatus) {
      this.setInPlayMatches();
      this.isMainHomeRefresh = 1;
      this.isMainHomeRefreshMobile = 1;
      this.isMatchViewRefresh = 1;
      this.isMatchViewRefreshMobile = 1;
      this.isSideBarRefresh = 1;
      this.isSideBarRefreshMobile = 1;
      this.onInPlatMatches$.next(checkStatus);
    }
    this.setMatchList();
    setTimeout(() => {
      this.onResetMatchList$.next(true);
    }, 2000);
  }

  public getOnResetMatchList(): Observable<any> {
    return this.onResetMatchList$;
  }

  setMatchList() {
    if (this.$const.__SITE_ID == 0) this.CurrentMatchList = (sessionStorage.getItem("CUStakes") !== null ? JSON.parse(this.$LYDRJ.decryptData(sessionStorage.getItem("CUStakes"))) : sessionStorage.getItem("CUStakes"));

    if (this.CurrentMatchList == null)
      return false;
    this.CurrentMatchList = this.CurrentMatchList.filter(function (el) {
      return el != null;
    });

    if (this.$const.__SITE_ID == 0) sessionStorage.setItem("CUStakes", this.$LYDRJ.encryptData(JSON.stringify(this.CurrentMatchList)));

    // this.InPlayMatchList = this.searchInPlay();

    this.CricketMatchList = this.search("4");

    this.TennisMatchList = this.search("2");

    this.SoccerMatchList = this.search("1");

    this.HorseMatchList = this.search("7");

    if (sessionStorage.getItem("favouriteMatches") != null) {
      this.resetFavourites();
      this.FavouriteMatchList = JSON.parse(this.$LYDRJ.decryptData(sessionStorage.getItem("favouriteMatches")));
    }
  }

  search(stSportsID12) {
    if (this.CurrentMatchList == null)
      return false;
    if (stSportsID12 != "7" && stSportsID12 != "4339") {
      return this.CurrentMatchList.filter(({ stSportsID, stMarketName }) => {
        return stSportsID != null && stSportsID == stSportsID12 && (stMarketName == 'Winner' || stMarketName == 'Match Odds')
      })
    }
    else {
      return this.CurrentMatchList.filter(({ stSportsID }) => {
        return stSportsID != null && stSportsID == stSportsID12
      })
    }
  }

  searchNew(stSportsID12) {
    if (this.CurrentMatchList == null)
      return false;

    return this.CurrentMatchList.filter(({ stSportsID }) => {
      return stSportsID != null && stSportsID == stSportsID12
    })

  }

  public OnLotusCasinoCalculate(__STATUS) {
    this.onLotusCasinoClick$.next(__STATUS);
  }

  public getOnLotusCasinoCalculate(): Observable<any> {
    return this.onLotusCasinoClick$;
  }

  GetLotusPL(Per) {
    var SessionUser = JSON.parse(this.$LYDRJ.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      "UID": SessionUser.UserId,
      "Per": Per
    }

    let __HASH = this.$LYDRJ.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.$const.__LOTUS_PL_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.$const.httpOptions);
  }

  getAdminMessageFun(__EVENTID) {
    var SessionUser = JSON.parse(this.$LYDRJ.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      "MID": __EVENTID
    }

    let __HASH = this.$LYDRJ.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.$const.getAdminMessageURL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.$const.httpOptions);
  }

  getMatchStackRangeAllInOne(__EID) {
    var SessionUser = JSON.parse(this.$LYDRJ.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      "UID": SessionUser.UserId,
      "EID": __EID
    }
    let __HASH = this.$LYDRJ.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.$const.MatchStackRangeAllInOne_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.$const.httpOptions);
  }

  getMatchStackRange(model: any) {
    var SessionUser = JSON.parse(this.$LYDRJ.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      "UID": SessionUser.UserId,
      "MID": model
    }
    let __HASH = this.$LYDRJ.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.$const.MatchStackRange + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.$const.httpOptions);
  }

  getStackRangeNew(MID, SID) {
    var SessionUser = JSON.parse(this.$LYDRJ.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      "UID": SessionUser.UserId,
      "MID": MID,
      "adminId": SessionUser.ParentId,
      "sid": SID
    }
    let __HASH = this.$LYDRJ.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.$const.__STACK_RANGE_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.$const.httpOptions);
  }

  getStackRangeEvent(EID) {
    var SessionUser = JSON.parse(this.$LYDRJ.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      "UID": SessionUser.UserId,
      "EID": EID,
      "inAdminID": SessionUser.ParentId
    }
    // console.log(__POSTDATA)
    let __HASH = this.$LYDRJ.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.$const.__STACK_RANGE_EVENT_URL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.$const.httpOptions);
  }

  getScoreLiveMatch(EventID) {
    var SessionUser = JSON.parse(this.$LYDRJ.decryptData(sessionStorage.user));
    const token = SessionUser.Message;

    let __POSTDATA =
    {
      'AUT': token.split('_')[1],
      "eventID": EventID
    }
    let __HASH = this.$LYDRJ.encryptOrderFunction(JSON.stringify(__POSTDATA));

    this.Url = this.$const.ScoreLiveURL + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.$const.httpOptions);
  }
}
