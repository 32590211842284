<header class="main-header">
    <!-- Logo -->

    <nav class="navbar navbar-static-top">

        <div class="row">

            <div class="col-xs-1 m-r-0 p-r-0">
                <div class="sidebar-toggle padding5" data-toggle="push-menu" role="button">
                    <span class="sr-only">Toggle navigation</span>
                </div>
            </div>
            <div class="col-xs-4 m-r-0 p-r-0">
                <a (click)="onHomeClick()" class="padding0">
                    <img src="images/HOTSPORTS/header-logo.png" class="img-responsive" width="116px" alt="" />
                </a>
            </div>
            <div class="col-xs-7 m-l-0 p-l-0">

                <div class="navbar-custom-menu">
                    <ul class="nav navbar-nav">
                        <li class="dropdown notifications-menu">
                            <a style="padding: 5px 0px;" (click)="getCurrentBalance()" class="dropdown-toggle"
                                data-toggle="dropdown">
                                <div class="bgBalance fs-8">
                                    Bal : {{CurrentMainBalance | number : '1.2-2'}}
                                </div>
                                <div class="bgExposure hoverPointer fs-8" (click)="onDynamicRouterClick('/mybets')">
                                    Exp. : {{CurrentExplosure | number : '1.2-2'}}
                                </div>
                            </a>
                        </li>
                        <li class="dropdown user user-menu notifications-menu">
                            <a href="#" style="padding: 5px;" class="dropdown-toggle" data-toggle="dropdown">
                                <!-- <i class="fa fa-user"></i> -->
                                <div class="bgBalance fs-8">
                                    {{UserName_DB}} <i class="fa fa-user"></i>
                                </div>
                                <div class="bgExposure hoverPointer fs-8">
                                    {{UserType_Check}}
                                    <i class="fa fa-chevron-down"></i>
                                </div>
                            </a>
                            <ul class="dropdown-menu" style="width: 210px;">

                                <li class="user-body padding0">
                                    <ul class="menu max-h-full fs-12">
                                        <li>
                                            <a class="padding8">
                                                <i class="fa fa-user"></i>
                                                <b>{{UserName_DB}}</b><span>({{UserType_Check}})</span>
                                            </a>
                                        </li>

                                        <!-- <li *ngIf="__ISADDBALANCE == 1 && UserType == 3">
                                            <a  *ngIf="__ISADDBALANCE == 1" data-backdrop="static" data-keyboard="false" href="#AddBalancePopupData" data-toggle="modal" data-target="#AddBalancePopupData">
                                                <i class="fa fa-money"></i>
                                                Deposit
                                            </a>
                                        </li>

                                        <li *ngIf="__ISADDBALANCE == 1 && UserType == 3">
                                            <a *ngIf="__ISADDBALANCE == 1" data-backdrop="static" data-keyboard="false" href="#WithdrawBalancePopupData" data-toggle="modal" data-target="#WithdrawBalancePopupData">
                                                <i class="fa fa-money"></i>
                                                Withdrawal
                                            </a>
                                        </li> -->

                                        <li *ngIf="__ISADDBALANCE == 1 && UserType == 3">
                                            <a (click)="onTransactionClick()" class="padding8">
                                                <i class="fa fa-file-o"></i>
                                                Transactions
                                            </a>
                                        </li>
                                        <li>
                                            <a (click)="onDynamicRouterClick('/mybets')" class="padding8">
                                                <i class="fa fa-file"></i>
                                                Trade Book
                                            </a>
                                        </li>

                                        <li>
                                            <a (click)="onDynamicRouterClick('/report/m/acStatement')" class="padding8">
                                                <i class="fa fa-file-text"></i>
                                                Account Statement
                                            </a>
                                        </li>

                                        <!-- <li>
                                            <a (click)="onDynamicRouterClick('/report/simple/acStatement')" class="padding8">
                                                <i class="fa fa-file-text"></i>
                                                A/C Statement (Simple)
                                            </a>
                                        </li> -->

                                        <li>
                                            <a (click)="onDynamicRouterClick('/report/ezugi/acStatement')"
                                                class="padding8">
                                                <i class="fa fa-file-text-o"></i>
                                                Casino Statement
                                            </a>
                                        </li>
                                        <li>
                                            <a (click)="onDynamicRouterClick('/report/ezugi/acStatement-old')"
                                                class="padding8">
                                                <i class="fa fa-file-text-o"></i>
                                                Old Casino Statement
                                            </a>
                                        </li>

                                        <li>
                                            <a (click)="onDynamicRouterClick('/report/m/pl')" class="padding8">
                                                <i class="fa fa-line-chart"></i>
                                                Profit Loss
                                            </a>
                                        </li>

                                        <li *ngIf="UserType == 3">
                                            <a href="#buttonStack" (click)="getButtonStack()" class="padding8"
                                                data-toggle="modal" data-target="#buttonStack">
                                                <i class="fa fa-stack-overflow"></i>
                                                Stack Values
                                            </a>
                                        </li>

                                        <li *ngIf="(IS_ACCESS && UserType == 4) || UserType != 4">
                                            <a href="#changePass" data-toggle="modal" class="padding8"
                                                data-target="#changePass">
                                                <i class="fa fa-edit"></i>
                                                Change Password
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#RulesAllForNewModal" data-toggle="modal" class="padding8"
                                                data-target="#RulesAllForNewModal">
                                                <i class="fa fa-file-text"></i>
                                                Rules
                                            </a>
                                        </li>
                                    </ul>
                                </li>

                                <!-- <li class="user-body padding0">
                                    <ul class="menu max-h-full fs-12">
                                        <li>
                                            <a>
                                                <i class="fa fa-user"></i>
                                                Support :
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://api.whatsapp.com/send?phone=918920006513" target="_blank"><i class="fa fa fa-whatsapp m-r-5 m-l-5 color-white"></i> <u class="support-us-text">+91 89 20 006 513</u></a>
                                        </li>
                                        <li>
                                            <a href="https://api.whatsapp.com/send?phone=917048936253" target="_blank"><i class="fa fa fa-whatsapp m-r-5 m-l-5 color-white"></i> <u class="support-us-text">+91 70 48 936 253</u></a>
                                        </li>
                                        
                                    </ul>
                                </li> -->

                                <li class="user-footer">
                                    <div class="pull-right">
                                        <form (ngSubmit)="Logout()" #f="ngForm">
                                            <button style="padding: 2px 8px; border-radius: 10px;"
                                                class="btn btn-danger btn-flat fs-12">
                                                <i class="fa fa-power-off"></i> Logout</button>
                                        </form>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- <marquee scrollamount="4" style="position: absolute; font-size: 10px; color: white;" id="MarqueeMessage">{{MarqueeMessage}}</marquee> -->

    </nav>

</header>

<div class="modal fade" id="buttonStack" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header popupColor">
                <button type="button" class="close popupClose" data-dismiss="modal">&times;</button>
                <h4 class="modal-title fs-12">Change Button Stack Values</h4>
            </div>
            <div class="modal-body" style="padding: 5px;">
                <div class="box-body">
                    <form name="form" (ngSubmit)="SaveStacks()" #f="ngForm">
                        <table class="table table-bordered">
                            <thead>
                                <tr class="fs-12">
                                    <th style="width: 10%;">ID</th>
                                    <th style="width: 45%;">BUTTON VALUE</th>
                                    <th style="width: 45%;">BUTTON NAME</th>
                                </tr>
                            </thead>
                            <tbody class="fs-12">
                                <tr>
                                    <td>1</td>
                                    <td>{{button0}}</td>
                                    <td>
                                        <input type="text" name="button0" [(ngModel)]="button0"
                                            class="form-control fs-12">
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>{{button1}}</td>
                                    <td>
                                        <input type="text" name="button1" [(ngModel)]="button1"
                                            class="form-control fs-12">
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>{{button2}}</td>
                                    <td>
                                        <input type="text" name="button2" [(ngModel)]="button2"
                                            class="form-control fs-12">
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>{{button3}}</td>
                                    <td>
                                        <input type="text" name="button3" [(ngModel)]="button3"
                                            class="form-control fs-12">
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>{{button4}}</td>
                                    <td>
                                        <input type="text" name="button4" [(ngModel)]="button4"
                                            class="form-control fs-12">
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>{{button5}}</td>
                                    <td>
                                        <input type="text" name="button5" [(ngModel)]="button5"
                                            class="form-control fs-12">
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3">
                                        <button class="btn btn-warning fs-12">Save Values</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="changePass" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header popupColor">
                <button type="button" class="close popupClose" data-dismiss="modal">&times;</button>
                <h4 class="modal-title fs-12">Change Password</h4>
            </div>
            <div class="modal-body" style="padding: 5px;">
                <div class="row">
                    <div class="col-sm-12 padding-two">
                        <form role="form" method="post" name="frmAdd">
                            <div class="col-md-6 padding-two">
                                <div class="form-group col-sm-12 padding-zero fs-12">
                                    <label>New Password</label>
                                    <input name="password81" id="password81" type="password" [(ngModel)]="NewPassword"
                                        class="form-control input-sm fs-12" />
                                </div>
                            </div>
                            <div class="col-md-6 padding-two">
                                <div class="form-group col-sm-12 padding-zero fs-12">
                                    <label>Confirm Password</label>
                                    <input name="password92" id="password92" type="password"
                                        [(ngModel)]="ConfirmPassword" class="form-control input-sm fs-12" />
                                </div>
                            </div>

                            <div class="col-md-12 padding-two">
                                <div class="form-group col-sm-3 padding-zero">
                                    <input type="button" class="btn btn-sm btn-block bg-blue fs-12" value="CHANGE"
                                        (click)="ChangePassword()" />
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>