import { Component, ChangeDetectorRef } from '@angular/core';
import { SignalRService } from '../app/services/signal-r.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HostListener } from "@angular/core";
import { CheckmobileService } from '../app/services/checkmobile.service';
import { MatchtableService } from '../app/services/matchtable.service';
import { LoginService } from '../app/services/login.service';
import { Subject } from 'rxjs';
import { SportsshowhideService } from '../app/services/sportsshowhide.service';
import { CryptojsService } from '../app/services/cryptojs.service';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { UserdetailsService } from '../app/services/userdetails.service';
import { CommonlistService } from '../app/services/commonlist.service';
import { ButtonstackrangeService } from '../app/services/buttonstackrange.service';
import { ChipsinoutService } from '../app/services/chipsinout.service';
import * as CryptoJS from 'crypto-js';
import { RjNotifierService } from '../app/services/rj-notifier.service';
import { SwUpdate } from "@angular/service-worker";
import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { WebsocketService } from './services/websocket.service';

declare var $: any;
declare function checkDev(): any;
declare var Image_00: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent {
  __APK_URL = this.UserdetailsService.__APK_URL;
  isLogin = false;
  isMobileView = false;

  CricketShowStatus = false;
  FancyShowStatus = false;
  LineShowStatus = false;
  ManualShowStatus = false;
  TennisShowStatus = false;
  SoccerShowStatus = false;
  SoccerGoaltShowStatus = false;
  HorseRacingShowStatus = false;
  TeenPattiT20ShowStatus = false;
  PokerT20ShowStatus = false;
  FunCasinoShowStatus = false;
  LotusShowStatus = false;
  CasinoAllShowStatus = false;
  EvolutionShowStatus = false;
  LiveCasinoShowStatus = false;

  isTabActive: any;

  PREVIOUS_ORDER_NO = "";
  PREVIOUS_ORDER_NO_UNMATCHED = "";

  __TRANSPORT: any;

  userTypeShow = 3;
  // Session_User = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
  UserType_Check: any;
  CurrentBalance_Temp: any;
  MarqueeMessage = "Welcome...";
  CurrentMainBalance = 0;
  CurrentOwnChips = 0;
  CurrentCreditChips = 0;
  CurrentExplosure = 0;
  CurrentPL = 0;
  CurrentRisk = 0;
  UserName_DB: any;
  UserNameDisplay_DB: any;
  UserID_DB: any;
  UserType = 0;

  myDate = new Date();

  ButtonValues: any;
  button0: any;
  button1: any;
  button2: any;
  button3: any;
  button4: any;
  button5: any;

  // CHANGE PASSWORD
  NewPasswordMain = "";
  ConfirmPasswordMain = "";

  isBetAllow = false;

  isWindowFocus = true;
  isCHECKBACKGROUNDAPP: any;
  __ISBACKFROMAPP = false;
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    // console.log("onKeydownHandler");
    // console.log(event);
    if (event.keyCode == 123) {
      return false;
    }
    if (event.ctrlKey && event.shiftKey && event.keyCode == 'I'.charCodeAt(0)) {
      return false;
    }
    if (event.ctrlKey && event.shiftKey && event.keyCode == 'C'.charCodeAt(0)) {
      return false;
    }
    if (event.ctrlKey && event.shiftKey && event.keyCode == 'J'.charCodeAt(0)) {
      return false;
    }
    if (event.ctrlKey && event.keyCode == 'U'.charCodeAt(0)) {
      return false;
    }
  }

  checkFocusTabFunction(status) {
    this.SignalRService.isWindowFocus = status;
  }
  private __ALLINONESUBSCRIPTION: Subscription = new Subscription();
  isEnterTableView = 0;

  closeConnectionLostPopup() {
    document.getElementById("internetConnectionPopup").style.height = "0%";
  }

  openConnectionLostPopup() {
    document.getElementById("internetConnectionPopup").style.height = "100%";
  }

  __CHECKDIFFERENCE = 3600000;
  __CHECK_BACKGROUND_TIME = 1800000;
  getDateTimeDifference(__DATE) {
    return new Date().getTime() - new Date(__DATE).getTime();
  }
  __isMOBILETOPMENU = 0;

  detectChanges() {
    this.cdr.detectChanges();
  }
  public constructor(private activatedRoute: ActivatedRoute, private swUpdate: SwUpdate, private cdr: ChangeDetectorRef, private RjNotifierService: RjNotifierService, private ChipsinoutService: ChipsinoutService, private ButtonstackrangeService: ButtonstackrangeService, private SignalRService: SignalRService, private SignalrService: WebsocketService, private CommonlistService: CommonlistService, private UserdetailsService: UserdetailsService, private CryptojsService: CryptojsService, private SportsshowhideService: SportsshowhideService, private LoginService: LoginService, private $css: CookieService, private MatchtableService: MatchtableService, private CheckmobileService: CheckmobileService, private Router: Router) {
    // window.onload = function()
    // {
    //   window.location.replace("https://hotsports.com/");
    // }
    // this.checkDev();
    // anonymous();
    if (environment.production) {
      if (this.swUpdate.isEnabled) {
        this.__ALLINONESUBSCRIPTION.add(
          this.swUpdate.available.subscribe(() => {
            $('#autoUpdateModel').modal({
              backdrop: 'static',
              keyboard: false
            });
            $("#autoUpdateModel").modal('show');
          })
        );
      }
    }
    this.isMobileView = this.CheckmobileService.isMobileView;

    this.checkLogin();

    this.__ALLINONESUBSCRIPTION.add(
      this.LoginService.getOnChangeDetect().subscribe(() => {
        this.detectChanges();
        // this.clearSessionData();
      })
    );

    let __DIAMONDS = [
      '/casino/games',

    ]
    /* VALIDATE DIAMOND CASINO DATACENTER */
    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnEnterDiamondCasino().subscribe(
        (message) => {
          if (message) {
            if (this.SignalRService.__CONNECTION_DIAMOND.state != 1) {
              this.SignalRService.initializeDiamondSignalRConnection();
            }
          }
          else {
            setTimeout(() => {
              if (!__DIAMONDS.includes(this.Router.url)) {
                this.SignalRService.StopDiamondConnection();
              }
            }, 1000);
          }
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalrService.getOnMessage().subscribe(
        (message) => {
          this.appSend(message);
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnMessageDiamond().subscribe(
        (message) => {
          if (this.SignalRService.isWindowFocus && this.__MAIN_USER_TYPE == 3)
            this.sendNotification('error', message);
        })
    );

    if (!this.isMobileView) {
      this.__ALLINONESUBSCRIPTION.add(
        this.SignalRService.getOnMatched().subscribe(
          (data) => {
            if (this.UserType == 3)
              this.getCurrentBalance();
          })
      );

      this.__ALLINONESUBSCRIPTION.add(
        this.SignalRService.getOnMatchedDiamond().subscribe(
          (data) => {
            if (this.UserType == 3)
              this.getCurrentBalance();
          })
      );

      this.__ALLINONESUBSCRIPTION.add(
        this.SignalRService.getOnLotusBalanceRefresh().subscribe(
          (data) => {
            if (this.UserType == 3)
              this.getCurrentBalance();
          })
      );
    }

    /* VALIDATE DIAMOND CASINO DATACENTER END */

    /* ================ INTERNET CONNECTION CHECK ============ */
    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnInternetConnectionState().subscribe(
        (message) => {
          if (message == false) {
            // this.openConnectionLostPopup();
          }
          else {
            this.resetJsonData();
          }
        })
    )
    /* ================ ADMIN PANEL ENTER STATE ========= */
    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnEnterAdminPanel().subscribe(
        (message) => {
          if (message == false) {
            if (document.getElementById("main-sidebar")) {
              document.getElementById("main-sidebar").className = "";
            }
            if (document.getElementById("main-sidebar-1")) {
              document.getElementById("main-sidebar-1").className = "skin-blue sidebar-mini";
            }
          }
          else {
            if (document.getElementById("main-sidebar")) {
              document.getElementById("main-sidebar").className = "sidebar-collapse";
            }
            if (document.getElementById("main-sidebar-1")) {
              document.getElementById("main-sidebar-1").className = "skin-blue sidebar-mini sidebar-collapse";
            }
          }
        })
    );
    /* ================ SIGNALR SERVICES ================= */

    // this.__ALLINONESUBSCRIPTION.add(
    //   this.SignalRService.getOnDataGuest().subscribe(
    //   (data) =>
    //   {
    //     if(this.isEnterTableView == 0)
    //     this.setLiveRate(data);
    //   })
    // );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnEnterTableView().subscribe(
        (message) => {
          this.isEnterTableView = message;

          if (message == 1) {
            // this.SignalRService.setOffMainRates();
            if (this.isMobileView) {
              this.__isMOBILETOPMENU = 1;
            }
          }
          else {
            // this.SignalRService.setOnMainRates();
            if (this.isMobileView) {
              this.__isMOBILETOPMENU = 0;
            }
          }
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnMessage().subscribe(
        (message) => {
          if (this.SignalRService.isWindowFocus && this.__MAIN_USER_TYPE == 3) {
            this.sendNotification('error', message);
            this.SignalRService.setCloseBet(true);
          }
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnServerMessage().subscribe(
        (message) => {
          if (document.getElementById("__MATCHTABLELOADERNew")) {
            document.getElementById("__MATCHTABLELOADERNew").style.height = "0%";
          }
          // console.log("getOnServerMessage => ")
          // console.log(message)
          if (message.interval < 0) {
            if (message.message != null && message.message != "") {
              if (document.getElementById("MarqueeMessage"))
                document.getElementById("MarqueeMessage").innerHTML = message.message;
            }
          }
          else if (message.interval == 0) {
            if (message.message != null && message.message != "") {
              if (document.getElementById("MarqueeMessage"))
                document.getElementById("MarqueeMessage").innerHTML = message.message;
            }
          }
          else {
            // if(this.SignalRService.isWindowFocus)
            // this.sendNotification("success", data);
          }
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnNewMaster().subscribe(
        (message) => {
          this.newGameAdded(message);
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnShowHideMatch().subscribe(
        (message) => {
          if (message.STATUS == true || message.STATUS == "D")
            this.showHideMatch(message.SID);
          else {
            this.getNewMaster(message.SID);
            setTimeout(() => {
              if (document.getElementById("CricketTR_" + message.SID)) {
                document.getElementById("CricketTR_" + message.SID).removeAttribute("style");
              }

              if (document.getElementById("CricketTRInPlay_" + message.SID)) {
                document.getElementById("CricketTRInPlay_" + message.SID).removeAttribute("style");
              }
              this.setMatchCounter();
            }, 2000);
          }
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnShowHideSport().subscribe(
        (message) => {
          this.setShowHideSports(message.SID, message.PID, message.STATUS);
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnDisconnected().subscribe(
        (message) => {
          this.sendNotification("error", message);
          setTimeout(() => {
            this.clearSessionData();
          }, 1000);
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnLogout().subscribe(
        (message) => {
          this.clearSessionData();
        })
    );

    // this.__ALLINONESUBSCRIPTION.add(
    //   this.SignalRService.getOnHorseInfo().subscribe(
    //   (message) =>
    //   {
    //     this.setHorseInfo(message);
    //   })
    // );

    this.__ALLINONESUBSCRIPTION.add(
      this.SportsshowhideService.getOnSportsShowHideShowStatus().subscribe(
        (message) => {
          this.getHidedSports();
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnHomeButtonClick().subscribe(
        (message) => {
          this.changeHeaderTabs(0, 0);
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.MatchtableService.getOnInPlayMatches().subscribe(
        (message) => {
          this.setMatchCounter();
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.MatchtableService.getOnLotusCasinoCalculate().subscribe(
        (message) => {
          this.openLotusPL();
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnFrontRate().subscribe(
        (message) => {
          this.getJsonRateData();
        })
    );
    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnMaintenance().subscribe(
        (message) => {
          if (this.SignalRService._MAINTENANCE_ID != message.ID)
            return false;
          if (message.STATUS) {
            this.openMaintenanceModal(message.MESSAGE);
          }
          else {
            this.closeMaintenanceModal();
          }
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.LoginService.getAdminAccess().subscribe(
        (message) => {
          this.checkAccess();
        })
    );
  }

  IS_ACCESS = false;
  checkAccess() {
    if (sessionStorage.getItem('_ACCESS') === null) {
      this.IS_ACCESS = false;
    }
    else
      this.IS_ACCESS = true;
  }

  reloadForUpdate() {
    window.location.reload();
  }

  openMaintenanceModal(_MSG) {
    if ($('#maintenanceModal')) {
      $('#maintenanceModal').modal({
        backdrop: 'static',
        keyboard: false
      });
      $('#maintenanceModal').modal('show');
      if (document.getElementById("maintenanceModalData")) {
        document.getElementById("maintenanceModalData").innerHTML = _MSG;
      }
    }
  }

  closeMaintenanceModal() {
    if ($('#maintenanceModal')) {
      $('#maintenanceModal').modal('hide');
    }
  }

  PREVIOUS_ORDR_NO = "";
  appSend(msg) {
    let data: any;
    let dta: any;
    try {
      data = JSON.parse(msg.data);
      dta = this.CryptojsService.decryptSocketFunction(data.errormsg);
      // console.log(dta);
      if (data.errorcode == "0") {
        // if (dta.split('=')[0].trim().toLowerCase() == 'delay') { }
        // else {
        //   try {
        //     let mdata = JSON.parse(dta);
        //     let mcode = mdata.pkHeader['shMessageCode'];
        //     if (mcode == 8000) {
        //       if(this.SessionUser.UserType == 3) {
        //         this.getCurrentBalance();
        //       }
        //       else  {
        //         if (this.PREVIOUS_ORDER_NO != mdata.lnOrderNo) {
        //           this.PREVIOUS_ORDER_NO = mdata.lnOrderNo;
  
        //           let message = '';
        //           message = 'Matched - ' + mdata.stRunnerName + ' - ' + mdata.dbOdds.toFixed(2) + ' - ' + mdata.dbStack.toFixed(2) + ' (' + mdata.stSentUser + ')';
        //           let _TYPE = (mdata.stSide.toLowerCase() == "back" ? "back" : "lay");
  
        //           this.sendNotification(_TYPE, message);
  
        //           this.getCurrentBalance()
        //         }
        //       }
        //     }
        //     else if (mcode == 3002) {
        //       if(this.SessionUser.UserType == 3) { }
        //       else {
        //         if (this.PREVIOUS_ORDR_NO != mdata.lnOrderNo) {
        //           this.PREVIOUS_ORDR_NO = mdata.lnOrderNo;

        //           this.sendNotification("warning", "Order Pending");
        //         }
        //       }
        //     }
        //     else {
        //       this.SignalRService.__CHIPS = dta.split(',')[1].toString();
        //       this.SignalRService.OnChips(dta.split(',')[1].toString());
        //     }
        //   }
        //   catch {
        //     this.SignalRService.__CHIPS = dta.split(',')[1].toString();
        //     this.SignalRService.OnChips(dta.split(',')[1].toString());
        //   }
        // }
      }
      else if (data.errorcode == "1") {
        if (dta == 0) {
          this.sendNotification('error', 'You will be disconnected');
          setTimeout(() => {
            this.Logout(1);
          }, 1000);
        }
      }
    }
    catch {
    }
  }

  SessionUser: any;
  TempAllMatches: any;
  tempDelayCounter = 0;
  checkFocus = true;

  setMatchesAll() {
    this.MatchtableService.setMatchList();
    this.MatchtableService.checkInPlayMatchFunction();
    this.setMatchCounter();
  }
  ResetMatchesAll() {
    this.MatchtableService.resetMatchList();
    this.MatchtableService.checkInPlayMatchFunction();
    this.setMatchCounter();
  }

  __COUNTER_TOTAL = 0;
  __COUNTER_CRICKET = 0;
  __COUNTER_TENNIS = 0;
  __COUNTER_SOCCER = 0;
  __COUNTER_HORSES = 0;
  AllMatchList = [];
  setMatchCounter() {
    if (this.MatchtableService.CurrentMatchList == undefined) {
      setTimeout(() => {
        this.setMatchCounter();
      }, 100);
      return false;
    }
    let __MATCHES = this.MatchtableService.CurrentMatchList;

    if (__MATCHES.length == 0) {
      this.AllMatchList = [];
      return false;
    }

    this.AllMatchList = __MATCHES.filter(({ stSportsID, stMarketName }) => {
      return stSportsID != null && (stMarketName == 'Winner' || stMarketName == 'Match Odds') && (stSportsID == 4 || stSportsID == 2 || stSportsID == 1)
    })

    this.__COUNTER_TOTAL = this.MatchtableService.InPlayMatchList.length;

    this.__COUNTER_CRICKET = this.filterSportsWiseMatch("4").length;
    this.__COUNTER_TENNIS = this.filterSportsWiseMatch("2").length;
    this.__COUNTER_SOCCER = this.filterSportsWiseMatch("1").length;
    this.__COUNTER_HORSES = this.filterSportsWiseMatch("7").length;

  }
  filterSportsWiseMatch(__SPORTSID) {
    if (this.MatchtableService.InPlayMatchList == null)
      return [];
    return this.MatchtableService.InPlayMatchList.filter(({ stSportsID }) => {
      return stSportsID != null && stSportsID == __SPORTSID
    })
  }
  CurrentDateTime: Date = new Date();
  __DATETIME_INTERVAL: any;
  setDateTime() {
    this.__DATETIME_INTERVAL = setInterval(() => {
      this.CurrentDateTime = new Date();
    }, 1);
  }
  __MAIN_USER_TYPE = -1;
  __ISADDBALANCE = 0;
  ngOnInit() {
    // this.getJsonRateData();
    // this.getJsonData();
    this.getJSONNDATA();

    var userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test(userAgent),
      ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
      if (safari) {
        //browser
      }
      else if (!safari) {
        //webview
      };
    }
    else {
      if (!window.navigator.userAgent.toLowerCase().match('chrome'))
        this.SignalRService.USERINTERFACE = this.SignalRService.__USERINTERFACE;
    };

    this.getMaintenanceFromApi();

    if (this.isMobileView) {
      if (sessionStorage.getItem('user') === null) {
        this.clearSessionDataNew();
        return false;
      }
    }
    else {
      if (sessionStorage.getItem('user') === null) {
        return false;
      }
    }

    this.SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    if(this.SessionUser != undefined && this.SessionUser != null) this.SignalrService.connect();

    var Session_User = this.SessionUser;
    this.UserName_DB = Session_User.UserName;
    // console.log("this.UserName_DB.length : " + this.UserName_DB.length)
    this.UserNameDisplay_DB = (this.UserName_DB.length > 7 ? this.UserName_DB.substr(0, 5) + "..." : this.UserName_DB);
    this.UserType = Session_User.UserType;
    this.UserID_DB = Session_User.UserId;
    this.__ISADDBALANCE = Session_User.inDelay;

    if (this.UserType == 4)
      this.checkAccess();

    switch (Session_User.UserType) {
      case 0:
        this.UserType_Check = "ADMIN";
        break;

      case 1:
        this.UserType_Check = "SUPER";
        break;

      case 2:
        this.UserType_Check = "MASTER";
        break;

      case 3:
        this.UserType_Check = "CLIENT";
        break;

      case 4:
        this.UserType_Check = "Hyper";
        break;

      case 5:
        this.UserType_Check = "SUB-SUPER-1";
        break;

      default:
        break;
    }
    this.getCurrentBalance();
    this.checkStackButtons();

    this.isMobileView = this.CheckmobileService.isMobileView;
    this.ButtonstackrangeService.ngOnInit();

    this.__MAIN_USER_TYPE = this.SessionUser.UserType;
    this.tempDelayCounter = 0;

    // this.MatchtableService.CurrentMatchList = null;

    if (this.isMobileView) {
      this.setDateTime();
      this.__TRANSPORT = this.SignalRService.Transport;
      // this.Router.navigate(["/Home_mobile"]);
    }

    this.SportsshowhideService.setShowHideSports();

    this.getHidedSports();

    this.PaymentGetModel()
    this.PaymentOption(2)

    this.getPaymentmethod();
  }

  getMaintenanceFromApi() {
    this.LoginService.getMaintenance().subscribe(
      data1 => {
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));
        if (data.status != 200) {
          return false;
        }

        for (let d of data.data) {
          if (d.site_id == this.SignalRService._MAINTENANCE_ID) {
            this.openMaintenanceModal(d.message);
          }
        }
      });
  }

  onAddBalanceClick() {
    if (document.getElementById("AddBalancePopupData")) {
      document.getElementById("AddBalancePopupData").style.display = "block";
    }
  }
  onTransactionClick() {
    this.Router.navigate(['/manage/payments/deposit']);
  }

  onHomeClick() {
    this.SignalRService.changeOnHomeButtonClick(1);
  }

  setServerMessage(data) {
    if (data != "")
      this.MarqueeMessage = data;
  }

  getCurrentBalance() {
    this.ChipsinoutService.getMyCurrentBalance(this.UserID_DB).subscribe(
      data1 => {
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));

        this.CurrentBalance_Temp = data.deBalance;
        this.CurrentMainBalance = data.deBalance;
        // this.CurrentOwnChips = data.deChips;
        this.CurrentOwnChips = data.deBalance;
        this.CurrentCreditChips = data.deCreditChips;
        this.CurrentPL = this.CurrentOwnChips - this.CurrentCreditChips;
        this.CurrentExplosure = data.deLiability + data.deRisk;
        this.CurrentRisk = data.deRisk;
      },
      error => {
        // console.log("Error@getCurrentBalance : " + JSON.stringify(error) + " : " + error.StatusCode);
        return false;
      });
  }

  Logout(status) {
    if (status == 0)
      return false;

    // setTimeout(() => {
    // if (!this.isMobileView)
    //   this.Router.navigate(['/login'],);
    // else
    //   this.Router.navigate(['/login_mobile'],);
    // }, 1500);
    // console.log("Logout Called");

    // if (this.SignalRService.connection.state == 1)
    //   this.SignalRService.BCast.invoke("LogoutClientMulti", this.UserID_DB).done(() => { });
    // else {
    //   this.SignalRService.connection.start().done(() => {
    //     this.SignalRService.BCast.invoke("LogoutClientMulti", this.UserID_DB).done(() => { });
    //   });
    // }

    this.LoginService.logoutAPI().subscribe(
      data => {
        // console.log("Resp@logoutAPI : " + JSON.stringify(data));
        // delete sessionStorage.user;
        // this.checkLogin();
      },
      error => {
        // console.log("Error@logoutAPI : " + error);
        // delete sessionStorage.user;
        // this.checkLogin();
      });

      delete sessionStorage.user;
      sessionStorage.clear();
      window.location.assign("");
  }

  checkStackButtons() {
    if (this.ButtonstackrangeService.ButtonValues == undefined) {
      setTimeout(() => {
        this.checkStackButtons();
      }, 100);
      return false;
    }
    this.getButtonStack();
  }

  getButtonStack() {
    this.ButtonValues = this.ButtonstackrangeService.ButtonValues;

    this.button0 = this.ButtonValues.split(',')[0];
    this.button1 = this.ButtonValues.split(',')[1];
    this.button2 = this.ButtonValues.split(',')[2];
    this.button3 = this.ButtonValues.split(',')[3];
    this.button4 = this.ButtonValues.split(',')[4];
    this.button5 = this.ButtonValues.split(',')[5];
  }

  SaveStacks() {
    this.ButtonstackrangeService.SaveButtonStackRange(this.button0, this.button1, this.button2, this.button3, this.button4, this.button5).subscribe(
      data1 => {
        // console.log(data1)
        // let data = this.CryptojsService.DecryptOrderFunction(data1);  
        if (data1 == null) {
          this.sendNotification("success", "Stacks has been saved Successfully.");
          this.ButtonstackrangeService.setButtonStackRange();
        }
        // console.log("Response@SaveButtonStackRange : " + data);
      },
      error => {
        // console.log("error@SaveButtonStackRange : " + error);  
      });
  }

  changeNavigation() {
    let __ROUTER = (!this.isMobileView ? '/Match:TableView' : "/Match:TableView_mobile");
    // console.log("EventID, stSportsID : " + EventID + " : " + stSportsID)
    this.Router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
      this.Router.navigate([__ROUTER], { queryParams: { EventsID: 28127348, SportsID: 4, MarketID: 1.179157300 } }));
  }

  getJsonRateData() {
    // this.LoginService.getRateFromJSON().subscribe(
    //   (data: any[]) => {
    //     this.SignalRService.__CURRENTODDS = data;
    //     this.setLiveRate(data);
    //     if (this.CommonlistService.__SITE_ID == 0) sessionStorage.setItem("datalive", this.CryptojsService.encryptData(JSON.stringify(data)));
    //   },
    //   error => {
    //     // console.log("Error@getRateFromJSON : " + JSON.stringify(error.message));
    //   });
  }

  resetJsonData() {
    // this.getJsonRateData()
    // this.LoginService.getMasterFromJSON().subscribe(
    //   data => {
    //     if (this.CommonlistService.__SITE_ID == 0) sessionStorage.setItem("LastMaster", new Date().toString());
    //     if (this.CommonlistService.__SITE_ID == 0) sessionStorage.setItem("CUStakes", this.CryptojsService.encryptData(JSON.stringify(data)));
    //     this.ResetMatchesAll();
    //   },
    //   error => {
    //     // console.log("Error@getMasterFromJSON : " + JSON.stringify(error.message));
    //   });
  }
  getMasterTimer: any;

  getJSONNDATA() {
    this.LoginService.getMasterSJSON("-1").subscribe(
      data0 => {
        let dta0 = JSON.parse(this.CryptojsService.decryptAES256(data0, "0V27WqAzmrZRSL2A0V27WqAzmrZRSL2A", "0V27WqAzmrZRSL2A"));
        // console.log("INP JSON", dta0);
        this.MatchtableService.InPlayMatchList = dta0;
      
        this.LoginService.getMasterSJSON("4").subscribe(
        data1 => {
          let data = JSON.parse(this.CryptojsService.decryptAES256(data1, "0V27WqAzmrZRSL2A0V27WqAzmrZRSL2A", "0V27WqAzmrZRSL2A"));
          // console.log("CRICKET JSON", data);
          this.MatchtableService.CricketMatchList = data;

          this.LoginService.getMasterSJSON("1").subscribe(
            data2 => {
              let data3 = JSON.parse(this.CryptojsService.decryptAES256(data2, "0V27WqAzmrZRSL2A0V27WqAzmrZRSL2A", "0V27WqAzmrZRSL2A"));
              // console.log("SOCCER JSON", data3);
              this.MatchtableService.SoccerMatchList = data3;

              //

              this.LoginService.getMasterSJSON("2").subscribe(
                data4 => {
                  let data5 = JSON.parse(this.CryptojsService.decryptAES256(data4, "0V27WqAzmrZRSL2A0V27WqAzmrZRSL2A", "0V27WqAzmrZRSL2A"));
                  // console.log("TENNIS JSON", data5);
                  this.MatchtableService.TennisMatchList = data5;
                  
                  //

                  this.LoginService.getMasterSJSON("7").subscribe(
                    data6 => {
                      let data7 = JSON.parse(this.CryptojsService.decryptAES256(data6, "0V27WqAzmrZRSL2A0V27WqAzmrZRSL2A", "0V27WqAzmrZRSL2A"));
                      // console.log("HORSE JSON", data7);
                      this.MatchtableService.HorseMatchList = data7;

                      //

                      if(this.MatchtableService.CricketMatchList.length > 0 || this.MatchtableService.TennisMatchList.length > 0 || this.MatchtableService.SoccerMatchList.length > 0 || this.MatchtableService.HorseMatchList.length > 0) {
                        this.MatchtableService.MatchesList = true;
                        if (this.CommonlistService.__SITE_ID == 100) {}
                        /*else */ this.MatchtableService.CurrentMatchList = this.MatchtableService.CricketMatchList.concat(this.MatchtableService.TennisMatchList, this.MatchtableService.SoccerMatchList, this.MatchtableService.HorseMatchList)
                      }

                    },
                    error6 => {});

                },
                error4 => {});
            },
            error2 => {});

          // this.setLiveRRateN(data);

          // if (this.CURRENT_MATCH_LIST == undefined || this.CURRENT_MATCH_LIST == null) {
          //   this.MatchtableService.CurrentMatchList = data;
          //   this.CURRENT_MATCH_LIST = this.ALL_MATCH_LIST = data;

          //   setTimeout(() => {
          //     this.getMatchList();
          //   }, 200);
          // }
          // else {
          //   if (this.CURRENT_MATCH_LIST.length != data.length) {
          //     this.MatchtableService.CurrentMatchList = data;
          //     this.CURRENT_MATCH_LIST = this.ALL_MATCH_LIST = data;

          //     setTimeout(() => {
          //       this.getMatchList();
          //     }, 200);
          //   }
          // }

        },
        error => {

        })
      },
      error0 => {

      })
  }

  getJsonData() {
    // this.getJsonRateData()
    if (sessionStorage.getItem('user') == null) {
      clearTimeout(this.getMasterTimer);
      this.getMasterTimer = null;
      return false;
    }

    // if(window.location.hash.split(this.CryptojsService.decryptData('U2FsdGVkX19QfMa3QnNU/Ypq92x4XiBM+WiKo6SbGbI='))[0].indexOf(this.CryptojsService.decryptData("U2FsdGVkX1+ggO2H0PcCbHVLMfHlvVjU8G6CLcG1eX4=")) >= 0 || window.location.hash.split(this.CryptojsService.decryptData('U2FsdGVkX19QfMa3QnNU/Ypq92x4XiBM+WiKo6SbGbI='))[0].indexOf(this.CryptojsService.decryptData("U2FsdGVkX1++Dt6JYAcPoXOpVj2ppnIviI6QZdWkGm4=")) >= 0 || window.location.hash.split(this.CryptojsService.decryptData('U2FsdGVkX19QfMa3QnNU/Ypq92x4XiBM+WiKo6SbGbI='))[0].indexOf(this.CryptojsService.decryptData("U2FsdGVkX1+5rZ9Gp0WS6xYxaG8sqGTptP4sEaEIbZ62fVNGMFwg6XYu5XujAOou")) >= 0 || window.location.hash.split(this.CryptojsService.decryptData('U2FsdGVkX19QfMa3QnNU/Ypq92x4XiBM+WiKo6SbGbI='))[0].indexOf(this.CryptojsService.decryptData("U2FsdGVkX1+TcFRerw1uyA6JfkxGYJFF6FPehmUUxiMke95syaDkU8QnXdPTE38Y")) >= 0 || window.location.hash.split(this.CryptojsService.decryptData('U2FsdGVkX19QfMa3QnNU/Ypq92x4XiBM+WiKo6SbGbI='))[0].indexOf(this.CryptojsService.decryptData("U2FsdGVkX19eSFCUZR1OQz/+M0qMI7+T6wN8WDPGR6ZxTvK1Ah05mpx1TugCEe6i")) >= 0) {
    this.LoginService.getMasterFromJSON().subscribe(
      data1 => {
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunctionU(data1, "U2FsdGVkX18LyQo6FkAa5M9ec8JxMhgBmHG1bx21XiE="));
        this.LoginService.setMasters(data);
        this.MatchtableService.CurrentMatchList = data;
        if (this.CommonlistService.__SITE_ID == 0) sessionStorage.setItem("LastMaster", new Date().toString());
        if (this.CommonlistService.__SITE_ID == 0) sessionStorage.setItem("CUStakes", this.CryptojsService.encryptData(JSON.stringify(data)));
        this.setMatchesAll();
        clearTimeout(this.getMasterTimer);
        this.getMasterTimer = null;
        this.getMasterTimer = setTimeout(() => {
          this.getJsonData();
        }, this.CommonlistService.MTimer);
      },
      error => {
        // console.log("Error@getMasterFromJSON : " + JSON.stringify(error.message));
        clearTimeout(this.getMasterTimer);
        this.getMasterTimer = null;
        this.getMasterTimer = setTimeout(() => {
          this.getJsonData();
        }, this.CommonlistService.MTimer);
      });
  }

  setLiveRate(data) {
    for (let i = 0; i < data.length; i++) {
      this.setLiveRateMatchWise(data[i]);
    }
  }

  setLiveRateMatchWise(data) {
    if (data.Back[0].Price.toFixed(2) == "0.00" && data.Lay[0].Price.toFixed(2) == "0.00") {
      if (document.getElementById("__SUSPEND_" + data.stInstrumentID)) {
        document.getElementById("__SUSPEND_" + data.stInstrumentID).style.display = "flex";
      }
      if (document.getElementById("__BACK_P_INPLAY_" + data.stInstrumentID)) {
        document.getElementById("__BACK_P_INPLAY_" + data.stInstrumentID).innerHTML = "-";
      }

      if (document.getElementById("__LAY_P_INPLAY_" + data.stInstrumentID)) {
        document.getElementById("__LAY_P_INPLAY_" + data.stInstrumentID).innerHTML = "-";
      }
    }
    else {
      if (document.getElementById("__SUSPEND_" + data.stInstrumentID)) {
        document.getElementById("__SUSPEND_" + data.stInstrumentID).style.display = "none";
      }
      if (document.getElementById("__BACK_P_INPLAY_" + data.stInstrumentID)) {
        let __BACK_P_INPLAY = document.getElementById("__BACK_P_INPLAY_" + data.stInstrumentID);
        if (__BACK_P_INPLAY.innerHTML != data.Back[0].Price.toFixed(2)) {
          __BACK_P_INPLAY.innerHTML = data.Back[0].Price.toFixed(2);

          if (document.getElementById("__BACK_INPLAY_" + data.stInstrumentID))
            this.RateAnimation(document.getElementById("__BACK_INPLAY_" + data.stInstrumentID));
        }
      }

      if (document.getElementById("__LAY_P_INPLAY_" + data.stInstrumentID)) {
        let __LAY_P_INPLAY = document.getElementById("__LAY_P_INPLAY_" + data.stInstrumentID);
        if (__LAY_P_INPLAY.innerHTML != data.Lay[0].Price.toFixed(2)) {
          __LAY_P_INPLAY.innerHTML = data.Lay[0].Price.toFixed(2);

          if (document.getElementById("__LAY_INPLAY_" + data.stInstrumentID))
            this.RateAnimation(document.getElementById("__LAY_INPLAY_" + data.stInstrumentID));
        }
      }
    }
  }
  AnimationDuration = 50;
  RateAnimation(__ELEMENT) {
    if (__ELEMENT)
      __ELEMENT.classList.add("Rate-animation");
    setTimeout(() => {
      if (__ELEMENT)
        __ELEMENT.classList.remove("Rate-animation");
    }, this.AnimationDuration);
  }

  setHorseInfo(data) {
    if (document.getElementById("HorseInfo_" + data.stMarketID + "_" + data.stSelectionId)) {
      // console.log("HorseInfo GOES IN  =====> ");
      var x = document.getElementById('MATCH_SUSPEND_TR_' + data.stMarketID + "_" + data.stSelectionId).offsetLeft;
      var y = document.getElementById('MATCH_SUSPEND_TR_' + data.stMarketID + "_" + data.stSelectionId).offsetTop;
      var h = document.getElementById('MATCH_SUSPEND_TR_' + data.stMarketID + "_" + data.stSelectionId).scrollHeight;

      document.getElementById('HorseInfo_TD_' + data.stMarketID + "_" + data.stSelectionId).innerHTML = 'NON RUNNER  ' + data.btAdjutFector + ' %';

      var divOverlay = document.getElementById('HorseInfo_' + data.stMarketID + "_" + data.stSelectionId);
      divOverlay.style.position = 'absolute';
      // divOverlay.style.top = y + 'px';
      if (this.isMobileView) {
        divOverlay.style.left = "60%";
        divOverlay.style.width = "40%";
      }
      else {
        divOverlay.style.left = "40%";
        divOverlay.style.width = "60%";
      }
      divOverlay.style.height = h + 'px';
      divOverlay.style.display = 'block';
    }
  }

  setShowHideSports(data, ParentID, Status) {
    switch (data) {
      case "4":
        this.CricketShowStatus = this.SportsshowhideService.CricketShowStatus = Status;
        break;

      case "2":
        this.TennisShowStatus = this.SportsshowhideService.TennisShowStatus = Status;
        break;

      case "1":
        this.SoccerShowStatus = this.SportsshowhideService.SoccerShowStatus = Status;
        break;

      case "7":
        this.HorseRacingShowStatus = this.SportsshowhideService.HorseRacingShowStatus = Status;
        break;

      case "0":
        this.FancyShowStatus = this.SportsshowhideService.FancyShowStatus = Status;
        break;

      case "18":
        this.LineShowStatus = this.SportsshowhideService.LineShowStatus = Status;
        break;

      case "13":
        this.ManualShowStatus = this.SportsshowhideService.ManualShowStatus = Status;
        break;

      case "14":
        this.TeenPattiT20ShowStatus = this.SportsshowhideService.TeenPattiT20ShowStatus = Status;
        break;

      case "17":
        this.PokerT20ShowStatus = this.SportsshowhideService.PokerT20ShowStatus = Status;
        break;

      case "1501":
        this.FunCasinoShowStatus = this.SportsshowhideService.FunCasinoShowStatus = Status;
        break;

      case "1601":
        this.LotusShowStatus = this.SportsshowhideService.LotusShowStatus = Status;
        break;

      case "-10":
        this.CasinoAllShowStatus = this.SportsshowhideService.CasinoAllShowStatus = Status;
        break;

      case "10001":
        this.EvolutionShowStatus = this.SportsshowhideService.EvolutionShowStatus = Status;
        break;

      case "10002":
        this.LiveCasinoShowStatus = this.SportsshowhideService.LiveCasinoShowStatus = Status;
        break;
    }
  }

  getMasterList() {
    // this.LoginService.getMasterAllList("").subscribe(
    //   data1 => {
    //     let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));

    //     // console.log("getMasterAllList =>");
    //     // console.log(data);
    //     // if (this.CommonlistService.__SITE_ID == 0) sessionStorage.setItem("CUStakes", this.CryptojsService.encryptData(JSON.stringify(data)));
    //     // this.setMatchesAll();
    //   },
    //   error => {
    //     // console.log("Error@getMasterList : " + JSON.stringify(error.message));
    //   });
  }

  __PREVIOUSTOPTAB = 0;
  changeHeaderTabs(__NEXTTAB, __PARAM) {
    if (this.__PREVIOUSTOPTAB != -1) {
      if (document.getElementById("__TOPBARTABS_" + this.__PREVIOUSTOPTAB)) {
        document.getElementById("__TOPBARTABS_" + this.__PREVIOUSTOPTAB).className = "hoverPointer";
      }
    }
    let __TAB_NO = (__NEXTTAB == 1 ? (__PARAM == 0 ? 1 : 2) : (__PARAM == 0 ? 0 : 3));
    this.__PREVIOUSTOPTAB = __TAB_NO;
    if (document.getElementById("__TOPBARTABS_" + __TAB_NO)) {
      document.getElementById("__TOPBARTABS_" + __TAB_NO).className = "hoverPointer router-link-active";
    }
    let __ROUTER = "";
    sessionStorage.setItem("__PREVIOUSTOPTAB", this.__PREVIOUSTOPTAB.toString());
    switch (__NEXTTAB) {
      case 0:
        if (!this.isMobileView)
          this.SignalRService.changeEnterAdminPanel(false);

        __ROUTER = (!this.isMobileView ? "/Home" : "/Home_mobile");
        this.Router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
          this.Router.navigate([__ROUTER], { queryParams: { params: this.CryptojsService.encryptOrderFunction(__PARAM) } }));
        break;

      case 1:
        __ROUTER = "/casino/casino-games";
        this.Router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
          this.Router.navigate([__ROUTER], { queryParams: { params: this.CryptojsService.encryptOrderFunction(__PARAM) } }));
        break;
    }
    if (__ROUTER == "")
      return false;
  }

  clearSessionDataNew() {
    sessionStorage.clear();
    this.checkLogin();
    this.DoLogout();
  }

  clearSessionData() {
    sessionStorage.clear();
    this.checkLogin();
    this.DoLogout();
    // this.getJsonData();
  }

  DoLogout() {
    if (!this.isMobileView)
      this.Router.navigate(['/login']);
    else
      this.Router.navigate(['/login_mobile']);
  }

  ngAfterViewInit() {
    if (window.location.href.match('btag=')) {
      var expiryDate = new Date();
      expiryDate.setMonth(expiryDate.getMonth() + 1);

      let _BTag = decodeURIComponent(window.location.href.split('btag=')[1]);
      console.log("btag Available =>");
      document.cookie = "bTag=" + this.CryptojsService.encryptData(_BTag) + "; expires=" + expiryDate + "; SameSite=Strict";
    }

    if (this.SessionUser == undefined) {
      if (this.LoginService.$Maintenance) this.clearSessionData();
      return false;
    }

    if (this.SessionUser.isResetPwd) {
      this.openChangePasswordFirstPopup();
    }

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['payments'] != undefined)
        this.openPaymentsModel();
    });

    // if(document.getElementById("__MATCHTABLELOADERNew"))
    // {
    //   document.getElementById("__MATCHTABLELOADERNew").style.height= "100%";
    // }

    if (sessionStorage.getItem("__PREVIOUSTOPTAB") !== null) {
      let __INDEX_TOPMENU = sessionStorage.getItem("__PREVIOUSTOPTAB");
      this.changeMainTabsAuto(parseInt(__INDEX_TOPMENU));
    }
  }

  openPaymentsModel() {
    if (document.getElementById("paymentsModel")) {
      $("#paymentsModel").modal('show');
    }
    else {
      setTimeout(() => {
        this.openPaymentsModel();
      }, 500);
    }
  }

  changeMainTabsAuto(__NEXTTAB) {
    if (this.__PREVIOUSTOPTAB != -1) {
      if (document.getElementById("__TOPBARTABS_" + this.__PREVIOUSTOPTAB)) {
        document.getElementById("__TOPBARTABS_" + this.__PREVIOUSTOPTAB).className = "hoverPointer";
      }
    }
    this.__PREVIOUSTOPTAB = __NEXTTAB;
    if (document.getElementById("__TOPBARTABS_" + __NEXTTAB)) {
      document.getElementById("__TOPBARTABS_" + __NEXTTAB).className = "hoverPointer router-link-active";
    }
    if (!this.isMobileView)
      this.SignalRService.changeEnterAdminPanel(false);
  }

  // CHANGE PASSWORD
  NewPassword = "";
  ConfirmPassword = "";
  PasswordErrorMessage = "";
  ChangePassword() {
    if (this.NewPassword == "") {
      this.sendNotification('error', "Please enter New Password.");
      this.PasswordErrorMessage = "Please enter New Password.";
      setTimeout(() => {
        this.PasswordErrorMessage = "";
      }, 5000);
    }
    else {
      if (this.NewPassword == this.ConfirmPassword) {
        this.UserdetailsService.changePasswordSelf(this.SessionUser.UserId, this.NewPassword).subscribe(
          data1 => {
            let data = this.CryptojsService.DecryptOrderFunction(data1);

            if (data != "1") {
              this.sendNotification("error", data);
              this.PasswordErrorMessage = data;
              setTimeout(() => {
                this.PasswordErrorMessage = "";
              }, 5000);
              return false;
            }
            // this.sendNotification('success', "Password has been changed Successfully.");
            this.ConfirmPassword = this.NewPassword = "";
            this.PasswordErrorMessage = "Password has been changed Successfully. Please wait...";

            setTimeout(() => {
              // this.clearSessionData();
              this.SessionUser.isResetPwd = false;
              sessionStorage.setItem("user", this.CryptojsService.encryptData(JSON.stringify(this.SessionUser)));

              this.closeChangePasswordFirstPopup();
            }, 2500);
          },
          error => {
            // console.log("error@Password : " + JSON.stringify(error));
          });
      }
      else {
        this.PasswordErrorMessage = "New and Confirm password must be same.";
        setTimeout(() => {
          this.PasswordErrorMessage = "";
        }, 5000);
      }
    }
  }

  closeChangePasswordFirstPopup() {
    if (document.getElementById("ChangePasswordFirstPopup"))
      document.getElementById("ChangePasswordFirstPopup").style.height = "0%";
  }

  openChangePasswordFirstPopup() {
    if (document.getElementById("ChangePasswordFirstPopup"))
      document.getElementById("ChangePasswordFirstPopup").style.height = "100%";
  }

  onDynamicRouterClick(__ROUTER) {
    this.Router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
      this.Router.navigate([__ROUTER]));
  }

  onResultDataClick(__ROUTER, __PARAM) {
    this.Router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
      this.Router.navigate([__ROUTER], { queryParams: { params: this.CryptojsService.encryptOrderFunction(__PARAM) } }));
  }

  onDynamicClientListingClick(__PARAM) {
    this.Router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
      this.Router.navigate(['/client-listing/data'], { queryParams: { params: this.CryptojsService.encryptOrderFunction(__PARAM) } }));
  }

  onMatchListClick(param) {
    this.Router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
      this.Router.navigate(['/MatchView'], { queryParams: { SportsID: param } }));
  }

  onMatchListMobileClick(param) {
    this.Router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
      this.Router.navigate(['/MatchView_mobile'], { queryParams: { SportsID: param } }));
  }

  onCasinoGameButtonClick(ID) {
    this.Router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
      this.Router.navigate(['/iframecasino'], { queryParams: { GameID: ID } }));
  }

  ngOnDestroy() {
    if (this.__DATETIME_INTERVAL)
      clearInterval(this.__DATETIME_INTERVAL);
    this.__ALLINONESUBSCRIPTION.unsubscribe();

    this.SignalrService.closeConnection();
  }

  getHidedSports() {
    this.CricketShowStatus = this.SportsshowhideService.CricketShowStatus;
    this.TennisShowStatus = this.SportsshowhideService.TennisShowStatus;
    this.SoccerShowStatus = this.SportsshowhideService.SoccerShowStatus;
    this.HorseRacingShowStatus = this.SportsshowhideService.HorseRacingShowStatus;
    this.FancyShowStatus = this.SportsshowhideService.FancyShowStatus;
    this.LineShowStatus = this.SportsshowhideService.LineShowStatus;
    this.ManualShowStatus = this.SportsshowhideService.ManualShowStatus;
    this.TeenPattiT20ShowStatus = this.SportsshowhideService.TeenPattiT20ShowStatus;
    this.PokerT20ShowStatus = this.SportsshowhideService.PokerT20ShowStatus;
    this.FunCasinoShowStatus = this.SportsshowhideService.FunCasinoShowStatus;
    this.LotusShowStatus = this.SportsshowhideService.LotusShowStatus;
    this.CasinoAllShowStatus = this.SportsshowhideService.CasinoAllShowStatus;
    this.EvolutionShowStatus = this.SportsshowhideService.EvolutionShowStatus;
    this.LiveCasinoShowStatus = this.SportsshowhideService.LiveCasinoShowStatus;
  }

  getNewMaster(MarketID) {
    if (this.MatchtableService.checkMaster(MarketID) == 0) {
      this.LoginService.getMatchListParticular(MarketID).subscribe(
        data1 => {
          let NewMaster = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));


          if (NewMaster.length == 0)
            return false;

          var temp: any;
          if (this.CommonlistService.__SITE_ID == 0) temp = JSON.parse(this.CryptojsService.decryptData(sessionStorage.getItem("CUStakes")));
          /*else */ temp = this.MatchtableService.CurrentMatchList;

          if (this.MatchtableService.checkMaster(MarketID) == 0)
            temp.push(NewMaster[0]);

          if (this.CommonlistService.__SITE_ID == 0) sessionStorage.setItem("CUStakes", this.CryptojsService.encryptData(JSON.stringify(temp)));
            /*else */this.MatchtableService.CurrentMatchList = temp;

          this.MatchtableService.addNewMatch(NewMaster[0].stSportsID);
        },
        error => {
          // console.log("Error@getMatchList : " + error.message);
        });
    }
  }

  getSportsID(MarketID) {
    var data: any;
    if (this.CommonlistService.__SITE_ID == 0) data = JSON.parse(this.CryptojsService.decryptData(sessionStorage.getItem("CUStakes")));
    /*else */data = this.MatchtableService.CurrentMatchList;
    return data.filter(({ stMarketID }) => {
      return stMarketID == MarketID
    })
  }

  HideAllNotification() {
    this.RjNotifierService.hideNotifier();
  }

  sendNotification(Type, Message) {
    this.RjNotifierService.showNotifier(Type, Message);
  }

  previousMarketHide = "";
  showHideMatch(data) {
    if (data != "") {
      if (document.getElementById("CricketTR_" + data)) {
        document.getElementById("CricketTR_" + data).style.display = "none";
      }

      if (document.getElementById("CricketTRInPlay_" + data)) {
        document.getElementById("CricketTRInPlay_" + data).style.display = "none";
      }

      if (document.getElementById("Fancy0" + data + "_Score")) {
        document.getElementById("Fancy0" + data + "_Score").style.display = "none";
      }

      if (document.getElementById("Fancy0" + data.split("_")[0] + "_Score"))
        document.getElementById("Fancy0" + data.split("_")[0] + "_Score").style.display = "none";

      if (document.getElementById("FM_" + data + "_Score")) {
        document.getElementById("FM_" + data + "_Score").style.display = "none";
      }

      if (document.getElementById("FM_HOVER_" + data + "_Score")) {
        document.getElementById("FM_HOVER_" + data + "_Score").style.display = "none";
      }

      if (document.getElementById("Fancy1" + data + "_Score"))
        document.getElementById("Fancy1" + data + "_Score").style.display = "none";

      if (document.getElementById("Fancy2" + data + "_Score"))
        document.getElementById("Fancy2" + data + "_Score").style.display = "none";

      if (document.getElementById("SuspendPopup_" + data + "_Score") && data.split(".")[0] != "1")
        document.getElementById("SuspendPopup_" + data + "_Score").style.display = "none";

      if (document.getElementById("SuspendPopupFancy_" + data + "_Score") && data.split(".")[0] != "1")
        document.getElementById("SuspendPopupFancy_" + data + "_Score").style.display = "none";

      if (document.getElementById("SuspendPopupFancyAdmin_" + data + "_Score") && data.split(".")[0] != "1")
        document.getElementById("SuspendPopupFancyAdmin_" + data + "_Score").style.display = "none";

      if (this.MatchtableService.CurrentMatchList === null)
        return false;

      let MatchData: any;
      if (this.CommonlistService.__SITE_ID == 0) MatchData = JSON.parse(this.CryptojsService.decryptData(sessionStorage.getItem("CUStakes")))
      /*else */MatchData = this.MatchtableService.CurrentMatchList;

      let getDeletedMatch = this.getSportsID(data);

      let i = 0;
      for (let result of MatchData) {
        if (result.stMarketID == data) {
          break;
        }
        i++;
      }

      if (MatchData[i] != undefined)
        delete MatchData[i];

      var filtered = MatchData.filter(function (el) {
        return el != null;
      });
      i = 0;

      if (this.CommonlistService.__SITE_ID == 0) sessionStorage.setItem("CUStakes", this.CryptojsService.encryptData(JSON.stringify(filtered)));
      /*else */this.MatchtableService.CurrentMatchList = filtered;

      if (sessionStorage.getItem("favouriteMatches") != null) {
        var LocalDataFav = JSON.parse(this.CryptojsService.decryptData(sessionStorage.getItem("favouriteMatches")));
        var checkFav = LocalDataFav.filter((stMarketID) => {
          return stMarketID == data;
        });

        // if(checkFav.length > 0)
        {
          let j = 0;
          for (let result of LocalDataFav) {
            if (result.stMarketID == data) {
              break;
            }
            j++;
          }
          delete LocalDataFav[j];

          var filteredNew = LocalDataFav.filter(function (el) {
            return el != null;
          });
          j = 0;
          sessionStorage.setItem("favouriteMatches", this.CryptojsService.encryptData(JSON.stringify(filteredNew)));
        }
      }

      if (getDeletedMatch.length > 0)
        this.MatchtableService.addNewMatch(getDeletedMatch[0].stSportsID);

      data = "";
    }
  }

  newGameAdded(data) {
    if (this.MatchtableService.checkMaster(data.stMarketID) == 0) {
      this.LoginService.getMatchListParticular(data.stMarketID).subscribe(
        data1 => {
          let NewMaster = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));

          if (NewMaster.length == 0)
            return false;

          var temp: any;
          if (this.CommonlistService.__SITE_ID == 0) temp = JSON.parse(this.CryptojsService.decryptData(sessionStorage.getItem("CUStakes")));
          /*else */temp = this.MatchtableService.CurrentMatchList;

          if (this.MatchtableService.checkMaster(data.stMarketID) == 0)
            temp.push(NewMaster[0]);

          if (this.CommonlistService.__SITE_ID == 0) sessionStorage.setItem("CUStakes", this.CryptojsService.encryptData(JSON.stringify(temp)));
          /*else */this.MatchtableService.CurrentMatchList = temp;

          this.MatchtableService.addNewMatch(NewMaster[0].stSportsID);
        },
        error => {
          // console.log("Error@getMatchList : " + error.message);
        });
    }
  }

  checkLogin() {
    if (this.isMobileView) {
      if (sessionStorage.getItem('user') !== null) {
        this.isLogin = true;
        if (sessionStorage.getItem("isReload") !== null) {
          if (sessionStorage.getItem("isReload") == "1") {
            sessionStorage.setItem("isReload", "0");

            // if(!this.isMobileView)
            //   this.Router.navigate(["/Home"]);
            // else
            //   this.Router.navigate(["/Home_mobile"]);
          }
          else {
            // if(!this.isMobileView)
            //   this.Router.navigate(["/Home"]);
            // else
            //   this.Router.navigate(["/Home_mobile"]);
          }
        }
      }
      else {
        this.MatchtableService.destroyInPlayMatchFunction();
        this.SignalrService.closeConnection();
        this.isLogin = false;
      }
    }
    else {
      if (sessionStorage.getItem('user') !== null) {
        this.isLogin = true;
        if (sessionStorage.getItem("isReload") !== null) {
          if (sessionStorage.getItem("isReload") == "1") {
            sessionStorage.setItem("isReload", "0");

            // if(!this.isMobileView)
            //   this.Router.navigate(["/Home"]);
            // else
            //   this.Router.navigate(["/Home_mobile"]);
          }
          else {
            // if(!this.isMobileView)
            //   this.Router.navigate(["/Home"]);
            // else
            //   this.Router.navigate(["/Home_mobile"]);
          }
        }
      }
      else {
        this.MatchtableService.destroyInPlayMatchFunction();
        this.SignalrService.closeConnection();
        this.isLogin = false;
      }
    }
  }

  __SIGNUP_GOOGLEPAY_NAME = "HADA INTERPRICES";
  //__SIGNUP_GOOGLEPAY = "+91 9289632265";
  // __SIGNUP_PAY_NUMBER = "9289632265";
  __SIGNUP_GOOGLEPAY = "";
  __SIGNUP_PAY_NUMBER = "";
  __SIGNUP_GOOGLEPAY_LINK = "https://pay.google.com/";
  __SIGNUP_PHONEPE_LINK = "https://www.phonepe.com/";
  __SIGNUP_PAYTM_LINK = "https://paytm.com/";
  __SELECTED_METHOD = 4;//2;
  __PAY_TO_TEXT = this.__SIGNUP_PAY_NUMBER + "@paytm";
  __PAY_FROM = "";
  __PAY_AMOUNT = "";
  __PAY_TNS_ID = "";
  __IS_BANKACCOUNT = true; //false;
  __AC_NUMBER = "";
  __AC_HOLDER = "";
  __AC_IFSC = "";
  __AC_SWIFT = "";
  __AC_BANK = "";

  AddBalanceNew() {
    if (this.__SELECTED_METHOD == -1) {
      this.sendNotification("error", "Please select Payment Method.");
      return false;
    }
    if (this.__PAY_FROM == "" || (/^[\s()+-]*([0-9][\s()+-]*){6,20}$/.test(this.__PAY_FROM) == false)) {
      this.sendNotification("error", "Please enter a valid Mobile Number.");
      return false;
    }
    if (this.__PAY_AMOUNT == "" || parseFloat(this.__PAY_AMOUNT) <= 0.00) {
      this.sendNotification("error", "Amount should be greater than 0(zero)!");
      return false;
    }
    if (this.__PAY_TNS_ID == "") {
      this.sendNotification("error", "Please enter a valid Transaction ID.");
      return false;
    }

    if (document.getElementById("__PAY_BUTTON")) {
      document.getElementById("__PAY_BUTTON").style.display = "none";
    }
    if (document.getElementById("__PAY_CLOSE")) {
      document.getElementById("__PAY_CLOSE").style.display = "none";
    }
    if (document.getElementById("__PAY_LOADER")) {
      document.getElementById("__PAY_LOADER").style.display = "block";
    }
    if (document.getElementById("__PAY_FROM")) {
      document.getElementById("__PAY_FROM").setAttribute("disabled", "true");
    }
    if (document.getElementById("__PAY_AMOUNT")) {
      document.getElementById("__PAY_AMOUNT").setAttribute("disabled", "true");
    }
    if (document.getElementById("__PAY_TNS_ID")) {
      document.getElementById("__PAY_TNS_ID").setAttribute("disabled", "true");
    }

    let __DATETIME = formatDate(new Date(), 'yyyy-MM-dd', 'en');

    this.LoginService.addBalance(this.SessionUser.UserId, this.__PAY_TNS_ID, this.__PAY_AMOUNT, this.__SELECTED_METHOD, __DATETIME, this.__PAY_FROM).subscribe(
      data1 => {

        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));


        if (document.getElementById("__PAY_BUTTON")) {
          document.getElementById("__PAY_BUTTON").style.display = "inline-block";
        }
        if (document.getElementById("__PAY_CLOSE")) {
          document.getElementById("__PAY_CLOSE").style.display = "inline-block";
        }
        if (document.getElementById("__PAY_LOADER")) {
          document.getElementById("__PAY_LOADER").style.display = "none";
        }
        if (document.getElementById("__PAY_FROM")) {
          document.getElementById("__PAY_FROM").removeAttribute("disabled");
        }
        if (document.getElementById("__PAY_AMOUNT")) {
          document.getElementById("__PAY_AMOUNT").removeAttribute("disabled");
        }
        if (document.getElementById("__PAY_TNS_ID")) {
          document.getElementById("__PAY_TNS_ID").removeAttribute("disabled");
        }

        if (data.status != 200) {
          this.sendNotification("error", data.message);
          return false;
        }

        this.sendNotification("success", data.message);
        this.CloseAddBalancePopup();
      },
      error => {
        this.sendNotification("error", "Something went wrong in Deposit. Please try again.");
        // console.log("error@RegisterCreateOrder => ");
        // console.log(error);
        if (document.getElementById("__PAY_BUTTON")) {
          document.getElementById("__PAY_BUTTON").style.display = "inline-block";
        }
        if (document.getElementById("__PAY_CLOSE")) {
          document.getElementById("__PAY_CLOSE").style.display = "inline-block";
        }
        if (document.getElementById("__PAY_LOADER")) {
          document.getElementById("__PAY_LOADER").style.display = "none";
        }
        if (document.getElementById("__PAY_FROM")) {
          document.getElementById("__PAY_FROM").removeAttribute("disabled");
        }
        if (document.getElementById("__PAY_AMOUNT")) {
          document.getElementById("__PAY_AMOUNT").removeAttribute("disabled");
        }
        if (document.getElementById("__PAY_TNS_ID")) {
          document.getElementById("__PAY_TNS_ID").removeAttribute("disabled");
        }
      })
  }
  CloseAddBalancePopup() {
    this.closeSignup();
    this.__SELECTED_METHOD = 4; //2;
    this.__PAY_TO_TEXT = this.__SIGNUP_PAY_NUMBER + "@paytm";
    this.__PAY_FROM = "";
    this.__PAY_AMOUNT = "";
    this.__PAY_TNS_ID = "";

    if (document.getElementById("AddBalancePopupData"))
      $('#AddBalancePopupData').modal('hide');
    if (document.getElementById("AddBalancePopupData")) {
      document.getElementById("AddBalancePopupData").style.display = "none";
    }
  }

  CloseWithdrawBalancePopup() {
    this.__SELECTED_METHOD = 4; //2;
    this.__PAY_TO_TEXT = this.__SIGNUP_PAY_NUMBER + "@paytm";
    this.__PAY_FROM = "";
    this.__PAY_AMOUNT = "";
    this.__PAY_TNS_ID = "";

    if (document.getElementById("WithdrawBalancePopupData"))
      $('#WithdrawBalancePopupData').modal('hide');
    // if(document.getElementById("AddBalancePopupData"))
    // {
    //   document.getElementById("AddBalancePopupData").style.display = "none";
    // }
  }
  checkminimumLimit(amount) {
    if (amount < 500) {
      // document.getElementById("__PAY_BUTTON").disabled = true;
      this.sendNotification("error", "Minimum Deposit Amount Limit is Rs. 500");

      return false;
    }
    else {
      // document.getElementById("__PAY_BUTTON").disabled = false;
      return true;
    }
  }
  ChangePaymentMethod(__WHICHMETHOD) {
    this.__SELECTED_METHOD = __WHICHMETHOD;

    switch (__WHICHMETHOD) {
      case 0:
        this.__IS_BANKACCOUNT = false;
        this.__PAY_TO_TEXT = this.__SIGNUP_PAY_NUMBER + "@googlepay";
        break;
      case 1:
        this.__IS_BANKACCOUNT = false;
        this.__PAY_TO_TEXT = this.__SIGNUP_PAY_NUMBER + "@phonepe";
        break;
      case 2:
        this.__IS_BANKACCOUNT = false;
        this.__PAY_TO_TEXT = this.__SIGNUP_PAY_NUMBER + "@paytm";
        break;
      case 4:
        this.__IS_BANKACCOUNT = true;
        this.__PAY_TO_TEXT = "Add your Bank Account Details";
        break;
      case 5:
        this.__IS_BANKACCOUNT = false;
        this.__PAY_TO_TEXT = "Enter Bhim UPI.";
        break;
    }
  }

  __REGISTER_UID = -1;
  __REGISTER_PID = -1;
  __MERCHANT_KEY = this.CommonlistService.__MERCHANT_KEY;
  __MERCHANT_TNS_ID = "";
  __MERCHANT_AMOUNT = 0.00;
  __MERCHANT_P_INFO = "login100";
  __MERCHANT_F_NAME = "";
  __MERCHANT_L_NAME = "";
  __MERCHANT_EMAIL = "";
  __MERCHANT_PHONE = "";
  __MERCHANT_SURL = this.CommonlistService.__PAYU_PAYMENT_CALLBACK_URL// + "?p=1";
  __MERCHANT_FURL = this.CommonlistService.__PAYU_PAYMENT_CALLBACK_URL// + "?p=2";
  __MERCHANT_CURL = this.CommonlistService.__PAYU_PAYMENT_CALLBACK_URL// + "?p=3";
  __MERCHANT_HASH: any;
  __MERCHANT_SALT = this.CommonlistService.__MERCHANT_SALT;
  __IS_SUCCESS = false;
  __IS_SEND_REQUEST = false;

  closeSignup() {
    this.__IS_SUCCESS = false;
    this.__IS_SEND_REQUEST = false;
    this.__MERCHANT_AMOUNT = 0.00;
  }

  CloseSignupPopup() {
    this.closeSignup();

    if (document.getElementById("AddBalancePopupData"))
      $('#AddBalancePopupData').modal('hide');
  }

  enableDepositLoader() {
    if (document.getElementById('__DEPOSIT_LOADER')) {
      document.getElementById('__DEPOSIT_LOADER').style.display = "inline-block";
    }
    if (document.getElementById('__DEPOSIT_OK')) {
      document.getElementById('__DEPOSIT_OK').style.display = "none";
    }
    if (document.getElementById('__DEPOSIT_CANCEL')) {
      document.getElementById('__DEPOSIT_CANCEL').style.display = "none";
    }
  }

  disableDepositLoader() {
    if (document.getElementById('__DEPOSIT_LOADER')) {
      document.getElementById('__DEPOSIT_LOADER').style.display = "none";
    }
    if (document.getElementById('__DEPOSIT_OK')) {
      document.getElementById('__DEPOSIT_OK').style.display = "inline-block";
    }
    if (document.getElementById('__DEPOSIT_CANCEL')) {
      document.getElementById('__DEPOSIT_CANCEL').style.display = "inline-block";
    }
  }
  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }


  openFloxyPayRequest() {
    if (this.__MERCHANT_AMOUNT <= 0) {
      this.sendNotification("error", "Please enter a valid Amount.");
      return false;
    }

    this.enableDepositLoader();

    // PHONE_PAISA

    let __POSTDATA =
    {
      "amount": this.__MERCHANT_AMOUNT,
      "order_id": this.makeid(20),
      "name": this.SessionUser.UserName,
      "email": this.SessionUser.stEmailID, //EMAIL
      "phone": this.SessionUser.stContactNo, // phone,
      "currency": "INR",
      "description": "description",
      "mode": "LIVE",
      "city": "Indore",
      "country": "IND",
      "zip_code": "382364"
    }

    let __POSTDATA1 =
    {
      "UID": this.SessionUser.UserId,
      "orderid": __POSTDATA.order_id,
      "PID": this.SessionUser.ParentId,
      "name": this.SessionUser.UserName,
      "email": this.SessionUser.stEmailID,
      "status": 200,
      "country": "IND",
      "mobile": this.SessionUser.stContactNo,
      "amount": this.__MERCHANT_AMOUNT
    }



    this.LoginService.setPhonePaisaPayUser(__POSTDATA1).subscribe(
      data1 => {
        this.disableDepositLoader();
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));

        if (data.status != "200") {
          let __Msg = (data.hasOwnProperty('message') ? data.message : "Something went wrong. Please try again");

          this.sendNotification("error", __Msg);
          return false;
        }

        this.OpenWindowWithPost(__POSTDATA);

        this.__IS_SEND_REQUEST = true;
        this.__MERCHANT_TNS_ID = __POSTDATA.order_id;

        setTimeout(() => {
          this.CloseAddBalancePopup();
        }, 10000);
      },
      error => {
        this.disableDepositLoader();
      });
    return false;

    //PHONE_PAISA


    //   let __POSTDATA2 =
    //   {
    //     "UID" : this.SessionUser.UserId,
    //     "orderid" : this.makeid(20),
    //     "PID" : this.SessionUser.ParentId,
    //     "name" : this.SessionUser.UserName,
    //     "email" : this.SessionUser.stEmailID,
    //     "status" : 200,
    //     "country" : "In",
    //     "mobile" : this.SessionUser.stContactNo,
    //     "amount" : this.__MERCHANT_AMOUNT
    //   }
    //   // console.log("Data/paymentRequestHotSports1", __POSTDATA2);
    //   this.LoginService.setPhonePaisaPayUser(__POSTDATA2).subscribe(
    //   data1 =>
    //   {
    //     this.disableDepositLoader();
    //     let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));
    //     // console.log("POSDATA2", __POSTDATA2);
    //     // console.log(data);

    //     if(data.status != "200")
    //     {
    //       let __Msg = (data.hasOwnProperty('message') ? data.message : "Something went wrong. Please try again");

    //       this.sendNotification("error", __Msg);
    //       return false;
    //     }


    //     this.LoginService.setFloxyPayUser(this.SessionUser.ParentId, this.SessionUser.UserId, "In", this.SessionUser.UserName, this.SessionUser.stEmailID, this.SessionUser.stContactNo, this.__MERCHANT_AMOUNT, this.makeid(20)).subscribe(
    //       data1 =>
    //       {
    //         this.disableDepositLoader();
    //         let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));

    //         // console.log("Resp @ setFloxyPayUser =>");
    //         // console.log(data);

    //         if(data.status != "200")
    //         {
    //           let __Msg = (data.hasOwnProperty('message') ? data.message : "Something went wrong. Please try again");

    //           this.sendNotification("error", __Msg);
    //           return false;
    //         }
    //         this.__IS_SEND_REQUEST = true;
    //         this.__MERCHANT_TNS_ID = data.orderId;
    //         setTimeout(() =>
    //         {
    //           if(document.getElementById("__PAY_LOAD_DATA"))
    //           {
    //             var iFrameNew = '<iframe src="' + data.url + '" marginwidth="0" marginheight="0" frameborder="0" width="100%" height="500px" scrolling="yes"></iframe>'
    //             if(document.getElementById('__PAY_LOAD_DATA'))
    //               document.getElementById('__PAY_LOAD_DATA').innerHTML = iFrameNew;
    //           }
    //         }, 100);
    //         // window.open(data.url, "_blank");
    //       },
    //       error =>
    //       {
    //         this.disableDepositLoader();
    //         // console.log("Error @ setFloxyPayUser => ");
    //         // console.log(error);
    //       });



    // this.OpenWindowWithPost(__POSTDATA);

    //     // this.__IS_SEND_REQUEST = true;
    //     // this.__MERCHANT_TNS_ID = __POSTDATA.order_id;

    //     // setTimeout(() => {
    //     //   this.CloseAddBalancePopup();
    //     // }, 10000);
    //   },
    //   error =>
    //   {
    //     this.disableDepositLoader();
    //   });
  }

  IS_CRYPTO = false;
  toggleCurrency() {
    this.IS_CRYPTO = !this.IS_CRYPTO;
  }

  openIPintPayRequest() {
    if (this.__MERCHANT_AMOUNT <= 25) {
      this.sendNotification("error", "minimum Deposit amount is 26$.");
      return false;
    }

    this.enableDepositLoader();

    let __POSTDATA =
    {
      "uid": this.SessionUser.UserId,
      "inParentID": this.SessionUser.ParentId,
      "uname": this.SessionUser.UserName,
      "mNo": this.SessionUser.stContactNo,
      "email": this.SessionUser.stEmailID,
      "paymentMethod": 191,
      "amount": this.__MERCHANT_AMOUNT,
      "AUT": "123"
    }

    this.LoginService.openIPintPayRequest(__POSTDATA).subscribe(
      data1 => {
        this.disableDepositLoader();

        let data = JSON.parse(data1);

        if (data.status != "200") {
          let __Msg = (data.hasOwnProperty('message') ? data.message : "Something went wrong. Please try again");

          this.sendNotification("error", __Msg);
          return false;
        }
        window.open(data.url, '_self').focus();

        this.__IS_SEND_REQUEST = true;
        this.__MERCHANT_TNS_ID = data.url.split("?id=")[1];

        setTimeout(() => {
          this.CloseAddBalancePopup();
        }, 10000);
      },
      error => {
        this.disableDepositLoader();
      });
    return false
  }

  openPayuRequest() {
    if (this.__MERCHANT_AMOUNT <= 0) {
      this.sendNotification("error", "Please enter valid Amount.");
      return false;
    }

    this.__IS_SEND_REQUEST = true;
    this.__MERCHANT_TNS_ID = this.uuidv4();
    this.__MERCHANT_P_INFO = "BUY" + this.__MERCHANT_AMOUNT;
    this.__MERCHANT_F_NAME = this.SessionUser.UserName;
    this.__MERCHANT_L_NAME = this.SessionUser.fullName;
    this.__MERCHANT_EMAIL = this.SessionUser.stEmailID;
    this.__MERCHANT_PHONE = this.SessionUser.stContactNo;
    let __COMBINED_STR = this.__MERCHANT_KEY + "|" + this.__MERCHANT_TNS_ID + "|" + this.__MERCHANT_AMOUNT + "|" + this.__MERCHANT_P_INFO + "|" + this.__MERCHANT_F_NAME + "|" + this.__MERCHANT_EMAIL + "|||||||||||" + this.__MERCHANT_SALT;
    this.__MERCHANT_HASH = CryptoJS.SHA512(__COMBINED_STR);

    let __PARAMS = {
      "firstname": this.__MERCHANT_F_NAME,
      "lastname": this.__MERCHANT_L_NAME,
      "surl": this.__MERCHANT_SURL,
      "phone": this.__MERCHANT_PHONE,
      "key": this.__MERCHANT_KEY,
      "hash": this.__MERCHANT_HASH,
      "curl": this.__MERCHANT_CURL,
      "furl": this.__MERCHANT_FURL,
      "txnid": this.__MERCHANT_TNS_ID,
      "productinfo": this.__MERCHANT_P_INFO,
      "amount": this.__MERCHANT_AMOUNT,
      "email": this.__MERCHANT_EMAIL
    }

    this.LoginService.RegisterSendTNSID(this.SessionUser.UserId, this.SessionUser.UserName, this.__MERCHANT_TNS_ID).subscribe(
      data1 => {
        // console.log("openPayuRequest => ")
      },
      error => {
        // console.log("openPayuRequest Error => ")
        // this.sendNotification("error", "Somwthing wrong.");
        // console.log(error)
      });
    this.OpenWindowWithPost(__PARAMS);
  }

  OpenWindowWithPost(__PARAMS) {
    var form = document.createElement("form");
    //form.setAttribute("method", "post");
    // form.setAttribute("action","https://secure.floxypay.com/pay/h86avf8ml8bnhun2h86avf8ml8bnhun3-d4RCeOP8ATY2Z4GtyNrPoC5C");//"https://secure.floxypay.com/pay/c3upplp11l89pgrk2c3upplp11l89pgrk3-u1NoQog2AgOVB7RUIy3VZhua"); //"https://pay.eternalpant.com/payments/process.php");//this.CommonlistService.__PAYU_PAYMENT_URL);
    form.setAttribute("action", "https://secure.floxypay.com/pay/h86avq2ol8clg48th86avq2ol8clg48u-frdy2UveSg7LtCn7qstdI6Ks");
    //form.setAttribute("target", "_blank");

    for (var i in __PARAMS) {
      if (__PARAMS.hasOwnProperty(i)) {
        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = i;
        input.value = __PARAMS[i];
        form.appendChild(input);
      }

    }
    // "https://signup.hotsports.com/Api/Data/FloxyResponce"
    document.body.appendChild(form);

    form.submit();
    document.body.removeChild(form);


    // setTimeout(() => {
    //   this.CloseSignupPopup();
    // }, 2000);
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  WithdrawBalanceClick() {

    if (this.__SELECTED_METHOD == -1) {
      this.sendNotification("error", "Please select Payment Method.");
      return false;
    }

    {
      if (this.__SELECTED_METHOD == 5) {
        if (this.__PAY_FROM == "") {
          this.sendNotification("error", "Please enter a BHIM UPI ID.");
          return false;
        }
      }
      else {
        if (this.__PAY_FROM == "" || (/^[\s()+-]*([0-9][\s()+-]*){6,20}$/.test(this.__PAY_FROM) == false)) {
          this.sendNotification("error", "Please enter a valid Mobile Number.");
          return false;
        }
      }

      if (this.__PAY_AMOUNT == "" || parseFloat(this.__PAY_AMOUNT) <= 0.00) {
        this.sendNotification("error", "Amount should be greater than 0(zero)!");
        return false;
      }
    }
    if (this.__IS_BANKACCOUNT) {
      if (this.__AC_BANK == "") {
        this.sendNotification("error", "Please enter Bank Name.");
        return false;
      }
      // alert(this.__AC_NUMBER)
      // if(this.__AC_NUMBER == "" || (/^([0-9]{11})|([0-9]{2}-[0-9]{3}-[0-9]{6})$/.test(this.__AC_NUMBER) == false))
      // {
      //   this.sendNotification("error", "Please enter a valid Account Number.");
      //   return false;
      // }
      if (this.__AC_HOLDER == "") {
        this.sendNotification("error", "Please enter Account Holder.");
        return false;
      }
      // if(this.__AC_IFSC == "" || (/^[A-Z]{4}0[A-Z0-9]{6}$/.test(this.__AC_IFSC) == false))
      this.__AC_IFSC = this.__AC_IFSC.toUpperCase();
      if (this.__AC_IFSC == "") {
        this.sendNotification("error", "Please enter a valid IFSC Code.");
        return false;
      }
    }
    else {
      this.__AC_NUMBER = this.__AC_HOLDER = this.__AC_IFSC = this.__AC_BANK = this.__AC_SWIFT = "-";
    }
    if (document.getElementById("__WITHDRAW_BUTTON")) {
      document.getElementById("__WITHDRAW_BUTTON").style.display = "none";
    }
    if (document.getElementById("__WITHDRAW_CLOSE")) {
      document.getElementById("__WITHDRAW_CLOSE").style.display = "none";
    }
    if (document.getElementById("__WITHDRAW_LOADER")) {
      document.getElementById("__WITHDRAW_LOADER").style.display = "inline-block";
    }
    if (document.getElementById("__PAY_FROM")) {
      document.getElementById("__PAY_FROM").setAttribute("disabled", "true");
    }
    if (document.getElementById("__PAY_AMOUNT")) {
      document.getElementById("__PAY_AMOUNT").setAttribute("disabled", "true");
    }
    // console.log("WithdrawBalanceClick = 2");

    let __DATETIME = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.LoginService.withdrawBalance(this.SessionUser.UserId, this.__PAY_AMOUNT, this.__SELECTED_METHOD, __DATETIME, this.__PAY_FROM, this.__AC_NUMBER, this.__AC_HOLDER, this.__AC_IFSC, this.__AC_BANK, this.__AC_SWIFT).subscribe(
      data1 => {
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));

        if (document.getElementById("__WITHDRAW_BUTTON")) {
          document.getElementById("__WITHDRAW_BUTTON").style.display = "inline-block";
        }
        if (document.getElementById("__WITHDRAW_CLOSE")) {
          document.getElementById("__WITHDRAW_CLOSE").style.display = "inline-block";
        }
        if (document.getElementById("__WITHDRAW_LOADER")) {
          document.getElementById("__WITHDRAW_LOADER").style.display = "none";
        }
        if (document.getElementById("__PAY_FROM")) {
          document.getElementById("__PAY_FROM").removeAttribute("disabled");
        }
        if (document.getElementById("__PAY_AMOUNT")) {
          document.getElementById("__PAY_AMOUNT").removeAttribute("disabled");
        }
        if (data.status != 200) {
          this.sendNotification("error", data.message);
          return false;
        }

        this.sendNotification("success", data.message);
        this.CloseWithdrawBalancePopup();
      },
      error => {
        this.sendNotification("error", "Something went wrong in Withdraw Balance. Please try again.");

        if (document.getElementById("__WITHDRAW_BUTTON")) {
          document.getElementById("__WITHDRAW_BUTTON").style.display = "inline-block";
        }
        if (document.getElementById("__WITHDRAW_CLOSE")) {
          document.getElementById("__WITHDRAW_CLOSE").style.display = "inline-block";
        }
        if (document.getElementById("__WITHDRAW_LOADER")) {
          document.getElementById("__WITHDRAW_LOADER").style.display = "none";
        }
        if (document.getElementById("__PAY_FROM")) {
          document.getElementById("__PAY_FROM").removeAttribute("disabled");
        }
        if (document.getElementById("__PAY_AMOUNT")) {
          document.getElementById("__PAY_AMOUNT").removeAttribute("disabled");
        }
      })
  }

  WithdrawBalanceApexClick() {

    if (this.__SELECTED_METHOD == -1) {
      this.sendNotification("error", "Please select Payment Method.");
      return false;
    }

    {
      if (this.__SELECTED_METHOD == 5) {
        if (this.__PAY_FROM == "") {
          this.sendNotification("error", "Please enter a BHIM UPI ID.");
          return false;
        }
      }
      else {
        if (this.__PAY_FROM == "" || (/^[\s()+-]*([0-9][\s()+-]*){6,20}$/.test(this.__PAY_FROM) == false)) {
          this.sendNotification("error", "Please enter a valid Mobile Number.");
          return false;
        }
      }

      if (this.__PAY_AMOUNT == "" || parseFloat(this.__PAY_AMOUNT) <= 0.00) {
        this.sendNotification("error", "Amount should be greater than 0(zero)!");
        return false;
      }
    }
    if (this.__IS_BANKACCOUNT) {
      if (this.__AC_BANK == "") {
        this.sendNotification("error", "Please enter Bank Name.");
        return false;
      }
      // if(this.__AC_NUMBER == "" || (/^([0-9]{11})|([0-9]{2}-[0-9]{3}-[0-9]{6})$/.test(this.__AC_NUMBER) == false))
      if (this.__AC_NUMBER == "") {
        // this.sendNotification("error", "Please enter a valid Account Number.");
        return false;
      }
      if (this.__AC_HOLDER == "") {
        this.sendNotification("error", "Please enter Account Holder.");
        return false;
      }
      // if(this.__AC_IFSC == "" || (/^[A-Z]{4}0[A-Z0-9]{6}$/.test(this.__AC_IFSC) == false))
      this.__AC_IFSC = this.__AC_IFSC.toUpperCase();
      if (this.__AC_IFSC == "") {
        this.sendNotification("error", "Please enter a valid IFSC Code.");
        return false;
      }
    }
    else {
      this.__AC_NUMBER = this.__AC_HOLDER = this.__AC_IFSC = this.__AC_BANK = this.__AC_SWIFT = "-";
    }
    if (document.getElementById("__WITHDRAW_BUTTON")) {
      document.getElementById("__WITHDRAW_BUTTON").style.display = "none";
    }
    if (document.getElementById("__WITHDRAW_CLOSE")) {
      document.getElementById("__WITHDRAW_CLOSE").style.display = "none";
    }
    if (document.getElementById("__WITHDRAW_LOADER")) {
      document.getElementById("__WITHDRAW_LOADER").style.display = "inline-block";
    }
    if (document.getElementById("__PAY_FROM")) {
      document.getElementById("__PAY_FROM").setAttribute("disabled", "true");
    }
    if (document.getElementById("__PAY_AMOUNT")) {
      document.getElementById("__PAY_AMOUNT").setAttribute("disabled", "true");
    }
    // console.log("WithdrawBalanceClick = 2");

    let __DATETIME = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.LoginService.withdrawBalance(this.SessionUser.UserId, this.__PAY_AMOUNT, this.__SELECTED_METHOD, __DATETIME, this.__PAY_FROM, this.__AC_NUMBER, this.__AC_HOLDER, this.__AC_IFSC, this.__AC_BANK, this.__AC_SWIFT).subscribe(
      data1 => {
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));

        if (document.getElementById("__WITHDRAW_BUTTON")) {
          document.getElementById("__WITHDRAW_BUTTON").style.display = "inline-block";
        }
        if (document.getElementById("__WITHDRAW_CLOSE")) {
          document.getElementById("__WITHDRAW_CLOSE").style.display = "inline-block";
        }
        if (document.getElementById("__WITHDRAW_LOADER")) {
          document.getElementById("__WITHDRAW_LOADER").style.display = "none";
        }
        if (document.getElementById("__PAY_FROM")) {
          document.getElementById("__PAY_FROM").removeAttribute("disabled");
        }
        if (document.getElementById("__PAY_AMOUNT")) {
          document.getElementById("__PAY_AMOUNT").removeAttribute("disabled");
        }
        if (data.status != 200) {
          this.sendNotification("error", data.message);
          return false;
        }

        this.sendNotification("success", data.message);
        this.CloseWithdrawBalancePopup();
      },
      error => {
        this.sendNotification("error", "Something went wrong in Withdraw Balance. Please try again.");

        if (document.getElementById("__WITHDRAW_BUTTON")) {
          document.getElementById("__WITHDRAW_BUTTON").style.display = "inline-block";
        }
        if (document.getElementById("__WITHDRAW_CLOSE")) {
          document.getElementById("__WITHDRAW_CLOSE").style.display = "inline-block";
        }
        if (document.getElementById("__WITHDRAW_LOADER")) {
          document.getElementById("__WITHDRAW_LOADER").style.display = "none";
        }
        if (document.getElementById("__PAY_FROM")) {
          document.getElementById("__PAY_FROM").removeAttribute("disabled");
        }
        if (document.getElementById("__PAY_AMOUNT")) {
          document.getElementById("__PAY_AMOUNT").removeAttribute("disabled");
        }
      })
  }

  /* LOTUS CASINO CALCULATION */
  __PERCENTAGE = 100;
  __LOTUS_PL = 0;
  __CALCULATED_PL = 0;
  enableLotusPlLoader() {
    if (document.getElementById("__LOADERLOTUSPL")) {
      document.getElementById("__LOADERLOTUSPL").style.display = "block";
    }
  }
  disableLotusPlLoader() {
    if (document.getElementById("__LOADERLOTUSPL")) {
      document.getElementById("__LOADERLOTUSPL").style.display = "none";
    }
  }
  openLotusPL() {
    this.enableLotusPlLoader();
    this.MatchtableService.GetLotusPL(this.__PERCENTAGE).subscribe(
      data1 => {
        this.disableLotusPlLoader();
        let data = this.CryptojsService.DecryptOrderFunction(data1);

        this.__LOTUS_PL = parseInt(data);

        this.CanculatePercentage();
      },
      error => {
        this.disableLotusPlLoader();
      });
  }
  CanculatePercentage() {
    this.__CALCULATED_PL = (this.__LOTUS_PL * this.__PERCENTAGE) / 100;
  }

  _IS_CONTACT = false;
  openContact() {
    this._IS_CONTACT = !this._IS_CONTACT;
  }

  _ACCESS_PASSWORD = "";
  EnableAdminAccess() {
    if (this._ACCESS_PASSWORD == "") {
      this.sendNotification('error', "Please enter Profile Password.");
    }
    else {
      this.UserdetailsService.validateAdminPassword(this._ACCESS_PASSWORD).subscribe(
        data1 => {
          let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));

          if (data.status != 200) {
            sessionStorage.removeItem("_ACCESS");
            this.LoginService.changeAccess("0");
            this.sendNotification("error", data.message);
            return false;
          }

          this.sendNotification("success", data.message);
          this._ACCESS_PASSWORD = "";

          if (document.getElementById("_ENABLE_ACCESS"))
            $('#_ENABLE_ACCESS').modal('hide');

          sessionStorage.setItem("_ACCESS", this.CryptojsService.encryptOrderFunction("1").toString());
          this.LoginService.changeAccess("1");
        },
        error => {
        });
    }
  }

  __HASHKEY = "b7b81e7711dd425c";
  // PAY_ID = "6803520104175511";
  PAY_ID = "6553511228190058";
  ORDER_ID = "T1644296510474";
  AMOUNT = "";
  CUST_NAME = "";
  CUST_STREET_ADDRESS1 = "";
  CUST_ZIP = "";
  CUST_PHONE = "";
  CUST_EMAIL = "";
  CUST_ID = "";
  PRODUCT_DESC = "Demo Transaction";
  CURRENCY_CODE = "356";
  RETURN_URL = "https://signup.hotsports.com/Api/Data/ApexGatewayResponseHotSports"; //"https://signup.moneyexch.uk/Api/Data/ApexGatewayResponse";
  HASH = "";
  randomString(length) {
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  submitFormNew() {
    this.CUST_PHONE = this.SessionUser.stContactNo;
    this.CUST_EMAIL = this.SessionUser.stEmailID;
    this.CUST_NAME = this.SessionUser.UserName;
    this.CUST_STREET_ADDRESS1 = "Gurgaon";
    this.CUST_ZIP = "122016";

    this.ORDER_ID = 'T' + this.randomString(16) + String(new Date().getTime());

    if (this.AMOUNT == "" || parseFloat(this.AMOUNT) <= 0.00) {
      this.sendNotification("error", "Amount should be greater than 0(zero)!");
      return false;
    }

    if (this.CUST_NAME == "") {
      this.sendNotification("error", "Please enter Customer Name.");
      return false;
    }

    if (this.CUST_STREET_ADDRESS1 == "") {
      this.sendNotification("error", "Please enter Street Address.");
      return false;
    }

    if (this.CUST_ZIP == "") {
      this.sendNotification("error", "Please enter Zip Code.");
      return false;
    }
    var form = document.createElement("form");
    form.setAttribute("method", "post");
    // form.setAttribute("target", "_blank");
    form.setAttribute("action", "https://prod.apexpay.co.in/pgui/jsp/paymentrequest");
    let inputElements = document.getElementsByClassName('signuptextfield');
    var valueArray = new Array();
    var sortedArray = new Array();
    var nameArray = [];
    for (let i = 0; i < inputElements.length; i++) {
      if (inputElements[i].getAttribute('name') == "AMOUNT")
        valueArray[inputElements[i].getAttribute('name')] = (parseFloat((inputElements[i] as HTMLInputElement).value) * 100).toString();
      else if (inputElements[i].getAttribute('name') == "ORDER_ID")
        valueArray[inputElements[i].getAttribute('name')] = this.ORDER_ID;
      else
        valueArray[inputElements[i].getAttribute('name')] = (inputElements[i] as HTMLInputElement).value;

      nameArray[i] = inputElements[i].getAttribute('name');

      var input = document.createElement('input');
      input.type = 'hidden';
      input.name = inputElements[i].getAttribute('name');

      if (inputElements[i].getAttribute('name') == "AMOUNT")
        input.value = (parseFloat((inputElements[i] as HTMLInputElement).value) * 100).toString();
      else if (inputElements[i].getAttribute('name') == "ORDER_ID")
        input.value = this.ORDER_ID;
      else
        input.value = (inputElements[i] as HTMLInputElement).value;

      form.appendChild(input);
    }
    nameArray.sort();

    var inputString = '';
    for (let j = 0; j < nameArray.length; j++) {
      var element = nameArray[j];
      inputString += '~';
      inputString += element;
      inputString += '=';
      inputString += valueArray[element];
    }
    inputString = inputString.substr(1);
    // inputString += document.getElementById('hashkey').getAttribute('value');
    inputString += this.__HASHKEY;

    // if(confirm("Do you want to proceed? "))
    {
      let options = { msgFormat: 'string', outFormat: 'hex' };
      var hash = CryptoJS.SHA256(inputString).toString().toUpperCase();
      // console.log(hash);
      var input = document.createElement('input');
      input.type = 'hidden';
      input.name = "HASH";
      input.value = hash;
      form.appendChild(input);

      document.getElementById("hash").setAttribute('ng-reflect-model', hash);
      // this.HASH = hash;

      let __DATETIME = formatDate(new Date(), 'yyyy-MM-dd', 'en');
      this.LoginService.addBalance(this.SessionUser.UserId, this.ORDER_ID, this.AMOUNT, 10, __DATETIME, this.CUST_PHONE).subscribe(
        data1 => {
          let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));

          if (data.status != 200) {
            this.sendNotification("error", data.message);
            return false;
          }

          this.sendNotification("success", data.message);
          this.CloseAddBalancePopup();

          document.body.appendChild(form);
          // console.log(form);

          form.submit();

          document.body.removeChild(form);
        },
        error => {
          this.sendNotification("error", "Something went wrong in Add Balance. Please try again.");
          this.CloseAddBalancePopup();
        })
    }
  }

  __IS_MANUAL_DONE = false;
  __SELECTED_OPTION = 1;
  PaymentOption(__WHICHOPTION) {
    this.__SELECTED_OPTION = __WHICHOPTION;
    this.__IS_MANUAL_DONE = false;
  }

  onWorldCasinoClick(__PARAM, __TAB_NO) {
    if (this.__PREVIOUSTOPTAB != -1) {
      if (document.getElementById("__TOPBARTABS_" + this.__PREVIOUSTOPTAB)) {
        document.getElementById("__TOPBARTABS_" + this.__PREVIOUSTOPTAB).className = "hoverPointer";
      }
    }
    // let __TAB_NO = (__NEXTTAB == 1 ? (__PARAM == 0 ? 1 : 2) : (__PARAM == 0 ? 0 : 3));
    this.__PREVIOUSTOPTAB = __TAB_NO;
    if (document.getElementById("__TOPBARTABS_" + __TAB_NO)) {
      document.getElementById("__TOPBARTABS_" + __TAB_NO).className = "hoverPointer router-link-active";
    }
    sessionStorage.setItem("__PREVIOUSTOPTAB", this.__PREVIOUSTOPTAB.toString());

    this.Router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
      this.Router.navigate(['/iframecasino'], { queryParams: { GameID: this.CryptojsService.encryptData(__PARAM) } }));
  }

  onFenixCasinoClick(__PARAM) {
    this.Router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
      this.Router.navigate(['/iframecasino'], { queryParams: { GameID: this.CryptojsService.encryptData(__PARAM) } }));
  }
  onDynamiRouterClick(__ROUTER, __PARAM) {
    this.Router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
      this.Router.navigate([__ROUTER], { queryParams: { GID: this.CryptojsService.encryptData(__PARAM) } }));
  }

  __IS_URL_LOADED = false;

  makeRandom(lengthOfCode: number) {
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  AutoAddBalanceNew() {
    if (this.__PAY_AMOUNT == "" || parseFloat(this.__PAY_AMOUNT) <= 0.00) {
      this.sendNotification("error", "Amount should be greater than 0(zero)!");
      return false;
    }
    if (document.getElementById("__PAY_BUTTON")) {
      document.getElementById("__PAY_BUTTON").style.display = "none";
    }
    if (document.getElementById("__PAY_CLOSE")) {
      document.getElementById("__PAY_CLOSE").style.display = "none";
    }
    if (document.getElementById("__PAY_LOADER")) {
      document.getElementById("__PAY_LOADER").style.display = "block";
    }
    if (document.getElementById("__PAY_FROM")) {
      document.getElementById("__PAY_FROM").setAttribute("disabled", "true");
    }
    if (document.getElementById("__PAY_AMOUNT")) {
      document.getElementById("__PAY_AMOUNT").setAttribute("disabled", "true");
    }
    if (document.getElementById("__PAY_TNS_ID")) {
      document.getElementById("__PAY_TNS_ID").setAttribute("disabled", "true");
    }

    let __DATETIME = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    let CUST_PHONE = this.SessionUser.stContactNo;
    let TNS_ID = this.makeRandom(10);
    this.LoginService.AutoaddBalance(this.SessionUser.UserId, TNS_ID, this.__PAY_AMOUNT, this.__SELECTED_METHOD, __DATETIME, CUST_PHONE).subscribe(
      data1 => {
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));
        if (document.getElementById("__PAY_BUTTON")) {
          document.getElementById("__PAY_BUTTON").style.display = "inline-block";
        }
        if (document.getElementById("__PAY_CLOSE")) {
          document.getElementById("__PAY_CLOSE").style.display = "inline-block";
        }
        if (document.getElementById("__PAY_LOADER")) {
          document.getElementById("__PAY_LOADER").style.display = "none";
        }
        if (document.getElementById("__PAY_FROM")) {
          document.getElementById("__PAY_FROM").removeAttribute("disabled");
        }
        if (document.getElementById("__PAY_AMOUNT")) {
          document.getElementById("__PAY_AMOUNT").removeAttribute("disabled");
        }
        if (document.getElementById("__PAY_TNS_ID")) {
          document.getElementById("__PAY_TNS_ID").removeAttribute("disabled");
        }

        if (data.status != 200) {
          this.sendNotification("error", data.message);
          return false;
        }

        this.__IS_URL_LOADED = true;
        setTimeout(() => {
          if (document.getElementById("urlLoad")) {
            document.getElementById("urlLoad").innerHTML = '<iframe src="' + data.message + '" marginwidth="0" marginheight="0" height="500px" width="100%" frameborder="0"></iframe>';
          }
        }, 500);
      },
      error => {
        this.sendNotification("error", "Something went wrong in Add Balance. Please try again.");
        // console.log("error@RegisterCreateOrder => ");
        // console.log(error);
        if (document.getElementById("__PAY_BUTTON")) {
          document.getElementById("__PAY_BUTTON").style.display = "inline-block";
        }
        if (document.getElementById("__PAY_CLOSE")) {
          document.getElementById("__PAY_CLOSE").style.display = "inline-block";
        }
        if (document.getElementById("__PAY_LOADER")) {
          document.getElementById("__PAY_LOADER").style.display = "none";
        }
        if (document.getElementById("__PAY_FROM")) {
          document.getElementById("__PAY_FROM").removeAttribute("disabled");
        }
        if (document.getElementById("__PAY_AMOUNT")) {
          document.getElementById("__PAY_AMOUNT").removeAttribute("disabled");
        }
        if (document.getElementById("__PAY_TNS_ID")) {
          document.getElementById("__PAY_TNS_ID").removeAttribute("disabled");
        }
      })
  }
  PaymentOn = ""

  PaymentGetModel() {
    this.LoginService.getpaymentgateshow().subscribe(
      data1 => {
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));
        this.PaymentOn = data[0].status;
      },
      error => {

        return false;
      });
  }

  __P_QRCODE_IMAGE = ""; //"../images/qrcode.png";
  __P_ACCOUNT_HOLDER = "";
  __P_BANK_NAME = "";
  __P_IFSC_CODE = "";
  __P_BANK_NUMBER = "";
  __P_UPI_ID = "";
  getPaymentmethod() {
    this.LoginService.getPaymentApi().subscribe(
      data1 => {
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));
        console.log(data)
        if (data.length == 0) {
          return false;
        }
        data = data.filter(({ ac }) => {
          return ac == 1;
        })


        this.__P_QRCODE_IMAGE = data[0].stImage;
        this.__P_ACCOUNT_HOLDER = data[0].Accountholder;
        this.__P_BANK_NAME = data[0].bankname;
        this.__P_IFSC_CODE = data[0].ifsccode;
        this.__P_BANK_NUMBER = data[0].banknumber;
        this.__P_UPI_ID = data[0].bttype;
      },
      error => {
      });
  }

}
