import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatchtableService } from '../services/matchtable.service';
import { SignalRService } from '../services/signal-r.service';
import { CheckmobileService } from '../services/checkmobile.service';
import { SportsshowhideService } from '../services/sportsshowhide.service';
import { CryptojsService } from '../services/cryptojs.service';
import { LoginService } from '../services/login.service';
import { Subscription } from 'rxjs';
import { HostListener } from "@angular/core";
import { formatDate } from '@angular/common';

declare var $: any;
@Component({
  selector: 'app-mainhomeview',
  templateUrl: './mainhomeview.component.html',
  styleUrls: ['./mainhomeview.component.css']
})
export class MainhomeviewComponent {
  UserID: any;

  FilteredSubMatchList = [];
  FilteredSubMatchListLength = 0;

  isMobileView = false;

  CricketShowStatus = false;
  TennisShowStatus = false;
  SoccerShowStatus = false;
  HorseRacingShowStatus = false;

  selectedWhichTab = -1;
  activeMain = "activeMain";
  activeTabPan = "active tab-pane";
  TabPan = "tab-pane";
  __SPORTSID = "4";

  __EVOLUTION = this.SignalRService.__EVOLUTION;
  __EZUGI = this.SignalRService.__EZUGI;
  __VIVOGAMING = this.SignalRService.__VIVOGAMING;
  __BETGAMES = this.SignalRService.__BETGAMES;

  url: any
  __LOTUS_GAMES = this.CryptojsService.__LOTUS_GAMES;

  private __ALLINONESUBSCRIPTION: Subscription = new Subscription();

  constructor(private activatedRoute: ActivatedRoute, private LoginService: LoginService, private CryptojsService: CryptojsService, private SportsshowhideService: SportsshowhideService, private ActivatedRoute: ActivatedRoute, private CheckmobileService: CheckmobileService, private SignalRService: SignalRService, private router: Router, private MatchtableService: MatchtableService) {
    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnNewMaster().subscribe(
        (message) => {
          setTimeout(() => {
            this.refreshMarketList(message);
          }, 2000);
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnShowHideMatch().subscribe(
        (message) => {
          if (message.STATUS == true || message.STATUS == "D")
            this.showHideMatch(message.SID);
          else {
            // console.log(data);
            setTimeout(() => {
              this.resetMatchList()
            }, 2000);
          }
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnShowHideSport().subscribe(
        (message) => {
          this.setShowHideSports(message.SID, message.PID, message.STATUS);
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SportsshowhideService.getOnSportsShowHideShowStatus().subscribe(
        (message) => {
          this.getHidedSports();
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.MatchtableService.getOnInPlayMatches().subscribe(
        (message) => {
          this.checkInPlayMatches();
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.MatchtableService.getOnResetMatchList().subscribe(
        (message) => {
          this.resetMatchList();
        })
    );
    this.__ALLINONESUBSCRIPTION.add(
      this.LoginService.getBenners().subscribe(
        (message) => {
          this.getBanners();
        })
    );
    this.getBanners();
  }

  __ACTIVE_S = "item active";
  __INACTIVE_S = "item";
  __BANNERS = [];
  getBanners() {
    this.__BANNERS = this.LoginService.__BANNERS;
  }

  IsMarketAvailable(MarketID, data) {
    return data.filter(({ stMarketID }) => {
      return stMarketID == MarketID
    })
  }

  deleteCurrentMatch(MrktID, data) {
    let i = 0;
    for (let result of data) {
      if (result.stMarketID == MrktID) {
        break;
      }
      i++;
    }

    var LocalData = data;

    delete LocalData[i];

    var filtered = LocalData.filter(function (el) {
      return el != null;
    });
    i = 0;
    return filtered;
  }

  resetMatchList() {
    let __NEWMATCHES = [];
    switch (parseInt(this.__SPORTSID)) {
      case -1:
        __NEWMATCHES = this.MatchtableService.InPlayMatchList;
        this.reSetInPlayMatches();
        break;

      case 4:
        __NEWMATCHES = this.MatchtableService.CricketMatchList.filter(({ stMarketName }) => {
          return stMarketName != "Winner"
        });
        break;

      case 2:
        __NEWMATCHES = this.MatchtableService.TennisMatchList;
        break;

      case 1:
        __NEWMATCHES = this.MatchtableService.SoccerMatchList;
        break;

      case 7:
        __NEWMATCHES = this.MatchtableService.HorseMatchList;
        break;
    }

    if (__NEWMATCHES.length > 0) {
      for (let d of this.FilteredSubMatchList) {
        let __REMOVED = this.IsMarketAvailable(d.stMarketID, __NEWMATCHES);
        if (__REMOVED.length == 0) {
          let filtered = this.deleteCurrentMatch(d.stMarketID, this.FilteredSubMatchList);
          this.FilteredSubMatchList = (filtered.length > 0 ? filtered : []);
          this.FilteredSubMatchListLength = this.FilteredSubMatchList.length;
        }
      }

      setTimeout(() => {
        for (let d of __NEWMATCHES) {
          let __ADDED = this.IsMarketAvailable(d.stMarketID, this.FilteredSubMatchList);
          if (__ADDED.length == 0) {
            this.FilteredSubMatchList.push(d);
            this.FilteredSubMatchListLength = this.FilteredSubMatchList.length;
          }
        }
      }, 1000);

      if (this.__SPORTSID == '-1') {
        this.reSetInPlayMatches();
      }
    }
    else {
      this.FilteredSubMatchList = [];
      this.FilteredSubMatchListLength = this.FilteredSubMatchList.length;
    }

    this.checkMasterFancy();

    this.LoginService.getM2MForAllMaster(this.UserID).subscribe(
      data1 => {
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));
        // console.log("getM2MForAllMaster => ");
        // console.log(data);
        if (data.length > 0)
          this.setM2MForAll(data)
      },
      error => {
        // console.log("Error@getMatchList : " + error.message);
      });

    // if (this.SignalRService.__CURRENTODDS == undefined)
    //   return false;

    // let __ODDSDATA = this.SignalRService.__CURRENTODDS;
    // setTimeout(() => {
    //   // for (let i = 0; i < __ODDSDATA.length; i++) {
    //   //   this.setFrontRates(__ODDSDATA[i]);
    //   // }
    // }, 10);
  }

  goToUnauthorize() {
    this.router.navigate(["/Unauthorize"]);
  }

  getMatchListWinner() {
    if (this.MatchtableService.CurrentMatchList == undefined) {
      setTimeout(() => {
        this.getMatchListWinner();
      }, 100);
      return false;
    }

    this.FilteredSubMatchList = [];
    this.FilteredSubMatchListLength = 0;
    if (this.__FRONT_RATE_TIMER) {
      clearTimeout(this.__FRONT_RATE_TIMER);
      this.__FRONT_RATE_TIMER = null;
    }

    let __MATCHES = this.MatchtableService.CurrentMatchList.filter(({ stMarketName }) => {
      return stMarketName == "Winner"
    })

    this.FilteredSubMatchList = __MATCHES;
    this.FilteredSubMatchListLength = this.FilteredSubMatchList.length;

    // if (this.SignalRService.__CURRENTODDS == undefined)
    //   return false;

    // let __ODDSDATA = this.SignalRService.__CURRENTODDS;
    // setTimeout(() => {
    //   // for (let i = 0; i < __ODDSDATA.length; i++) {
    //   //   this.setFrontRates(__ODDSDATA[i]);
    //   // }
    // }, 10);
  }

  __PreviousIndex = -1;
  getMatchList(__SPORTSID, __INDEX) {
    if (this.__PreviousIndex == __INDEX) {
      return false;
    }
    if (document.getElementById("__MATCHTABLELOADER")) {
      document.getElementById("__MATCHTABLELOADER").style.height = "100%";
    }
    if (this.MatchtableService.CurrentMatchList == undefined) {
      setTimeout(() => {
        this.getMatchList(__SPORTSID, __INDEX);
      }, 100);
      return false;
    }

    this.FilteredSubMatchList = [];
    this.FilteredSubMatchListLength = 0;
    if (this.__FRONT_RATE_TIMER) {
      clearTimeout(this.__FRONT_RATE_TIMER);
      this.__FRONT_RATE_TIMER = null;
    }

    if (document.getElementById("__HomeTabs_" + this.__PreviousIndex)) {
      document.getElementById("__HomeTabs_" + this.__PreviousIndex).className = "";
    }
    if (document.getElementById("__HomeTabs_" + __INDEX)) {
      document.getElementById("__HomeTabs_" + __INDEX).className = "active";
    }
    this.__PreviousIndex = __INDEX;

    setTimeout(() => {
      if (document.getElementById("__MATCHTABLELOADER")) {
        document.getElementById("__MATCHTABLELOADER").style.height = "0%";
      }
    }, 10);

    this.__SPORTSID = __SPORTSID;

    switch (this.__SPORTSID) {
      case '-1':
        // this.MatchtableService.InPlayMatchList = this.MatchtableService.CurrentMatchList.filter(({ stMarketID, stSportsID, btMarketStatus, stMarketName }) => {
        //   return stMarketID != null && stMarketID.split(".")[0] == "1" && stSportsID != null && (((stSportsID != "7" && stSportsID != "4339") && (stMarketName == 'Winner' || stMarketName == 'Match Odds')) || (stSportsID == "7" || stSportsID == "4339"))
        // })
        this.FilteredSubMatchList = this.MatchtableService.InPlayMatchList;
        // this.reSetInPlayMatches();
        break;

      case '4':
        // this.FilteredSubMatchList = this.MatchtableService.CricketMatchList.filter(({ stMarketName }) => {
        //   return stMarketName != "Winner"
        // });
        this.FilteredSubMatchList = this.MatchtableService.CricketMatchList;
        break;

      case '2':
        this.FilteredSubMatchList = this.MatchtableService.TennisMatchList;
        break;

      case '1':
        this.FilteredSubMatchList = this.MatchtableService.SoccerMatchList;
        break;

      case '7':
        this.FilteredSubMatchList = this.MatchtableService.HorseMatchList;
        break;
    }
    this.checkMasterFancy();
    this.FilteredSubMatchListLength = this.FilteredSubMatchList.length;
    this.MatchtableService.getRequestList(this.FilteredSubMatchList);
    // console.log("FILTERED", this.FilteredSubMatchList)

    // if (this.__FRONT_RATE_TIMER) {
    //   clearTimeout(this.__FRONT_RATE_TIMER);
    //   this.__FRONT_RATE_TIMER = null;
    // }
    // this.getFrontRate();    

    setTimeout(() => {
      // let __ODDSDATA = this.SignalRService.__CURRENTODDS;
      // if (__ODDSDATA != undefined) {
      //   this.setFrontRate(false);
      // }
      // else {
      //   if (this.__FRONT_RATE_TIMER) {
      //     clearTimeout(this.__FRONT_RATE_TIMER);
      //     this.__FRONT_RATE_TIMER = null;
      //   }
      //   // this.__FRONT_M = false;
      //   this.getFrontRate();
      // }
      this.setM2MForAll(this.ALLM2M);
    }, 200);
  }

  setFrontRate(data) {
    for (let i = 0; i < this.SignalRService.__CURRENTODDS.length; i++) {
      this.setFrontRates(this.SignalRService.__CURRENTODDS[i]);

      if (data == false && i == this.SignalRService.__CURRENTODDS.length - 1) {
        setTimeout(() => {
          if (this.__FRONT_RATE_TIMER) {
            clearTimeout(this.__FRONT_RATE_TIMER);
            this.__FRONT_RATE_TIMER = null;
          }
          // this.__FRONT_M = false;
          this.getFrontRate();
        }, 500);
      }
    }
  }

  checkMasterFancy() {
    let __MATCHES = this.MatchtableService.CurrentMatchList;
    let i = 0;
    for (let d of this.FilteredSubMatchList) {
      let __CHECK = __MATCHES.filter(({ stSportsName, stEventID, stMarketName }) => {
        return (stSportsName.split("_")[0].toLowerCase().match("fancy") || stMarketName.toLowerCase().match("goal") || stMarketName.toLowerCase().match("chance") || stMarketName.toLowerCase().match("winner")) && stEventID == this.FilteredSubMatchList[i].stEventID
      });

      this.FilteredSubMatchList[i]['isFancy'] = (__CHECK.length > 0 ? 1 : 0);
      __CHECK = [];
      i++;
    }
  }

  onCasinoClick(__GID) {
    let __ROUTER = (!this.CheckmobileService.isMobileView ? "lotuscasino" : "lotuscasino_Mobile")

    if (__ROUTER != "")
      this.onDynamicRouterClickNew(__ROUTER, __GID)
  }

  onDynamicRouterClickNew(__ROUTER, __PARAM) {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
      this.router.navigate([__ROUTER], { queryParams: { GID: this.CryptojsService.encryptData(__PARAM) } }));
  }

  onEzugiButtonClick(__ROUTER, __PARAM, __PARAM1) {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
      this.router.navigate([__ROUTER], { queryParams: { system: this.CryptojsService.encryptData(__PARAM), pc: this.CryptojsService.encryptData(__PARAM1) } }));
  }

  setM2MForAll(data) {
    // console.log("SETM2M", this.ALLM2M)
    for (let m2mData of this.ALLM2M) {
      this.ALLM2ME[m2mData.stEventId] = m2mData.stValue.toFixed(2);
      // if (document.getElementById('InPlaym2m_' + m2mData.stEventId)) {
      //   document.getElementById('InPlaym2m_' + m2mData.stEventId).innerHTML = m2mData.stValue.toFixed(2);
      //   if (m2mData.stValue < 0) {
      //     document.getElementById('InPlaym2m_' + m2mData.stEventId).classList.remove("htTnxO");
      //     document.getElementById('InPlaym2m_' + m2mData.stEventId).classList.add("ksWbmr");
      //   }
      //   else {
      //     document.getElementById('InPlaym2m_' + m2mData.stEventId).classList.remove("ksWbmr");
      //     document.getElementById('InPlaym2m_' + m2mData.stEventId).classList.add("htTnxO");
      //   }
      // }
    }
    // console.log("M2ME", this.ALLM2ME)
  }

  // setM2MForAll(data) {
  //   if (this.MatchtableService.CurrentMatchList == undefined) {
  //     setTimeout(() => {
  //       this.setM2MForAll(data);
  //     }, 100);
  //     return false;
  //   }

  //   setTimeout(() => {
  //     for (let m2mData of data) {
  //       let CurrentEvent = m2mData.stEventId;

  //       let CurrentMatch = this.FilteredSubMatchList.filter(({ stEventID, stMarketID }) => {
  //         return stEventID == CurrentEvent || stMarketID == CurrentEvent
  //       });

  //       if (CurrentMatch.length > 0) {
  //         if (document.getElementById('InPlaym2m_' + CurrentEvent)) {
  //           document.getElementById('InPlaym2m_' + CurrentEvent).innerHTML = m2mData.stValue.toFixed(2);
  //           if (m2mData.stValue < 0) {
  //             document.getElementById('InPlaym2m_' + CurrentEvent).classList.remove("htTnxO");
  //             document.getElementById('InPlaym2m_' + CurrentEvent).classList.add("ksWbmr");
  //           }
  //           else {
  //             document.getElementById('InPlaym2m_' + CurrentEvent).classList.remove("ksWbmr");
  //             document.getElementById('InPlaym2m_' + CurrentEvent).classList.add("htTnxO");
  //           }
  //         }
  //       }
  //     }
  //   }, 1000);
  // }

  @HostListener("window:callRoute", ['$event'])
  oncallRoute(event) {
    if (event.detail == null || event.detail == undefined) {
      return false;
    }
    if (event.detail.match("ezugi")) {
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/casino/ezugi']));
    }
    else if (event.detail.match("evolution")) {
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/casino/evolution'], { queryParams: { GID: this.CryptojsService.encryptData(1000000) } }));
    }
    else
      this.onCasinoClick(event.detail);
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.selectedWhichTab = (params['params'] == undefined ? 0 : parseInt(this.CryptojsService.DecryptOrderFunction(params['params'])));
      // console.log("this.selectedWhichTab : " + this.selectedWhichTab)
    });

    window.scroll(0, 0);
    this.isMobileView = this.CheckmobileService.isMobileView;

    if (this.isMobileView)
      this.router.navigate(["/Home_mobile"]);
    // if(!this.isMobileView)
    //   this.router.navigate(["/Home"]);
    if (this.isMobileView)
      this.router.navigate(["/Home_mobile"]);

    if (sessionStorage.getItem('user') === null) {
      this.Logout();
      return false;
    }
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    this.UserID = SessionUser.UserId;
    // var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));

    if (this.selectedWhichTab != 1)
      this.getMatchList('4', 1);
    else
      this.getMatchListWinner();

    this.getM2MForAll();

    // this.LoginService.getM2MForAllMaster(this.UserID).subscribe(
    //   data1 => {
    //     let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));
    //     // console.log(data);
    //     if (data.length > 0)
    //       this.setM2MForAll(data)
    //   },
    //   error => {
    //     // console.log("Error@getMatchList : " + error.message);
    //   });

    this.getHidedSports();
    this.getFrontRate();
  }
  __FRONT_RATE_TIMER: any;
  getFrontRate() {
    if (sessionStorage.getItem("user") == undefined || sessionStorage.getItem("user") == null) {
      return false;
    }

    this.SignalRService.getFrontRate();
    this.__FRONT_RATE_TIMER = setTimeout(() => {
      this.getFrontRate();
    }, this.SignalRService.__FRONT_TIME);

    if (this.MatchtableService.CurrentMatchList == null) {
      setTimeout(() => {
        this.getFrontRate();
      }, 100);
      return false;
    }

    if (this.FilteredSubMatchList == null || this.FilteredSubMatchList == undefined) {
      setTimeout(() => {
        this.getFrontRate();
      }, 100);
      return false;
    }

    if(window.location.hash.split(this.CryptojsService.decryptData('U2FsdGVkX19QfMa3QnNU/Ypq92x4XiBM+WiKo6SbGbI='))[0].indexOf(this.CryptojsService.decryptData("U2FsdGVkX189fefXzk/WcI+a+/yo9nJm89xIZ5et6bI=")) >= 0) {
      let _MID = "";
      //for (let m of this.MatchtableService.CurrentMatchList) {
      for (let m of this.FilteredSubMatchList) {
        if (m.stMarketName == "Match Odds" || m.stMarketName == "Winner") {
          if (_MID == "") _MID = m.stMarketID;
          else _MID = _MID + "," + m.stMarketID;
        }
      }
      if (_MID == "") {
        if (this.__FRONT_RATE_TIMER) {
          clearTimeout(this.__FRONT_RATE_TIMER);
          this.__FRONT_RATE_TIMER = null;
        }

        setTimeout(() => {
          this.getFrontRate();
        }, 100);
        return false;
      }
      else {
        // this.LoginService.getRateFromJSON(_MID).subscribe(
        this.LoginService.getMasterSJSON(this.__SPORTSID).subscribe(
          (data1: any) => {
            // let data = JSON.parse(this.CryptojsService.DecryptOrderFunctionU(data1, "U2FsdGVkX18LyQo6FkAa5M9ec8JxMhgBmHG1bx21XiE="));
            let data = JSON.parse(this.CryptojsService.decryptAES256(data1, "0V27WqAzmrZRSL2A0V27WqAzmrZRSL2A","0V27WqAzmrZRSL2A"));
            // console.log("FRONT LIVE", data);

            switch (this.__SPORTSID) {
              case "-1":
                this.MatchtableService.InPlayMatchList = data;
                break;
  
              case "4":
                this.MatchtableService.CricketMatchList = data;
                break;
  
              case "2":
                this.MatchtableService.TennisMatchList = data;
                break;
  
              case "1":
                this.MatchtableService.SoccerMatchList = data;
                break;

              case "7":
                this.MatchtableService.HorseMatchList = data;
                break;
            }

            for (let i = 0; i < data.length; i++) {
              if (data[i] && data[i].stMarketID) {
                if (document.getElementById('CricketTRInPlay_' + data[i].stMarketID)) {
                  this.setFrontRates(data[i]);
                }

                let stC = this.SignalRService.__CURRENTODDS.findIndex(m => {
                  return m.stMarketID == data[i].stMarketID
                })
                if (stC >= 0) {
                  this.SignalRService.__CURRENTODDS[stC] = data[i];
                }

                let stI = this.MatchtableService.CurrentMatchList.findIndex(m => {
                  return m.stMarketID == data[i].stMarketID
                })
                if (stI >= 0) {
                  this.MatchtableService.CurrentMatchList[stI] = data[i];
                }

                if (i == data.length - 1) {
                  this.setOdds();
                }

                this.LoginService.onChangeDetect();
              }
            }
          },
          error => {
          });
      }
    }
    else {
      if (this.__FRONT_RATE_TIMER) {
        clearTimeout(this.__FRONT_RATE_TIMER);
        this.__FRONT_RATE_TIMER = null;
      }
    }
  }

  ALLM2M = [];
  ALLM2ME = [];
  getM2MForAll() {
    // console.log("REQ > " + formatDate(new Date(), 'HH:mm:ss', 'en'));
    this.LoginService.getM2MForAllMaster(this.UserID).subscribe(
      data1 => {
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));
        // console.log("RESP > " + formatDate(new Date(), 'HH:mm:ss', 'en'), data);

        this.ALLM2M = data;
        if (data.length > 0) {
          this.setM2MForAll(data)
        }
      },
      error => {
      });
  }

  setShowHideSports(data, ParentID, Status) {
    switch (data) {
      case "4":
        this.CricketShowStatus = Status;
        break;

      case "2":
        this.TennisShowStatus = Status;
        break;

      case "1":
        this.SoccerShowStatus = Status;
        break;

      case "7":
        this.HorseRacingShowStatus = Status;
        break;
    }
    if (this.__SPORTSID == '-1')
      this.reSetInPlayMatches();
  }

  onDynamicRouterClick(__ROUTER) {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
      this.router.navigate([__ROUTER]));
  }

  checkInPlayMatches() {
    if (this.MatchtableService.isMainHomeRefresh == 1) {
      this.MatchtableService.isMainHomeRefresh = 0;
      setTimeout(() => {
        this.resetMatchList()
      }, 2000);
    }
  }

  ngAfterViewInit() {
    $('.carousel .vertical .item').each(function () {
      var next = $(this).next();
      // console.log(next)
      if (!next.length) {
        next = $(this).siblings(':first');
      }
      next.children(':first-child').clone().appendTo($(this));
      for (var i = 1; i < 3; i++) {
        next = next.next();
        if (!next.length) {
          next = $(this).siblings(':first');
        }
        next.children(':first-child').clone().appendTo($(this));
      }
    });

    $('.carousel .vertical .item .login-hover').click(function ($event) {
      var dataId = $(this).attr("data-id");

      var event = new CustomEvent("callRoute", { 'detail': dataId });
      window.dispatchEvent(event);
    });
  }

  setOdds() {
    if (this.SignalRService.__CURRENTODDS == undefined || this.SignalRService.__CURRENTODDS == null) {
      // if (document.getElementById("loaderLoad")) {
      //   document.getElementById("loaderLoad").className = "loaderLoad";
      // }
      return false;
    }
    if (this.SignalRService.__CURRENTODDS.length <= 0) {
      // if (document.getElementById("loaderLoad")) {
      //   document.getElementById("loaderLoad").className = "loaderLoad";
      // }
      setTimeout(() => {
        this.setOdds();
      }, 200);
      return false;
    }

    setTimeout(() => {
      let __ODDSDATA = this.SignalRService.__CURRENTODDS;
      // if (document.getElementById("loaderLoad")) {
      //   document.getElementById("loaderLoad").className = "loaderLoad";
      // }
      for (let i = 0; i < __ODDSDATA.length; i++) {
        this.setFrontRates(__ODDSDATA[i]);
      }
    }, 500);
  }

  setFrontRates(data) {
    if (data && data.submaster) {
      let depth = data.submaster;

      if(data.btMarketStatus == 3 || data.btMarketStatus == 2) {
        for (var d = 0; d < depth.length; d++) {
          if (document.getElementById('__SUSPEND_' + depth[d].stInstrumentID))
            document.getElementById('__SUSPEND_' + depth[d].stInstrumentID).style.display = "flex";
          
          if (document.getElementById("__BACK_P_INPLAY_" + depth[d].stInstrumentID))
            document.getElementById("__BACK_P_INPLAY_" + depth[d].stInstrumentID).innerHTML = "-";

          if (document.getElementById("__LAY_P_INPLAY_" + depth[d].stInstrumentID))
            document.getElementById("__LAY_P_INPLAY_" + depth[d].stInstrumentID).innerHTML = "-";
        }
      }
      else {        
        for (var d = 0; d < depth.length; d++) {
          if (document.getElementById("__BACK_P_INPLAY_" + depth[d].stInstrumentID)) {
            if(depth[d].rates.Back) {
              for(var b=0; b < depth[d].rates.Back.length; b++) {
                if(depth[d].rates.Back[0].Price.toFixed == "0.00") {
                  if (document.getElementById('__SUSPEND_' + depth[d].stInstrumentID))
                    document.getElementById('__SUSPEND_' + depth[d].stInstrumentID).style.display = "flex";
                  
                  document.getElementById("__BACK_P_INPLAY_" + depth[d].stInstrumentID).innerHTML = "-"; 
                }
                else {
                  if (document.getElementById('__SUSPEND_' + depth[d].stInstrumentID))
                    document.getElementById('__SUSPEND_' + depth[d].stInstrumentID).style.display = "none";

                  document.getElementById("__BACK_P_INPLAY_" + depth[d].stInstrumentID).innerHTML = depth[d].rates.Back[0].Price.toFixed(2);
                }
              }
            }
          }

          if (document.getElementById("__LAY_P_INPLAY_" + depth[d].stInstrumentID)) {
            if(depth[d].rates.Lay) {
              for(var b=0; b < depth[d].rates.Lay.length; b++) {
                if(depth[d].rates.Lay[0].Price.toFixed == "0.00") {
                  if (document.getElementById('__SUSPEND_' + depth[d].stInstrumentID))
                    document.getElementById('__SUSPEND_' + depth[d].stInstrumentID).style.display = "flex";
                  
                  document.getElementById("__LAY_P_INPLAY_" + depth[d].stInstrumentID).innerHTML = "-"; 
                }
                else {
                  if (document.getElementById('__SUSPEND_' + depth[d].stInstrumentID))
                    document.getElementById('__SUSPEND_' + depth[d].stInstrumentID).style.display = "none";

                  document.getElementById("__LAY_P_INPLAY_" + depth[d].stInstrumentID).innerHTML = depth[d].rates.Lay[0].Price.toFixed(2);
                }
              }
            }
          }
        }
      }      
    }
  }
  AnimationDuration = 50;
  RateAnimation(__ELEMENT) {
    if (__ELEMENT)
      __ELEMENT.classList.add("Rate-animation");
    setTimeout(() => {
      if (__ELEMENT)
        __ELEMENT.classList.remove("Rate-animation");
    }, this.AnimationDuration);
  }

  reSetInPlayMatches() {
    if (this.CricketShowStatus) {
      this.FilteredSubMatchList = this.MatchtableService.InPlayMatchList.filter(({ stSportsID }) => {
        return stSportsID != null && stSportsID != "4"
      })
    }
    else {
      this.FilteredSubMatchList = this.MatchtableService.InPlayMatchList;
    }

    if (this.FilteredSubMatchList == undefined)
      return false;

    if (this.TennisShowStatus) {
      this.FilteredSubMatchList = this.FilteredSubMatchList.filter(({ stSportsID }) => {
        return stSportsID != null && stSportsID != "2"
      })
    }
    if (this.SoccerShowStatus) {
      this.FilteredSubMatchList = this.FilteredSubMatchList.filter(({ stSportsID }) => {
        return stSportsID != null && stSportsID != "1"
      })
    }

    if (this.HorseRacingShowStatus) {
      this.FilteredSubMatchList = this.FilteredSubMatchList.filter(({ stSportsID }) => {
        return stSportsID != null && stSportsID != "7"
      })
    }

    this.FilteredSubMatchList = this.FilteredSubMatchList.filter(({ stMarketName }) => {
      return stMarketName != "Winner"
    });
    this.FilteredSubMatchListLength = this.FilteredSubMatchList.length;
  }

  getHidedSports() {
    if (this.__SPORTSID == '-1') {
      this.reSetInPlayMatches();
    }
    this.CricketShowStatus = this.SportsshowhideService.CricketShowStatus;

    this.TennisShowStatus = this.SportsshowhideService.TennisShowStatus;

    this.SoccerShowStatus = this.SportsshowhideService.SoccerShowStatus;

    this.HorseRacingShowStatus = this.SportsshowhideService.HorseRacingShowStatus;
  }
  showHideMatch(data) {
    // if(data != "")
    // {
    //   if(document.getElementById("CricketTR_" + data))
    //   {
    //     document.getElementById("CricketTR_" + data).style.display = "none";
    //   }
    // }
  }

  changeNavigation(EventID, stSportsID, stMarketID) {
    if (this.__FRONT_RATE_TIMER) {
      clearTimeout(this.__FRONT_RATE_TIMER);
      this.__FRONT_RATE_TIMER = null;
    }
    this.router.navigate(['/Match:TableView'], { queryParams: { EventsID: EventID, SportsID: stSportsID, MarketID: stMarketID } });
  }

  Logout() {
    // console.log("Logout Called");
    delete sessionStorage.user;
    // this.AppComponent.checkLogin();
    // sessionStorage.clear();
    this.isMobileView = this.CheckmobileService.isMobileView;
    if (!this.isMobileView)
      this.router.navigate(['/login']);
    else
      this.router.navigate(['/login_mobile']);
  }

  ngOnDestroy() {
    if (this.__FRONT_RATE_TIMER) {
      clearTimeout(this.__FRONT_RATE_TIMER);
      this.__FRONT_RATE_TIMER = null;
    }
    this.__ALLINONESUBSCRIPTION.unsubscribe();
  }

  refreshMarketList(result) {
    this.resetMatchList()
    return false;
  }
  trackByFn(index, item) {
    return index; // or item.id
  }
}