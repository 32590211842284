<div class="back-light">

<div class="preloader">
  <!-- <span style="color: #ffffff; text-align: center; font-size: 30px; display: block; margin-top: 25%;">SITE IS UNDER MAINTENANCE</span> -->
  <div class="loaderLogin">
    
    <img src="./images/24Favicon.png" style="width: 100px;" class="loaderMainLogin">
    <!-- <i class="loaderMainLogin fa fa-star fs-130"></i> -->
    <!-- <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span> -->
  </div>
</div>

<section id="header">

  <nav id="cssmenu" class="mobile">
    <div class="logo">
      <a class="logo">
        <img src="images/HOTSPORTS/header-logo.png" class="img-responsive" alt="" />
      </a>
    </div>
    <div id="head-mobile"></div>
    <div class="login-apk-class">
      <button class="btn btn-primary btnLogin m-r-5" data-toggle="modal" (click)="openSignup()" style="right: 75px;" data-target="#registerForm">Register</button>
      <button class="btn btn-primary btnLogin m-r-5" data-toggle="modal" (click)="openLogin()" data-target="#loginForm">Login</button>
      <!-- <a href="{{__APK_URL}}" target="_blank" class="download-apk"><i
        class="fa fa-android m-r-5"></i>Download APK</a> -->
    </div>
  </nav>
</section>

<!-- Modal -->
<div class="modal fade" id="registerForm" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content bg-black-80 text-white p-3 px-md-5 border-white">
      <div class="modal-header border-0" *ngIf="!_IS_REGISTER_SUCCESS">
      <button type="button" class="close popupClose" (click)="closeLogin()" style="color: rgb(255, 255, 255)!important;" data-dismiss="modal">&times;</button>
      <h5 class="modal-title" *ngIf="!__IS_SUCCESS">REGISTER</h5>
      <h5 class="modal-title" *ngIf="__IS_SUCCESS">OTP has been sent to your Mobile Number.</h5>
    </div>
    <div class="modal-header border-0" *ngIf="_IS_REGISTER_SUCCESS">
      <button type="button" class="close popupClose" (click)="closeLogin()" style="color: rgb(255, 255, 255)!important;" data-dismiss="modal">&times;</button>
      <h5 class="modal-title">Registration Success</h5>
    </div>
    <div class="modal-body fs-18" *ngIf="!_IS_REGISTER_SUCCESS">
      <form *ngIf="!__IS_SUCCESS">
        <div class="form-group row">
          <label for="USERNAME" class="col-sm-12 col-form-label">Username<span *ngIf="space_error != ''" class="text-red fs-11 m-l-5">({{space_error}})</span></label>
          <div class="col-sm-12">
            <input type="text" class="form-control" name="USERNAME" id="USERNAME" [(ngModel)]="USERNAME" (keypress)="checkUserName($event)" placeholder="Enter your username" />
          </div>
        </div>
        <div class="form-group row">
          <label for="FIRSTNAME" class="col-sm-12 col-form-label">Full Name</label>
          <div class="col-sm-12">
            <input type="text" class="form-control" name="FIRSTNAME" id="FIRSTNAME" [(ngModel)]="FIRSTNAME" placeholder="Enter your fullname" />
          </div>
        </div>
        <div class="form-group row">
          <label for="MOBILENUMBER" class="col-sm-12 col-form-label">Contact No.</label>
          <div class="col-sm-12">
            <div class="input-group">
              <span class="input-group-addon">+91</span>
              <input type="number" class="form-control" name="MOBILENUMBER" id="MOBILENUMBER" [(ngModel)]="MOBILENUMBER" placeholder="Mobile No." />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="EMAILADDRESS" class="col-sm-12 col-form-label">Email Id</label>
          <div class="col-sm-12">
            <input type="email" class="form-control" name="EMAILADDRESS" id="EMAILADDRESS" [(ngModel)]="EMAILADDRESS" placeholder="Enter your email" />
          </div>
        </div>
        <div class="form-group row">
          <label for="PASSWORD" class="col-sm-12 col-form-label">Password</label>
          <div class="col-sm-12">
            <input type="password" class="form-control" name="PASSWORD" id="PASSWORD" [(ngModel)]="PASSWORD" placeholder="Password" />
          </div>
        </div>
        <div class="form-group row">
          <label for="REPASSWORD" class="col-sm-12 col-form-label">Confirm Password</label>
          <div class="col-sm-12">
            <input type="password" class="form-control" name="REPASSWORD" id="REPASSWORD" [(ngModel)]="REPASSWORD" placeholder="Password" />
          </div>
        </div>
        <div *ngIf="!_BTAG_STATUS" class="form-group row">
          <label for="REPASSWORD" class="col-sm-12 col-form-label">Promo Code (Optional)</label>
          <div class="col-sm-12">
            <input type="text" class="form-control" name="_BTAG" id="_BTAG" [(ngModel)]="_BTAG" placeholder="Enter Promo Code here" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-12 w-100">
            <div class="m-b-10 fs-12">
                <input id="AGREE_TERMS" (change)="onCheckedCheckBox($event.target.checked)" type="checkbox" target="_blank" rel="noopener" name="rule" class="reg-check_email">
                I M ABOVE 18 YEARS OF AGE AND READ AND ACCEPTED THE TERMS AND CONDITION.
            </div>
            <button type="submit" (click)="registerClient()" id="__RegisterBtn1" class="btn btn-primary btnLoginPopup">
              Register
            </button>
            <div class="loader" style="display: none;" id="__REGISTER_LOADER1"></div>
          </div>
        </div>
      </form>
      <form *ngIf="__IS_SUCCESS">
        <div class="form-group row">
          <label for="__OTP" class="col-sm-12 col-form-label">Enter OTP</label>
          <div class="col-sm-12">
            <input type="number" class="form-control" name="__OTP" id="__OTP" [(ngModel)]="__OTP" placeholder="Enter OTP" />
            <div class="col-sm-12 w-100 text-right">
              <span class="text-right" *ngIf="!IS_RESEND_OTP">{{OTPTIMER}}</span>
              <span class="text-right" *ngIf="IS_RESEND_OTP"><div (click)="ResendOTPMethod()" class="hoverPointer"><i class="fa fa-repeat"></i> Resend OTP</div></span>
            </div>
          </div>
        </div>
        
        <div class="form-group row">
          <div class="col-sm-12 w-100">
            <button type="submit" (click)="RegisterClientSend()" id="__RegisterBtn" class="btn btn-primary btnLoginPopup">
              VERIFY & REGISTER
            </button>
            <div class="loader" style="display: none;" id="__REGISTER_LOADER"></div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-body fs-18" *ngIf="_IS_REGISTER_SUCCESS">
      <div class="row">
        <div class="col-md-12">
          <h3>Your Registration has been success.</h3>
          <p>Your User ID is : <b>{{__UserName}}</b></p>
          <p>Click here to Login <b><button class="btn btn-primary btnLogin" data-toggle="modal" (click)="openLogin();CloseSignupPopup();"
            data-target="#loginForm">Login</button></b></p>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<div class="modal fade" id="loginForm" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered" role="document">
  <div *ngIf="!__IS_FORGOT_PASSWORD" class="modal-content bg-black-80 text-white p-3 px-md-5 border-white">
    <div class="modal-header border-0">
      <button type="button" class="close popupClose" (click)="closeLogin()" style="color: rgb(255, 255, 255)!important;" data-dismiss="modal">&times;</button>
      <h4 class="modal-title">LOGIN</h4>
    </div>
    <div class="modal-body fs-18">
      <form method="POST" name="form" (ngSubmit)="login()" #f="ngForm">
        <div class="form-group row">
          <label for="inputEmail" class="col-sm-12 col-form-label">Username</label>
          <div class="col-sm-12">
            <input type="text" class="form-control" id="inputEmail" name="ID" [(ngModel)]="model.ID"
              placeholder="Enter your Username/Email" />
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword" class="col-sm-12 col-form-label">Password</label>
          <div class="col-sm-12">
            <input type="password" class="form-control" id="inputPassword" name="Key" [(ngModel)]="model.Key"
              placeholder="Enter your Password" />
          </div>
        </div>
        <div class="form-group row m-t-20">
          <div class="col-sm-12 w-100">
            <button type="submit" id="LoginButton" class="btn btn-primary btnLoginPopup">
              Login
            </button>
          </div>
        </div>
        <div class="form-group row m-t-20">
          <div class="col-sm-12 w-100">
            <div class="col-sm-6 w-100 text-right">
              <a (click)="openForgotPassword(true)" class="color-white hoverPointer">Forgot Password?</a>
            </div>
          </div>
        </div>
        
      </form>
    </div>
  </div>
  
  <div *ngIf="__IS_FORGOT_PASSWORD" class="modal-content bg-black-80 text-white p-3 px-md-5 border-white">
    <div class="modal-header border-0">
      <button type="button" class="close popupClose" (click)="closeLogin()" style="color: rgb(255, 255, 255)!important;" data-dismiss="modal">&times;</button>
      <h4 class="modal-title">FORGOT PASSWORD</h4>
    </div>
    <div class="modal-body fs-18">
      <form method="POST" *ngIf="__IS_SEND_FORGOT_PASSWORD == -1">
        <div class="form-group row">
          <label for="__MOBILENUMBER" class="col-sm-12 col-form-label">Mobile Number :</label>
          <div class="col-sm-12">
            <input type="text" class="form-control" id="__MOBILENUMBER" name="__MOBILENUMBER" [(ngModel)]="__MOBILENUMBER"
              placeholder="Enter your Mobile Number" />
          </div>
        </div>
        <div class="form-group row m-t-20">
          <div class="col-sm-12 w-100">
            <button (click)="generateOTP()" type="submit" id="F_PSW_OTP_BTN" class="btn btn-primary btnLoginPopup">
              Get OTP
            </button>
            <div class="loader" style="display: none;" id="F_PSW_OTP_LOADER"></div>
          </div>
        </div>
        <div class="form-group row m-t-20">
          <div class="col-sm-6 w-100 text-right">
            <a (click)="openForgotPassword(false)" class="color-white hoverPointer">Click here to Login.</a>
          </div>
        </div>
        
      </form>
      <form method="POST" *ngIf="__IS_SEND_FORGOT_PASSWORD == 1">
        <div class="form-group row m-b-10">
          <label for="__OTP_F_PSW" class="col-sm-12 col-form-label">OTP has been sent to Entered mobile number.</label>
        </div>
        <div class="form-group row">
          <label for="__OTP_F_PSW" class="col-sm-12 col-form-label">ENTER OTP :</label>
          <div class="col-sm-12">
            <input type="text" class="form-control" id="__OTP_F_PSW" name="__OTP_F_PSW" [(ngModel)]="__OTP_F_PSW"
              placeholder="Enter OTP" />
          </div>
        </div>
        <div class="form-group row m-t-20">
          <div class="col-sm-6 w-100">
            <button (click)="ValidateOTP()" type="submit" class="btn btn-primary btnLoginPopup">
              CONTINUE
            </button>
          </div>
        </div>
        <div class="form-group row m-t-20">
          <div class="col-sm-6 w-100 text-right">
            <a (click)="openForgotPassword(false)" class="color-white hoverPointer">Click here to Login.</a>
          </div>
        </div>
      </form>

      <form method="POST" *ngIf="__IS_SEND_FORGOT_PASSWORD == 2">
       
        <div class="form-group row">
          <label for="_NEW_PASSWORD" class="col-sm-12 col-form-label">New Password :</label>
          <div class="col-sm-12">
            <input type="password" class="form-control" id="_NEW_PASSWORD" name="_NEW_PASSWORD" [(ngModel)]="_NEW_PASSWORD" placeholder="Enter New Password" />
          </div>
        </div>
        <div class="form-group row">
          <label for="_CONFIRM_PASSWORD" class="col-sm-12 col-form-label">Confirm Password :</label>
          <div class="col-sm-12">
            <input type="password" class="form-control" id="_CONFIRM_PASSWORD" name="_CONFIRM_PASSWORD" [(ngModel)]="_CONFIRM_PASSWORD" placeholder="Enter Confirm Password" />
          </div>
        </div>
        <div class="form-group row m-t-20">
          <div class="col-sm-6 w-100">
            <button (click)="changePassword()" type="submit" id="F_PSW_OTP_BTN_1" class="btn btn-primary btnLoginPopup">
              Change Password
            </button>
            <div class="loader" style="display: none;" id="F_PSW_OTP_LOADER_1"></div>
          </div>
        </div>
      </form>

    </div>
  </div>
</div>
</div>

<div id="carousel-example-generic" class="carousel slide" data-ride="carousel" style="box-shadow: rgba(0, 0, 0, 0.2) -2px -1px 6px;">
  <div class="carousel-inner">
    <div *ngFor="let banners of __BANNERS; let isFirst = first;" [class]="isFirst ? __ACTIVE_S : __INACTIVE_S">
      <div class="carousel-item">
        <img src="{{banners.stImage}}" style="height: 100px;" alt="Third slide" />
      </div>
      <div class="carousel-caption"></div>
    </div>
  </div>
  <a class="left carousel-control" href="#carousel-example-generic" data-slide="prev">
    <span class="fa fa-angle-left"></span>
  </a>
  <a class="right carousel-control" href="#carousel-example-generic" data-slide="next">
    <span class="fa fa-angle-right"></span>
  </a>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="upcoming-fixure">
      <!-- <div class="fixure-title">Upcoming Fixtures</div> -->
      <div class="fixure-box-container" style="width: 100%;">
        <div class="marqueeUpcoming">
          <marquee class="marquee-inner">
            <div class="marquee-content">
              <div class="login-fixture">
                <ng-container *ngFor="let d of AllMatchList">
                  <div class="fixure-box">
                    <div>
                      <img *ngIf="d.stSportsID == '4'" src="/images/cricket-sidebar.png" style="width: 20px;">
                      <img *ngIf="d.stSportsID == '2'" src="/images/tennis-sidebar.png" style="width: 20px;">
                      <img *ngIf="d.stSportsID == '1'" src="/images/football-sidebar.png" style="width: 20px;">
                      {{d.stEventName}}
                    </div>
                    <div>{{d.dtStartDate | date:'dd, MMM yyyy HH:mm:ss'}}</div>
                  </div>
                </ng-container>

              </div>
            </div>
          </marquee>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <h2 class="sport-list-title">Our Live Casinos</h2>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="casino-banners-list">
      <ng-container *ngFor="let d of __EZUGI_GAMES">
        <div class="casino-banner-item login-hover">
          <a href="javascript:void(0);" data-toggle="modal" (click)="openLogin()" data-target="#loginForm">
            <img src="{{d.IMG}}" class="img-fluid">
            <div role="button" tabindex="0">Login</div>
          </a>
        </div>
      </ng-container>

    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="casino-banners-list">
      <ng-container *ngFor="let d of __EVOLUTION_GAMES">
        <div class="casino-banner-item login-hover">
          <a href="javascript:void(0);" data-toggle="modal" (click)="openLogin()" data-target="#loginForm">
            <img src="{{d.IMG}}" class="img-fluid">
            <div role="button" tabindex="0">Login</div>
          </a>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!-- <div class="row">
  <div class="col-md-12">
    <h2 class="sport-list-title">Evolution Games</h2>
  </div>
</div> -->


<!-- <div class="row">
  <div class="col-md-12">
    
  </div>
</div> -->
<!-- <div class="row">
  <div class="col-md-12">
    <div class="casino-banners-list">

      <ng-container *ngFor="let d of __LOTUS_GAMES">
        <div class="casino-banner-item login-hover">
          <a href="javascript:void(0);" data-toggle="modal" (click)="openLogin()" data-target="#loginForm">
            <img src="{{d.IMG}}" class="img-fluid">
            <span *ngIf="d.VIRTUAL" class="casino-text-name">(Virtual)</span>
            <div role="button" tabindex="0">Login</div>
          </a>
        </div>
      </ng-container>
      
    </div>
  </div>
</div> -->


<!-- <div class="row">
  <div class="col-md-12">
    <h2 class="sport-list-title">Our Sports</h2>
  </div>
</div> -->
<div class="row">
  <div class="col-md-12">
    <div class="casino-banners-list">
      <div class="casino-banner-item login-hover">
        <a href="javascript:void(0);" data-toggle="modal" (click)="openLogin()" data-target="#loginForm">
          <img src="images/HOTSPORTS/ads/CRICKET.jpg" style="height:26vw;" class="img-fluid">
          <span class="casino-text-name">Cricket</span>
          <div role="button" tabindex="0">Login</div>
        </a>
      </div>
      <div class="casino-banner-item login-hover">
        <a href="javascript:void(0);" data-toggle="modal" (click)="openLogin()" data-target="#loginForm">
          <img src="images/HOTSPORTS/ads/CRICKET1.jpg" style="height:26vw;" class="img-fluid">
          <span class="casino-text-name">Cricket (Virtual)</span>
          <div role="button" tabindex="0">Login</div>
        </a>
      </div>
      <div class="casino-banner-item login-hover">
        <a href="javascript:void(0);" data-toggle="modal" (click)="openLogin()" data-target="#loginForm">
          <img src="images/HOTSPORTS/ads/Tennis.jpg" style="height:26vw;" class="img-fluid">
          <span class="casino-text-name">Tennis</span>
          <div role="button" tabindex="0">Login</div>
        </a>
      </div>
      <div class="casino-banner-item login-hover">
        <a href="javascript:void(0);" data-toggle="modal" (click)="openLogin()" data-target="#loginForm">
          <img src="images/HOTSPORTS/ads/Soccer.jpg" style="height:26vw;" class="img-fluid">
          <span class="casino-text-name">Soccer</span>
          <div role="button" tabindex="0">Login</div>
        </a>
      </div>
      <div class="casino-banner-item login-hover">
        <a href="javascript:void(0);" data-toggle="modal" (click)="openLogin()" data-target="#loginForm">
          <img src="images/HOTSPORTS/ads/HorseRacing.jpg" style="height:26vw;" class="img-fluid">
          <span class="casino-text-name">Horse Racing</span>
          <div role="button" tabindex="0">Login</div>
        </a>
      </div>
      
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <h2 class="sport-list-title">Live Casinos</h2>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="casino-banners-list mt-2">
      <div class="casino-banner-item login-hover">
        <a href="javascript:void(0);" data-toggle="modal" (click)="openLogin()" data-target="#loginForm">
          <img src="images/HOTSPORTS/ads/evolution.jpg" style="height:26vw;" class="img-fluid">
          <!-- <span class="casino-text-name">Cricket</span> -->
          <div role="button" tabindex="0">Login</div>
        </a>
      </div>
      <div class="casino-banner-item login-hover">
        <a href="javascript:void(0);" data-toggle="modal" (click)="openLogin()" data-target="#loginForm">
          <img src="images/HOTSPORTS/ads/ezugi.jpg" style="height:26vw;" class="img-fluid">
          <!-- <span class="casino-text-name">Tennis</span> -->
          <div role="button" tabindex="0">Login</div>
        </a>
      </div>
      <div class="casino-banner-item login-hover">
        <a href="javascript:void(0);" data-toggle="modal" (click)="openLogin()" data-target="#loginForm">
          <img src="images/HOTSPORTS/ads/vivo-gaming.jpg" style="height:26vw;" class="img-fluid">
          <!-- <span class="casino-text-name">Soccer</span> -->
          <div role="button" tabindex="0">Login</div>
        </a>
      </div>
      <div class="casino-banner-item login-hover">
        <a href="javascript:void(0);" data-toggle="modal" (click)="openLogin()" data-target="#loginForm">
          <img src="images/HOTSPORTS/ads/slot-games.jpg" style="height:26vw;" class="img-fluid">
          <!-- <span class="casino-text-name">Soccer</span> -->
          <div role="button" tabindex="0">Login</div>
        </a>
      </div>
      <div class="casino-banner-item login-hover">
        <a href="javascript:void(0);" data-toggle="modal" (click)="openLogin()" data-target="#loginForm">
          <img src="images/HOTSPORTS/ads/betgames-og.jpg" style="height:26vw;" class="img-fluid">
          <!-- <span class="casino-text-name">Soccer</span> -->
          <div role="button" tabindex="0">Login</div>
        </a>
      </div>
      
    </div>
  </div>
</div>

<section id="contact-us" class="contact-us back-dark contact section py-4">
  <div class="container-fluid px-2 px-md-5">
    <div class="row">
      <div class="col-xs-12">
        <div class="heading">
          <h2>Navigate</h2>
          <ul class="text-center text-md-left p-l-0">
            <li class="footer-item py-2">
              <a class="text-white" style="text-decoration: none;" target="_self" aria-label="Home page">Home</a>
            </li>
            <li class="footer-item py-2">
              <a class="text-white" style="text-decoration: none;" target="_self"
                aria-label="Terms and conditions page">Terms &amp; Conditions</a>
            </li>
            <li class="footer-item py-2">
              <a class="text-white" style="text-decoration: none;" target="_self"
                aria-label="Privacy policy page">Privacy Policy</a>
            </li>
            <li class="footer-item py-2">
              <a class="text-white" style="text-decoration: none;" target="_self" aria-label="Self exclusion page">Self
                Exclusion</a>
            </li>
            <li class="footer-item py-2">
              <a class="text-white" style="text-decoration: none;" target="_self" aria-label="Game rules page">Game
                Rules</a>
            </li>
            <li class="footer-item py-2">
              <a class="text-white" style="text-decoration: none;" target="_self"
                aria-label="Responsible gambling page">Responsible Gambling</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-md-4">
        <div class="d-flex flex-column justify-content-between h-100">
          <div class="row">
            <div class="col-lg-12">
              <div class="payments">
                <div class="heading">
                  <h2>Payment Methods</h2>
                  <div class="row d-flex payment-styles">

                      <span class="payment-method-images">
                        <img src="./images/HOTSPORTS/payment/Plugin icon - 1.png" alt="VISA" />
                      </span>

                      <span class="payment-method-images">
                        <img src="./images/HOTSPORTS/payment/Plugin icon - 2.png" alt="MASTERCARD" /></span>

                      <span class="payment-method-images">
                        <img src="./images/HOTSPORTS/payment/Plugin icon - 3.png" alt="paytm" /></span>

                      <span class="payment-method-images">
                        <img src="./images/HOTSPORTS/payment/Plugin icon - 4.png" alt="Gpay Payment" /></span>
                    
                      <span class="payment-method-images">
                        <img src="./images/HOTSPORTS/payment/Plugin icon - 5.png" alt="Airtel" /></span>

                      <span class="payment-method-images">
                        <img src="./images/HOTSPORTS/payment/Plugin icon - 6.png" alt="Jio" /></span>

                      <span class="payment-method-images">
                        <img src="./images/HOTSPORTS/payment/Plugin icon - 7.png"
                          alt="Unified Payments Interface" /></span>

                      <span class="payment-method-images">
                        <img src="./images/HOTSPORTS/payment/Plugin icon - 8.png" alt="Bank Payment" />
                      </span>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="d-flex h-100 flex-column justify-content-between">
          <div class="heading">
            <h2>Gambling Therapy</h2>
            <ul class="text-center text-md-left p-l-0">
              <li class="footer-item py-2">
                <a class="text-white" style="text-decoration: none;" href="'" target="_self"
                  aria-label="Home page">Gambling Therapy</a>
              </li>
              <li class="footer-item py-2">
                <a class="text-white" style="text-decoration: none;" href="'" target="_self"
                  aria-label="Home page">GameCare</a>
              </li>
            </ul>
            <ul class="d-flex justify-content-center justify-content-md-start text-center p-l-0">
              <div>
                <img alt="License Validation Link" style="width: auto; height: 40px;"
                  src="./images/HOTSPORTS/cards/verify.png" />
                <a>
                  <img style="width: auto; height: 40px;" src="./images/HOTSPORTS/18plus.png" />
                </a>
                <a href="https://www.gamcare.org.uk/" target="_blank">
                  <img style="width: auto; height: 40px;" src="./images/HOTSPORTS/gamecare.png" />
                </a>
                <a href="https://www.gamblingtherapy.org/en" target="_blank">
                  <img style="width: auto; height: 40px;" src="./images/HOTSPORTS/gt.png" />
                </a>
              </div>
            </ul>
          </div>
        </div>
      </div>


      <div class="col-xs-12">
        <div class="d-flex flex-column justify-content-between h-100">
          <div class="heading">
            <h2>Follow Us</h2>
            <ul class="d-flex text-white justify-content-center justify-content-md-start text-center p-l-0">
              <!-- <a href="{{_FACEBOOK}}" class="color-white" target="_blank"><i class="fa fa-2x fa-facebook-f m-r-5"></i></a>
              <a href="{{_TWITTER}}" class="color-white" target="_blank"><i class="fa fa-2x fa-twitter m-r-5"></i></a>
              <a href="{{_INSTAGRAM}}" class="color-white" target="_blank"><i class="fa fa-2x fa-instagram m-r-5"></i></a>
              <a href="{{_LINKEDIN}}" class="color-white" target="_blank"><i class="fa fa-2x fa-linkedin m-r-5"></i></a>
              <a href="{{_YOUTUBE}}" class="color-white" target="_blank"><i class="fa fa-2x fa-youtube m-r-5"></i></a> -->
              <a href="{{_INSTAGRAM}}" class="color-white" target="_blank"><i class="fa fa-2x fa-instagram m-r-5"></i></a>
              <!-- <span class="m-r-5"><i class="fa fa-2x fa-facebook-f"></i></span>
              <span class="m-r-5"><i class="fa fa-2x fa-whatsapp"></i></span>
              <span class="m-r-5"><i class="fa fa-2x fa-telegram"></i></span>
              <span class="m-r-5"><i class="fa fa-2x fa-instagram"></i></span>
              <span class="m-r-5"><i class="fa fa-2x fa-twitter"></i></span>
              <span class="m-r-5"><i class="fa fa-2x fa-youtube"></i></span> -->
            </ul>
          </div>
          <!-- <div class="subscribe pb-5">
            <div class="heading">
              <h2>Subscribe for offers</h2>
              <div class="sub-form">
                <form>
                  <div class="form-group col-sm-12">
                    <input type="text" class="form-control" name="email" placeholder="Enter Your Email" />
                  </div>
                  <div class="casino-btn newsletter">
                    <a class="btn-4 yellow-btn">Subscribe</a>
                  </div>
                </form>
              </div>
            </div>
          </div> -->
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <a>
          <img class="img-fluid logo-color" style="width: 200px;" src="./images/HOTSPORTS/header-logo.png" alt="logo" />
        </a>
          <div class="control-pad"></div>
      </div>
    </div>
    <div class="justify-content-center text-center">
      <div class="secondary-footer-style d-flex justify-content-center">
        <span class="secondary-footer-contents">
          <p class="lead" style="font-weight: 100; font-size: .8rem; color: rgba(255,255,255,.5);">
            This website is operated by FOX246 International N.V., registered in Curaçao under the number 148532, with the address at Heelsumstraat 51, Vredenberg, Willemstad, P.O. box Orionweg 5 C, Curaçao. The transactions are processed by FOX246 International N.V which owns and operates the website fox246.com.
            <br /><br>
            © Copyright 2021. All Rights Reserved By fox246.com
          </p>
          <br />
        </span>
      </div>
    </div>
  </div>
</section>
</div>