import { Injectable } from '@angular/core';
import { ClientlistService } from './clientlist.service';
import { CryptojsService } from '../services/cryptojs.service';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SportsshowhideService {
  CricketShowStatus = false;
  FancyShowStatus = false;
  LineShowStatus = false;
  ManualShowStatus = false;
  TennisShowStatus = false;
  SoccerShowStatus = false;
  SoccerGoaltShowStatus = false;
  HorseRacingShowStatus = false;
  TeenPattiT20ShowStatus = false;
  PokerT20ShowStatus = false;
  FunCasinoShowStatus = false;
  LotusShowStatus = false;
  CasinoAllShowStatus = false;
  EvolutionShowStatus = false;
  LiveCasinoShowStatus = false;


  onSportsShowHideShowStatus$: Subject<any>;

  SessionUser: any;

  constructor(private CryptojsService: CryptojsService, private ClientlistService: ClientlistService) {
    this.onSportsShowHideShowStatus$ = new Subject<any>();
  }

  setShowHideSports() {
    this.SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    this.ClientlistService.getHidedSports(this.SessionUser.UserId).subscribe(
      data1 => {
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));

        for (let i = 0; i < data.length; i++) {
          switch (data[i].stSportsID) {
            case "4":
              this.CricketShowStatus = true;
              this.onSportsShowHideShowStatus(true);
              break;

            case "2":
              this.TennisShowStatus = true;
              this.onSportsShowHideShowStatus(true);
              break;

            case "1":
              this.SoccerShowStatus = true;
              this.onSportsShowHideShowStatus(true);
              break;

            case "7":
              this.HorseRacingShowStatus = true;
              this.onSportsShowHideShowStatus(true);
              break;

            case "0":
              this.FancyShowStatus = true;
              this.onSportsShowHideShowStatus(true);
              break;

            case "18":
              this.LineShowStatus = true;
              this.onSportsShowHideShowStatus(true);
              break;

            case "13":
              this.ManualShowStatus = true;
              this.onSportsShowHideShowStatus(true);
              break;

            case "14":
              this.TeenPattiT20ShowStatus = true;
              this.onSportsShowHideShowStatus(true);
              break;

            case "17":
              this.PokerT20ShowStatus = true;
              this.onSportsShowHideShowStatus(true);
              break;

            case "1501":
              this.FunCasinoShowStatus = true;
              this.onSportsShowHideShowStatus(true);
              break;

            case "1601":
              this.LotusShowStatus = true;
              this.onSportsShowHideShowStatus(true);
              break;

            case "-10":
              this.CasinoAllShowStatus = true;
              this.onSportsShowHideShowStatus(true);
              break;

            case "10001":
              this.EvolutionShowStatus = true;
              this.onSportsShowHideShowStatus(true);
              break;

            case "10002":
              this.LiveCasinoShowStatus = true;
              this.onSportsShowHideShowStatus(true);
              break;


          }
        }
      },
      error => {
        return false;
      });
  }

  onSportsShowHideShowStatus(__STATUS) {
    this.onSportsShowHideShowStatus$.next(__STATUS);
  }

  public getOnSportsShowHideShowStatus(): Observable<any> {
    return this.onSportsShowHideShowStatus$;
  }
}
