<div id="carousel-example-generic" class="carousel slide" data-ride="carousel"
  style="box-shadow: rgba(0, 0, 0, 0.2) -2px -1px 6px;">
  <div class="carousel-inner">
    <div *ngFor="let banners of __BANNERS; let isFirst = first;" [class]="isFirst ? __ACTIVE_S : __INACTIVE_S">
      <div class="carousel-item">
        <img src="{{banners.stImage}}" style="height: 100px; width: 100%;" alt="Third slide" />
      </div>
      <div class="carousel-caption"></div>
    </div>
  </div>
  <a class="left carousel-control" href="#carousel-example-generic" data-slide="prev">
    <span class="fa fa-angle-left"></span>
  </a>
  <a class="right carousel-control" href="#carousel-example-generic" data-slide="next">
    <span class="fa fa-angle-right"></span>
  </a>
</div>

<div class="scrollmenuMain" *ngIf="selectedWhichTab != 1">
  <div>
    <a class="activeMain">
      In-Play
    </a>
  </div>
  <div>
    <a (click)="onMatchListMobileClick('4')">
      Sports
    </a>
  </div>
  <!-- <div>
        <a (click)="onDynamicRouterClick('casino/games-m')">
            Casino + Slot
        </a>
    </div> -->
  <!-- <div *ngIf="!LiveCasinoShowStatus || !EvolutionShowStatus">
    <a (click)="onDynamicRouterClick('/casino/casino-games')" class="blinking">
        CASINO
    </a>
  </div> -->
</div>

<div class="scrollmenuSub" *ngIf="selectedWhichTab != 1">

  <div>
    <a *ngIf="!SoccerShowStatus" id="__INPLAYLIST_1" (click)="getMatchList('1')">
      <p>
        <img src="/images/football-sidebar.png" width="20px" />
      </p>
      <p>Football</p>
    </a>
  </div>
  <div>
    <a *ngIf="!TennisShowStatus" id="__INPLAYLIST_2" (click)="getMatchList('2')">
      <p>
        <img src="/images/tennis-sidebar.png" width="20px" />
      </p>
      <p>Tennis</p>
    </a>
  </div>
  <div>
    <a *ngIf="!CricketShowStatus" id="__INPLAYLIST_4" (click)="getMatchList('4')" class="active">
      <p>
        <img src="/images/cricket-sidebar.png" width="20px" />
      </p>
      <p>Cricket</p>
    </a>
  </div>
  <div>
    <a *ngIf="!HorseRacingShowStatus" id="__INPLAYLIST_7" (click)="getMatchList('7')">
      <p>
        <img src="/images/horseracing-sidebar.png" width="20px" />
      </p>
      <p>Horse Racing</p>
    </a>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <div id="__MATCHTABLELOADER1" class="overlayLoaderPopup" style="height: 0%;">
      <div class="overlayLoaderPopup-content" style="color: #ff8282;">
        <!-- <div class="loader" id="__MATCHTABLELOADER"></div> -->
        <img src="/images/LoginLoaderGIF.gif" width="50px;" />
      </div>
    </div>
    <div class="sc-fjdhpX sAjab">
      <div class="sc-epnACN bGHpJS">

        <div class="sc-sPYgB dqbEeb" *ngIf="FilteredSubMatchListLength == 0">No real-time records found</div>
        <div id="CricketTRInPlay_{{d.stMarketID}}" (click)="changeNavigation(d.stEventID, d.stSportsID, d.stMarketID)"
          class="sc-hXRMBi gVVkIY" *ngFor="let d of FilteredSubMatchList; let i = index;">
          <div *ngIf="d.btMarketStatus == 1 && __SPORTSID == d.stSportsID" class="sc-bwCtUz sc-gwVKww cXWrKs">
            <div class="sc-bXGyLb sc-cpmLhU beoeno">

              <div class="sc-emmjRN cpsDzQ">
                <div class="sc-eerKOB ldkwXi">
                  <div class="sc-eilVRo ehEWqk">
                    <b *ngIf="d.btMarketStatus == 1" class="live-list m-r-5">Live</b>{{d.stEventName}}
                    <div class="font-weight-100 fs-11">
                      {{d.dtStartDate | date:'dd MMM, HH:mm'}}

                      <div class="list-tv-bm-f-icon">
                        <img *ngIf="d.btMarketStatus == 1" src="./images/tv-white.png" width="25px" class="m-r-5" />
                        <span *ngIf="d.btMarketStatus == 1 && d.stSportsID == 4" class="list-bm-color m-r-5">BM</span>
                        <span *ngIf="d.isFancy && d.isFancy == 1" class="list-f-color">F</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="sc-bXGyLb sc-cpmLhU beoenoRJ">
              <div class="sc-emmjRN cpsDzQ">
                <div class="sc-eerKOB ldkwXi">
                  <div class="sc-eilVRo ehEWqk">
                    <ng-container *ngIf="d.stSportsID != 7 && d.stSportsID != 4339">
                      <ng-container *ngIf="ALLM2ME[d.stEventID] == undefined">
                        <span class="sc-ibxdXY bvjTkI" id="InPlaym2m_{{d.stEventID}}">0.00</span>
                      </ng-container>
                      <ng-container *ngIf="ALLM2ME[d.stEventID] != undefined">
                        <span *ngIf="ALLM2ME[d.stEventID] < 0" class="sc-ibxdXY bvjTkI ksWbmr" id="InPlaym2m_{{d.stEventID}}">
                          {{ALLM2ME[d.stEventID]}}</span>
                        <span *ngIf="ALLM2ME[d.stEventID] >= 0" class="sc-ibxdXY bvjTkI htTnxO" id="InPlaym2m_{{d.stEventID}}">
                          {{ALLM2ME[d.stEventID]}}</span>
                      </ng-container>
                    </ng-container>
                    
                    <ng-container *ngIf="d.stSportsID == 7 || d.stSportsID == 4339">
                      <ng-container *ngIf="ALLM2ME[d.stMarketID] == undefined">
                        <span class="sc-ibxdXY bvjTkI" id="InPlaym2m_{{d.stMarketID}}">0.00</span>
                      </ng-container>
                      <ng-container *ngIf="ALLM2ME[d.stMarketID] != undefined">
                        <span *ngIf="ALLM2ME[d.stMarketID] < 0" class="sc-ibxdXY bvjTkI ksWbmr" id="InPlaym2m_{{d.stMarketID}}">
                          {{ALLM2ME[d.stMarketID]}}</span>
                        <span *ngIf="ALLM2ME[d.stMarketID] >= 0" class="sc-ibxdXY bvjTkI htTnxO" id="InPlaym2m_{{d.stMarketID}}">
                          {{ALLM2ME[d.stMarketID]}}</span>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div class="sc-bXGyLb sc-bnXvFD vUVLn">
              <div class="sc-jzgbtB cDeqWmRJ">
                1
              </div>
              <div class="sc-jzgbtB cDeqWmRJ">
                X
              </div>
              <div class="sc-jzgbtB cDeqWmRJ">
                2
              </div>
            </div>

            <div class="sc-bXGyLb sc-bnXvFD vUVLn" *ngIf="d.submaster.length > 2">
              <div *ngIf="d.stSportsID == 7 || d.stSportsID == 4339" class="sc-iQNlJl kEIzqlHorse"
                id="__SUSPEND_{{d.submaster[0].stInstrumentID}}">BET OPEN</div>
              <div *ngIf="d.stSportsID != 7 && d.stSportsID != 4339" class="sc-iQNlJl kEIzql" 
                id="__SUSPEND_{{d.submaster[0].stInstrumentID}}">SUSPENDED</div>
              <div class="sc-jzgbtB cDeqWm">
                <div type="BACK" class="sc-cmthru jOBoYn">
                  <div type="BACK" id="__BACK_P_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[0].rates ? d.submaster[0].rates.Back[0].Price : '-'}}
                  </div>
                  <!-- <div type="BACK" id="__BACK_S_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                </div>
                <div type="LAY" class="sc-cmthru bEnOkM">
                  <div type="LAY" id="__LAY_P_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[0].rates ? d.submaster[0].rates.Lay[0].Price : '-'}}
                  </div>
                  <!-- <div type="LAY" id="__LAY_S_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                </div>
              </div>
              <div class="sc-jzgbtB cDeqWm">
                <div type="BACK" class="sc-cmthru jOBoYn">
                  <div type="BACK" id="__BACK_P_INPLAY_{{d.submaster[2].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[1].rates ? d.submaster[1].rates.Back[0].Price : '-'}}
                  </div>
                  <!-- <div type="BACK" id="__BACK_S_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                </div>
                <div type="LAY" class="sc-cmthru bEnOkM">
                  <div type="LAY" id="__LAY_P_INPLAY_{{d.submaster[2].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[1].rates ? d.submaster[1].rates.Lay[0].Price : '-'}}
                  </div>
                  <!-- <div type="LAY" id="__LAY_S_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                </div>
              </div>
              <div class="sc-jzgbtB cDeqWm">
                <div type="BACK" class="sc-cmthru jOBoYn">
                  <div type="BACK" id="__BACK_P_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[2].rates ? d.submaster[2].rates.Back[0].Price : '-'}}
                  </div>
                  <!-- <div type="BACK" id="__BACK_S_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                </div>
                <div type="LAY" class="sc-cmthru bEnOkM">
                  <div type="LAY" id="__LAY_P_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[2].rates ? d.submaster[2].rates.Lay[0].Price : '-'}}
                  </div>
                  <!-- <div type="LAY" id="__LAY_S_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                </div>
              </div>

            </div>

            <div class="sc-bXGyLb sc-bnXvFD vUVLn" *ngIf="d.submaster.length == 2">
              <div *ngIf="d.stSportsID == 7 || d.stSportsID == 4339" class="sc-iQNlJl kEIzqlHorse"
                id="__SUSPEND_{{d.submaster[0].stInstrumentID}}">BET OPEN</div>
              <div *ngIf="d.stSportsID != 7 && d.stSportsID != 4339" class="sc-iQNlJl kEIzql" [style.display]="d.btMarketStatus == 0 || d.btMarketStatus == 1 ? 'none' : 'flex'"
                id="__SUSPEND_{{d.submaster[0].stInstrumentID}}">SUSPENDED</div>
              <div class="sc-jzgbtB cDeqWm">
                <div type="BACK" class="sc-cmthru jOBoYn">
                  <div type="BACK" id="__BACK_P_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[0].rates ? d.submaster[0].rates.Back[0].Price : '-'}}
                  </div>
                  <!-- <div type="BACK" id="__BACK_S_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                </div>
                <div type="LAY" class="sc-cmthru bEnOkM">
                  <div type="LAY" id="__LAY_P_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[0].rates ? d.submaster[0].rates.Lay[0].Price : '-'}}
                  </div>
                  <!-- <div type="LAY" id="__LAY_S_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                </div>
              </div>
              <div class="sc-jzgbtB cDeqWm">
                <div type="BACK" class="sc-cmthru jVWUZo">
                  <div type="BACK" class="sc-iuJeZd hkqSUv">-</div>
                </div>
                <div type="LAY" class="sc-cmthru jVWUZo">
                  <div type="LAY" class="sc-iuJeZd hkqSUv">-</div>
                </div>
              </div>
              <div class="sc-jzgbtB cDeqWm">
                <div type="BACK" class="sc-cmthru jOBoYn">
                  <div type="BACK" id="__BACK_P_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[1].rates ? d.submaster[1].rates.Back[0].Price : '-'}}
                  </div>
                  <!-- <div type="BACK" id="__BACK_S_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                </div>
                <div type="LAY" class="sc-cmthru bEnOkM">
                  <div type="LAY" id="__LAY_P_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[1].rates ? d.submaster[1].rates.Lay[0].Price : '-'}}
                  </div>
                  <!-- <div type="LAY" id="__LAY_S_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                </div>
              </div>

            </div>

            <div class="sc-bXGyLb sc-bnXvFD vUVLn" *ngIf="d.submaster.length == 1">
              <div *ngIf="d.stSportsID == 7 || d.stSportsID == 4339" class="sc-iQNlJl kEIzqlHorse"
                id="__SUSPEND_{{d.submaster[0].stInstrumentID}}">BET OPEN</div>
              <div *ngIf="d.stSportsID != 7 && d.stSportsID != 4339" class="sc-iQNlJl kEIzql" [style.display]="d.btMarketStatus == 0 || d.btMarketStatus == 1 ? 'none' : 'flex'"
                id="__SUSPEND_{{d.submaster[0].stInstrumentID}}">SUSPENDED</div>
              <div class="sc-jzgbtB cDeqWm">
                <div type="BACK" class="sc-cmthru jOBoYn">
                  <div type="BACK" id="__BACK_P_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-iuJeZd hkqSUv">0.00
                  </div>
                  <!-- <div type="BACK" id="__BACK_S_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                </div>
                <div type="LAY" class="sc-cmthru bEnOkM">
                  <div type="LAY" id="__LAY_P_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-iuJeZd hkqSUv">0.00
                  </div>
                  <!-- <div type="LAY" id="__LAY_S_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                </div>
              </div>
              <div class="sc-jzgbtB cDeqWm">
                <div type="BACK" class="sc-cmthru jVWUZo">
                  <div type="BACK" class="sc-iuJeZd hkqSUv">-</div>
                </div>
                <div type="LAY" class="sc-cmthru jVWUZo">
                  <div type="LAY" class="sc-iuJeZd hkqSUv">-</div>
                </div>
              </div>
              <div class="sc-jzgbtB cDeqWm">
                <div type="BACK" class="sc-cmthru jVWUZo">
                  <div type="BACK" class="sc-iuJeZd hkqSUv">-</div>
                </div>
                <div type="LAY" class="sc-cmthru jVWUZo">
                  <div type="LAY" class="sc-iuJeZd hkqSUv">-</div>
                </div>
              </div>

            </div>

            <div class="sc-bXGyLb sc-bnXvFD vUVLn" *ngIf="d.submaster.length == 0">
              <div class="sc-jzgbtB cDeqWm">
                <div type="BACK" class="sc-cmthru jVWUZo">
                  <div type="BACK" class="sc-iuJeZd hkqSUv">-</div>
                </div>
                <div type="LAY" class="sc-cmthru jVWUZo">
                  <div type="LAY" class="sc-iuJeZd hkqSUv">-</div>
                </div>
              </div>
              <div class="sc-jzgbtB cDeqWm">
                <div type="BACK" class="sc-cmthru jVWUZo">
                  <div type="BACK" class="sc-iuJeZd hkqSUv">-</div>
                </div>
                <div type="LAY" class="sc-cmthru jVWUZo">
                  <div type="LAY" class="sc-iuJeZd hkqSUv">-</div>
                </div>
              </div>
              <div class="sc-jzgbtB cDeqWm">
                <div type="BACK" class="sc-cmthru jVWUZo">
                  <div type="BACK" class="sc-iuJeZd hkqSUv">-</div>
                </div>
                <div type="LAY" class="sc-cmthru jVWUZo">
                  <div type="LAY" class="sc-iuJeZd hkqSUv">-</div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row m-t-5 casinoSec">
  <div class="col-md-12 p-t-20">
    <div class="row">
      <div class="col-md-12">
        <h2 class="sport-list-title">OUR LIVE CASINOS</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="casino-banners-list mt-2">

          <ng-container *ngFor="let d of __EZUGI_GAMES">
            <div class="casino-banner-item login-hover">
              <a href="javascript:void(0);" (click)="onDynamicRouterClick('/casino/ezugi')">
                <img src="{{d.IMG}}" class="img-fluid">
                <div role="button" tabindex="0">Play</div>
              </a>
            </div>
          </ng-container>

          <ng-container *ngFor="let d of __EVOLUTION_GAMES">
            <div class="casino-banner-item login-hover">
              <a href="javascript:void(0);" (click)="onDynamicRouterClick('/casino/evolution')">
                <img src="{{d.IMG}}" class="img-fluid">
                <div role="button" tabindex="0">Play</div>
              </a>
            </div>
          </ng-container>

          <!-- <ng-container *ngFor="let d of __LOTUS_GAMES">
            <div class="casino-banner-item login-hover">
              <a href="javascript:void(0);" (click)="onCasinoClick(d.GID)">
                <img src="{{d.IMG}}" class="img-fluid">
                <span *ngIf="d.VIRTUAL" class="casino-text-name">(Virtual)</span>
                <div role="button" tabindex="0">Play</div>
              </a>
            </div>
          </ng-container> -->

        </div>
      </div>
    </div>

  </div>
</div>