<div class="row">
    <div class="col-sm-12 padding-two">
        <form role="form" method="post" name="frmAdd" style="color: #ffffff;">
            <div class="col-md-6 padding-two">
                <div class="form-group col-sm-12 padding-zero">
                    <label>BANK NAME</label>
                    <input name="bankname" id="bankname" type="text" [(ngModel)]="BankName"
                        class="form-control input-sm" />
                </div>
            </div>
            <div class="col-md-6 padding-two">
                <div class="form-group col-sm-12 padding-zero">
                    <label>ACCOUNT HOLDER</label>
                    <input name="acholder" id="acholder" type="text" [(ngModel)]="AccountHolder"
                        class="form-control input-sm" />
                </div>
            </div>
            <div class="col-md-6 padding-two">
                <div class="form-group col-sm-12 padding-zero">
                    <label>ACCOUNT NUMBER</label>
                    <input name="acnumber" id="acnumber" type="text" [(ngModel)]="AccountNumber"
                        class="form-control input-sm" />
                </div>
            </div>
            <div class="col-md-6 padding-two">
                <div class="form-group col-sm-12 padding-zero">
                    <label>IFSC CODE</label>
                    <input name="ifsc" id="ifsc" type="text" [(ngModel)]="IfscCode" class="form-control input-sm" />
                </div>
            </div>
            <div class="col-md-6 padding-two">
                <div class="form-group col-sm-12 padding-zero">
                    <label>UPI ID</label>
                    <input name="upiid" id="upiid" type="text" [(ngModel)]="UpiId" class="form-control input-sm" />
                </div>
            </div>
            <div class="col-md-6 padding-two">
                <div class="form-group col-sm-12 padding-zero">
                    <label>IMAGE UPLOAD</label>
                    <input name="imgupload" id="imgupload" type="file" [(ngModel)]="ImgUpload"
                        (change)="handleFileInput($event.target.files)" class="form-control input-sm"
                        style="height:35px !important ;" />
                    <!-- <img *ngIf="__P_QRCODE_IMAGE" src="{{__P_QRCODE_IMAGE}}" alt="QR Code" /> -->
                </div>
            </div>
            <div class="col-md-6 padding-two">
                <div class="form-group col-sm-12 padding-zero">
                    <label>Active/Deactive</label>
                    <select id="optActive" name="optActive" [(ngModel)]="__P_ACT"
                        class="form-control input-sm borderRound-10">
                        <option value="-1">--- Select ---</option>
                        <option value="1">Active</option>
                        <option value="0">Deactive</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6 padding-two">
                <div class="form-group col-sm-3 padding-zero">
                    <input type="button" class="btn btn-sm btn-block bg-blue" value="SAVE DETAILS"
                        (click)="ImgUploadePayment(0, 0)" />
                </div>
            </div>

        </form>
    </div>
</div>

<div class="row">
    <div class="col-sm-12 padding-two">
        <form class="fs-12 padding5" role="form" method="post" name="frmAdd">
            <table class="listing apl-table fs-12 m-t-0" style="background: #ffffff;color: #000000;">
                <thead>
                    <tr>
                        <td style="width: 10%;"> Action</td>
                        <td style="width: 25%;"> Bank Name</td>
                        <td style="width: 20%;"> Account Name</td>
                        <td style="width: 20%;"> Account Number</td>
                        <td style="width: 10%;"> IFSC</td>
                        <td style="width: 10%;"> UPI</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of __BANKLIST; let i = index;">
                        <td class="text-center">
                            <i *ngIf="data.ac == 1" class="fa fa-circle fa-2x text-green" title="Active"></i>
                            <i *ngIf="data.ac == 0" class="fa fa-circle fa-2x text-red" title="Not Active"></i>

                            <i class="fa fa-edit fa-2x text-aqua hoverPointer m-l-10" (click)="getAccount(data)"
                                title="Edit"></i>
                        </td>
                        <td>{{data.bankname}}</td>
                        <td>{{data.Accountholder}}</td>
                        <td>{{data.banknumber}}</td>
                        <td>{{data.ifsccode}}</td>
                        <td>{{data.bttype}}</td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
</div>