import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { AppComponent } from '../app.component';
import { CookieService } from 'ngx-cookie-service';
import { CryptojsService } from '../services/cryptojs.service';
import * as CryptoJS from 'crypto-js';
import { CommonlistService } from '../services/commonlist.service';
import { MatchtableService } from '../services/matchtable.service';
import * as jQuery from "jquery";
import { RjNotifierService } from '../services/rj-notifier.service';
import { Subscription } from 'rxjs';

declare var $: any;
@Component({
  selector: 'app-login-mobile',
  templateUrl: './login-mobile.component.html',
  styleUrls: ['./login-mobile.component.css', './responsive.css', './menu.css', './style.css', './font-awesome.min.css']//, './bootstrapNew.min.css'
})
export class LoginMobileComponent {

  model: any = {
    ID: "",
    Key: ""
  };
  _BTAG = "";
  _BTAG_STATUS = false;

  isRememberMe = false;

  __APK_URL = this.CommonlistService.__APK_URL;
  _FACEBOOK = this.CommonlistService._FACEBOOK;
  _WHATSAPP = this.CommonlistService._WHATSAPP;
  _TWITTER = this.CommonlistService._TWITTER;
  _INSTAGRAM = this.CommonlistService._INSTAGRAM;
  _LINKEDIN = this.CommonlistService._LINKEDIN;
  _YOUTUBE = this.CommonlistService._YOUTUBE;

  __EZUGI_GAMES = this.CryptojsService.__EZUGI_GAMES;
  __EVOLUTION_GAMES = this.CryptojsService.__EVOLUTION_GAMES;
  __LOTUS_GAMES = this.CryptojsService.__LOTUS_GAMES;

  private __ALLINONESUBSCRIPTION: Subscription = new Subscription();

  errorMessage: string;
  constructor(private RjNotifierService: RjNotifierService, private MatchtableService: MatchtableService, private CommonlistService: CommonlistService, private CryptojsService: CryptojsService, private CookieService: CookieService, private AppComponent: AppComponent, private router: Router, private LoginService: LoginService) {
    this.__ALLINONESUBSCRIPTION.add(
      this.LoginService.getBenners().subscribe(
        (message) => {
          this.getBanners();
        })
    );
    this.getBanners();
  }

  __ACTIVE_S = "item active";
  __INACTIVE_S = "item";
  __BANNERS = [];
  getBanners() {
    this.__BANNERS = this.LoginService.__BANNERS;
  }

  HideAllNotification() {
    this.RjNotifierService.hideNotifier();
  }

  sendNotification(Type, Message) {
    this.RjNotifierService.showNotifier(Type, Message);
  }

  AllMatchList = [];
  getMatchList() {
    if (this.MatchtableService.CurrentMatchList == undefined) {
      setTimeout(() => {
        this.getMatchList();
      }, 100);
      return false;
    }
    let __MATCHES = this.MatchtableService.CurrentMatchList;

    if (__MATCHES.length == 0) {
      this.AllMatchList = [];
      return false;
    }

    this.AllMatchList = __MATCHES.filter(({ stSportsID, stMarketName }) => {
      return stSportsID != null && (stMarketName == 'Winner' || stMarketName == 'Match Odds') && (stSportsID == 4 || stSportsID == 2 || stSportsID == 1)
    })
  }
  ngOnInit() {
    this.getMatchList();

    $(function () {
      "use strict";

      $(document).click(function (event) {
        if (!$(event.target).closest(".modal,.js-open-modal").length) {
          $("body").find(".modal").removeClass("show");
        }
      });

      // $(window).scroll(function ()
      // {
      //   var $scrolling = $(this).scrollTop();
      //   var bc2top = $("#back-top-btn");
      //   var stickytop = $(".sticky-top");
      //   if ($scrolling >= 10)
      //   {
      //     stickytop.addClass('navcss');
      //   }
      //   else
      //   {
      //     stickytop.removeClass('navcss');
      //   }
      //   if ($scrolling > 150)
      //   {
      //     bc2top.fadeIn(1000);
      //   }
      //   else
      //   {
      //     bc2top.fadeOut(1000);
      //   }
      // });


      $('.full_nav .nav > li > .more-less').on('click', function () {
        $('.full_nav .nav').toggleClass("tog-nav");
        $('.full_nav .nav').toggleClass("fa-time");
      });

      var nav = $('nav'),
        navOffset = nav.offset().top,
        $window = $(window);

      var html_body = $('html, body');
      $('nav a').on('click', function () {
        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
          var target = $(this.hash);
          if (target.length) {
            html_body.animate(
              {
                scrollTop: target.offset().top - 80
              }, 1000);
            return false;
          }
        }
      });

      // var bc2top = $('#back-top-btn');
      // bc2top.on('click', function ()
      // {
      //   html_body.animate(
      //   {
      //     scrollTop: 0
      //   }, 1300);
      // });

      $('.nav-link').on('click', function () {
        $('.navbar-collapse').collapse('hide');
      });
    });

    (function ($) {
      $.fn.menumaker = function (options) {
        var cssmenu = $(this),
          settings = $.extend(
            {
              format: "dropdown",
              sticky: false
            }, options);
        return this.each(function () {
          $(this).find(".button").on('click', function () {
            $(this).toggleClass('menu-opened');
            var mainmenu = $(this).next('ul');
            if (mainmenu.hasClass('open')) {
              mainmenu.slideToggle().removeClass('open');
            }
            else {
              mainmenu.slideToggle().addClass('open');
              if (settings.format === "dropdown") {
                mainmenu.find('ul').show();
              }
            }
          });
          cssmenu.find('li ul').parent().addClass('has-sub');
          var multiTg = function () {
            cssmenu.find(".has-sub").prepend('<span class="submenu-button"></span>');
            cssmenu.find('.submenu-button').on('click', function () {
              $(this).toggleClass('submenu-opened');
              if ($(this).siblings('ul').hasClass('open')) {
                $(this).siblings('ul').removeClass('open').slideToggle();
              }
              else {
                $(this).siblings('ul').addClass('open').slideToggle();
              }
            });
          };
          if (settings.format === 'multitoggle') multiTg();
          else cssmenu.addClass('dropdown');
          if (settings.sticky === true) cssmenu.css('position', 'fixed');
          var resizeFix = function () {
            var mediasize = 1000;
            if ($(window).width() > mediasize) {
              cssmenu.find('ul').show();
            }
            if ($(window).width() <= mediasize) {
              cssmenu.find('ul').hide().removeClass('open');
            }
          };
          resizeFix();
          return $(window).on('resize', resizeFix);
        });
      };
    })(jQuery);

    (function ($) {
      $(document).ready(function () {
        $("#cssmenu").menumaker(
          {
            format: "multitoggle"
          });
      });
    })(jQuery);

    if (sessionStorage.getItem('user') !== null) {
      this.router.navigate(["/LoginSuccess_mobile"]);
      return false;
    }
    if (this.CookieService.check('UserName') == false) {
      this.isRememberMe = false;
      this.model.ID = "";
      this.model.Key = "";
    }
    else {
      this.isRememberMe = true;
      this.model.ID = this.CryptojsService.decryptData(this.CookieService.get('UserName'));
      this.model.Key = this.CryptojsService.decryptData(this.CookieService.get('UserKey'));
    }

    if (this.CookieService.check('bTag')) {
      this._BTAG_STATUS = true;
      this._BTAG = this.CryptojsService.decryptData(this.CookieService.get('bTag'));
    }

    sessionStorage.clear();

    this.AppComponent.checkLogin();
  }

  openLogin() {
    document.getElementById("loginForm").classList.add("show");
  }

  closeLogin() {
    this.__IS_FORGOT_PASSWORD = false;
    this.__MOBILENUMBER = "";
    this.__IS_SEND_FORGOT_PASSWORD = -1;
    this.__GENERATED_OTP_F_PSW = "";
    this.__OTP_F_PSW = "";
    this.__OTP_UID = -1;
    document.getElementById("loginForm").classList.remove("show");
  }

  openSignup() {
    document.getElementById("registerForm").classList.add("show");
  }

  closeSignup() {
    this.__UserName = "";
    this.__FullName = "";
    this.__MobileNo = "";
    this.__EmailId = "";
    this.__Password = "";
    this.__IS_SUCCESS = false;
    this.__IS_SEND_REQUEST = false;

    document.getElementById("registerForm").classList.remove("show");
  }

  ngOnDestroy() {
    this.__ALLINONESUBSCRIPTION.unsubscribe();
  }
  ngAfterViewInit() {
    $('.preloader').delay(2500).fadeOut(1000);
  }

  enableLoginButton() {
    document.getElementById("LoginButton").removeAttribute("disabled");
    document.getElementById("LoginButton").setAttribute("value", "Sign in");
  }
  login() {
    document.getElementById("LoginButton").setAttribute("disabled", "true");
    document.getElementById("LoginButton").setAttribute("value", "Please wait...");
    if (this.model.ID.length == 0) {
      this.errorMessage = "Please Enter User Name";
      this.sendNotification("error", this.errorMessage);
      this.enableLoginButton();
      return false;
    }
    if (this.model.Key.length == 0) {
      this.errorMessage = "Please Enter Password.";
      this.sendNotification("error", this.errorMessage);
      this.enableLoginButton();
      return false;
    }

    this.LoginService.Login(this.model).subscribe(
      data1 => {
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));

        if (data.UserId != "0") {
          if (this.isRememberMe) {
            document.cookie = "UserName=" + this.CryptojsService.encryptData(this.model.ID) + "; Max-Age=${60*60*24}; SameSite=Strict";
            document.cookie = "UserKey=" + this.CryptojsService.encryptData(this.model.Key) + "; Max-Age=${60*60*24}; SameSite=Strict";
          }
          else {
            document.cookie = "UserName=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
            document.cookie = "UserKey=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
          }

          let SHA_256_KEY = CryptoJS.SHA256(Date.now().toString());

          sessionStorage.setItem("SHA_256_KEY", SHA_256_KEY.toString());
          sessionStorage.setItem("user", this.CryptojsService.encryptData(JSON.stringify(data)));

          sessionStorage.setItem("isPopupShow", "0");

          this.redirectHomePage(2);
        }
        else {
          this.enableLoginButton();
          this.errorMessage = data.Message;
          this.sendNotification("error", this.errorMessage);
          setTimeout(() => {
            this.errorMessage = "";
          }, 3000);
        }
      },
      error => {
        this.enableLoginButton();
        this.errorMessage = "Something went Wrong. Please check your Internet connection.";
        this.sendNotification("error", this.errorMessage);
        setTimeout(() => {
          this.errorMessage = "";
        }, 3000);
      });
  }

  redirectHomePage(RefreshCheck) {
    if (RefreshCheck == 2) {
      sessionStorage.setItem("isReload", "1");
      window.location.assign("");
    }
  }


  __UserName = "";
  __FullName = "";
  __MobileNo = "";
  __EmailId = "";
  __Password = "";
  __CPassword = "";

  AGREE_TERMS = false;

  ErrorFieldColor = "#ff0000";
  DefaultFieldColor = "#00a65a";

  onCheckedCheckBox(isChecked: boolean) {
    this.AGREE_TERMS = isChecked;
  }

  CloseSignupPopup() {
    this._IS_REGISTER_SUCCESS = false;
    this.closeSignup();

    if (document.getElementById("registerForm"))
      $('#registerForm').modal('hide');
  }

  __REGISTER_UID = -1;
  __REGISTER_PID = -1;
  __MERCHANT_KEY = this.CommonlistService.__MERCHANT_KEY;
  __MERCHANT_TNS_ID = "";
  __MERCHANT_AMOUNT = 0.00;
  __MERCHANT_P_INFO = "login100";
  __MERCHANT_F_NAME = "";
  __MERCHANT_L_NAME = "";
  __MERCHANT_EMAIL = "";
  __MERCHANT_PHONE = "";
  __MERCHANT_SURL = this.CommonlistService.__PAYU_PAYMENT_CALLBACK_URL// + "?p=1";
  __MERCHANT_FURL = this.CommonlistService.__PAYU_PAYMENT_CALLBACK_URL// + "?p=2";
  __MERCHANT_CURL = this.CommonlistService.__PAYU_PAYMENT_CALLBACK_URL// + "?p=3";
  __MERCHANT_HASH: any;
  __MERCHANT_SALT = this.CommonlistService.__MERCHANT_SALT;
  __IS_SUCCESS = false;
  __IS_SEND_REQUEST = false;
  __GENERATED_OTP = "1234";
  __OTP = "";
  signupDataSend() {
    if (this.__UserName == "") {
      if (document.getElementById("__UserName")) {
        document.getElementById("__UserName").style.borderColor = this.ErrorFieldColor;
      }
      return false;
    }
    else {
      if (document.getElementById("__UserName")) {
        document.getElementById("__UserName").removeAttribute("style");
      }
    }

    if (this.__FullName == "") {
      if (document.getElementById("__FullName")) {
        document.getElementById("__FullName").style.borderColor = this.ErrorFieldColor;
      }
      return false;
    }
    else {
      if (document.getElementById("__FullName")) {
        document.getElementById("__FullName").removeAttribute("style");
      }
    }

    if (this.__MobileNo == "" || (/^[\s()+-]*([0-9][\s()+-]*){6,20}$/.test(this.__MobileNo) == false)) {
      if (document.getElementById("__MobileNo")) {
        document.getElementById("__MobileNo").style.borderColor = this.ErrorFieldColor;
      }
      this.sendNotification("error", "Please enter valid Mobile Number.");
      return false;
    }
    else {
      if (document.getElementById("__MobileNo")) {
        document.getElementById("__MobileNo").removeAttribute("style");
      }
    }

    if (this.__EmailId == "" || (/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(this.__EmailId) == false)) {
      if (document.getElementById("__EmailId")) {
        document.getElementById("__EmailId").style.borderColor = this.ErrorFieldColor;
      }
      this.sendNotification("error", "Please enter valid Email address.");
      return false;
    }
    else {
      if (document.getElementById("__EmailId")) {
        document.getElementById("__EmailId").removeAttribute("style");
      }
    }

    if (this.__Password == "") {
      if (document.getElementById("__Password")) {
        document.getElementById("__Password").style.borderColor = this.ErrorFieldColor;
      }
      return false;
    }
    else {
      if (document.getElementById("__Password")) {
        document.getElementById("__Password").removeAttribute("style");
      }
    }

    if (this.__CPassword == "") {
      if (document.getElementById("__CPassword")) {
        document.getElementById("__CPassword").style.borderColor = this.ErrorFieldColor;
      }
      return false;
    }
    else {
      if (document.getElementById("__CPassword")) {
        document.getElementById("__CPassword").removeAttribute("style");
      }
    }

    if (this.__Password != this.__CPassword) {
      if (document.getElementById("__CPassword")) {
        document.getElementById("__CPassword").style.borderColor = this.ErrorFieldColor;
      }
      this.sendNotification("error", "Password and Confirm Password must be same.")
      return false;
    }
    else {
      if (document.getElementById("__CPassword")) {
        document.getElementById("__CPassword").removeAttribute("style");
      }
    }

    if (!this.AGREE_TERMS) {
      this.sendNotification("error", "Please agree with our terms & condition.");
      return false;
    }

    if (document.getElementById("__REGISTER_LOADER1")) {
      document.getElementById("__REGISTER_LOADER1").style.display = "block";
    }
    if (document.getElementById("__RegisterBtn1")) {
      document.getElementById("__RegisterBtn1").style.display = "none";
    }
    this.__GENERATED_OTP = Math.floor(100000 + Math.random() * 900000).toString();

    this.LoginService.RegisterSendOTP(this.__MobileNo, this.__GENERATED_OTP, this.__UserName).subscribe(
      data1 => {
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));

        if (document.getElementById("__REGISTER_LOADER1")) {
          document.getElementById("__REGISTER_LOADER1").style.display = "none";
        }
        if (document.getElementById("__RegisterBtn1")) {
          document.getElementById("__RegisterBtn1").style.display = "block";
        }

        if (data.status != 200) {
          this.sendNotification("error", data.message);
          return false;
        }
        this.sendNotification("success", data.message);
        this.__IS_SUCCESS = true;
        this.OTPTIMER = this.RESEND_SECONDS;
        this.ResendOTPTimer();
      },
      error => {
        this.sendNotification("error", "Something went wrong in Send OTP. Please try again.");

        if (document.getElementById("__REGISTER_LOADER1")) {
          document.getElementById("__REGISTER_LOADER1").style.display = "none";
        }
        if (document.getElementById("__RegisterBtn1")) {
          document.getElementById("__RegisterBtn1").style.display = "block";
        }
      });
    return false;
  }

  _IS_REGISTER_SUCCESS = false;
  signupSubmit() {
    if (this.__GENERATED_OTP != this.__OTP) {
      this.sendNotification("error", "Invalid OTP.");
      return false;
    }

    if (document.getElementById("__REGISTER_LOADER")) {
      document.getElementById("__REGISTER_LOADER").style.display = "block";
    }
    if (document.getElementById("__RegisterBtn")) {
      document.getElementById("__RegisterBtn").style.display = "none";
    }

    this.LoginService.RegisterSubmitSend(this.__MobileNo, this.__UserName, this.__FullName, this.__Password, this.__EmailId, this._BTAG).subscribe(
      data1 => {
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));

        if (document.getElementById("__REGISTER_LOADER")) {
          document.getElementById("__REGISTER_LOADER").style.display = "none";
        }
        if (document.getElementById("__RegisterBtn")) {
          document.getElementById("__RegisterBtn").style.display = "block";
        }

        if (data.status != 200) {
          this.sendNotification("error", data.message);
          return false;
        }
        this.__REGISTER_UID = data.inUserID;
        this.__REGISTER_PID = data.inParentID;

        this.sendNotification("success", data.message);
        this._IS_REGISTER_SUCCESS = true;
        // this.CloseSignupPopup();
      },
      error => {
        this.sendNotification("error", "Something went wrong in Signup. Please try again.");

        if (document.getElementById("__REGISTER_LOADER")) {
          document.getElementById("__REGISTER_LOADER").style.display = "none";
        }
        if (document.getElementById("__RegisterBtn")) {
          document.getElementById("__RegisterBtn").style.display = "block";
        }
      });
  }

  SELECT_COUNTRY = '-1';
  COUNTRY_LIST = this.LoginService.COUNTRY_LIST;
  FIRSTNAME = '';
  LASTNAME = '';
  USERNAME = '';

  MOBILENUMBER = '';
  EMAILADDRESS = '';
  PASSWORD = '';
  REPASSWORD = '';
  REFERRALCODE = '';;

  SELECTED_CURRENCY = '-1';
  IS_SEND_OTP = false;
  SELECTED_BONUS = '-1';

  errorSet(_DOCUMENT) {
    if (document.getElementById(_DOCUMENT))
      document.getElementById(_DOCUMENT).style.borderColor = "red";
  }

  errorRemove(_DOCUMENT) {
    if (document.getElementById(_DOCUMENT))
      document.getElementById(_DOCUMENT).removeAttribute("style");
  }

  registerClient() {
    if (this.FIRSTNAME.trim().length == 0) {
      this.errorSet('FIRSTNAME');
      this.sendNotification("error", "Please Enter First name.");
      return false;
    }
    else
      this.errorRemove('FIRSTNAME');

    // if(this.LASTNAME.trim().length == 0)
    // {
    //   this.errorSet('LASTNAME');
    //   this.sendNotification("error", "Please Enter Last name.");
    //   return false;
    // }
    // else
    // this.errorRemove('LASTNAME');

    if (this.USERNAME.trim().length == 0) {
      this.errorSet('USERNAME');
      this.sendNotification("error", "Please Enter User name.");
      return false;
    }
    else
      this.errorRemove('USERNAME');

    // if(this.REFERRALCODE.trim().length == 0)
    // {
    //   this.errorSet('REFERRALCODE');
    //   this.sendNotification("error", "Please Enter Referral Code.");
    //   return false;
    // }
    // else
    // this.errorRemove('REFERRALCODE');

    if (this.MOBILENUMBER == "" || (/^[\s()+-]*([0-9][\s()+-]*){6,20}$/.test(this.MOBILENUMBER) == false)) {
      this.errorSet('MOBILENUMBER');
      this.sendNotification("error", "Please enter valid Mobile Number.");
      return false;
    }
    else
      this.errorRemove('MOBILENUMBER');

    if (this.EMAILADDRESS == "" || (/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(this.EMAILADDRESS) == false)) {
      this.errorSet('EMAILADDRESS');
      this.sendNotification("error", "Please enter valid Email address.");
      return false;
    }
    else
      this.errorRemove('EMAILADDRESS');

    // if(this.PERCENT < 80)
    // {
    //   this.errorSet('PASSWORD');
    //   this.sendNotification("error", "Please enter strong password.")
    //   return false;
    // }
    // else
    //   this.errorRemove('PASSWORD');

    // if(/[+^()\-=\[\]'"\\|<>\/~]/.test(this.PASSWORD))
    // {
    //   this.errorSet('PASSWORD');
    //   this.sendNotification("error", "Password should not contain special characters like : " + /[+^()\-=\[\]'"\\|<>\/~]/);
    //   return false;
    // }
    // else
    //   this.errorRemove('PASSWORD');

    if (this.PASSWORD != this.REPASSWORD) {
      this.errorSet('REPASSWORD');
      this.sendNotification("error", "Password and Repassword must be same");
      return false;
    }
    this.errorRemove('REPASSWORD');

    if (!this.AGREE_TERMS) {
      this.sendNotification("error", "Please agree with our terms & condition.");
      return false;
    }

    this.LoginService.RegisterSendOTP(this.MOBILENUMBER, this.__GENERATED_OTP, this.USERNAME).subscribe(
      data1 => {
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));

        if (document.getElementById("__REGISTER_LOADER1")) {
          document.getElementById("__REGISTER_LOADER1").style.display = "none";
        }
        if (document.getElementById("__RegisterBtn1")) {
          document.getElementById("__RegisterBtn1").style.display = "block";
        }

        if (data.status != 200) {
          this.sendNotification("error", data.message);
          return false;
        }
        this.sendNotification("success", data.message);
        this.__IS_SUCCESS = true;

        this.OTPTIMER = this.RESEND_SECONDS;
        this.ResendOTPTimer();
      },
      error => {
        this.sendNotification("error", "Something went wrong in Send OTP. Please try again.");

        if (document.getElementById("__REGISTER_LOADER1")) {
          document.getElementById("__REGISTER_LOADER1").style.display = "none";
        }
        if (document.getElementById("__RegisterBtn1")) {
          document.getElementById("__RegisterBtn1").style.display = "block";
        }
      });
    return false;

    // this.sendOTP();
    // this.RegisterClientSend();
  }

  enableLoader() {
    if (document.getElementById("REGISTERBTN")) {
      document.getElementById("REGISTERBTN").style.display = "none";
    }
    if (document.getElementById("REGISTERLOADER")) {
      document.getElementById("REGISTERLOADER").style.display = "inline-block";
    }
  }

  disableLoader() {
    if (document.getElementById("REGISTERBTN")) {
      document.getElementById("REGISTERBTN").style.display = "inline-block";
    }
    if (document.getElementById("REGISTERLOADER")) {
      document.getElementById("REGISTERLOADER").style.display = "none";
    }
  }

  RegisterClientSend() {
    if (this.__GENERATED_OTP != this.__OTP) {
      this.sendNotification("error", "Invalid OTP.");
      return false;
    }

    this.SELECT_COUNTRY = 'IN';

    let _IP = this.CommonlistService.ipAddress;
    let _COUNTRY = this.COUNTRY_LIST.filter(({ code }) => {
      return code == this.SELECT_COUNTRY;
    });

    this.enableLoader();

    this.LoginService.RegisterClient(_IP, this.MOBILENUMBER, this.USERNAME, this.FIRSTNAME + ' ' + this.LASTNAME, this.EMAILADDRESS, _COUNTRY[0].name, _COUNTRY[0].code, this.SELECTED_CURRENCY, this.SELECTED_BONUS, this.PASSWORD, this.REFERRALCODE).subscribe(
      data1 => {
        this.disableLoader();
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));

        if (data.status != 200) {
          this.sendNotification("error", data.message);
          return false;
        }

        this.SELECT_COUNTRY = 'IN';

        this.FIRSTNAME = '';
        this.LASTNAME = '';
        this.USERNAME = '';

        this.MOBILENUMBER = '';
        this.EMAILADDRESS = '';
        this.PASSWORD = '';
        this.REPASSWORD = '';

        this.SELECTED_CURRENCY = '-1';
        this.SELECTED_BONUS = '-1';

        this.AGREE_TERMS = false;

        this.sendNotification("success", data.message);
        this.CloseSignupPopup();
      },
      error => {
        this.disableLoader();
        this.sendNotification("error", "Something went wrong in Register. Please try again.");
      });
  }

  openPayuRequest() {
    if (this.__MERCHANT_AMOUNT <= 0) {
      this.sendNotification("error", "Please enter valid Amount.");
      return false;
    }

    this.__IS_SEND_REQUEST = true;
    this.__MERCHANT_TNS_ID = this.uuidv4();
    this.__MERCHANT_P_INFO = "BUY" + this.__MERCHANT_AMOUNT;
    this.__MERCHANT_F_NAME = this.__UserName;
    this.__MERCHANT_L_NAME = this.__FullName;
    this.__MERCHANT_EMAIL = this.__EmailId;
    this.__MERCHANT_PHONE = this.__MobileNo;
    let __COMBINED_STR = this.__MERCHANT_KEY + "|" + this.__MERCHANT_TNS_ID + "|" + this.__MERCHANT_AMOUNT + "|" + this.__MERCHANT_P_INFO + "|" + this.__MERCHANT_F_NAME + "|" + this.__MERCHANT_EMAIL + "|||||||||||" + this.__MERCHANT_SALT;
    this.__MERCHANT_HASH = CryptoJS.SHA512(__COMBINED_STR);

    let __PARAMS = {
      "firstname": this.__MERCHANT_F_NAME,
      "lastname": this.__MERCHANT_L_NAME,
      "surl": this.__MERCHANT_SURL,
      "phone": this.__MERCHANT_PHONE,
      "key": this.__MERCHANT_KEY,
      "hash": this.__MERCHANT_HASH,
      "curl": this.__MERCHANT_CURL,
      "furl": this.__MERCHANT_FURL,
      "txnid": this.__MERCHANT_TNS_ID,
      "productinfo": this.__MERCHANT_P_INFO,
      "amount": this.__MERCHANT_AMOUNT,
      "email": this.__MERCHANT_EMAIL
    }

    this.LoginService.RegisterSendTNSID(this.__REGISTER_UID, this.__UserName, this.__MERCHANT_TNS_ID).subscribe(
      data1 => {
      },
      error => {
      });
    this.OpenWindowWithPost(__PARAMS);
  }

  OpenWindowWithPost(__PARAMS) {
    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", this.CommonlistService.__PAYU_PAYMENT_URL);
    form.setAttribute("target", "_blank");

    for (var i in __PARAMS) {
      if (__PARAMS.hasOwnProperty(i)) {
        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = i;
        input.value = __PARAMS[i];
        form.appendChild(input);
      }
    }

    document.body.appendChild(form);

    form.submit();

    document.body.removeChild(form);
  }

  __IS_FORGOT_PASSWORD = false;
  __MOBILENUMBER = "";
  __IS_SEND_FORGOT_PASSWORD = -1;
  __GENERATED_OTP_F_PSW = "1234";
  __OTP_F_PSW = "";
  __OTP_UID = -1;
  openForgotPassword(STATUS) {
    if (!STATUS) {
      this.__MOBILENUMBER = "";
      this.__IS_SEND_FORGOT_PASSWORD = -1;
      this.__GENERATED_OTP_F_PSW = "";
      this.__OTP_F_PSW = "";
      this.__OTP_UID = -1;
    }
    this.__IS_FORGOT_PASSWORD = STATUS;
  }

  generateOTP() {
    if (this.__MOBILENUMBER == "" || (/^\d{10}$/.test(this.__MOBILENUMBER) == false)) {
      if (document.getElementById("__MOBILENUMBER")) {
        document.getElementById("__MOBILENUMBER").style.borderColor = this.ErrorFieldColor;
      }
      this.sendNotification("error", "Please enter valid Mobile Number.");
      return false;
    }
    else {
      if (document.getElementById("__MOBILENUMBER")) {
        document.getElementById("__MOBILENUMBER").removeAttribute("style");
      }
    }

    if (document.getElementById("F_PSW_OTP_LOADER")) {
      document.getElementById("F_PSW_OTP_LOADER").style.display = "block";
    }
    if (document.getElementById("F_PSW_OTP_BTN")) {
      document.getElementById("F_PSW_OTP_BTN").style.display = "none";
    }
    this.__GENERATED_OTP_F_PSW = Math.floor(100000 + Math.random() * 900000).toString();

    this.LoginService.ForgotPasswordSendOTP(this.__MOBILENUMBER, this.__GENERATED_OTP_F_PSW).subscribe(
      data1 => {
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));

        if (document.getElementById("F_PSW_OTP_LOADER")) {
          document.getElementById("F_PSW_OTP_LOADER").style.display = "none";
        }
        if (document.getElementById("F_PSW_OTP_BTN")) {
          document.getElementById("F_PSW_OTP_BTN").style.display = "block";
        }

        if (data.status != 200) {
          this.sendNotification("error", data.message);
          return false;
        }
        this.sendNotification("success", data.message.split("|")[1]);
        this.__OTP_UID = data.message.split("|")[0];
        this.__IS_SEND_FORGOT_PASSWORD = 1; // 1 for SENT OTP, // 2 for CHANGE PASSWORD
      },
      error => {
        this.sendNotification("error", "Something went wrong in Send OTP. Please try again.");

        if (document.getElementById("F_PSW_OTP_LOADER")) {
          document.getElementById("F_PSW_OTP_LOADER").style.display = "none";
        }
        if (document.getElementById("F_PSW_OTP_BTN")) {
          document.getElementById("F_PSW_OTP_BTN").style.display = "block";
        }
      });
  }

  OTPTIMER = 0;
  RESEND_SECONDS = 60;
  OTPTIMER_CHECK: any;
  IS_RESEND_OTP = false;

  ResendOTPMethod() {
    this.LoginService.RegisterSendOTP(this.__MobileNo, this.__GENERATED_OTP, this.__UserName).subscribe(
      data1 => {
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));

        if (data.status != 200) {
          this.sendNotification("error", data.message);
          return false;
        }
        this.sendNotification("success", data.message);

        this.IS_RESEND_OTP = false;
        this.OTPTIMER = this.RESEND_SECONDS;
        this.ResendOTPTimer();
      },
      error => {
        this.sendNotification("error", "Something went wrong in send OTP. Please try again.");
      });
  }

  ResendOTPTimer() {
    this.OTPTIMER_CHECK = setInterval(() => {
      this.OTPTIMER--;

      if (this.OTPTIMER <= 0) {
        this.IS_RESEND_OTP = true;
        this.__IS_SUCCESS = true;
        clearInterval(this.OTPTIMER_CHECK);
        this.OTPTIMER_CHECK = null;
      }
    }, 1000);
  }

  ValidateOTP() {
    if (this.__GENERATED_OTP_F_PSW != this.__OTP_F_PSW) {
      this.sendNotification("error", "Invalid OTP.");
      return false;
    }
    this.__IS_SEND_FORGOT_PASSWORD = 2; // 1 for SENT OTP, // 2 for CHANGE PASSWORD
  }

  _NEW_PASSWORD = "";
  _CONFIRM_PASSWORD = "";
  changePassword() {
    if (this._NEW_PASSWORD == "") {
      this.sendNotification('error', "Please enter New Password.");
      return false;
    }

    if (this._CONFIRM_PASSWORD == "") {
      this.sendNotification("error", "Please enter Confirm Password.");
      return false;
    }

    if (this._NEW_PASSWORD != this._CONFIRM_PASSWORD) {
      this.sendNotification("error", "New and Confirm password does not Match.");
      return false;
    }

    if (document.getElementById("F_PSW_OTP_LOADER_1")) {
      document.getElementById("F_PSW_OTP_LOADER_1").style.display = "block";
    }
    if (document.getElementById("F_PSW_OTP_BTN_1")) {
      document.getElementById("F_PSW_OTP_BTN_1").style.display = "none";
    }

    this.LoginService.ForgotPasswordChange(this.__OTP_UID, this._NEW_PASSWORD).subscribe(
      data1 => {
        if (document.getElementById("F_PSW_OTP_LOADER_1")) {
          document.getElementById("F_PSW_OTP_LOADER_1").style.display = "none";
        }
        if (document.getElementById("F_PSW_OTP_BTN_1")) {
          document.getElementById("F_PSW_OTP_BTN_1").style.display = "block";
        }

        let data = this.CryptojsService.DecryptOrderFunction(data1);
        if (data != "1") {
          this.sendNotification("error", data);
          return false;
        }

        this.sendNotification('success', "Password has been changed Successfully.");
        this._NEW_PASSWORD = this._CONFIRM_PASSWORD = "";
        this.closeLogin();
        if (document.getElementById("loginForm"))
          $('#loginForm').modal('hide');
      },
      error => {
        this.sendNotification('error', "Something went wrong in Change Password. Please try again.");
        if (document.getElementById("F_PSW_OTP_LOADER_1")) {
          document.getElementById("F_PSW_OTP_LOADER_1").style.display = "none";
        }
        if (document.getElementById("F_PSW_OTP_BTN_1")) {
          document.getElementById("F_PSW_OTP_BTN_1").style.display = "block";
        }
      });
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  space_error = "";
  checkUserName(evt): boolean {
    const cCode = (evt.which) ? evt.which : evt.keyCode;
    var inp = String.fromCharCode(cCode);
    if (!(/[a-zA-Z0-9]/.test(inp))) {
      this.space_error = "No space or special characters allowed";
      setTimeout(() => {
        this.space_error = "";
      }, 2000);
      return false;
    }
    return true;
  }
}