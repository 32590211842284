import { Component, OnInit } from '@angular/core';
import { CryptojsService } from '../services/cryptojs.service';
import { UserdetailsService } from '../services/userdetails.service';
import { CheckmobileService } from '../services/checkmobile.service';
import { formatDate } from '@angular/common';
import { RjNotifierService } from '../services/rj-notifier.service';
import * as CryptoJS from 'crypto-js';
import { LoginService } from '../services/login.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-manage-deposit-pending',
  templateUrl: './manage-deposit-pending.component.html',
  styleUrls: ['./manage-deposit-pending.component.css']
})
export class ManageDepositPendingComponent implements OnInit
{
  SessionUser : any;

  __REQUEST_LIST = [];
  __REQUEST_LIST_LENGTH = 0;
  __USERTYPE = -1;
  isMobileView = this.CheckmobileService.isMobileView;
  private __ALLINONESUBSCRIPTION : Subscription = new Subscription();

  constructor(private LoginService : LoginService, private RjNotifierService : RjNotifierService, private CheckmobileService : CheckmobileService, private UserdetailsService : UserdetailsService, private CryptojsService : CryptojsService)
  {
    this.__ALLINONESUBSCRIPTION.add(
      this.LoginService.getAdminAccess().subscribe(
      (message) =>
      {
        this.checkAccess();
      })
    );
  }

  IS_ACCESS = false;
  checkAccess()
  {
    if(sessionStorage.getItem('_ACCESS') === null)
    {
      this.IS_ACCESS = false;
    }
    else
      this.IS_ACCESS = true;
  }

  ngOnDestroy()
  {
    this.__ALLINONESUBSCRIPTION.unsubscribe();
  }

  ngOnInit()
  {
    this.SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    this.__USERTYPE = this.SessionUser.UserType;
    // if(this.__USERTYPE == 3)
    //   this.getDeposirReqList();
    // else
      this.getDepositPendingAdmin();

    if(this.__USERTYPE == 4)
    this.checkAccess();
  }
  
  HideAllNotification()
  {
    this.RjNotifierService.hideNotifier();
  }

  sendNotification(Type, Message)
  {
    this.RjNotifierService.showNotifier(Type, Message);
  }

  enableLoader()
  {
    if(document.getElementById("__LOADERLIST"))
    document.getElementById("__LOADERLIST").style.height = "100%";
  }

  disableLoader()
  {
    if(document.getElementById("__LOADERLIST"))
    document.getElementById("__LOADERLIST").style.height = "0%";
  }

  __CLASSACTIVE = "active hoverPointer";
  __CLASSNORMAL = "hoverPointer";
  __U_TYPE = -1;
  __U_TYPE_CHECK = -1;
  __PAGE_NUMBER = 1;
  __PAGE_SIZE = 10;
  __TOTAL_PAGES = 0;
  __TOTAL_ENTRIES = 0;
  __SHOWING_FROM = 0;
  __SHOWING_TO = 0;
  __START_INDEX = 0;
  __END_INDEX = 0;

  changePageSizeList(__SIZE)
  {
    this.__PAGE_NUMBER = 1;
    this.__PAGE_SIZE = __SIZE;
    this.getDepositPendingAdmin()
  }

  ChangeStatus()
  {
    this.getDepositPendingAdmin();
  }
  showAllClients()
  {
    this.__PAGE_NUMBER = 1;
    this.getDepositPendingAdmin()
  }
  
  // __SEARCH_WHICH_TYPE = -1;
  // __SEARCH_KEYWORD = "";
  // searchClientListing()
  // {
  //   if(this.__SEARCH_KEYWORD == "")
  //   {
  //     this.sendNotification("error", "Please enter Name.");
  //     return false;
  //   }
    
  //   this.enableLoader();
  //   this.UserdetailsService.getDepositReqListSearch(this.SessionUser.UserId, this.__SEARCH_KEYWORD, this.__PAGE_SIZE, this.__PAGE_NUMBER).subscribe(
  //   data1 =>
  //   {
  //     this.disableLoader();
  //     let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));

  //     if(data.status != 200)
  //     {
  //       this.sendNotification("error", "No data Found");
  //       return false;
  //     }

  //     if(data.data.length == 0)
  //     {
  //       this.sendNotification("error", "No data Found");
  //       return false;
  //     }

  //     this.__REQUEST_LIST = data.data;
  //     this.__REQUEST_LIST_LENGTH = this.__REQUEST_LIST.length;
  //     this.__TOTAL_ENTRIES = this.__REQUEST_LIST[0].TotalCount;
  //     this.createPagination();
  //   },
  //   error =>
  //   {
  //     this.disableLoader();
  //   });
  // }

  createPagination()
  {
    if(this.__TOTAL_ENTRIES == 0)
    {
      this.__TOTAL_PAGES = this.__SHOWING_FROM = this.__SHOWING_TO = 0;
      return false;
    }
    this.__TOTAL_PAGES = Math.ceil(this.__TOTAL_ENTRIES / this.__PAGE_SIZE);

    this.__SHOWING_FROM = ((this.__PAGE_NUMBER * this.__PAGE_SIZE) - this.__PAGE_SIZE) + 1;
    this.__SHOWING_TO = ((this.__PAGE_NUMBER * this.__PAGE_SIZE) > this.__TOTAL_ENTRIES ? this.__TOTAL_ENTRIES : (this.__PAGE_NUMBER * this.__PAGE_SIZE));
    
    this.resetLimitPages();
  }
  __DISPLAYPAGE = [];
  resetLimitPages()
  {
    this.__DISPLAYPAGE = [];
    this.__START_INDEX = Math.max(1, this.__PAGE_NUMBER - 5);
    this.__END_INDEX = Math.min(this.__PAGE_NUMBER + 5, this.__TOTAL_PAGES);
    for(let i = this.__START_INDEX; i < this.__END_INDEX + 1; i++)
    {
      this.__DISPLAYPAGE.push(i);
    }
  }

  onNextButton()
  {
    this.__PAGE_NUMBER = this.__PAGE_NUMBER + 1;
    this.getDepositPendingAdmin();
  }

  onPreviousButton()
  {
    this.__PAGE_NUMBER = this.__PAGE_NUMBER - 1;
    this.getDepositPendingAdmin();
  }

  onDirectButton(__WHICHPAGE)
  {
    if(this.__PAGE_NUMBER == __WHICHPAGE)
    {
      return false;
    }
    this.__PAGE_NUMBER = __WHICHPAGE;
    this.getDepositPendingAdmin();
  }
  __STARTDATE = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  __ENDDATE = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  __SELECT_STATUS = -1;
  __TOTAL_AMOUNT = 0;
  getDepositPendingAdmin()
  {
    this.enableLoader();
    this.UserdetailsService.getDepositPendingList(this.SessionUser.UserId, this.__STARTDATE, this.__ENDDATE, this.__SELECT_STATUS, this.__PAGE_SIZE, this.__PAGE_NUMBER).subscribe(
    data1 =>
    {
      this.disableLoader();
      let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));

      if(data.status != 200)
      {
        this.sendNotification("error", "No data Found");
        return false;
      }

      if(data.data.length == 0)
      {
        this.sendNotification("error", "No data Found");
        return false;
      }

      this.__REQUEST_LIST = data.data;
      this.__REQUEST_LIST_LENGTH = this.__REQUEST_LIST.length;
      this.__TOTAL_ENTRIES = this.__REQUEST_LIST[0].TotalCount;
      this.__TOTAL_AMOUNT = this.__REQUEST_LIST[0].TotalAmount;
      this.createPagination();
    },
    error =>
    {
      this.disableLoader();
    });
  }

  onTakeActionCompleted(UserID, inParentID, tid, amount, status, method)
  {
    let Message = "";

    switch(status)
    {
      case 0:
        Message = "Do you want to take action in Requested?"
        break;
    
      case 1:
        Message = "Do you want to Reject?"
        break;
      
      case 2:
        Message = "Is Payment is Completed?"
        break;
    }

    if(!confirm(Message))
    {
      return false;
    }

    let __DATETIME = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.UserdetailsService.getDepositReqCompleteAction(UserID, inParentID, tid, amount, status, method, __DATETIME, this.__ACTION_REMARK).subscribe(
    data1 =>
    {
      let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));
        //  console.log(data)
      if(data.status != 200)
      {
        this.sendNotification("error", data.message);
        return false;
      }
      this.__ACTION_REMARK = "";
      this.__ACTION_NAME = "";
      this.__ACTION_UID = "";
      this.__ACTION_PID = "";
      this.__ACTION_TID = "";
      this.__ACTION_AMT = "";
      this.__ACTION_ST = "";
      this.__ACTION_M = "";
      if(document.getElementById("__ADD_REMARK_BTN"))
      {
        document.getElementById("__ADD_REMARK_BTN").style.display = "inline-block";
      }
      this.sendNotification("success", "Action taken Successfully");
      this.getDepositPendingAdmin()
      // console.log("getDepositReqCompleteAction => ");
      // console.log(data);
    },
    error =>
    {
      this.__ACTION_REMARK = "";
      this.__ACTION_NAME = "";
      this.__ACTION_UID = "";
      this.__ACTION_PID = "";
      this.__ACTION_TID = "";
      this.__ACTION_AMT = "";
      this.__ACTION_ST = "";
      this.__ACTION_M = "";
      if(document.getElementById("__ADD_REMARK_BTN"))
      {
        document.getElementById("__ADD_REMARK_BTN").style.display = "inline-block";
      }
    });
  }

  __ACTION_REMARK = "";
  __ACTION_NAME = "";
  __ACTION_UID = "";
  __ACTION_PID = "";
  __ACTION_TID = "";
  __ACTION_AMT = "";
  __ACTION_ST = "";
  __ACTION_M = "";
  onOpenActionModal(UserID, inParentID, tid, amount, status, method)
  {
    this.__ACTION_UID = UserID;
    this.__ACTION_PID = inParentID;
    this.__ACTION_TID = tid;
    this.__ACTION_AMT = amount;
    this.__ACTION_ST = status;
    this.__ACTION_M = method;

    switch(status)
    {
      case 1:
      this.__ACTION_NAME = "Reject Transaction"
        break;
      
      case 2:
      this.__ACTION_NAME = "Complete Transaction"
        break;
    }
  }
  
  onActionClick()
  {
    if(this.__ACTION_REMARK == "")
    {
      this.sendNotification("warning", "Please enter Remark.");
      return false;
    }
    if(document.getElementById("__ADD_REMARK_BTN"))
    {
      document.getElementById("__ADD_REMARK_BTN").style.display = "none";
    }
    this.onTakeActionCompleted(this.__ACTION_UID, this.__ACTION_PID, this.__ACTION_TID, this.__ACTION_AMT, this.__ACTION_ST, this.__ACTION_M)
  }

  _API_KEY = "bd6f6b41-80df-4c14-9ba1-447c2eaa88a5";
  _SALT = "3f54dbf832191104d520fde8a15437b880f74b27";
  checkPendingStatus(TID)
  {
    if(document.getElementById('btnStatus_'+TID))
    {
      // document.getElementById('btnStatus_'+TID).innerHTML = "PLEASE WAIT...";
      // document.getElementById('btnStatus_'+TID).className = "btn btn-sm btn-info";
      // document.getElementById('btnStatus_'+TID).setAttribute('disabled', 'disabled');
      document.getElementById('btnStatus_'+TID).style.display = "none";
      document.getElementById('txtStatus_'+TID).innerHTML = '<img src="/images/LoginLoaderGIF.gif" width="25px;" />';

      let _JSON_PARAMS =
      {
        api_key : this._API_KEY,
        merchant_reference_number : TID,
      }
      _JSON_PARAMS['hash'] = this.createHashRequest(_JSON_PARAMS, 0);

      this.UserdetailsService.setPendingAuto(_JSON_PARAMS).subscribe(
      data =>
      {
      
        try
        {
          let _RESP = JSON.parse(data);
          let _STT = 0;
           console.log(_RESP);
          if(_RESP.data)
          {
            let respcod = _RESP.data[0].response_code;
            if(respcod == 0)
            {
              _STT = 2;
              document.getElementById('btnStatus_'+TID).style.display = "none";
              document.getElementById('txtStatus_'+TID).innerHTML = "SUCCESS";
            }
            else //if(respcod == 9999 || respcod == 1000)
            {
              _STT = 1;
              document.getElementById('btnStatus_'+TID).style.display = "none";
              document.getElementById('txtStatus_'+TID).innerHTML = "FAILED";
            }
          }
          else
          {
            if(_RESP.error.code == 1028)
            {          
              _STT = 1;
              document.getElementById('btnStatus_'+TID).style.display = "none";
              document.getElementById('txtStatus_'+TID).innerHTML = 'REJECTED';
            }
            else if(_RESP.data.status == "SUCCESS")
            {
              _STT = 2;
              document.getElementById('btnStatus_'+TID).style.display = "none";
              document.getElementById('txtStatus_'+TID).innerHTML = 'SUCCESS';
            }
          }

          setTimeout(() => {
            this.UserdetailsService.savePendingAuto(_STT, TID).subscribe(
            data1 =>
            {},
            error =>
            {});
          }, 200);
        }
        catch(e)
        {
          document.getElementById('btnStatus_'+TID).style.display = "none";
          document.getElementById('txtStatus_'+TID).innerHTML = "TRANSACTION NOT FOUND";
        }
      },
      error =>
      {
        document.getElementById('btnStatus_'+TID).style.display = "none";
        this.sendNotification("error", "Something went wrong. Please try again.");
        // this.__ACTION_REMARK = "";
        // this.__ACTION_UID = "";
        // this.__ACTION_PID = "";
        // this.__ACTION_TID = "";
        // this.__ACTION_AMT = "";
        // this.__ACTION_ST = "";
        // this.__ACTION_M = "";
        // if(document.getElementById("__ADD_REMARK_BTN"))
        // {
        //   document.getElementById("__ADD_REMARK_BTN").style.display = "inline-block";
        // }
      });

    }
  }

  createHashRequest($parameters, _STATUS)
  {
    let secure_hash = null;

    let _CLOUMNS = [];

    switch(_STATUS)
    {
      case 0:
        _CLOUMNS = ["api_key", "merchant_reference_number", "amount", "transfer_type", "account_name", "account_number", "ifsc_code", "bank_name"];
        break;
      case 1:
        _CLOUMNS = ["api_key", "merchant_reference_number", "amount", "upi_id"];
        break;
      case 2:
        _CLOUMNS = ["api_key", "merchant_reference_number"];
        break;
    }
    _CLOUMNS.sort();

    let _INPUT = $parameters;

    let hash_data = this._SALT;
    for (let d of _CLOUMNS)
    {
      if(_INPUT[d])
      {
        if(_INPUT[d].length > 0)
        {
          hash_data += '|' + _INPUT[d].trim();
        }
      }
    }
    secure_hash = CryptoJS.SHA512(hash_data).toString().toUpperCase();
    return secure_hash;
  }

}
