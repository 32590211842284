import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';  
import { CommonlistService } from '../services/commonlist.service'
import { CryptojsService } from '../services/cryptojs.service';

@Injectable({
  providedIn: 'root'
})
export class ButtonstackrangeService
{
  Url :string;
  CommonHeader : any;
  SessionUser : any;

  ButtonValues : any;
  
  private onButtonValues$: Subject<any>;
  constructor(private CryptojsService : CryptojsService, private http : HttpClient, private CommonlistService:CommonlistService)
  {
    this.onButtonValues$ = new Subject<any>();
  }

  ngOnInit()
  {
    this.setButtonStackRange();
  }

  setButtonStackRange()
  {
    this.getButtonStackRange().subscribe(
    data1 =>
    {
      let data = this.CryptojsService.DecryptOrderFunction(data1);
      this.ButtonValues = data;
      this.onButtonValues$.next(data);
    },
    error =>
    {
      // console.log("error@getButtonStackRange : " + JSON.stringify(error));
    });
  }

  public getOnButtonValues(): Observable<any>
  {
    return this.onButtonValues$;
  }

  getButtonStackRange()
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    
    let __POSTDATA =
    {
      'AUT' : token.split('_')[1],
      "ID" : SessionUser.UserId
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART = 
    {
      "Data" : __HASH
    }
    
    this.Url = this.CommonlistService.ButtonStackUrl + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }

  SaveButtonStackRange(Btn0, Btn1, Btn2, Btn3, Btn4, Btn5)
  {
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    const token = SessionUser.Message;
    
    let __POSTDATA =
    {
      'AUT' : token.split('_')[1],
      "ID" : SessionUser.UserId,
      "S1" : Btn0,
      "S2" : Btn1,
      "S3" : Btn2,
      "S4" : Btn3,
      "S5" : Btn4,
      "S6" : Btn5
    }
    let __HASH = this.CryptojsService.encryptOrderFunction(JSON.stringify(__POSTDATA));

    let __BODYPART = 
    {
      "Data" : __HASH
    }
    
    this.Url = this.CommonlistService.SaveButtonStackUrl + "?Data=" + encodeURIComponent(__HASH.toString());
    return this.http.post<any>(this.Url, this.CommonlistService.httpOptions);
  }
}
