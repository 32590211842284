import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChipsinoutService } from '../services/chipsinout.service';
import { AppComponent } from '../app.component';
import { ButtonstackrangeService } from '../services/buttonstackrange.service';
import { CheckmobileService } from '../services/checkmobile.service';
import { LoginService } from '../services/login.service';
import { SignalRService } from '../services/signal-r.service';
import { UserdetailsService } from '../services/userdetails.service';
import { CryptojsService } from '../services/cryptojs.service';
import { CommonlistService } from '../services/commonlist.service';
import { Subscription } from 'rxjs';
import { RjNotifierService } from '../services/rj-notifier.service';

@Component({
  selector: 'app-headermenu',
  templateUrl: './headermenu.component.html',
  styleUrls: ['./headermenu.component.css']
})
export class HeadermenuComponent
{
  userTypeShow = 3;
  // Session_User = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
  UserType_Check : any;
  CurrentBalance_Temp : any;
  MarqueeMessage = "Welcome...";
  CurrentMainBalance = 0;
  CurrentOwnChips = 0;
  CurrentCreditChips = 0;
  CurrentExplosure = 0;
  CurrentPL = 0;
  CurrentRisk = 0;
  UserName_DB : any;
  UserNameDisplay_DB : any;
  UserID_DB : any;
  UserType = 0;

  myDate = new Date();

  ButtonValues : any;
  button0 : any;
  button1 : any;
  button2 : any;
  button3 : any;
  button4 : any;
  button5 : any;

  isMobileView = false;

  // CHANGE PASSWORD
  NewPassword = "";
  ConfirmPassword = "";

  isBetAllow = false;

  __APK_URL = this.CommonlistService.__APK_URL;

  private __ALLINONESUBSCRIPTION : Subscription = new Subscription();

  CurrentDateTime: Date = new Date();
  constructor(private RjNotifierService : RjNotifierService, private CommonlistService : CommonlistService, private CryptojsService : CryptojsService, private UserdetailsService : UserdetailsService, private SignalRService : SignalRService, private Router : Router, private LoginService : LoginService, private CheckmobileService : CheckmobileService, private ButtonstackrangeService : ButtonstackrangeService, private AppComponent : AppComponent, private ChipsinoutService : ChipsinoutService, private router:Router)
  {
    this.isMobileView = this.CheckmobileService.isMobileView;

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnMatched().subscribe(
      (data) =>
      {
        if(this.UserType == 3)
        this.getCurrentBalance();
      })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnMatchedDiamond().subscribe(
      (data) =>
      {
        if(this.UserType == 3)
        this.getCurrentBalance();
      })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnLotusBalanceRefresh().subscribe(
      (data) =>
      {
        if(this.UserType == 3)
        this.getCurrentBalance();
      })
    );
  }

  setDateTime()
  {
    setInterval(() => {
      this.CurrentDateTime = new Date();
    }, 1);
  }

  __ISADDBALANCE = 0;
  ngOnInit()
  {
    if(sessionStorage.getItem('user') !== null)
    {
      this.setDateTime();
      // console.log("sessionStorage.user : " + sessionStorage.user);
      var Session_User = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
      this.UserName_DB = Session_User.UserName;
      // console.log("this.UserName_DB.length : " + this.UserName_DB.length)
      this.UserNameDisplay_DB = (this.UserName_DB.length > 7 ? this.UserName_DB.substr(0, 5) + "..." : this.UserName_DB);
      this.UserType =  Session_User.UserType;
      this.UserID_DB = Session_User.UserId;
      this.__ISADDBALANCE = Session_User.inDelay;

      switch (Session_User.UserType)
      {
        case 0:
          this.UserType_Check = "ADMIN";
          break;

        case 1:
          this.UserType_Check = "SUPER";
          break;

        case 2:
          this.UserType_Check = "MASTER";
          break;

        case 3:
          this.UserType_Check = "CLIENT";
          break;

        case 4:
          this.UserType_Check = "Hyper";
          break;

        case 5:
          this.UserType_Check = "SUB-SUPER-1";
          break;

        default:
          break;
      }
      this.getCurrentBalance();
      
      this.isMobileView = this.CheckmobileService.isMobileView;
      this.ButtonstackrangeService.ngOnInit();
    }
    else
      this.Logout(1);

    this.checkStackButtons();
  }

  ngOnDestroy()
  {
    this.__ALLINONESUBSCRIPTION.unsubscribe();
  }

  onAddBalanceClick()
  {
    if(document.getElementById("AddBalancePopupData"))
    {
      document.getElementById("AddBalancePopupData").style.display = "block";
    }
  }
  onTransactionClick()
  {
    this.Router.navigate(['/manage/payments/deposit']);
  }
  
  onHomeClick()
  {
    this.SignalRService.changeOnHomeButtonClick(1);
  }

  onDynamicRouterClick(__ROUTER)
  {
    this.Router.navigateByUrl('/RefreshComponent', {skipLocationChange: true}).then(()=>
    this.Router.navigate([__ROUTER]));
  }

  setServerMessage(data)
  {
    if(data != "")
      this.MarqueeMessage = data;
  }
  
  HideAllNotification()
  {
    this.RjNotifierService.hideNotifier();
  }

  sendNotification(Type, Message)
  {
    this.RjNotifierService.showNotifier(Type, Message);
  }

  ChangePassword()
  {
    if(this.NewPassword == "")
    {
      this.sendNotification('error', "Please enter New Password.");
    }
    else
    {
      if(this.NewPassword == this.ConfirmPassword)
      {
        this.UserdetailsService.changePasswordSelf(this.UserID_DB, this.NewPassword).subscribe(
        data1 =>
        {
          let data = this.CryptojsService.DecryptOrderFunction(data1);

          if(data != "1")
          {
            this.sendNotification("error", data);
            return false;
          }
          this.sendNotification('success', "Password has been changed Successfully.");
          this.ConfirmPassword = this.NewPassword = "";
        },
        error=>
        {
          // console.log("error@Password : " + JSON.stringify(error));
        });
      }
      else
      {
        this.sendNotification('error', "New and Confirm password must be same.");
      }
    }
  }

  getCurrentBalance()
  {
    this.ChipsinoutService.getMyCurrentBalance(this.UserID_DB).subscribe(
    data1 =>
    {
      let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));

      this.CurrentBalance_Temp = data.deBalance;
      this.CurrentMainBalance = data.deBalance;
      // this.CurrentOwnChips = data.deChips;
      this.CurrentOwnChips = data.deBalance;
      this.CurrentCreditChips = data.deCreditChips;
      this.CurrentPL = this.CurrentOwnChips - this.CurrentCreditChips;
      this.CurrentExplosure = data.deLiability + data.deRisk;
      this.CurrentRisk = data.deRisk;
    },    
    error => 
    {
      // console.log("Error@getCurrentBalance : " + JSON.stringify(error) + " : " + error.StatusCode);
      return false;
    });
  }

  Logout(status)
  {
    if(status == 0)
      return false;

    // setTimeout(() => {
      if(!this.isMobileView)
        this.router.navigate(['/login'],); 
      else
        this.router.navigate(['/login_mobile'],);
    // }, 1500);
    // console.log("Logout Called");
    // sessionStorage.clear();
    
    // if(this.SignalRService.connection.state == 1)
    // this.SignalRService.BCast.invoke("LogoutClientMulti", this.UserID_DB).done(() => { });
    // else
    // {
    //   this.SignalRService.connection.start().done(() =>
    //   {
    //     this.SignalRService.BCast.invoke("LogoutClientMulti", this.UserID_DB).done(() => { });
    //   });
    // }
    
    this.LoginService.logoutAPI().subscribe(
    data =>
    {
      // console.log("Resp@logoutAPI : " + JSON.stringify(data));
      delete sessionStorage.user;
      this.AppComponent.checkLogin();
    },
    error =>
    {
      // console.log("Error@logoutAPI : " + error);
      delete sessionStorage.user;
      this.AppComponent.checkLogin();
    });
  }

  checkStackButtons()
  {
    if(this.ButtonstackrangeService.ButtonValues == undefined)
    {
      setTimeout(() => {
        this.checkStackButtons();
      }, 100);
      return false;
    }
    this.getButtonStack();
  }
  
  getButtonStack()
  {
    this.ButtonValues = this.ButtonstackrangeService.ButtonValues;
    
    this.button0 = this.ButtonValues.split(',')[0];
    this.button1 = this.ButtonValues.split(',')[1];
    this.button2 = this.ButtonValues.split(',')[2];
    this.button3 = this.ButtonValues.split(',')[3];
    this.button4 = this.ButtonValues.split(',')[4];
    this.button5 = this.ButtonValues.split(',')[5];
  }

  SaveStacks()
  {
    this.ButtonstackrangeService.SaveButtonStackRange(this.button0, this.button1, this.button2, this.button3, this.button4, this.button5).subscribe(    
    data1 => 
    {
      // console.log(data1)
      // let data = this.CryptojsService.DecryptOrderFunction(data1);  
      if(data1 == null)
      {
        this.sendNotification("success", "Stacks has been saved Successfully.");
        this.ButtonstackrangeService.setButtonStackRange();
      }
      // console.log("Response@SaveButtonStackRange : " + data);
    },    
    error => 
    {    
      // console.log("error@SaveButtonStackRange : " + error);  
    }); 
  }
}

