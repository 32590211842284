import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonlistService {
  // commonUrl_Fundist = "https://fundiest.moneyexch.co/Api/";
  // commonUrl = "https://signup.moneyexch.uk/Api/";
  // SignalRURL = "https://dcbet.moneyexch.uk/signalr/hubs";
  // __DIAMOND_SIGNALR_URL = "https://dcbet1.moneyexch.uk/signalr/hubs";
  // __DIAMOND_CASINO_URL = "https://casino.moneyexch.uk/Api/";

  // __COINS_SignalRURL = "https://coins.moneyexch.uk/signalr/hubs";

  // // commonUrl = "https://signup.golden9exchange.com/Api/";
  // commonUrl_Fundist = "https://fundiest.golden9exchange.com/Api/";
  // //commonUrl = "https://signup.hotsports.com/Api/";
  // commonUrl = "https://ssexch.onexchbet.com/Api/"; 
  commonUrl11 = "https://betapi.orange9.club/Api/";
  // // commonUrl = "https://dataapi.hotsports.com/Api/";
  // SignalRURL = "https://dcbet.golden9exchange.com/signalr/hubs";
  // __DIAMOND_SIGNALR_URL = "https://dcbet1.golden9exchange.com/signalr/hubs";
  // __DIAMOND_CASINO_URL = "https://casino.golden9exchange.com/Api/";

  commonUrl_Fundist = "https://fundiest.orange9exch.com/Api/";
  commonUrl = "https://ssexch.onex99.com/Api/"; //"https://betx.onex99.com/Api/";
  SignalRURL = "https://hypapi.orange9.club/signalr/hubs"; //"https://dcbet.orange9.club/signalr/hubs";
  // commonUrl = "https://ssexch.onexchbet.com/Api/"; //"https://betx.onex99.com/Api/";
  // SignalRURL = "https://hyp.onexchbet.com/signalr/hubs"; //"https://dcbet.orange9.club/signalr/hubs";
  __DIAMOND_SIGNALR_URL = "https://dcbet1.orange9.club/signalr/hubs";
  __DIAMOND_CASINO_URL = "https://casino.orange9.club/Api/";

  MTimer = 5000;
  RTimer = 1000;

  // commonUrl = "https://dataapi.hotsports.com/Api/";
  // SignalRURL = "https://datatest.hotsports.com/signalr/hubs";

  // _MAINTENANCE_ID = 12;
  _MAINTENANCE_ID = 12;

  TeenPattiLiveVideoURL = "https://www.starlive.live:8888/embed_player?urlServer=wss://www.starlive.live:8443&streamName=ch_1&mediaProviders=WebRTC,Flash,MSE,WSPlayer";
  PokerLiveVideoURL = "https://www.starlive.live:8888/embed_player?urlServer=wss://www.starlive.live:8443&streamName=ptt20&mediaProviders=WebRTC,Flash,MSE,WSPlayer";

  __WEBSOCKET_URL = "wss://wssfoxlive.speed777.in/api/stream";

  DecryptKKey = ["M", "z", "*", "e", "~", "v", "5", "#", "B", "1", "c", "^", "u", "!", "W", "s", "$", "F", "h", "#", "p", "e", "*", "u", "8", "!", "w", "3", "L", "*"];
  DecryptRateKey = ["3", "#", "D", "e", "~", "v", "5", "^", "B", "1", "c", "~", "u", "$", "W", "s", "F", "!", "h", "p", "@", "e", "u", "^", "8", "~", "w", "w", "^", "5"];
  DecryptSocket = ["~", "S", "n", "^", "y", "!", "k", "9", "#", "C", "z", "o", "@", "q", "^", "o", "W", "!", "b", "f", "8", "$", "O", "2", "Z", "*", "F", "!", "r", "7"];
  DecryptUserKey = ["y", "!", "L", "y", "L", "#", "*", "r", "^", "F", "h", "#", "a", "U", "!", "n", "K", "T", "$", "j", "*", "n", "p", "O", "#", "e", "t", "*", "t", "e"];

  LoginWebKey = 'dt18mn11yr20yhfuyf465421cbcbniD45lsd1xcvW27ats3ER2dxIvuysi7gbin3';

  DecOKey = ["K", "m", "^", "j", "m", "!", "e", "4", "~", "s", "$", "h", "*", "C", "~", "0", "v", "X", "a", "@", "9", "E", "z", "X", "$", "e", "!", "B", "n", "*"];

  __AUTO_HIDE = 4000;
  getVideoTokenURL = this.commonUrl + "TeenPatti/GetVideoToken";

  CommonHorseImageURL = "https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/";// + mktWatch.COLOURS_FILENAME

  ipAddress: any;
  loginHeader: any;
  Connection = 4;

  PlusMinusStackValue = 100;
  __APK_URL = "https://www.onupdate.in/app/fox246/fox246.apk";
  _FACEBOOK = "https://www.facebook.com/moneyexch/";
  _WHATSAPP = "";
  _TWITTER = "https://twitter.com/Moneyexch1";
  _INSTAGRAM = "https://instagram.com/hotsportsindia?utm_medium=copy_link";
  _LINKEDIN = "https://www.linkedin.com/company/moneyexch";
  _YOUTUBE = "https://youtube.com/channel/UCKgfjQndfyUdcOC60N2wOow";

  // __CASINO_VIDEO_URL = "https://tv.goldan1.com/#/?params=";
  __CASINO_VIDEO_URL = "https://live.appleexch.co/?params=";
  // __TV_STREAM_URL = "https://tv.appleexch.co/#/?params=";
  // __TV_STREAM_URL = "https://sportbet.id/live_tv/index.html?eventId=";

  __TV_STREAM_URL = "https://heroexch247.com/tv?eventId="//"https://orangeexch999.com/jmp/nm3/all.php?eventId=";
  __LIVE_SCORE_URL = "https://sportsscore.onupdate.in/#/";
  __LIVE_SCORE_NEW_URL = "https://sportsscore.onupdate.in/#/";

  __MASTERREQUESTJSON_URL = "https://bts.onex99.com/api/Admin/GetMaster";
  __LIVERATEJSON_URL = "https://bts.onex99.com/api/Admin/GetAllBrodCC";
  __LIVERATEJSONE_URL = "https://bts.onex99.com/api/Admin/GetAllBrodCC";
  _SINGLE_RATE_URL = 'https://file.orange9.club/';

  __MASTER_NEW_JSON_URL = "https://nodebb.speed777.in/api/file/getMaster";
  __ORDER_URL = "https://foxorderapi.speed777.in/api/admin/TestOrder";

  // TEST GATEWAY
  // __PAYU_PAYMENT_URL =  "https://test.payu.in/_payment";
  // __MERCHANT_KEY = "gtKFFx";
  // __MERCHANT_SALT = "eCwWELxi";
  // LIVE GATEWAY
  __PAYU_PAYMENT_URL = "https://secure.payu.in/_payment";
  __MERCHANT_KEY = "zmJYBK";
  __MERCHANT_SALT = "YP9edC50";x

  __PAYU_PAYMENT_CALLBACK_URL = this.commonUrl + "Data/GetDataMoney";

  __LIVE_SCORE_MOBILE_URL = "https://livesportscore.xyz/VRN/v1/api/scoreid/get?eventid="; // Get Score ID
  // __LIVE_SCORE_MOBILE_FRAME_URL =  "https://sportbet.id/cricket_score/index.html?scoreId={ScoreId}&matchDate={YYYY-MM-DD HH:MM:SS}";
  __LIVE_SCORE_MOBILE_FRAME_URL = "https://livesportscore.xyz/{whichScore}/?scoreId={ScoreId}&matchDate={YYYY-MM-DD HH:MM:SS}";

  __OPERATORID = 9343;
  __LOTUSRESULT_URL = "https://lotus.golden9exchange.com/APi/Poker/resultsOwn?oid=" + this.__OPERATORID + "&roundID=";

  __SLOTS_IMAGE_URL = "https://static.egamings.com/games/";

  /* FLOXY PAY ============================================== */
  __FLOXY_PAY_USER_URL = this.commonUrl + "Data/FloxyGetUser";
  __PAYMENT_REQUEST_URL = this.commonUrl + "Data/paymentRequestHotSports1";
  // __PAYMENT_REQUEST_URL= this.commonUrl + "Data/FloxyGetUser";
  // __PAYMENT_REQUEST_URL = this.commonUrl + "Data/paymentRequestHotSports1";
  __IPINT_PAYMENT_REQUEST_URL = this.commonUrl + "Data/ipintCreateInvoice";

  // __PAYMENT_REQUEST_URL = this.commonUrl + "Data/EternalPantGateway";
  // __PAYMENT_REQUEST_URL = "https://pay.eternalpant.com/payments/process.php";
  /* SECURE API ============================================= */
  // __SITE_ID = 201;
  // __SITE_ID = 26;
  // // __GETBANNERS_URL = this.commonUrl + "Data/getImageHotSports";
  // // __CHANGEBANNERS_URL = this.commonUrl + "Data/UploadFilesHotSports";
  // __GETBANNERS_URL = this.commonUrl11 + "Data/getImageOrange";
  // __CHANGEBANNERS_URL = this.commonUrl11 + "Data/UploadFilesOrange";
  commonUrl15 = "https://parker.speed777.in/Api/";
  __SITE_ID = 14;
  // commonUrl11 = "https://betapi.orange9.club/Api/";
  __GETBANNERS_URL = this.commonUrl15 + "flex/getallImages3exch";
  __CHANGEBANNERS_URL = this.commonUrl15 + "flex/UploadFiles3exch";

  httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          hash: "my-hash-key"
        })
    };

  /* MULTI API CHANGES */
  //LoginUrl = this.commonUrl + "User/AuthenticateSECUREMultiHotSport";//AuthenticateSECUREMulti";
  LoginUrl = this.commonUrl + "CommanUser/aWeyv0omF7krQvlrfEEbCS9rss8HSbsWtK5jHr0Moab5ZICZibj8g5NlCH7WvIokjzg4StLc8"; //  LoginUrl = this.commonUrl + "User/AuthenticateUniqueSECURE";
  // getParentNameURL = this.commonUrl + "Data/ParentNameSECUREMulti";
  getParentNameURL = this.commonUrl + "Radhe/euiNsnJDfCLop3ELW675pMqbucOwAwfyXXGkEbOf5VjoCqrVSQ"; //  getParentNameURL = this.commonUrl + "Data/ParentNameSECURE";

  // __Register_SUBMIT_URL = this.commonUrl + "Data/onlineSignUpHOTSPORTNEWSECUREMulti";
  __Register_SUBMIT_URL = this.commonUrl + "fox/antsSLGgXzQaxcd89VmRDAgdWoG2tz4gdpZLUaAqUJjsMnknt6J02K6DHLjR"; //   __Register_SUBMIT_URL = this.commonUrl + "Data/SignUpSkyNew99SECURE";

  REGISTER_CLIENT_URL = this.commonUrl + "fox/antsSLGgXzQaxcd89VmRDAgdWoG2tz4gdpZLUaAqUJjsMnknt6J02K6DHLjR"; //SignUpOneXBetSECURE"; //   REGISTER_CLIENT_URL = this.commonUrl + "Data/SignUpSkyNew99SECURE";//SignUpOneXBetSECURE";

  // __FORGOTPSW_OTP_URL = this.commonUrl + "Data/forgotPasswordOTPHotSportsMulti";
  __FORGOTPSW_OTP_URL = this.commonUrl + "Giga/Hj49b0rVYtqwiqBloDJPC0mHg6TEexD0kXgpollIVkF997KHZIOaGEt13pQEIjyGL5UCurVUw";//forgotPasswordOTPHotSports"; //   __FORGOTPSW_OTP_URL = this.commonUrl + "Data/forgotPasswordOTPSkyNew99";//forgotPasswordOTPHotSports";

  //__FORGOTPSW_CHANGE_URL = this.commonUrl + 'Data/forgotPasswordChangeHotSportsMulti';
  __FORGOTPSW_CHANGE_URL = this.commonUrl + 'Giga/Hj49b0rVYtqwiqBloDJPC0mHg6TEexCcYocTYj5pQjyX66a2pcjn2ECYllhTvM5iDJVsqrc2rjhqrPLyww';//forgotPasswordChangeHotSports'; //   __FORGOTPSW_CHANGE_URL = this.commonUrl + 'Data/forgotPasswordChangeSkyNew99';//forgotPasswordChangeHotSports';

  // __REGISTER_OTP_URL = this.commonUrl + "Data/sendRegisterOTPHotSportsMulti";
  __REGISTER_OTP_URL = this.commonUrl + "Giga/NGA2EIS6MkImj3HFKwGlP9Oy2tRPTi4EmddLXMP8ic51U4wjdOfyBsC3Vtwi4zM0OKTwq8d0";//sendRegisterOTPOneXBet";//sendRegisterOTPHotSports"; //   __REGISTER_OTP_URL = this.commonUrl + "Data/sendRegisterOTPSkyNew99";//sendRegisterOTPOneXBet";//sendRegisterOTPHotSports";

  // GetUserDetailsByNameURL = this.commonUrl + 'Admin/UserDetailByNameSECUREMulti';
  GetUserDetailsByNameURL = this.commonUrl + 'CommanAdmin/UserDetailByNameSECURE';//GetUserDetailsByNameURL = this.commonUrl + 'Admin/UserDetailByNameSECURE';

  //__CLIENTLIST_SEARCH_URL = this.commonUrl + "Data/UserListByReportSearchSECUREMulti"; // UID, TYPE, WORD, PS, PN
  __CLIENTLIST_SEARCH_URL = this.commonUrl + "fox/VxPHWO1ztzY4HGLYdUdGlAgETdZ7uWKTDHBVvAAYZvckglSUe00a1a70zNP55CGDjU7OgMNMos6n7Rw"; //  __CLIENTLIST_SEARCH_URL = this.commonUrl + "Data/UserListByReportSearchSECURE";// UID, TYPE, WORD, PS, PN

  //AddNewUserURL = this.commonUrl + 'user/AddUserSECUREMulti';
  AddNewUserURL = this.commonUrl + 'CommanUser/mVjffUA04YxKR2dwn6CJvrk32sJLQGC1Ndn7ngz970c';//AddNewUserURL = this.commonUrl + 'user/AddUserSECURE';
  __LockUnlockData = this.commonUrl + "user/AddLockUnlock"

  // LoginUrl = this.commonUrl + "User/AuthenticateHotSportsSECURE";
  // getParentNameURL = this.commonUrl + "Data/ParentNameSECURE";
  // GetUserDetailsByNameURL = this.commonUrl + 'Admin/UserDetailByNameSECURE';
  // AddNewUserURL = this.commonUrl + 'user/AddUserSECURE';

  // __CLIENTLIST_SEARCH_URL = this.commonUrl + "Data/UserListByReportSearchSECURE";

  // __FORGOTPSW_CHANGE_URL = this.commonUrl + 'Data/forgotPasswordChangeHotSports';
  // __FORGOTPSW_OTP_URL = this.commonUrl + "Data/forgotPasswordOTPHotSports";
  // __REGISTER_OTP_URL = this.commonUrl + "Data/sendRegisterOTPHotSports";
  // __Register_SUBMIT_URL = this.commonUrl + "Data/onlineSignUpHOTSPORTNEWSECURE";

  /* MULTI API CHANGES END */

  __GET_IPADDRESS_LIST_URL = this.commonUrl + "Data/GetUserIPList";//"https://api.fonepaisa.com/v3/fundtransfer";
  __WITHDRAW_REMOVE_PROCESSED_URL = this.commonUrl + "Data/WithdrawHotsportsRemoveProcessedSECURE";//"https://api.fonepaisa.com/v3/fundtransfer";
  //__WITHDRAW_AUTO_URL = this.commonUrl + "Data/WithdrawHotsportsAutoSECURE";//"https://api.fonepaisa.com/v3/fundtransfer";
  __WITHDRAW_AUTO_URL = this.commonUrl + "Giga/kXETTTXGG7JwNfhWpmBd5TKq0B9L80y03u5aBshjzGDhpyGobTEEoIDeu61KyUhfNeKy7duaB8ihd5k2jcwqZw";//__WITHDRAW_AUTO_URL = this.commonUrl + "Data/WithdrawAutoSkyNew99SECURE";  //WithdrawHotsportsAutoSECURE";  //"https://api.fonepaisa.com/v3/fundtransfer";
  __WITHDRAW_AUTO_STATUS_URL = this.commonUrl + "Data/kXETTTXGG7JwNfhWpmBd5XuWNzh6jYKNcTRkChS7RNS3zvuHdRT7zpHyNIKc0caYcH3SwP6Puf4E65p85E4Ji3O4Lt7r3";//__WITHDRAW_AUTO_STATUS_URL = this.commonUrl + "Data/WithdrawHotsportsAutoStatusSECURE";  //"https://api.fonepaisa.com/v3/fundtransfer";

  //__WITHDRAW_AUTO_STATUS_URL = this.commonUrl + "Data/WithdrawHotsportsAutoStatusSECURE";//"https://api.fonepaisa.com/v3/fundtransfer";

  // __PENDING_AUTO_URL = this.commonUrl + "Data/PenddingCheckHotsportsSECURE";//"https://api.fonepaisa.com/v3/fundtransfer";
  __PENDING_AUTO_URL = this.commonUrl + "Radhe/nAeaECqJCzM2bckPJ5UtxKBviZcRlgeSOHyMxLyyHwv02sQSqdmnoerbMHaS7N8sDeWxeqXykpOclWYOXa8Uw";//__PENDING_AUTO_URL = this.commonUrl + "Data/PenddingCheckHotsportsSECURE";//"https://api.fonepaisa.com/v3/fundtransfer";

  //__PENDING_SAVE_URL = this.commonUrl + 'Data/PenddingSaveHotsportsSECURE';
  __PENDING_SAVE_URL = this.commonUrl + 'Radhe/nAeaECqJCzM2bckPJ5UtxBcRecYIufAryg0oypn4RSawyPuYb23ifUj0JHh4uFg7kqiqcZf0C8';//__PENDING_SAVE_URL = this.commonUrl + 'Data/PenddingSaveHotsportsSECURE';


  //__CLIENTLIST_REGISTERED_URL = this.commonUrl + "Data/GetUserListByReportOnlineHotSport"; // UID, TYPE, PS, PN
  __CLIENTLIST_REGISTERED_URL = this.commonUrl + "Radhe/aEY64IeJHH5RkNHRpjeTdY106xr2UEPcykTpFd4XBYpLoafVq9RICYGaFGtuNbdjOfHq1o"; //__CLIENTLIST_REGISTERED_URL = this.commonUrl + "Data/GetUserListByReportOnline"; // UID, TYPE, PS, PN

  // __CLIENTLIST_SEARCH_REGISTERED_URL = this.commonUrl + "Data/GetUserListByReportOnlineSearch"; // UID, TYPE, WORD, PS, PN
  __CLIENTLIST_SEARCH_REGISTERED_URL = this.commonUrl + "Radhe/aEY64IeJHH5RkNHRpjeTdY106xr2UEPcykTpFd4XBYpLoafVq9RICYGaFGtIDcEXTzyPd4BxehcugODEg"; //__CLIENTLIST_SEARCH_REGISTERED_URL = this.commonUrl + "Data/GetUserListByReportOnlineSearch"; // UID, TYPE, WORD, PS, PN


  //__STACK_RANGE_EVENT_URL = this.commonUrl + "Admin/StackRangeByEventIDSECURE";
  __STACK_RANGE_EVENT_URL = this.commonUrl + "CommanAdmin/yeKQxH4WnqNQZi5ZuINFLHxGeHZ30kuZLlsrHxrjMZ3WlUPs53O9Fnk9hpflqvXuUAs5SsgXY"; // __STACK_RANGE_EVENT_URL = this.commonUrl + "Admin/StackRangeByEventIDSECURE";

  //__STACK_RANGE_URL = this.commonUrl + "Admin/StackRangeNewSECURE";
  __STACK_RANGE_URL = this.commonUrl + "CommanAdmin/yeKQxH4WnqNQZi5ZuINFLFIbMXFxN6vMI8nqKPEA4DUwrCVxq5ORQ";//  __STACK_RANGE_URL = this.commonUrl + "Admin/StackRangeNewSECURE";

  CheckSecurity = this.commonUrl + "User/SecurityCheck";
  // LoginUrl = this.commonUrl + "User/AuthenticateGURUSECURE";
  // LogoutURL = this.commonUrl + "User/LogoutGoldenSECURE";
  LogoutURL = this.commonUrl + "CommanUser/kuQE5s7bmwOA2NNHgBvcyoFoRKAvZCff4XQKowzsJ0";//LogoutURL = this.commonUrl + "User/LogoutSECURE";//LogoutGoldenSECURE";

  //SETONLINE_URL = this.commonUrl + "User/OnlineGoldenSECURE";
  SETONLINE_URL = this.commonUrl + "CommanUser/ozes0yHysl7M6cTHQkwG0xO4OPST7X4BrvIJbwb3e5OpfHs5pLw";//  SETONLINE_URL = this.commonUrl + "User/OnlineGoldenSECURE";


  //BalanceUrl = this.commonUrl + "User/MyBalanceSECURE";
  BalanceUrl = this.commonUrl + "CommanUser/kmwBqooYvUg1NatfGFdfnqTfJok3da6VWW6gE5prRCU";//  BalanceUrl = this.commonUrl + "User/MyBalanceSECURE";

  //BalanceTransactionUrl = this.commonUrl + "User/MyBalanceWithPLSECURE";
  BalanceTransactionUrl = this.commonUrl + "CommanUser/kmwBqooYvUg1NatfGFdfnkrIttmVfrtsLd4FcdxI1IEomCyTDDdo7M5UirJt22E";//  BalanceTransactionUrl = this.commonUrl + "User/MyBalanceWithPLSECURE";

  // __MyBalanceUserUrl = this.commonUrl + "User/MyBalanceWithCreditChipsSECURE";
  __MyBalanceUserUrl = this.commonUrl + "CommanUser/kmwBqooYvUg1NatfGFdfnkrIttmVfrtsIwukfhX82BpAS182BnL6wNmC7Xo42lJGcIZ80SqBzjXRsXyjosFRQ";//__MyBalanceUserUrl = this.commonUrl + "User/MyBalanceWithCreditChipsSECURE";

  // MatchListUrl = this.commonUrl + "Master/MasterReqSECURE";
  MatchListUrl = this.commonUrl + "CommanMaster/36f0iSITzpmMQCJL7pNWryjDDRjN5IBpZuWIKGRQQ"; //  MatchListUrl = this.commonUrl + "Master/MasterReqSECURE";

  // SubMatchListAllUrl = this.commonUrl + "Master/SubMasterAllSECURE";
  SubMatchListAllUrl = this.commonUrl + "CommanMaster/7ynrVBBeKOLBLq74Wvz5lr3bY7sRy5EIsFo2S3RUEutT9SbhtSIwoQ"; //  SubMatchListAllUrl = this.commonUrl + "Master/SubMasterAllSECURE";

  getShowHideSportsURL = this.commonUrl + "Admin/HideSportsSECURE";
  // getBlockedMatchesURL = this.commonUrl + "Master/BlockedMatchesSECURE";
  getBlockedMatchesURL = this.commonUrl + "CommanMaster/Lm6M4tZbFImMBSwBSypSa7JwKTwgiYqZ6K5fEsaoUFhDZM5geTr764hRAuiagE"; //  getBlockedMatchesURL = this.commonUrl + "Master/BlockedMatchesSECURE";

  //SportsListURL = this.commonUrl + "Master/SportsSECURE";
  SportsListURL = this.commonUrl + "CommanMaster/N0y72h8xJDLLWvatFjUlguOO5DH1RkypkqRfMVvhaoo"; //SportsListURL = this.commonUrl + "Master/SportsSECURE";

  //ButtonStackUrl = this.commonUrl + "User/StackSECURE";
  ButtonStackUrl = this.commonUrl + "CommanUser/yeKQxH4WnqNg2OvqgesThMfBy4h8Psr"; //  ButtonStackUrl = this.commonUrl + "User/StackSECURE";

  //M2MLiveAllMasterUrl = this.commonUrl + "Data/HomePLForAllSECURE";
  M2MLiveAllMasterUrl = this.commonUrl + "Radhe/L2fOTRn7Av3OU9zaeh74J9oaSVQ4iLO6FHtu9FK5Ydq0hlXNMGeQ"; //  M2MLiveAllMasterUrl = this.commonUrl + "Data/HomePLForAllSECURE";


  //MatchedHistoryUrl = this.commonUrl + "Data/MatchedLiveSECURE";
  MatchedHistoryUrl = this.commonUrl + "Data/MatchedLiveSECURE";


  //UnMatchedHistoryUrl = this.commonUrl + "Data/UnMatchedLiveNewSECURE";
  UnMatchedHistoryUrl = this.commonUrl + "Radhe/BSSKJPI5LpnqcmSEIA3h4SiKkx1eQWqU1zSBo9qRdXhkAlfINJGxkUmcfIPPliF4"; //  UnMatchedHistoryUrl = this.commonUrl + "Data/UnMatchedLiveNewSECURE";

  //M2MLiveUrl = this.commonUrl + "Data/M2MLiveSECURE";
  M2MLiveUrl = this.commonUrl + "Radhe/DmnbdrjZFRiXuVGgOAYm63ejww0WKnyWivGPDdW6JY"; //  M2MLiveUrl = this.commonUrl + "Data/M2MLiveSECURE";

  //M2MLiveUrl_POKER = this.commonUrl + "Data/M2MLiveSECURECasinoPoker";
  M2MLiveUrl_POKER = this.commonUrl + "Radhe/DmnbdrjZFRiXuVGgOAYm63ejww0WKnyWpbdS7eV68lPFx9i6obIIIlKw4oNQEgkI76ffs0H2D8"; //M2MLiveUrl_POKER = this.commonUrl + "Data/M2MLiveSECURECasinoPoker";

  //getLiveStreamsURL = this.commonUrl + "Admin/TVSECURE";
  getLiveStreamsURL = this.commonUrl + "CommanAdmin/OV1KDpOw7hZCxnkU9Wn4bhly2UE4wBxt"; //getLiveStreamsURL = this.commonUrl + "Admin/TVSECURE";

  //ProfitLossUrl = this.commonUrl + "Data/PLCommHistorySECURE";
  ProfitLossUrl = this.commonUrl + "Radhe/QIE19H7fF75JJteW1KjEcuZq6wNYvQFNR0r42pfBVwpDHvXnTL2Dg"; //  ProfitLossUrl = this.commonUrl + "Data/PLCommHistorySECURE"; 

  //MatchedPLHistoryURL = this.commonUrl + "Data/MatchedHistorySECURE";
  MatchedPLHistoryURL = this.commonUrl + "Radhe/z3ZP3nK6jMoL5CDh05MN4GfjuiX0gKcFHSHUqaBX5TXcFiBnk3rMzB5fNJBu0Y8c"; //  MatchedPLHistoryURL = this.commonUrl + "Data/MatchedHistorySECURE";

  // MatchedHistoryUrlAllTrade = this.commonUrl + "Data/AllMatchedLiveSECURE";
  MatchedHistoryUrlAllTrade = this.commonUrl + "Radhe/nuSamJ7Q90NQ6DYx3PCIoKEhQPl7J1loBS40Tdz3GjBugz4NkFL7XGwyWHtmHQ"; //  MatchedHistoryUrlAllTrade = this.commonUrl + "Data/AllMatchedLiveSECURE";


  // ChipHistoryUrl = this.commonUrl + "Data/ChipHistorySECURE";
  ChipHistoryUrl = this.commonUrl + "Radhe/zhYU8joRV9sTllv8rxGAZoYghUgkciFhJW5x1MsaUsOFVNx3f07w"; //ChipHistoryUrl = this.commonUrl + "Data/ChipHistorySECURE";

  //ClientListURL_A = this.commonUrl + "Admin/UserListParentSECURE";
  ClientListURL_A = this.commonUrl + "CommanAdmin/VxPHWO1ztzY4HGLYdUdGqcNYqFdynlKmUrrKJ97Q4LVPvk1IOD9qUD0xGGTK57"; //  ClientListURL_A = this.commonUrl + "Admin/UserListParentSECURE";

  //AdminChipDetails = this.commonUrl + "User/MyChipsListSECURE";
  AdminChipDetails = this.commonUrl + "CommanUser/WhbNglOjG4NlqX2tNzwfjtIZtUjMXnbXP0vCttXw9T8QPslNkii9tA"; //AdminChipDetails = this.commonUrl + "User/MyChipsListSECURE";

  // BalanceClientUrl = this.commonUrl + "User/MyBalanceFrontSECURE";
  BalanceClientUrl = this.commonUrl + "CommanUser/kmwBqooYvUg1NatfGFdfnsD3V3vg7oRH8EedmI8S4LUZiMovGq9L3JL9fwWe5jps"; //BalanceClientUrl = this.commonUrl + "User/MyBalanceFrontSECURE";

  //ChildChipSummaryClientURL = this.commonUrl + "Admin/ChipSummarySECURE";
  ChildChipSummaryClientURL = this.commonUrl + "CommanAdmin/zhYU8joRV9toBcSnLmWA2oYeJR5kxXvkR2uYL1czqeIp26TGrUS9aw"; //ChildChipSummaryClientURL = this.commonUrl + "Admin/ChipSummarySECURE";

  //SportsLimitClientURL = this.commonUrl + "Admin/SportsLimitsSECURE";
  SportsLimitClientURL = this.commonUrl + "CommanAdmin/N0y72h8xJDJKib5JZ4lFsF3qhFQ1tfDZZW8xXnebETEyJhiUx0sA"; //  SportsLimitClientURL = this.commonUrl + "Admin/SportsLimitsSECURE";

  //UserPositionNewURL = this.commonUrl + "Data/PositionNewSECURE";
  UserPositionNewURL = this.commonUrl + "Radhe/LmSh1wCUqJ76eeegZng51MlJyjn8uw4ADQ1XPTiW05GAnOGWsvw"; //  UserPositionNewURL = this.commonUrl + "Data/PositionNewSECURE";

  // UserPositionMatchURL = this.commonUrl + "Data/PositionMatchNewSECURE";
  UserPositionMatchURL = this.commonUrl + "Radhe/LmSh1wCUqJ76eeegZng51F6g6XoFvNB8YjMttkGiVGzJCykaKs70ranzmJIOw3o6"; //  UserPositionMatchURL = this.commonUrl + "Data/PositionMatchNewSECURE";

  //UserPositionURL = this.commonUrl + "Data/PositionSECURE";
  UserPositionURL = this.commonUrl + "Radhe/LmSh1wCUqJ76eeegZng51FGCgSCuBmjuFDLLQ8VMMQ"; //  UserPositionURL = this.commonUrl + "Data/PositionSECURE";

  //ScoreLiveURL = this.commonUrl + "Data/ScoreAllSECURE/";
  ScoreLiveURL = this.commonUrl + "Radhe/Z8lkbPD9xiCcFptbIS9xsJQiVpqsXyistCGyWIByI"; //  ScoreLiveURL = this.commonUrl + "Data/ScoreAllSECURE";

  //SaveDefaultSportsLimit = this.commonUrl + "User/SaveDefaultLimitsSECURE";
  SaveDefaultSportsLimit = this.commonUrl + "CommanUser/QbpQkNyiK6u3bZ5WRdpJLzlelg83bDjdfqyjp8pS8a5vhMJwJG9ZSkB6JmDGzUR";//SaveDefaultSportsLimit = this.commonUrl + "User/SaveDefaultLimitsSECURE";

  //SaveSportsLimitHyperURL = this.commonUrl + "Admin/SaveSportsLimitsHyperSECURE"; //==================================================
  SaveSportsLimitHyperURL = this.commonUrl + "CommanAdmin/QbpQkNyiK6t85PQInH8RCzLlzsL0bDHcuxYDDKCIR94uVVFDpkEva7wyTpm983FhEYnSL0MtM"; //SaveSportsLimitHyperURL = this.commonUrl + "Admin/SaveSportsLimitsHyperSECURE";


  // SharingPercentURL = this.commonUrl + "Admin/SharingPercentSECURE";
  SharingPercentURL = this.commonUrl + "CommanAdmin/NGigN9QVo2jVnvGmbuUIxAtBb3ylW8EDqODczJjq0XRUFF3NzF429CKwbkkHMm";//SharingPercentURL = this.commonUrl + "Admin/SharingPercentSECURE";


  //SaveButtonStackUrl = this.commonUrl + "User/SaveStackSECURE";
  SaveButtonStackUrl = this.commonUrl + "CommanUser/QbpQkNyiK6tNRBizIJzOxh7DjgxRfejsFrhsURNGMfI";//SaveButtonStackUrl = this.commonUrl + "User/SaveStackSECURE";

  //ChipHistoryByTypeUrl = this.commonUrl + "Data/ChipHistoryByTypeSECURE";
  ChipHistoryByTypeUrl = this.commonUrl + "Radhe/zhYU8joRV9sTllv8rxGAZPDuWDGa30ZZuX3PL2UJ6DOp2MIjiNGgxpsX4RQ4W";// ChipHistoryByTypeUrl = this.commonUrl + "Data/ChipHistoryByTypeSECURE";

  //getOnlyClientsURL = this.commonUrl + "Admin/ClientOnlySECURE";
  getOnlyClientsURL = this.commonUrl + "CommanAdmin/sT3PT6Y4f1gUEZTsRTQpvlPWFRVSUFAm3nJ4o8Ga4Nfyq86MFuR6A";//getOnlyClientsURL = this.commonUrl + "Admin/ClientOnlySECURE";

  //SubMatchListUrl = this.commonUrl + "Master/SubMasterSECURE";
  SubMatchListUrl = this.commonUrl + "CommanMaster/7ynrVBBeKOLBLq74Wvz5ll9dLgn9X6wvYAG7yHgAkMU";//SubMatchListUrl = this.commonUrl + "Master/SubMasterSECURE";

  MatchStackRange = this.commonUrl + "Admin/StackRangeSECURE";
  // MatchStackRangeAllInOne_URL = this.commonUrl + "Admin/StackRangeByEventIDSECURE";
  MatchStackRangeAllInOne_URL = this.commonUrl + "CommanAdmin/yeKQxH4WnqNQZi5ZuINFLHxGeHZ30kuZLlsrHxrjMZ3WlUPs53O9Fnk9hpflqvXuUAs5SsgXY";//MatchStackRangeAllInOne_URL = this.commonUrl + "Admin/StackRangeByEventIDSECURE";

  //MatchedPLResultURL = this.commonUrl + "Data/LGResultDetailSECURE";
  MatchedPLResultURL = this.commonUrl + "Radhe/8whP6VN8yjeJPPod7FnxN3iT6xwyvMxoBSXpsA1QxMfVZzM2Lwd2FpKZGLlt8KEA";//MatchedPLResultURL = this.commonUrl + "Data/LGResultDetailSECURE";

  //CasinoAllInOnePLResultURL = this.commonUrl + "Data/CasinoGameResultSECURE";
  CasinoAllInOnePLResultURL = this.commonUrl + "Radhe/UL965sPqDdzvSc4Y7gmQC47945DZ8DuRkWJy4CQQ8dsG5KCzsbqAsZjt0pSNH";//CasinoAllInOnePLResultURL = this.commonUrl + "Data/CasinoGameResultSECURE";


  //__MASTERREQUEST_URL = this.commonUrl + "Master/MasterReqAllSECURE";
  __MASTERREQUEST_URL = this.commonUrl + "CommanMaster/36f0iSITzpmMQCJL7pNWr2R37r7OPeDyJ4o1RoF4oK8lC68PPzvyA";// __MASTERREQUEST_URL = this.commonUrl + "Master/MasterReqAllSECURE";

  //__MasterRequestParticular_URL = this.commonUrl + "Master/MasterReqOneSECURE";
  __MasterRequestParticular_URL = this.commonUrl + "CommanMaster/iSITzpmMQCJL7pNWrzFYYCWhUYWHFJAQgrnQxszrvYhQu6CQ";//__MasterRequestParticular_URL = this.commonUrl + "Master/MasterReqOneSECURE";

  //__ProfitLossRangeUrl = this.commonUrl + "Data/PLCommRangeHistorySECURE";
  __ProfitLossRangeUrl = this.commonUrl + "Rahde/QIE19H7fF76fsupDEDkwEsrDGT8mzYl33iwqvyhA75E5pkpzeA4chMqklVPpBFbpzTdqOu94";//__ProfitLossRangeUrl = this.commonUrl + "Data/PLCommRangeHistorySECURE";

  ChangePasswordURL = this.commonUrl + 'User/ChangeIdentityByAdminSECURE';
  ChangePasswordSelfURL = this.commonUrl + 'CommanUser/G8PgX6hlcnJSdOuav9pYMVRgvBjWW5v3adeWy8gnhLpnQ32Od3hOfqD8muPyuBWV';//ChangePasswordSelfURL = this.commonUrl + 'User/ChangeIdentitySECURE';
  //ChangePasswordSelfURL = this.commonUrl + 'User/ChangeIdentitySECURE';
  //__ChipHistoryRangeUrl = this.commonUrl + "Data/ChipHistoryRangeNewSECURE";//ChipHistoryRangeSECURE";
  __ChipHistoryRangeUrl = this.commonUrl + "Rahde/zhYU8joRV9sTllv8rxGAW5Eoy2ARaD6gPZmbe6OcUqtaD9HrPgojnXm3Fs3jc";// __ChipHistoryRangeUrl = this.commonUrl + "Data/ChipHistoryRangeSECURE";

  //__MatchedUnMatchedLive = this.commonUrl + "Data/MatchedUnMatchedLiveSECURE";
  __MatchedUnMatchedLive = this.commonUrl + "Radhe/z3ZP3nK6jMqKAJtE7ZHrV7JSZe6vKAmtI21NV97mW472vrpUbH25q1R6rg7R75kcebPPw81atc";//__MatchedUnMatchedLive = this.commonUrl + "Data/MatchedUnMatchedLiveSECURE";
  BalanceAdminUrl = this.commonUrl + "CommanUser/kmwBqooYvUg1NatfGFdfnu0z27LFDOmch5UvAYK8WnfL5Cvovo07MiBqLG0opC";//BalanceAdminUrl = this.commonUrl + "User/MyBalanceForTopSECURE";


  //BalanceAdminUrl = this.commonUrl + "User/MyBalanceForTopSECURE";

  // saveShowHideSportsURL = this.commonUrl + "Master/ShowHideSportSECURE";
  // ProfitToChipsURL = this.commonUrl + 'user/ProfitToOwnChipsSECURE';
  // AccountLedgerURL = this.commonUrl + 'Data/AccountLedgerSECURE';
  // SaveSportsLimitClientURL = this.commonUrl + "Admin/SaveSportsLimitsSECURE";
  // ProfitLossDateWiseUrl = this.commonUrl + "Data/PLCommHistoryBySportSECURE";
  // MatchedPLHistoryNewURL = this.commonUrl + "Data/MatchedHistoryByFormatSECURE";
  saveShowHideSportsURL = this.commonUrl + "CommanMaster/PyryyKBgq6OsCFbCEtn3WehsfNSaJen7EfFQTdIVo0zvyFtDGIQg";//saveShowHideSportsURL = this.commonUrl + "Master/ShowHideSportSECURE";
  //ProfitToChipsURL = this.commonUrl + 'user/ProfitToOwnChipsSECURE';
  ProfitToChipsURL = this.commonUrl + 'CommanUser/vmQTptFnxGndJgfC6U49eCKKgdfMRtmaqhPnsgqGWPZXQBeGJvcGvVVV2SkNH';//ProfitToChipsURL = this.commonUrl + 'user/ProfitToOwnChipsSECURE';


  //AccountLedgerURL = this.commonUrl + 'Data/AccountLedgerSECURE';
  AccountLedgerURL = this.commonUrl + 'Data/FBpAd8Mx13b7JnmYTqepcRYfzya3NgRCGkeqwhBqRNWjVDLktA';// AccountLedgerURL = this.commonUrl + 'Data/AccountLedgerSECURE';

  //SaveSportsLimitClientURL = this.commonUrl + "Admin/SaveSportsLimitsSECURE";
  SaveSportsLimitClientURL = this.commonUrl + "CommanAdmin/QbpQkNyiK6t85PQInH8RCzLlzsL0bDHcuxYDDKCIRTqC7y11oNLV9ere4KFAfx";//SaveSportsLimitClientURL = this.commonUrl + "Admin/SaveSportsLimitsSECURE";

  //ProfitLossDateWiseUrl = this.commonUrl + "Data/PLCommHistoryBySportSECURE";
  ProfitLossDateWiseUrl = this.commonUrl + "Data/QIE19H7fF75JJteW1KjEcuZq6wNYvQFNJY35QHv44UJBO9lqcnEQZNafCADvoTPrBNyDDKGS4";//ProfitLossDateWiseUrl = this.commonUrl + "Data/PLCommHistoryBySportSECURE";

  MatchedPLHistoryNewURL = this.commonUrl + "Data/MatchedHistoryByFormatSECURE";
  // MatchedPLHistoryNewURL = this.commonUrl + "Data/z3ZP3nK6jMoL5CDh05MN4GfjuiX0gKcFwqQYHD0YeAxio9oRY1o9CXOdOaRX5xirMKrW3AxSNxLSL8Rr6BFnFg";//MatchedPLHistoryNewURL = this.commonUrl + "Data/MatchedHistoryByFormatSECURE";


  //AddTransactionURL = this.commonUrl + 'user/AddTransactionSECURE';
  AddTransactionURL = this.commonUrl + 'CommanUser/uWk4ifiZVYLjydzNwvKqIH1S8UkCGKwGE0gVZpBXI4khBiMvY5QNV6kHWVI6nG';//AddTransactionURL = this.commonUrl + 'user/AddTransactionSECURE';

  // LogoutURL = this.commonUrl + "User/LogoutSECURE";
  // GetWithdrawBalanceURL = this.commonUrl + 'User/WithdrawBalanceSECURE';
  // GetUserDetailsURL = this.commonUrl + 'User/UserDetailSECURE';
  // UpdateUserDetailsURL = this.commonUrl + 'user/UpdateUserSECURE';
  // saveLiveStreamsURL = this.commonUrl + "Admin/SaveAddTvSECURE";
  // setRollBackURL = this.commonUrl + "Data/CasinoRollBackSECURE";
  // MatchWiseLimitsURL = this.commonUrl + 'Admin/MatchWiseLimitsSECURE';
  // saveMatchWiseLimitsURL = this.commonUrl + 'Admin/SaveMatchWiseLimitsSECURE';
  // saveShowHideMatchesURL = this.commonUrl + "Master/ShowHideMatchSECURE";
  // getMatchResultsURL = this.commonUrl + "Master/MasterHistoryReqSECURE";
  // ClientListURL_ALL = this.commonUrl + "Admin/UserListSECURE";
  // getCasinoTotalPL = this.commonUrl + "Data/CasinoPLSECURE";
  // LiveResultURL = this.commonUrl + 'Data/LiveGameLastResultsSECURE';
  // MatchListParticularUrl = this.commonUrl + "Master/GetMDetailSECURE";
  // BaccaratPLResultURL = this.commonUrl + "Data/LGResultDetailForBaccaratSECURE";
  // SixPokerPLResultURL = this.commonUrl + "Data/LGResultDetailForSixPokerSECURE";
  // BetOnPokerPLResultURL = this.commonUrl + "Data/LGResultDetailForBetOnPokerSECURE";

  __UPLOAD_FILE_BANK_URL = this.commonUrl + "fox/UploadFilesBankCOM";
  __GET_FILE_BANK_URL = this.commonUrl + "fox/getBankImage";

  //GetWithdrawBalanceURL = this.commonUrl + 'User/WithdrawBalanceSECURE';
  GetWithdrawBalanceURL = this.commonUrl + 'CommanUser/kXETTTXGG7JwNfhWpmBd5Y3rpyPwAwm7pAVmq5KmitrvZJ2X78hCb26TIgAct2Z4';//GetWithdrawBalanceURL = this.commonUrl + 'User/WithdrawBalanceSECURE';


  //GetUserDetailsURL = this.commonUrl + 'User/UserDetailSECURE';
  GetUserDetailsURL = this.commonUrl + 'CommanUser/xPHWO1ztwqPMqItWmZyn12VeOEAtOBakYvS9U879Hdej0Y2HZA';//GetUserDetailsURL = this.commonUrl + 'User/UserDetailSECURE';

  //UpdateUserDetailsURL = this.commonUrl + 'user/UpdateUserSECURE';
  UpdateUserDetailsURL = this.commonUrl + 'CommanUser/GVAEyj4yJINe4cVniEp5uoOPlLXK9ApJ36fHUBOL8OsgCfj0yFvlQ';//UpdateUserDetailsURL = this.commonUrl + 'user/UpdateUserSECURE';

  //saveLiveStreamsURL = this.commonUrl + "Admin/SaveAddTvSECURE";
  saveLiveStreamsURL = this.commonUrl + "CommanAdmin/QbpQkNyiK6tbcWSlM1elwbfhDDeV2jUlSxujDpV4Ek";//saveLiveStreamsURL = this.commonUrl + "Admin/SaveAddTvSECURE";

  //setRollBackURL = this.commonUrl + "Data/CasinoRollBackSECURE";
  setRollBackURL = this.commonUrl + "Data/UL965sPqDc2l2Kdgvb2QwMrJjPEK9Ajv8lNK2XcDBgJrHqWAnGttVFOBeSnprut";//setRollBackURL = this.commonUrl + "Data/CasinoRollBackSECURE";

  //MatchWiseLimitsURL = this.commonUrl + 'Admin/MatchWiseLimitsSECURE';
  MatchWiseLimitsURL = this.commonUrl + 'CommanAdmin/z3ZP3nK6jMphA6bptLuWl8WzZR2jbKV7GUi6aJ9bK73ezvQ5D5pFI8aIh0LCHC6h';//MatchWiseLimitsURL = this.commonUrl + 'Admin/MatchWiseLimitsSECURE';


  //saveMatchWiseLimitsURL = this.commonUrl + 'Admin/SaveMatchWiseLimitsSECURE';
  saveMatchWiseLimitsURL = this.commonUrl + 'CommanAdmin/SaveMatchWiseLimitsSECURE';

  //saveShowHideMatchesURL = this.commonUrl + "Master/ShowHideMatchSECURE";
  saveShowHideMatchesURL = this.commonUrl + "CommanMaster/PyryyKBgq6OsCFbCEtn9MndvntLjkFX900m0HSoB1SZt9ZAvyQ";//saveShowHideMatchesURL = this.commonUrl + "Master/ShowHideMatchSECURE";

  //getMatchResultsURL = this.commonUrl + "Master/MasterHistoryReqSECURE";
  getMatchResultsURL = this.commonUrl + "CommanMaster/36f0iSITzpngJQ9DCAwHVFcXSGJizmNqza0NF5ctNdqohUfORv6gTlg4FuD0v1lb";//getMatchResultsURL = this.commonUrl + "Master/MasterHistoryReqSECURE";

  //ClientListURL_ALL = this.commonUrl + "Admin/UserListSECURE";
  ClientListURL_ALL = this.commonUrl + "CommanAdmin/VxPHWO1ztzY4HGLYdUdGvnzhcgcsvPL7Mk3juUsUdo";//ClientListURL_ALL = this.commonUrl + "Admin/UserListSECURE";

  //getCasinoTotalPL = this.commonUrl + "Data/CasinoPLSECURE";
  getCasinoTotalPL = this.commonUrl + "Radhe/UL965sPqDfvp5fZzXL90V4PGeMVK5I62PmzXJeYpOE";//getCasinoTotalPL = this.commonUrl + "Data/CasinoPLSECURE";

  //LiveResultURL = this.commonUrl + 'Data/LiveGameLastResultsSECURE';
  LiveResultURL = this.commonUrl + 'Radhe/zqJf6R1BkpOTmM6H9eZ14n1SDpzodZ8az1v0oEuENyB7HJMGiBHZRKABTHVDHm1Z5ajIcC0'; // LiveResultURL = this.commonUrl + 'Data/LiveGameLastResultsSECURE';

  //MatchListParticularUrl = this.commonUrl + "Master/GetMDetailSECURE";
  MatchListParticularUrl = this.commonUrl + "CommanMaster/Bj7YmSUap1fr9VL4XcLDLGlQAIjjX3iET7fJeUVAdq6op9KOXG3Wg";//MatchListParticularUrl = this.commonUrl + "Master/GetMDetailSECURE";


  //BaccaratPLResultURL = this.commonUrl + "Data/LGResultDetailForBaccaratSECURE";
  BaccaratPLResultURL = this.commonUrl + "Radhe/8whP6VN8yjeJPPod7FnxN3iT6xwyvMxotBKHaQOsvBGJPNDMJtRTXcBJhFETlbntMu0SDlSa51tsCQYmOzrg";//BaccaratPLResultURL = this.commonUrl + "Data/LGResultDetailForBaccaratSECURE";

  //SixPokerPLResultURL = this.commonUrl + "Data/LGResultDetailForSixPokerSECURE";
  SixPokerPLResultURL = this.commonUrl + "Radhe/8whP6VN8yjeJPPod7FnxN3iT6xwyvMxotBKHaQOsvBGaHBO0hfF3s2DApL2VlqpgUSN6ZcjBtYMOvywq2jJ9g";//SixPokerPLResultURL = this.commonUrl + "Data/LGResultDetailForSixPokerSECURE";


  //BetOnPokerPLResultURL = this.commonUrl + "Data/LGResultDetailForBetOnPokerSECURE";
  BetOnPokerPLResultURL = this.commonUrl + "Radhe/8whP6VN8yjeJPPod7FnxN3iT6xwyvMxotBKHaQOsvBEKA89hSZ28PukkCmGGpVCFp0KdIs8NRhsRr0VBJA/U9wZt3AAh2";//BetOnPokerPLResultURL = this.commonUrl + "Data/LGResultDetailForBetOnPokerSECURE";

  // WarOfBetsPLResultURL = this.commonUrl + "Data/LGResultDetailForWarOfBetsSECURE";
  // SpeedySevenPLResultURL = this.commonUrl + "Data/LGResultDetailForSpeedySevenSECURE";
  // M2MLiveTeenPattiUrl = this.commonUrl + "Data/M2MLiveTeenPattiSECURE";
  // UserPositionTeenPattiURL = this.commonUrl + "Data/PositionTeenPattiSECURE";
  // setEzugiCasinoURL = this.commonUrl + "User/AddEzugiTokenSECURE";
  // setLotusTokenURL = this.commonUrl + "User/AddLotusTokenNewSECURE";
  // setJackpotTonet = this.commonUrl + "User/AddJackPotTokenSECURE";
  // setFunCasinoURL = this.commonUrl + "User/AddFunTokenSECURE";
  // allowBlockMatchURL = this.commonUrl + "Master/AllowBlockMatchSECURE";
  // getAdminMessageURL = this.commonUrl + "Data/MarketMessageSECURE";
  // __GAMEDETAILS_URL = this.__DIAMOND_CASINO_URL + "Data/DiamondDataSECURE";
  // __GAMERESULTS_URL = this.__DIAMOND_CASINO_URL + "Data/DiamondResultSECURE";
  // __GAME10RESULTS_URL = this.commonUrl + 'Data/CasinoGameLastResultsSECURE'; //?TYP=1 GET   
  // __GETALLTV_URL = this.commonUrl + "Data/AllTvSECURE";
  // __ALLINONEM2M_URL = this.commonUrl + "Data/AllM22INGOSECURE"; // AUT, EID, UID
  // MatchedHistoryLimitUrl = this.commonUrl + "Data/MatchedLiveLimitNewSECURE";
  // __M2MFANCY_URL = this.commonUrl + "Data/M2MLiveWithPSECURE";
  // ChildPLReportURL = this.commonUrl + "Data/ChildProfitLossReportSECURE";
  // MarketReportURL = this.commonUrl + "Data/MarketReportSECURE";
  // ClientPlReportURL = this.commonUrl + "Data/ClientPLReportSECURE";
  // SportsPlReportURL = this.commonUrl + "Data/UserProfitLossBySportReportSECURE"; // AUT, UID, SD, ED, PS, PN
  // // AccountStatementReportURL = this.commonUrl + "Data/UserAccountStatementSECURE";
  // AccountStatementReportURL = this.commonUrl + "Data/UserAccountStatementSECURENew";
  // CasinoAccountStatementReportURL = this.commonUrl + "Data/EzugiTradeReports";
  // CasinoAccountStatementReportURLOld = this.commonUrl + "Data/EzugiTradeReportsOld";
  // ACReportMatchedHistoryURL = this.commonUrl + "Data/MatchedHistorySECURE";
  // ACDetailsURL = this.commonUrl + "Data/UserProfitLossByEventIdSECURE";
  // ClientProfitLossReportUrl = this.commonUrl + "Data/PLCommRangeHistoryWithCountSECURE";
  // ChipSummaryReportURL = this.commonUrl + "User/ChipSummaryReportNewSECURE";
  // AccountInfoReportURL = this.commonUrl + "User/MyAccInfoReportSECURE"; // AUT, UID
  // BetHistoryReportURL = this.commonUrl + "Data/BetHistorySECURE"; // AUT, SID, UID, SD, ED, PS, PN
  // BetHistoryCasinoReportURL = this.commonUrl + "Data/BetHistoryCasinoSECURE"; // AUT, SID, UID, SD, ED, PS, PN
  // SettlementHistoryReportURL = this.commonUrl + "Data/UserSettlingHistorySECURE"; // AUT, UID, SD, ED, PS, PN
  // __ALLTRADEBOOK_URL = this.commonUrl + "Data/AllMatchedLiveRangeSECURE";


  // // __CLIENTLIST_URL = this.commonUrl + "Data/UserListByReportSECURE"; // UID, TYPE, PS, PN
  // __CLIENTLIST_URL = this.commonUrl + "Data/UserListByReportHotSportSECURE"; // UID, TYPE, PS, PN
  // __ADD_USER_URL = this.commonUrl + "User/AddUserReportSECURE"; // AUT, CSPL, CCPL, PID, TYP, FNAME, PWD, UNAME, WI, DT
  // __GetPartnership_URL = this.commonUrl + "Data/UserPLShareSECURE"; // AUT, UID
  // __PARTNERSHIP_URL = this.commonUrl + "User/GetPartnerPerData"; // AUT, UID
  // __SAVESPORTSLIMITSALL_URL = this.commonUrl + "Admin/SaveAllSportsLimitsSECURE";
  // __MATCH_RESULTS_URL = this.commonUrl + "Master/ResultReportSECURE";
  // __PL_MATCHEDHISTIRY_URL = this.commonUrl + "Data/MatchedHistoryByRangeReportSECURE";
  // __SAVE_SPORTSCOMMISSION_URL = this.commonUrl + "Data/SaveCommonShareSECURE";
  // __CHANGE_SPORTSHARING_URL = this.commonUrl + "User/SaveUserPercentageSECURE";

  //WarOfBetsPLResultURL = this.commonUrl + "Data/LGResultDetailForWarOfBetsSECURE";
  WarOfBetsPLResultURL = this.commonUrl + "Radhe/8whP6VN8yjeJPPod7FnxN3iT6xwyvMxotBKHaQOsvBGLN0qhpnvleNyFJ8TYWQiWtvaGsFDzsS8EH00YaAeSmEqLLTrZYW"; //WarOfBetsPLResultURL = this.commonUrl + "Data/LGResultDetailForWarOfBetsSECURE";

  //SpeedySevenPLResultURL = this.commonUrl + "Data/LGResultDetailForSpeedySevenSECURE";
  SpeedySevenPLResultURL = this.commonUrl + "Radhe/8whP6VN8yjeJPPod7FnxN3iT6xwyvMxotBKHaQOsvBFzegIrypdqfrzfFrAVopjigpbahpszds2X1Sk8grrnji7ZfEt2x6C";//SpeedySevenPLResultURL = this.commonUrl + "Data/LGResultDetailForSpeedySevenSECURE";

  //M2MLiveTeenPattiUrl = this.commonUrl + "Data/M2MLiveTeenPattiSECURE";
  M2MLiveTeenPattiUrl = this.commonUrl + "Radhe/DmnbdrjZFRjJdSp2N13YooBG26nWV4EPp24mupKtk3WFnPafwJDkVdH0FL5GYN1";//M2MLiveTeenPattiUrl = this.commonUrl + "Data/M2MLiveTeenPattiSECURE";

  //UserPositionTeenPattiURL = this.commonUrl + "Data/PositionTeenPattiSECURE";
  UserPositionTeenPattiURL = this.commonUrl + "Radhe/LmSh1wCUqJ76eeegZng51Hl9u99AlLkxIFIuODae0tgcTHhxhaH7KgYJEDDNyu";// UserPositionTeenPattiURL = this.commonUrl + "Data/PositionTeenPattiSECURE";

  //setEzugiCasinoURL = this.commonUrl + "User/AddEzugiTokenSECURE";
  setEzugiCasinoURL = this.commonUrl + "CommanUser/3NeP2Fn5AQEMzLwdN4dWbgkBCoTHUho1OrJ6Nq2zJfrXY8licg"; //setEzugiCasinoURL = this.commonUrl + "User/AddEzugiTokenSECURE";

  //setLotusTokenURL = this.commonUrl + "User/AddLotusTokenNewSECURE";
  setLotusTokenURL = this.commonUrl + "CommanUser/OgAwYCoNVTvU5ihOrDf6l8QSIXoa9FNLig1KCkzRUCeIwHUCJKp0bPOV89y"; //setLotusTokenURL = this.commonUrl + "User/AddLotusTokenNewSECURE";

  //setJackpotTonet = this.commonUrl + "User/AddJackPotTokenSECURE";
  setJackpotTonet = this.commonUrl + "CommanUser/pWpsas14s0mAb9ngRjBQQSoFlALyx21e1gNbwD3XcgPL2z8KfHNA4FGRZ0hVeBl"; //  setJackpotTonet = this.commonUrl + "User/AddJackPotTokenSECURE";

  //setFunCasinoURL = this.commonUrl + "User/AddFunTokenSECURE";
  setFunCasinoURL = this.commonUrl + "CommanUser/JTH4B2FxfGm0W8Ev2oQAeVugXm3aS0cypnewMRrTytukNIzctvRA"; //  setFunCasinoURL = this.commonUrl + "User/AddFunTokenSECURE";

  //allowBlockMatchURL = this.commonUrl + "Master/AllowBlockMatchSECURE";
  allowBlockMatchURL = this.commonUrl + "CommanMaster/btwmNj3xwraUGtMzeJ47x6sFYx9paPyruDmJBuJAwyGrVpLpCrBmwaiGbBOOS"; //  allowBlockMatchURL = this.commonUrl + "Master/AllowBlockMatchSECURE";

  //getAdminMessageURL = this.commonUrl + "Data/MarketMessageSECURE";
  getAdminMessageURL = this.commonUrl + "Radhe/qVd9gKhkRLYsZJxibvOXHLqLeNX1o6rydwQWFe0vWkmcD6IkspFUxg"; //  getAdminMessageURL = this.commonUrl + "Data/MarketMessageSECURE";

  //__GAMEDETAILS_URL = this.__DIAMOND_CASINO_URL + "Data/DiamondDataSECURE";
  __GAMEDETAILS_URL = this.__DIAMOND_CASINO_URL + "Radhe/d00XinCR73zYN5qlsLQeRH8gzahCaRYIHFWthJuOaqPYJs6Nt1A"; //  __GAMEDETAILS_URL = this.__DIAMOND_CASINO_URL + "Data/DiamondDataSECURE"; //NUS

  // __GAMERESULTS_URL = this.__DIAMOND_CASINO_URL + "Data/DiamondResultSECURE";
  __GAMERESULTS_URL = this.__DIAMOND_CASINO_URL + "Radhe/d00XinCR73wckiPipWt0jKHyCLXvUvehyYy7mWOlQOTGKhUctg"; //  __GAMERESULTS_URL = this.__DIAMOND_CASINO_URL + "Data/DiamondResultSECURE";

  //__GAME10RESULTS_URL = this.commonUrl + 'Data/CasinoGameLastResultsSECURE'; //?TYP=1 GET   
  __GAME10RESULTS_URL = this.commonUrl + 'Radhe/UL965sPqDdzvSc4Y7gmQOS8LjOBRPxaLHCs8JqiFMlkYTVyjI7R0yDWPXLWtRDh7oQeCDOBww'; //__GAME10RESULTS_URL = this.commonUrl + 'Data/CasinoGameLastResultsSECURE'; //?TYP=1 GET 

  //__GETALLTV_URL = this.commonUrl + "Data/AllTvSECURE";
  __GETALLTV_URL = this.commonUrl + "Radhe/cYJCcraeTgQPr0j1aphWk1MDifiF2rV"; //  __GETALLTV_URL = this.commonUrl + "Data/AllTvSECURE";

  //__ALLINONEM2M_URL = this.commonUrl + "Data/AllM22INGOSECURE"; // AUT, EID, UID
  __ALLINONEM2M_URL = this.commonUrl + "Radhe/nuSamJ7Q90PZe8HInFRLPFyw3lVH1cx6g4doYmSiekioQCeVqlkw"; //  __ALLINONEM2M_URL = this.commonUrl + "Data/AllM22INGOSECURE";// AUT, EID, UID

  //MatchedHistoryLimitUrl = this.commonUrl + "Data/MatchedLiveLimitNewSECURE";
  MatchedHistoryLimitUrl = this.commonUrl + "Radhe/z3ZP3nK6jMqnvBSaatVYfM0CW7WjJGoMD0JWyjheEbr4sbB7DsujAvLLdQAY07ndtqJcWrwm4M"; //  MatchedHistoryLimitUrl = this.commonUrl + "Data/MatchedLiveLimitNewSECURE";

  //_M2MFANCY_URL = this.commonUrl + "Data/M2MLiveWithPSECURE";
  ChildPLReportURL = this.commonUrl + "Radhe/DmnbdrjZFRj2CWE3gdBv58g9IODoJreI5SD7pWeifNelmrUVXaWw"; //  __M2MFANCY_URL = this.commonUrl + "Data/M2MLiveWithPSECURE";

  //ChildPLReportURL = this.commonUrl + "Data/ChildProfitLossReportSECURE";
  __M2MFANCY_URL = this.commonUrl + "Radhe/hF4luGAVUMrfMorFmu7WpSJR97o4vWeRv224591GcNKlHiSdl5GD2TY5URftqTkKJex9cgA"; //  ChildPLReportURL = this.commonUrl + "Data/ChildProfitLossReportSECURE";

  //MarketReportURL = this.commonUrl + "Data/MarketReportSECURE";
  MarketReportURL = this.commonUrl + "Radhe/qVd9gKhkRLbWxOpH8dCjXLfreo1StuP5YWeRydZZ5LRdpPGdnKuZw"; //  MarketReportURL = this.commonUrl + "Data/MarketReportSECURE";

  //ClientPlReportURL = this.commonUrl + "Data/ClientPLReportSECURE";
  ClientPlReportURL = this.commonUrl + "Radhe/sT3PT6Y4f1jldburQD6BtRwgbPpzjW23uXQAkuCtbvuzw723FzwOEzl6mT5z"; //  ClientPlReportURL = this.commonUrl + "Data/ClientPLReportSECURE";

  //SportsPlReportURL = this.commonUrl + "Data/UserProfitLossBySportReportSECURE"; // AUT, UID, SD, ED, PS, PN
  SportsPlReportURL = this.commonUrl + "Radhe/VxPHWO1ztwqYxKTCcXH3x1DHk8MgcmGX7VFDPWnzprrIFNacoH7MhgfFN8esk9ctoM20pufsfEUOPWBNBv7Snau7cZtzTO3"; //SportsPlReportURL = this.commonUrl + "Data/UserProfitLossBySportReportSECURE";// AUT, UID, SD, ED, PS, PN

  //AccountStatementReportURL = this.commonUrl + "Data/UserAccountStatementSECURENew";//UserAccountStatementSECURE";
  AccountStatementReportURL = this.commonUrl + "Radhe/VxPHWO1ztxYndTOwU1mUmxqpfbwSFLHROF5kvLxUkiLaJGj08Mcl8mFuCAt3KigNsgQgnJEPnjv0Gz3Yhg";//AccountStatementReportURL = this.commonUrl + "Data/UserAccountStatementSECURENew";//UserAccountStatementSECURE";

  AccountStatementReportURL4 = this.commonUrl + "Data/UserAccountStatementSECUREFour";
  AccountStatementReportURL0 = this.commonUrl + "Data/UserAccountStatementSECUREOne";
  AccountStatementReportURL1 = this.commonUrl + "Data/UserAccountStatementSECURETwo";
  AccountStatementReportURL2 = this.commonUrl + "Data/UserAccountStatementSECUREThree";
  AccountStatementReportURL3 = this.commonUrl + "Data/UserAccountStatementSECUREFour";
  //Giga/VIVOTradeReports
  VIVOAccountStatementReportURL = this.commonUrl + "Giga/yWb7ryeixnuJjMhgvRg8w6mvC11cx4DdQaOnbD94YmF1ZiFF64Q"; //VIVOAccountStatementReportURL = this.commonUrl + "Data/VIVOTradeReports";

  //CasinoAccountStatementReportURL = this.commonUrl + "Data/EzugiTradeReportsSkyNew99";
  CasinoAccountStatementReportURL = this.commonUrl + "fox/abwcCVkDHWosM1mVhqt3zsQo2ORTvC92MJMtqq7cWlqOtGxhiPSm7ow4t2BfXzKEHT48esoE"; //  CasinoAccountStatementReportURL = this.commonUrl + "Data/EzugiTradeReportsSkyNew99";

  CasinoAccountStatementReportURLOld = this.commonUrl + "fox/abwcCVkDHWosM1mVhqt3zsQo2ORTvC92MJMtqq7cWlqOtGxhiPSm7ow4t2BfXzKEHT48esoEd";


  //ACReportMatchedHistoryURL = this.commonUrl + "Data/MatchedHistorySECURE";
  ACReportMatchedHistoryURL = this.commonUrl + "Radhe/z3ZP3nK6jMoL5CDh05MN4GfjuiX0gKcFHSHUqaBX5TXcFiBnk3rMzB5fNJBu0Y8c"; //  ACReportMatchedHistoryURL = this.commonUrl + "Data/MatchedHistorySECURE";

  //ACDetailsURL = this.commonUrl + "Data/UserProfitLossByEventIdSECURE";
  ACDetailsURL = this.commonUrl + "Radhe/VxPHWO1ztwqYxKTCcXH3x1DHk8MgcmGX7VFDPWnzpoOC62kESNTxYOkuXLKzH4tTgT7RcJMy5F38sf33YJdA"; //  ACDetailsURL = this.commonUrl + "Data/UserProfitLossByEventIdSECURE";

  //ClientProfitLossReportUrl = this.commonUrl + "Data/PLCommRangeHistoryWithCountSECURE";
  ClientProfitLossReportUrl = this.commonUrl + "Radhe/QIE19H7fF76fsupDEDkwEsrDGT8mzYl33iwqvyhCgV6yAveAZ3bkUq1Tln0F57s38WqJuR8N0EzVFTP5SPWVBoD2SVmW"; //  ClientProfitLossReportUrl = this.commonUrl + "Data/PLCommRangeHistoryWithCountSECURE";

  //ChipSummaryReportURL = this.commonUrl + "User/ChipSummaryReportNewSECURE";
  ChipSummaryReportURL = this.commonUrl + "CommanUser/zhYU8joRV9toBcSnLmWA2lmTmxBRrpxJOeX56UcCCkYqwmcVzbbolGBnbN26OvhGSoBWEJYAkSo"; //  ChipSummaryReportURL = this.commonUrl + "User/ChipSummaryReportNewSECURE";

  //AccountInfoReportURL = this.commonUrl + "User/MyAccInfoReportSECURE"; // AUT, UID
  AccountInfoReportURL = this.commonUrl + "CommanUser/yt2vkzh0SFoMrhSGC8fNA06cWBDvYz5QuuBi4D9dVCnPGdNKaOEGQQ5uaAjGiF"; //AccountInfoReportURL = this.commonUrl + "User/MyAccInfoReportSECURE";// AUT, UID

  //BetHistoryReportURL = this.commonUrl + "Data/BetHistorySECURE"; // AUT, SID, UID, SD, ED, PS, PN
  BetHistoryReportURL = this.commonUrl + "Radhe/LP7BInAKZJ1TLLlldyaRhfOn9M5HJSyAFbLumdISoiCC70QXeQzQ"; //BetHistoryReportURL = this.commonUrl + "Data/BetHistorySECURE";// AUT, SID, UID, SD, ED, PS, PN

  //BetHistoryCasinoReportURL = this.commonUrl + "Data/BetHistoryCasinoSECURE"; // AUT, SID, UID, SD, ED, PS, PN
  BetHistoryCasinoReportURL = this.commonUrl + "Radhe/LP7BInAKZJ1TLLlldyaRg6x4NGM1VAonQH2KKecowgKLHVw1u4UBNomPo5BdVf"; //  BetHistoryCasinoReportURL = this.commonUrl + "Data/BetHistoryCasinoSECURE";// AUT, SID, UID, SD, ED, PS, PN

  //SettlementHistoryReportURL = this.commonUrl + "Data/UserSettlingHistorySECURE"; // AUT, UID, SD, ED, PS, PN
  SettlementHistoryReportURL = this.commonUrl + "Radhe/VxPHWO1ztwaLIEK6t80ClxRioiNkekYfRSMwtxK9l3x4PioaL2z9NLXXd4CquKdAKilmXg"; //  SettlementHistoryReportURL = this.commonUrl + "Data/UserSettlingHistorySECURE";// AUT, UID, SD, ED, PS, PN

  //__ALLTRADEBOOK_URL = this.commonUrl + "Data/AllMatchedLiveRangeSECURE";
  __ALLTRADEBOOK_URL = this.commonUrl + "Radhe/nuSamJ7Q90NQ6DYx3PCIoKEhQPl7J1lo8Xzt3qw09lB2o6L0X3KGzgHkl0HkUBxETZij5KaISIU"; //  __ALLTRADEBOOK_URL = this.commonUrl + "Data/AllMatchedLiveRangeSECURE";

  //__CLIENTLIST_URL = this.commonUrl + "Data/UserListByReportSECURE"; //"Data/UserListByReportHotSportSECURE"; //"Data/UserListByReportSECURE"; // UID, TYPE, PS, PN
  __CLIENTLIST_URL = this.commonUrl + "fox/VxPHWO1ztzY4HGLYdUdGlAgETdZ7uWKTDHBVvAAZcwKDvKoLOtC5XmuVLn"; //  __CLIENTLIST_URL = this.commonUrl + "Data/UserListByReportSECURE"; //"Data/UserListByReportHotSportSECURE"; //"Data/UserListByReportSECURE"; // UID, TYPE, PS, PN


  //__ADD_USER_URL = this.commonUrl + "User/AddUserReportSECURE"; // AUT, CSPL, CCPL, PID, TYP, FNAME, PWD, UNAME, WI, DT
  __ADD_USER_URL = this.commonUrl + "CommanUser/mVjffUA04YyTKlIbc2JGTenwVVYVfZP3Jr31dom7yRSHfU79s35UOQ"; //__ADD_USER_URL = this.commonUrl + "User/AddUserReportSECURE"; // AUT, CSPL, CCPL, PID, TYP, FNAME, PWD, UNAME, WI, DT

  //__GetPartnership_URL = this.commonUrl + "Data/UserPLShareSECURE"; // AUT, UID
  __GetPartnership_URL = this.commonUrl + "Radhe/VxPHWO1ztzzqV66R9zoWgMfAdtCkhRNsBtGdBXjnaGdjhH6slp8w"; //  __GetPartnership_URL = this.commonUrl + "Data/UserPLShareSECURE"; // AUT, UID

  //__PARTNERSHIP_URL = this.commonUrl + "User/GetPartnerPerData"; // AUT, UID
  __PARTNERSHIP_URL = this.commonUrl + "CommanUser/ybF9jrl6oEjyVJYUzsQRQvjnSU7TWQSWuap1GSokWBKm4WOKxNlWg"; //  __PARTNERSHIP_URL = this.commonUrl + "User/GetPartnerPerData";// AUT, UID

  //__SAVESPORTSLIMITSALL_URL = this.commonUrl + "Admin/SaveAllSportsLimitsSECURE";
  __SAVESPORTSLIMITSALL_URL = this.commonUrl + "CommanAdmin/QbpQkNyiK6tgIUhQhfrQKTTaC63b4tLgnDLWaIyTx7nKhj76idP2gyu8tnUSFfrPXEJmqGjY0"; //  __SAVESPORTSLIMITSALL_URL = this.commonUrl + "Admin/SaveAllSportsLimitsSECURE";

  //__MATCH_RESULTS_URL = this.commonUrl + "Master/ResultReportSECURE";
  __MATCH_RESULTS_URL = this.commonUrl + "CommanMaster/02xYfAGr1wuJ0Y2n5Ap3eAFgC1TQPu9IkD5gxSn7WagvlXmvnmssQ"; //  __MATCH_RESULTS_URL = this.commonUrl + "Master/ResultReportSECURE";

  //__PL_MATCHEDHISTIRY_URL = this.commonUrl + "Data/MatchedHistoryByRangeReportSECURE";
  __PL_MATCHEDHISTIRY_URL = this.commonUrl + "Radhe/z3ZP3nK6jMoL5CDh05MN4GfjuiX0gKcFwqQYHD0YeAyt20SIqwzIXhpAiEPbNAatJQSCjZ6wMwWEtdL3WoQTo1vFgv1c4"; //__PL_MATCHEDHISTIRY_URL = this.commonUrl + "Data/MatchedHistoryByRangeReportSECURE";

  //__SAVE_SPORTSCOMMISSION_URL = this.commonUrl + "Data/SaveCommonShareSECURE";
  __SAVE_SPORTSCOMMISSION_URL = this.commonUrl + "Radhe/QbpQkNyiK6vf0E0dDG6BW4PtA4JqMwn61s9P466OjOUCeQ4No5q893NanRLjY2i"; //__SAVE_SPORTSCOMMISSION_URL = this.commonUrl + "Data/SaveCommonShareSECURE";

  //__CHANGE_SPORTSHARING_URL = this.commonUrl + "User/SaveUserPercentageSECURE";
  __CHANGE_SPORTSHARING_URL = this.commonUrl + "CommanUser/QbpQkNyiK6sSY4PE4BjqAouGGXv5B0wTPPfZqw47LIlQBptpxM2z6tr2zcR5fVy35OJEozk"; //  __CHANGE_SPORTSHARING_URL = this.commonUrl + "User/SaveUserPercentageSECURE";



  // __CASINO_MASTER_URL = this.commonUrl + "Master/MasterReqCasinoSECURE"; // AUT, SID, inParentID
  // getCommissionDetails_URL = this.commonUrl + "User/getChipSummaryCommDetailSECURE";
  // getOnlineUser_URL = this.commonUrl + "Data/getOnlineUserSECURE"; // AUT, UID
  __CASINO_MASTER_URL = this.commonUrl + "CommanMaster/36f0iSITzpmMQCJL7pNWrzx7krtsitLsvrynYdzqT9gGoHUAEproYlyBuLBPij";//__CASINO_MASTER_URL = this.commonUrl + "Master/MasterReqCasinoSECURE"; // AUT, SID, inParentID

  //getCommissionDetails_URL = this.commonUrl + "User/getChipSummaryCommDetailSECURE";
  getCommissionDetails_URL = this.commonUrl + "CommanUser/qPh2Yi8K951a4RGugASZvL3Oz9Vl8kjx9dZN9aG7DLn7eM67KyxmYVRClzf1uS2j4xRHWcUW4pXWBfKkNA";//  getCommissionDetails_URL = this.commonUrl + "User/getChipSummaryCommDetailSECURE";


  //getOnlineUser_URL = this.commonUrl + "Data/getOnlineUserSECURE"; // AUT, UID
  getOnlineUser_URL = this.commonUrl + "Radhe/2FCNjuPA9U5W8xvpTd8Xt3VOdHibPEnRtBwaEUqwe9o0iH4SNUrhA"; //   getOnlineUser_URL = this.commonUrl + "Data/getOnlineUserSECURE";


  //__Register_PAYMENT_TNS_URL = this.commonUrl + "Data/GetPayuMoneyData"; // userId, txnID, userName
  __Register_PAYMENT_TNS_URL = this.commonUrl + "Sky/ybF9jrl6oEg5XFeb4tr6g8JgJbEfcxFgfZvCclLDDEppNl7ZRlhrQ"; //   __Register_PAYMENT_TNS_URL = this.commonUrl + "Data/GetPayuMoneyData"; 

  __ADD_BALANCE_URL = this.commonUrl + "Data/paymentDepositReqSECURE";
  __ADD_BALANCE_URL_Auto = this.commonUrl + "Data/paymentDepositReqFoxExchSECURE";
  GetDepositBonus = this.commonUrl + 'Data/paymentDepositBonushotsports';//paymentDepositBonushotsport

  // GetDepositRequestList_URL = this.commonUrl + 'Data/paymentDepositHotHistorySECURE';//
  // GetDepositPendingList_URL = this.commonUrl + 'Data/paymentDepositPanddingHotHistorySECURE';
  GetManageDepositPendingList_URL = this.commonUrl + 'Data/DepositHistoryHotSportSECURE';
  // GetTransactionsList_URL = this.commonUrl + 'Data/AllPaymentHistorySECURE';
  GetDepositRequestList_URL = this.commonUrl + 'fox/iBDhAcU6WgcIVFvQtqD6CjGJMqHZPCjUX6IyKG05RmZAwGQ1eBOq07QELn9WYkRUEWLGXLf92ybriH51fWSNjg';// GetDepositRequestList_URL = this.commonUrl + 'Data/DepositHistorySkyNew99SECURE';

  //GetDepositPendingList_URL = this.commonUrl + 'Data/paymentDepositPanddingHotHistorySECURE';
  GetDepositPendingList_URL = this.commonUrl + 'fox/GuwmmGF3NtucJRu44CGRyOcJUbDZbDrCsfdMq1no8F7Ufy83M3QITsxuwUQxo2cHugw6XxJdCfqn86VZuFnX51xhCtw0UW8HAyB3z0k';//  GetDepositPendingList_URL = this.commonUrl + 'Data/paymentDepositPanddingHotHistorySECURE';

  //GetTransactionsList_URL = this.commonUrl + 'Data/getTransactionHistorySkyNew99';
  GetTransactionsList_URL = this.commonUrl + 'fox/LOyMqlduipHwqL5sBx3QagE6qN9js0JBj7H3o7GN7lcQyO43t8PEs1HWI1XOaOqZSDY8dUVL6BWudOLQ';// GetTransactionsList_URL = this.commonUrl + 'Data/getTransactionHistorySkyNew99';

  // GetRefferalsList_URL = this.commonUrl + 'User/MyReferralSECURE';
  // DepositRequestUpdateList_URL = this.commonUrl + 'Data/paymentDepositUpdateSECURE';
  // GetDepositRequestListSearch_URL = this.commonUrl + 'Data/paymentDepositHotSearchHistorySECURE';
  // GetWithdrawRequestList_URL = this.commonUrl + 'Data/paymentWithdrawHotHistorySECURE';
  // GetWithdrawRequestListSearch_URL = this.commonUrl + 'Data/PaymentWithdrawHotHistorySearchSECURE';
  // WithdrawRequestUpdateList_URL = this.commonUrl + 'Data/paymentWithUpdateSECURE';

  Payment_On_off_ = this.commonUrl + 'fox/sdfkkdjfjererxzdjasdaldlasdlooeroeskdskdk';

  Payment_GateWey_Show = this.commonUrl + 'fox/dHiiosdmmmemmsmdjjwe32ajsjajsh';

  // __WITHDRAW_BALANCE_URL = this.commonUrl + "Data/paymentWithdrawExpoReqSECURE";
  GetRefferalsList_URL = this.commonUrl + 'Giga/ZNIGUxh5SBx0DTgo7kInR9rFCxBbicDR3zWGORN40XM5y34aSRNWuDNc4QUXs4'; //GetRefferalsList_URL = this.commonUrl + 'User/MyReferralSECURE';

  //DepositRequestUpdateList_URL = this.commonUrl + 'Data/paymentDepositUpdateSECURE';
  DepositRequestUpdateList_URL = this.commonUrl + 'Radhe/GuwmmGF3NtucJRu44CGRyOcJUbDZbDHZ5RE2qy5aAY2IX5xuB4q6zSkAKTfGuCgGnZDxPg4';//   DepositRequestUpdateList_URL = this.commonUrl + 'Data/paymentDepositUpdateSECURE';

  //GetDepositRequestListSearch_URL = this.commonUrl + 'Data/paymentDepositHotSearchHistorySECURE';
  GetDepositRequestListSearch_URL = this.commonUrl + 'fox/GuwmmGF3NtucJRu44CGRyOcJUbDZbDGiEg0H53nYOgIgy4iDLE4P2932yQAEI4QGIJ8iqOsb4blaTwNY0PRBROh9ZIuG3eepBhlZvZR9jMyfKALp91g';//  YJLm5wuDyd15FwgTlhyIbEvvCOOTNN5rLhKUnjILnNG9ugKMTK1HAIFhVYQoi3JkPNthsOvCTiFxxLZnKIg = this.commonUrl + 'Data/paymentDepositHotSearchHistorySECURE';


  //GetWithdrawRequestList_URL = this.commonUrl + 'Data/WithdrawHistorySkyNew99SECURE';
  GetWithdrawRequestList_URL = this.commonUrl + 'fox/kXETTTXGG7JwNfhWpmBd5Zl9bCAtsEfL1XF1mg6ypqxnlZ5cm9rJgaH2Aki6IHozBVRLj7AycQNCTxMDQiPmw';//  GetWithdrawRequestList_URL = this.commonUrl + 'Data/WithdrawHistorySkyNew99SECURE';

  //GetWithdrawRequestListSearch_URL = this.commonUrl + 'Data/PaymentWithdrawHotHistorySearchSECURE';
  //GetWithdrawRequestListSearch_URL = this.commonUrl + 'Radhe/3XNYWqyx5VsyqyS1hr9EJBbek2LZOaLh0zpXaCEqK7ImKOC14QemfJHw3k3lOuvF6iCUyL2Ozibh6Av7HTPCPpBC94N7liiW7V8VlJ5JE';//   GetWithdrawRequestListSearch_URL = this.commonUrl + 'Data/PaymentWithdrawHotHistorySearchSECURE';
  GetWithdrawRequestListSearch_URL = this.commonUrl + 'fox/GuwmmGF3NtucJRu44CGRyOcJUbDZbDGiEg0H53nYOgIgy4iDLE4P2932yQAEI4QGIJ8iqOsb4blaTwNY0PRBROh9ZIuG3eepBhlZvZR9jMyfKALp91g';

  //WithdrawRequestUpdateList_URL = this.commonUrl + 'Data/paymentWithUpdateSECURE';
  WithdrawRequestUpdateList_URL = this.commonUrl + 'fox/GuwmmGF3NtT7dCR4PzzLsEiA41KqRTtQlUpRFiZF0uPYHrpC9SPkb7Lf3Gtk6YD';//  WithdrawRequestUpdateList_URL = this.commonUrl + 'Data/paymentWithUpdateSECURE';

  //__WITHDRAW_BALANCE_URL = this.commonUrl + "Data/paymentWithdrawExpoReqSECURE";
  __WITHDRAW_BALANCE_URL = this.commonUrl + "fox/GuwmmGF3NtT7dCR4PzzLkiuoE0BWYU03iujU2D6y9qxKb6Fhox7taojqaBrmvBf4J6bepUIdtzV5P86v65Q";// __WITHDRAW_BALANCE_URL = this.commonUrl + "Data/paymentWithdrawExpoReqSECURE";

  __WITHDRAW_BALANCE_APEX_URL = this.commonUrl + "Data/kXETTTXGG7JwNfhWpmBd5TKq0B9L80y03u5aBshjzGDhpyGobTEEoIDeu61KyUhfNeKy7duaB8ihd5k2jcwqZw"; //Data/WithdrawAutoGigaSportsSECURE

  GetManualDeposit_URL = this.commonUrl + 'Data/DepositHistoryHotSportsSECURE';

  __LOTUS_PL_URL = this.commonUrl + "Data/GetLotusPL"; // AUT,, UID, Per

  // __ALLOW_BLOCK_EVENT_URL = this.commonUrl + "Master/AllowBlockEventSECURE";
  // __GET_ALLOW_BLOCK_EVENT_URL = this.commonUrl + "Master/BlockedEventSECURE";
  // __GET_ALLOW_BLOCK_EVENT_ALL_URL = this.commonUrl + "Master/BlockedEventAllSECURE";

  // __EVOLUTION_CASINO_URL = this.commonUrl_Fundist + "Data/FundistUserNewAddSECURE";

  // // MAINTENANCE_GET = "https://comm.prideexch.co/Api/Data/getMaintenance";
  // MAINTENANCE_GET = "https://adminlab.orange9.club/Api/Data/getMaintenance";

  GET_DEPOSIT_WITHDRAW_BY_USER_URL = this.commonUrl + 'Data/getTotalDepositWithdraw';
  __ALLOW_BLOCK_EVENT_URL = this.commonUrl + "CommanMaster/3DbtwmNj3xwraUGtMzeJ47XCHdqFaiayfyBDPTLJ88GV8Tc89cGjnfviV4zbyC";//__ALLOW_BLOCK_EVENT_URL = this.commonUrl + "Master/AllowBlockEventSECURE";

  //__GET_ALLOW_BLOCK_EVENT_URL = this.commonUrl + "Master/BlockedEventSECURE";
  __GET_ALLOW_BLOCK_EVENT_URL = this.commonUrl + "CommanMaster/Lm6M4tZbFLwGuTc2jEBdTIG1SB40iwTbxMxGK9HmDZ7wXsIx3g";// __GET_ALLOW_BLOCK_EVENT_URL = this.commonUrl + "Master/BlockedEventSECURE";

  //__GET_ALLOW_BLOCK_EVENT_ALL_URL = this.commonUrl + "Master/BlockedEventAllSECURE";
  __GET_ALLOW_BLOCK_EVENT_ALL_URL = this.commonUrl + "CommanMaster/Lm6M4tZbFLwGuTc2jEBdTIG1SB40BVdJEyXwYytqItpxMyh4R0TA92txGTM";//__GET_ALLOW_BLOCK_EVENT_ALL_URL = this.commonUrl + "Master/BlockedEventAllSECURE";

  //__EVOLUTION_CASINO_URL = this.commonUrl_Fundist + "Data/FundistUserNewAddSECURE";
  __EVOLUTION_CASINO_URL = this.commonUrl_Fundist + "Radhe/WvGKA202syZa5TjU9kX8oLjWGiemHcMj31go2O3sTpgvKjINtmZAG9DhXbEo0P";//__EVOLUTION_CASINO_URL = this.commonUrl_Fundist + "Data/FundistUserNewAddSECURE";

  MAINTENANCE_GET = "https://adminlab.orange9.club/Api/Data/getMaintenance";
  //MAINTENANCE_GET = "https://adminlab.orange9.club/Api/Radha/ALQPwNffNmf3PUWe3XiplKxQOg9izUkI0o8V9knKpUU";// MAINTENANCE_GET = "https://adminlab.orange9.club/Api/Data/getMaintenance";

  //Giga/clientRechargeGigaSports
  USERDEPOSIT_URL = this.commonUrl + 'Giga/H9YGIwFlnil45l4Ae2ohBeUCyndRi2GFtqd4LTrxHbmUFv3G8227u0IU3qvA7Ojseaq4TIo'; //USERDEPOSIT_URL = this.commonUrl + 'Data/clientRechargeSkyNew99';


  ADMIN_VALIDATE_ACCESS_URL = this.commonUrl + "Data/validateAdminAccess";

  GetTopGainers_URL = this.commonUrl + "Data/GetGainerReportsHotsports";
  GetTopLoosers_URL = this.commonUrl + "Data/GettoplooserReportsHotsports";
  getSuperNowaGameListURL = "https://super.sodaexch.com/Api/Teenpatti/GameList";
  getSuperNowaURL = "https://super.sodaexch.com/Api/Teenpatti/AuthSuper";
  SET_DREAMCASINO_TOKEN_URL = "https://dreem.gigblitz.live/Api/" + "Teenpatti/getGameListEncrypt";
  setSuperNowaCasinoURL = this.commonUrl + "CommanUser/NeP2Fn5AQEMzLwdN4dWbgkBCoTHUho1OrJ6Nq2zJfrXY8licg";//"User/AddEzugiTokenSECURE";


  /* ========================================================= */

  /* ENCRYPT DECRYPT */
  setFunCasinoAuthURL = "http://apifc.bet24digital.com/Api/" + "TeenPatti/FunAuth";
  DeleteTradeUrl = this.commonUrl + "Admin/DeleteTrade";
  /* ======================================================= */

  constructor(private http: HttpClient) {
    this.http.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
        this.ipAddress = data.ip
      })

    const headerSettings: { [name: string]: string | string[]; } = {};
    this.loginHeader = new HttpHeaders(headerSettings);
  }

  COUNTRY_LIST =
    [
      { name: 'Afghanistan', code: 'AF' },
      { name: 'Åland Islands', code: 'AX' },
      { name: 'Albania', code: 'AL' },
      { name: 'Algeria', code: 'DZ' },
      { name: 'American Samoa', code: 'AS' },
      { name: 'AndorrA', code: 'AD' },
      { name: 'Angola', code: 'AO' },
      { name: 'Anguilla', code: 'AI' },
      { name: 'Antarctica', code: 'AQ' },
      { name: 'Antigua and Barbuda', code: 'AG' },
      { name: 'Argentina', code: 'AR' },
      { name: 'Armenia', code: 'AM' },
      { name: 'Aruba', code: 'AW' },
      { name: 'Australia', code: 'AU' },
      { name: 'Austria', code: 'AT' },
      { name: 'Azerbaijan', code: 'AZ' },
      { name: 'Bahamas', code: 'BS' },
      { name: 'Bahrain', code: 'BH' },
      { name: 'Bangladesh', code: 'BD' },
      { name: 'Barbados', code: 'BB' },
      { name: 'Belarus', code: 'BY' },
      { name: 'Belgium', code: 'BE' },
      { name: 'Belize', code: 'BZ' },
      { name: 'Benin', code: 'BJ' },
      { name: 'Bermuda', code: 'BM' },
      { name: 'Bhutan', code: 'BT' },
      { name: 'Bolivia', code: 'BO' },
      { name: 'Bosnia and Herzegovina', code: 'BA' },
      { name: 'Botswana', code: 'BW' },
      { name: 'Bouvet Island', code: 'BV' },
      { name: 'Brazil', code: 'BR' },
      { name: 'British Indian Ocean Territory', code: 'IO' },
      { name: 'Brunei Darussalam', code: 'BN' },
      { name: 'Bulgaria', code: 'BG' },
      { name: 'Burkina Faso', code: 'BF' },
      { name: 'Burundi', code: 'BI' },
      { name: 'Cambodia', code: 'KH' },
      { name: 'Cameroon', code: 'CM' },
      { name: 'Canada', code: 'CA' },
      { name: 'Cape Verde', code: 'CV' },
      { name: 'Cayman Islands', code: 'KY' },
      { name: 'Central African Republic', code: 'CF' },
      { name: 'Chad', code: 'TD' },
      { name: 'Chile', code: 'CL' },
      { name: 'China', code: 'CN' },
      { name: 'Christmas Island', code: 'CX' },
      { name: 'Cocos (Keeling) Islands', code: 'CC' },
      { name: 'Colombia', code: 'CO' },
      { name: 'Comoros', code: 'KM' },
      { name: 'Congo', code: 'CG' },
      { name: 'Congo, The Democratic Republic of the', code: 'CD' },
      { name: 'Cook Islands', code: 'CK' },
      { name: 'Costa Rica', code: 'CR' },
      { name: 'Cote D\'Ivoire', code: 'CI' },
      { name: 'Croatia', code: 'HR' },
      { name: 'Cuba', code: 'CU' },
      { name: 'Cyprus', code: 'CY' },
      { name: 'Czech Republic', code: 'CZ' },
      { name: 'Denmark', code: 'DK' },
      { name: 'Djibouti', code: 'DJ' },
      { name: 'Dominica', code: 'DM' },
      { name: 'Dominican Republic', code: 'DO' },
      { name: 'Ecuador', code: 'EC' },
      { name: 'Egypt', code: 'EG' },
      { name: 'El Salvador', code: 'SV' },
      { name: 'Equatorial Guinea', code: 'GQ' },
      { name: 'Eritrea', code: 'ER' },
      { name: 'Estonia', code: 'EE' },
      { name: 'Ethiopia', code: 'ET' },
      { name: 'Falkland Islands (Malvinas)', code: 'FK' },
      { name: 'Faroe Islands', code: 'FO' },
      { name: 'Fiji', code: 'FJ' },
      { name: 'Finland', code: 'FI' },
      { name: 'France', code: 'FR' },
      { name: 'French Guiana', code: 'GF' },
      { name: 'French Polynesia', code: 'PF' },
      { name: 'French Southern Territories', code: 'TF' },
      { name: 'Gabon', code: 'GA' },
      { name: 'Gambia', code: 'GM' },
      { name: 'Georgia', code: 'GE' },
      { name: 'Germany', code: 'DE' },
      { name: 'Ghana', code: 'GH' },
      { name: 'Gibraltar', code: 'GI' },
      { name: 'Greece', code: 'GR' },
      { name: 'Greenland', code: 'GL' },
      { name: 'Grenada', code: 'GD' },
      { name: 'Guadeloupe', code: 'GP' },
      { name: 'Guam', code: 'GU' },
      { name: 'Guatemala', code: 'GT' },
      { name: 'Guernsey', code: 'GG' },
      { name: 'Guinea', code: 'GN' },
      { name: 'Guinea-Bissau', code: 'GW' },
      { name: 'Guyana', code: 'GY' },
      { name: 'Haiti', code: 'HT' },
      { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
      { name: 'Holy See (Vatican City State)', code: 'VA' },
      { name: 'Honduras', code: 'HN' },
      { name: 'Hong Kong', code: 'HK' },
      { name: 'Hungary', code: 'HU' },
      { name: 'Iceland', code: 'IS' },
      { name: 'India', code: 'IN' },
      { name: 'Indonesia', code: 'ID' },
      { name: 'Iran, Islamic Republic Of', code: 'IR' },
      { name: 'Iraq', code: 'IQ' },
      { name: 'Ireland', code: 'IE' },
      { name: 'Isle of Man', code: 'IM' },
      { name: 'Israel', code: 'IL' },
      { name: 'Italy', code: 'IT' },
      { name: 'Jamaica', code: 'JM' },
      { name: 'Japan', code: 'JP' },
      { name: 'Jersey', code: 'JE' },
      { name: 'Jordan', code: 'JO' },
      { name: 'Kazakhstan', code: 'KZ' },
      { name: 'Kenya', code: 'KE' },
      { name: 'Kiribati', code: 'KI' },
      { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
      { name: 'Korea, Republic of', code: 'KR' },
      { name: 'Kuwait', code: 'KW' },
      { name: 'Kyrgyzstan', code: 'KG' },
      { name: 'Lao People\'S Democratic Republic', code: 'LA' },
      { name: 'Latvia', code: 'LV' },
      { name: 'Lebanon', code: 'LB' },
      { name: 'Lesotho', code: 'LS' },
      { name: 'Liberia', code: 'LR' },
      { name: 'Libyan Arab Jamahiriya', code: 'LY' },
      { name: 'Liechtenstein', code: 'LI' },
      { name: 'Lithuania', code: 'LT' },
      { name: 'Luxembourg', code: 'LU' },
      { name: 'Macao', code: 'MO' },
      { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
      { name: 'Madagascar', code: 'MG' },
      { name: 'Malawi', code: 'MW' },
      { name: 'Malaysia', code: 'MY' },
      { name: 'Maldives', code: 'MV' },
      { name: 'Mali', code: 'ML' },
      { name: 'Malta', code: 'MT' },
      { name: 'Marshall Islands', code: 'MH' },
      { name: 'Martinique', code: 'MQ' },
      { name: 'Mauritania', code: 'MR' },
      { name: 'Mauritius', code: 'MU' },
      { name: 'Mayotte', code: 'YT' },
      { name: 'Mexico', code: 'MX' },
      { name: 'Micronesia, Federated States of', code: 'FM' },
      { name: 'Moldova, Republic of', code: 'MD' },
      { name: 'Monaco', code: 'MC' },
      { name: 'Mongolia', code: 'MN' },
      { name: 'Montserrat', code: 'MS' },
      { name: 'Morocco', code: 'MA' },
      { name: 'Mozambique', code: 'MZ' },
      { name: 'Myanmar', code: 'MM' },
      { name: 'Namibia', code: 'NA' },
      { name: 'Nauru', code: 'NR' },
      { name: 'Nepal', code: 'NP' },
      { name: 'Netherlands', code: 'NL' },
      { name: 'Netherlands Antilles', code: 'AN' },
      { name: 'New Caledonia', code: 'NC' },
      { name: 'New Zealand', code: 'NZ' },
      { name: 'Nicaragua', code: 'NI' },
      { name: 'Niger', code: 'NE' },
      { name: 'Nigeria', code: 'NG' },
      { name: 'Niue', code: 'NU' },
      { name: 'Norfolk Island', code: 'NF' },
      { name: 'Northern Mariana Islands', code: 'MP' },
      { name: 'Norway', code: 'NO' },
      { name: 'Oman', code: 'OM' },
      { name: 'Pakistan', code: 'PK' },
      { name: 'Palau', code: 'PW' },
      { name: 'Palestinian Territory, Occupied', code: 'PS' },
      { name: 'Panama', code: 'PA' },
      { name: 'Papua New Guinea', code: 'PG' },
      { name: 'Paraguay', code: 'PY' },
      { name: 'Peru', code: 'PE' },
      { name: 'Philippines', code: 'PH' },
      { name: 'Pitcairn', code: 'PN' },
      { name: 'Poland', code: 'PL' },
      { name: 'Portugal', code: 'PT' },
      { name: 'Puerto Rico', code: 'PR' },
      { name: 'Qatar', code: 'QA' },
      { name: 'Reunion', code: 'RE' },
      { name: 'Romania', code: 'RO' },
      { name: 'Russian Federation', code: 'RU' },
      { name: 'RWANDA', code: 'RW' },
      { name: 'Saint Helena', code: 'SH' },
      { name: 'Saint Kitts and Nevis', code: 'KN' },
      { name: 'Saint Lucia', code: 'LC' },
      { name: 'Saint Pierre and Miquelon', code: 'PM' },
      { name: 'Saint Vincent and the Grenadines', code: 'VC' },
      { name: 'Samoa', code: 'WS' },
      { name: 'San Marino', code: 'SM' },
      { name: 'Sao Tome and Principe', code: 'ST' },
      { name: 'Saudi Arabia', code: 'SA' },
      { name: 'Senegal', code: 'SN' },
      { name: 'Serbia and Montenegro', code: 'CS' },
      { name: 'Seychelles', code: 'SC' },
      { name: 'Sierra Leone', code: 'SL' },
      { name: 'Singapore', code: 'SG' },
      { name: 'Slovakia', code: 'SK' },
      { name: 'Slovenia', code: 'SI' },
      { name: 'Solomon Islands', code: 'SB' },
      { name: 'Somalia', code: 'SO' },
      { name: 'South Africa', code: 'ZA' },
      { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
      { name: 'Spain', code: 'ES' },
      { name: 'Sri Lanka', code: 'LK' },
      { name: 'Sudan', code: 'SD' },
      { name: 'Suriname', code: 'SR' },
      { name: 'Svalbard and Jan Mayen', code: 'SJ' },
      { name: 'Swaziland', code: 'SZ' },
      { name: 'Sweden', code: 'SE' },
      { name: 'Switzerland', code: 'CH' },
      { name: 'Syrian Arab Republic', code: 'SY' },
      { name: 'Taiwan, Province of China', code: 'TW' },
      { name: 'Tajikistan', code: 'TJ' },
      { name: 'Tanzania, United Republic of', code: 'TZ' },
      { name: 'Thailand', code: 'TH' },
      { name: 'Timor-Leste', code: 'TL' },
      { name: 'Togo', code: 'TG' },
      { name: 'Tokelau', code: 'TK' },
      { name: 'Tonga', code: 'TO' },
      { name: 'Trinidad and Tobago', code: 'TT' },
      { name: 'Tunisia', code: 'TN' },
      { name: 'Turkey', code: 'TR' },
      { name: 'Turkmenistan', code: 'TM' },
      { name: 'Turks and Caicos Islands', code: 'TC' },
      { name: 'Tuvalu', code: 'TV' },
      { name: 'Uganda', code: 'UG' },
      { name: 'Ukraine', code: 'UA' },
      { name: 'United Arab Emirates', code: 'AE' },
      { name: 'United Kingdom', code: 'GB' },
      { name: 'United States', code: 'US' },
      { name: 'United States Minor Outlying Islands', code: 'UM' },
      { name: 'Uruguay', code: 'UY' },
      { name: 'Uzbekistan', code: 'UZ' },
      { name: 'Vanuatu', code: 'VU' },
      { name: 'Venezuela', code: 'VE' },
      { name: 'Viet Nam', code: 'VN' },
      { name: 'Virgin Islands, British', code: 'VG' },
      { name: 'Virgin Islands, U.S.', code: 'VI' },
      { name: 'Wallis and Futuna', code: 'WF' },
      { name: 'Western Sahara', code: 'EH' },
      { name: 'Yemen', code: 'YE' },
      { name: 'Zambia', code: 'ZM' },
      { name: 'Zimbabwe', code: 'ZW' }
    ]
}
