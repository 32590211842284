import { Injectable } from '@angular/core';
import * as Rx from 'rxjs';
import { CommonlistService } from './commonlist.service';
import { Subject, Observable } from "rxjs";
import { SignalRService } from "./signal-r.service";
import { CryptojsService } from './cryptojs.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  constructor(private http: HttpClient, private $opsudjnk: CryptojsService, private SignalRService: SignalRService, private CommonlistService: CommonlistService) { }

  private __ws;
  private subject: Subject<MessageEvent>;
  private connected$ = new Subject<any>();
  private onMessage$ = new Subject<any>();
  address: any = this.CommonlistService.__WEBSOCKET_URL;
  Url: any;

  public connect(): Rx.Subject<MessageEvent> {
    if (!this.subject) {
      if (sessionStorage.getItem('user') == undefined || sessionStorage.getItem('user') == null) {
      }
      else {
        var Session_User = JSON.parse(this.$opsudjnk.decryptData(sessionStorage.user));
        this.address = this.address + "?userid=" + encodeURIComponent(this.$opsudjnk.encryptUserFunction(Session_User.UserId).toString()) + "&connectionToken=" + encodeURIComponent(this.$opsudjnk.encryptUserFunction(sessionStorage.getItem("SHA_256_KEY") + "," + Session_User.HandshakeKey + "," + Session_User.Message.split('_')[2] + "," + Session_User.Message.split('_')[3]).toString());

        this.subject = this.create(this.address);
        this.connected$.next(true);

        this.checkConnection();
      }
    }
    return this.subject;
  }

  CONNECTION: any;
  public checkConnection() {
    let conn = this.connected().subscribe(data => { return data; });
    this.sendMessage((parseInt(this.$opsudjnk.decryptData("U2FsdGVkX1+i4kAzl18VY79q6HMHuYtzhBCBBOj1szE=")) - this.CommonlistService.Connection).toString());
    this.CONNECTION = setTimeout(() => {
      this.checkConnection();
    }, 5000);
  }

  public connected(): Observable<any> {
    return this.connected$;
  }

  OrderInformation(Token, Order) {
    let __POSTBODY = {
      "_accessToken": Token,
      "body": [Order]
    }
    let __HASH = this.$opsudjnk.encryptOrderFunction(JSON.stringify(__POSTBODY));
    
    return this.http.post<any>(this.CommonlistService.__ORDER_URL + "?Data=" + encodeURIComponent(__HASH.toString()), this.CommonlistService.httpOptions)
  }

  private create(url): Subject<MessageEvent> {
    this.__ws = new WebSocket(url);

    this.__ws.onmessage = (e) => {
      this.onMessage$.next(e);
      // this.closeConnection();
    }

    this.__ws.onerror = (e) => {
      // this.closeConnection();
    }

    this.__ws.onclose = (e) => {
      // this.reconnectWS();
      // console.log("ONCLOSE > RECONNECTING")
      var wsn = new WebSocket(this.address);
      wsn.onopen = this.__ws.onopen;
      wsn.onmessage = this.__ws.onmessage;
      wsn.onclose = this.__ws.onclose;
      wsn.onerror = this.__ws.onerror;
      this.__ws = wsn;
    }

    let observable = Observable.create(
      (obs: Rx.Observer<MessageEvent>) => {
        this.__ws.onmessage = obs.next.bind(obs);
        this.__ws.onerror = obs.error.bind(obs);
        this.__ws.onclose = obs.complete.bind(obs);
        return this.__ws.close.bind(this.__ws);
      });

    let observer =
    {
      next: (data: Object) => {
        if (this.__ws.readyState === WebSocket.OPEN) {
          this.__ws.send(JSON.stringify(data));
        }
      }
    }
    return Rx.Subject.create(observer, observable);
  }

  public sendMessage(order) {
    var stEnc = this.$opsudjnk.encryptSocketFunction(order);
    if (this.__ws && (this.__ws.readyState == WebSocket.OPEN)) {
      this.__ws.send(stEnc.toString())
    }
    else if (this.__ws && (this.__ws.readyState == WebSocket.CLOSED)) {
      this.onMessage(stEnc.toString());
    }
  }

  public onMessage(str) {
    this.subject = this.create(this.address);
    setTimeout(() => {
      this.sendMessage(str);
    }, 500);
  }

  private reconnectWS() {
    if (this.__ws && (this.__ws.readyState == WebSocket.CLOSED)) {
      this.subject = this.create(this.address);
    }
  }

  public closeConnection() {
    if (this.__ws && (this.__ws.readyState == WebSocket.OPEN)) {
      this.subject = null;
      this.__ws.close();
    }
  }

  public getOnMessage(): Observable<any> {
    return this.onMessage$;
  }
}
