import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainhomeviewComponent } from './mainhomeview/mainhomeview.component';
import { MainhomeviewMobileComponent } from './mainhomeview-mobile/mainhomeview-mobile.component';

import { LoginComponent } from './login/login.component';
import { LoginMobileComponent } from './login-mobile/login-mobile.component';

import { RefreshComponent } from './refresh/refresh.component';
import { ManageDepositPendingComponent } from './manage-deposit-pending/manage-deposit-pending.component';
import { PayuComponent } from './payu/payu.component';
import { BankDetailsComponent } from './bank-details/bank-details.component';

export const routes: Routes = [
  //WEBSITE
  { path: '', redirectTo: 'Home', pathMatch: 'full', },

  { path: 'RefreshComponent', component: RefreshComponent, data: { title: '7Exch - Refresh' } },

  { path: 'LoginSuccess', redirectTo: 'Home', pathMatch: 'full', },
  { path: 'LoginSuccess_mobile', redirectTo: 'Home_mobile', pathMatch: 'full', },


  { path: 'login', component: LoginComponent, data: { title: 'Login Page' } },
  { path: 'login_mobile', component: LoginMobileComponent, data: { title: 'Login Page' } },

  { path: 'Home', component: MainhomeviewComponent, data: { title: '7Exch - Home' } },
  { path: 'Home_mobile', component: MainhomeviewMobileComponent, data: { title: '7Exch - Home' } },

  { path: 'MatchView', loadChildren: './matchview/matchview.module#MatchviewModule', data: { SportsID: '4' } },
  { path: 'MatchView_mobile', loadChildren: './matchview-mobile/matchview-mobile.module#MatchviewMobileModule', data: { SportsID: '4' } },

  { path: 'Match:TableView', loadChildren: './matchtableview/matchtableview.module#MatchtableviewModule', data: { title: '7Exch - Match Table View' } },
  { path: 'Match:TableView_mobile', loadChildren: './matchtableview-mobile/matchtableview-mobile.module#MatchtableviewMobileModule', data: { title: '7Exch - Match Table View' } },

  { path: 'change-banner', loadChildren: './upload-image/upload-image.module#UploadImageModule', data: { title: '7Exch - Match Table View' } },

  { path: 'matchresults', loadChildren: './match-results/match-results.module#MatchResultsModule', data: { title: '7Exch - Home' } },
  { path: 'm/matchresults', loadChildren: './match-results-mobile/match-results-mobile.module#MatchResultsMobileModule', data: { title: '7Exch - Home' } },

  { path: 'closingapp', loadChildren: './closing-app/closing-app.module#ClosingAppModule', data: { title: '7Exch - Home' } },

  { path: 'iframecasino', loadChildren: './iframe-casino/iframe-casino.module#IframeCasinoModule', data: { title: '7Exch - Home' } },


  { path: 'ezugicasino', loadChildren: './ezugi-casino/ezugi-casino.module#EzugiCasinoModule', data: { title: '7Exch - Home' } },

  { path: 'lotuscasino', loadChildren: './lotus-casino/lotus-casino.module#LotusCasinoModule', data: { title: '7Exch - Home' } },
  { path: 'lotuscasino_Mobile', loadChildren: './lotus-casino-mobile/lotus-casino-mobile.module#LotusCasinoMobileModule', data: { title: '7Exch - Home' } },

  { path: 'eventslimits', loadChildren: './events-limits/events-limits.module#EventsLimitsModule', data: { title: '7Exch - Home' } },

  { path: 'mybets', loadChildren: './my-bets/my-bets.module#MyBetsModule', data: { title: '7Exch - Home' } },
  { path: 'pay', component: PayuComponent },

  { path: 'manage/bank', component: BankDetailsComponent, data: { title: '7Exch - Home' } },
  // DIAMOND CASINO  
  { path: 'casino/casino-games', loadChildren: './indian-poker/indian-poker.module#IndianPokerModule', data: { title: '7Exch - Home' } },
  { path: 'casino/ezugi', loadChildren: './ezugi-casino-new/ezugi-casino-new.module#EzugiCasinoNewModule', data: { title: '7Exch - Home' } },
  { path: 'casino/evolution', loadChildren: './evolution-casino/evolution-casino.module#EvolutionCasinoModule', data: { title: '7Exch - Home' } },


  //MOBILE VIEW
  { path: 'client-online/data', loadChildren: './clients-online/clients-online.module#ClientsOnlineModule', data: { title: '7Exch - Home' } },
  { path: 'client-registered/data', loadChildren: './clients-registered/clients-registered.module#ClientsRegisteredModule', data: { title: '7Exch - Home' } },

  { path: 'client-listing/data', loadChildren: './client-list-new/client-list-new.module#ClientListNewModule', data: { title: '7Exch - Home' } },
  { path: 'client-listing/m/data', loadChildren: './clientlist-mobile/clientlist-mobile.module#ClientlistMobileModule', data: { ClientID: 0 } },

  { path: 'report/client-pl', loadChildren: './report-client-pl/report-client-pl.module#ReportClientPlModule', data: { title: '7Exch - Home' } },
  { path: 'report/m/client-pl', loadChildren: './report-client-pl-mobile/report-client-pl-mobile.module#ReportClientPlMobileModule', data: { title: '7Exch - Home' } },

  { path: 'report/market-pl', loadChildren: './report-market-pl/report-market-pl.module#ReportMarketPlModule', data: { title: '7Exch - Home' } },
  { path: 'report/m/market-pl', loadChildren: './report-market-pl-mobile/report-market-pl-mobile.module#ReportMarketPlMobileModule', data: { title: '7Exch - Home' } },

  { path: 'report/sports-pl', loadChildren: './report-sports-pl/report-sports-pl.module#ReportSportsPlModule', data: { title: '7Exch - Home' } },
  { path: 'report/m/sports-pl', loadChildren: './report-sports-pl-mobile/report-sports-pl-mobile.module#ReportSportsPlMobileModule', data: { title: '7Exch - Home' } },

  { path: 'report/pl', loadChildren: './report-profit-loss/report-profit-loss.module#ReportProfitLossModule', data: { title: '7Exch - Home' } },
  { path: 'report/m/pl', loadChildren: './report-profit-loss-mobile/report-profit-loss-mobile.module#ReportProfitLossMobileModule', data: { title: '7Exch - Home' } },

  { path: 'report/ezugi/acStatement', loadChildren: './report-account-statement-ezugi/report-account-statement-ezugi.module#ReportAccountStatementEzugiModule', data: { title: '7Exch - Home' } },
  { path: 'report/ezugi/acStatement-old', loadChildren: './report-account-statement-ezugiold/report-account-statement-ezugiold.module#ReportAccountStatementEzugioldModule', data: { title: '7Exch - Home' } },
  { path: 'report/acStatement', loadChildren: './report-account-statement/report-account-statement.module#ReportAccountStatementModule', data: { title: '7Exch - Home' } },
  { path: 'report/simple/acStatement', loadChildren: './report-account-statement-old/report-account-statement-old.module#ReportAccountStatementOldModule', data: { title: '7Exch - Home' } },
  { path: 'report/m/acStatement', loadChildren: './report-account-statement-mobile/report-account-statement-mobile.module#ReportAccountStatementMobileModule', data: { title: '7Exch - Home' } },

  { path: 'report/chip-summary', loadChildren: './report-chip-summary/report-chip-summary.module#ReportChipSummaryModule', data: { title: '7Exch - Home' } },
  { path: 'report/m/chip-summary', loadChildren: './report-chip-summary-mobile/report-chip-summary-mobile.module#ReportChipSummaryMobileModule', data: { title: '7Exch - Home' } },

  { path: 'report/bet-history', loadChildren: './report-bet-history/report-bet-history.module#ReportBetHistoryModule', data: { title: '7Exch - Home' } },
  { path: 'report/m/bet-history', loadChildren: './report-bet-history-mobile/report-bet-history-mobile.module#ReportBetHistoryMobileModule', data: { title: '7Exch - Home' } },

  { path: 'report/account-info', loadChildren: './report-account-info/report-account-info.module#ReportAccountInfoModule', data: { title: '7Exch - Home' } },

  { path: 'report/live-bets', loadChildren: './report-live-bets/report-live-bets.module#ReportLiveBetsModule', data: { title: '7Exch - Home' } },
  { path: 'report/m/live-bets', loadChildren: './report-live-bets-mobile/report-live-bets-mobile.module#ReportLiveBetsMobileModule', data: { title: '7Exch - Home' } },

  { path: 'report/settlement-history', loadChildren: './report-settlement-history/report-settlement-history.module#ReportSettlementHistoryModule', data: { title: '7Exch - Home' } },
  { path: 'report/m/settlement-history', loadChildren: './report-settlement-history-mobile/report-settlement-history-mobile.module#ReportSettlementHistoryMobileModule', data: { title: '7Exch - Home' } },

  { path: 'report/user-general-setting', loadChildren: './report-user-general-setting/report-user-general-setting.module#ReportUserGeneralSettingModule', data: { title: '7Exch - Home' } },

  { path: 'report/gainers', loadChildren: './top-gainers/top-gainers.module#TopGainersModule', data: { title: '7Exch - Home' } },
  { path: 'report/losers', loadChildren: './top-losers/top-losers.module#TopLosersModule', data: { title: '7Exch - Home' } },

  { path: 'stream/data', loadChildren: './livetvstreamsadmin/livetvstreamsadmin.module#LivetvstreamsadminModule', data: { title: '7Exch - Home' } },

  { path: 'manage/payments/deposit', loadChildren: './manage-payment-deposite/manage-payment-deposite.module#ManagePaymentDepositeModule', data: { title: '7Exch - Home' } },
  { path: 'manage/payments/withdraw', loadChildren: './manage-payment-withdraw/manage-payment-withdraw.module#ManagePaymentWithdrawModule', data: { title: '7Exch - Home' } },
  { path: 'manage/payments/deposit-pending', component: ManageDepositPendingComponent, data: { title: '7Exch - Home' } },
  { path: 'manage/manual/payments/deposit', loadChildren: './manage-manual-payment-deposite/manage-manual-payment-deposite.module#ManageManualPaymentDepositeModule', data: { title: '7Exch - Home' } },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }