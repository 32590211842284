<div class="row" *ngIf="__USERTYPE != 3">
    <div class="col-md-12 col-xs-12">
        <div class="box box-default box-solid bgwhite">
            <div class="box-header with-border ReportsClass" *ngIf="!isMobileView">
                Pending Deposits
                <select (change)="changePageSizeList(__PAGE_SIZE)" [(ngModel)]="__PAGE_SIZE" style="width: 100px; display: inline-block;" required class="form-control input-sm borderRound-10">
                    <option value="10" selected="selected">10 Entries</option>
                    <option value="50">50 Entries</option>
                    <option value="100">100 Entries</option>
                </select>
                <!-- <div class="box-tools pull-right">
                    <button class="btn btn-success action-button ShowAllClientButton" (click)="showAllClients()">Show All List</button>
                    <input type="text" name="SearchClient"[(ngModel)]="__SEARCH_KEYWORD" placeholder="Enter Name" class="form-control border-radious-5 fs-12 SearchClientTextArea">
                    <button class="btn btn-success action-button" (click)="searchClientListing()"><i class="fa fa-search fs-16"></i></button>
                </div> -->
            </div>
            <div class="box-header with-border ReportsClass" *ngIf="isMobileView">
                Pending Deposits
                <select (change)="changePageSizeList(__PAGE_SIZE)" [(ngModel)]="__PAGE_SIZE" style="width: 100px; display: inline-block;" required class="form-control input-sm borderRound-10">
                    <option value="10" selected="selected">10 Entries</option>
                    <option value="50">50 Entries</option>
                    <option value="100">100 Entries</option>
                </select>
            </div>
            <!-- <div class="box-header with-border ReportsClass" *ngIf="isMobileView">
                <button class="btn btn-success action-button m-r-5" (click)="showAllClients()" style="display: inline-block;">Show All List</button>
                <input type="text" name="SearchClient"[(ngModel)]="__SEARCH_KEYWORD" style="display: inline-block; width: 170px;" placeholder="Enter Name" class="form-control border-radious-5 fs-12">
                <button class="btn btn-success action-button" style="display: inline-block;" (click)="searchClientListing()"><i class="fa fa-search fs-16"></i></button>
            </div> -->

            <div id="collapseMatchOddsTab">
                <div class="row padding5">
                    <div class="col-md-9 col-xs-12">
                        <section class="sc-BOulX kkZGZK m-b-5">
                            <div class="sc-fqCOlO JYTwG">
                                <span class="sc-dhVevo dNYyDj">Select Date :</span>
                                <div class="DayPickerInput">
                                    <input type="date" [(ngModel)]="__STARTDATE" placeholder="YYYY/MM/dd">
                                </div>
                            </div>
                            <div class="sc-fqCOlO JYTwG">
                                <span class="sc-dhVevo dNYyDj">To</span>
                                <div class="DayPickerInput">
                                    <input type="date" [(ngModel)]="__ENDDATE" placeholder="YYYY/MM/dd">
                                </div>
                            </div>

                            <button (click)="getDepositPendingAdmin()" class="sc-dchYKM hGlYAS">
                                <div name="magnifier-white" class="sc-bdVaJa eYojSI">
                                    <img src="/images/magnifier-white.png">
                                </div>Search</button>
                            
                        </section>
                    </div>
                    <div class="col-md-3 col-xs-12">
                        <div class="row" *ngIf="(IS_ACCESS && __USERTYPE == 4) || __USERTYPE != 4">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="info-box info-box-rj">
                                    <span class="info-box-icon info-box-icon-rj bg-aqua"><i class="fa fa-rupee"></i></span>
                                    <div class="info-box-content info-box-content-rj">
                                        <span class="info-box-text">Total Pending Deposit</span>
                                        <span class="info-box-number">{{__TOTAL_AMOUNT | number:'1.2-2'}} /-</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="demo" class="box-body padding5" style="overflow-x: auto;">
                    <!-- <section class="sc-eklfrZ hQHOa">
                        <div class="sc-fYAFcb ifNYf">Total Amount :
                            <span class="sc-clNaTc htTnxO" *ngIf="__TOTAL_AMOUNT >= 0">{{__TOTAL_AMOUNT | number:'1.2-2'}}</span>
                            <span class="sc-clNaTc ksWbmr" *ngIf="__TOTAL_AMOUNT < 0">{{__TOTAL_AMOUNT | number:'1.2-2'}}</span>
                        </div>
                    </section> -->
                    <div id="__LOADERLIST" class="overlayLoaderPopup" style="height: 0%;">
                        <div class="overlayLoaderPopup-content" style="color: #ff8282;">
                            <img src="/images/LoginLoaderGIF.gif" width="50px;" />
                        </div>
                    </div>
                    <table class="table table-hover fs-12">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Date Time</th>
                                <th>Name</th>
                                <th>Mobile</th>
                                <th>Transaction ID</th>
                                <th>Balance</th>
                                <th>Requested Amount</th>
                                <th>Payment Using</th>
                                <!-- <th>Remark</th> -->
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="__REQUEST_LIST_LENGTH > 0">
                            <tr *ngFor="let data of __REQUEST_LIST; let i = index;">
                                <td class="text-left">{{__SHOWING_FROM + i}}</td>
                                <td class="text-left">{{data.stDate | date:'dd-MM-yy h:mm:ssa'}}</td>
                                <td class="text-left">{{data.UserName}}</td>
                                <td class="text-left color-blue"><b><a target="blank" href="https://wa.me/+916363829642">+91 6363829642</a></b></td>
                                <td class="text-left">{{data.tid}}</td>
                                <td class="text-left">{{data.balance}}</td>
                                <td class="text-left">{{data.reqAmount}}</td>
                                <td class="text-left">
                                    <span *ngIf="data.paymentMethod == 0">Google Pay</span>
                                    <span *ngIf="data.paymentMethod == 1">PhonePe</span>
                                    <span *ngIf="data.paymentMethod == 2">Paytm</span>
                                    <span *ngIf="data.paymentMethod == 191">Crypto</span>
                                </td>
                                <!-- <td class="text-left">{{(data.status != 0 ? data.remark : '-')}}</td> -->
                                <td class="text-left">
                                    <!-- <span *ngIf="data.status == 0">Canceled</span>
                                    <span *ngIf="data.status == 1">Rejected</span>
                                    <span *ngIf="data.status == 2">Completed</span> -->
                                    <span id="txtStatus_{{data.tid}}">PENDING</span>
                                    <button *ngIf="data.paymentMethod != 191 && ((IS_ACCESS && __USERTYPE == 4) || __USERTYPE != 4)" id="btnStatus_{{data.tid}}" type="button" class="btn btn-sm btn-primary m-l-5" (click)="checkPendingStatus(data.tid)">CHECK STATUS</button>
                                </td>
                                <td>
                                    <span *ngIf="data.status == 0" class="dropdown">
                                        <a class="dropdown-toggle btn btn-danger padding1-6 fs-12" data-toggle="dropdown" href="#">
                                        Action <span class="caret"></span>
                                    </a>
                                    <ul class="dropdown-menu fs-12 margin0 padding0" style="left: auto; right: 0!important; box-shadow: 0 0 5px 0px;">
                                        <li (click)="onTakeActionCompleted(data.inUserID, data.inParentID, data.tid, data.reqAmount, 0, data.paymentMethod)" role="presentation" class="Drop-down-border hoverPointer"><a role="menuitem" tabindex="-1">Requested</a></li>
                                        <li data-toggle="modal" data-target="#__TAKEACTIONMODAL" (click)="onOpenActionModal(data.inUserID, data.inParentID, data.tid, data.reqAmount, 1, data.paymentMethod)" role="presentation" class="Drop-down-border hoverPointer"><a role="menuitem" tabindex="-1">Reject</a></li>
                                        <li data-toggle="modal" data-target="#__TAKEACTIONMODAL" (click)="onOpenActionModal(data.inUserID, data.inParentID, data.tid, data.reqAmount, 2, data.paymentMethod)" role="presentation" class="Drop-down-border hoverPointer"><a role="menuitem" tabindex="-1">Completed</a></li>
                                    </ul>
                                    </span>
                                    <span *ngIf="data.status != 0" class="dropdown">
                                        -
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="__REQUEST_LIST_LENGTH == 0">
                            <tr>
                                <td colspan="9">No data Found</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="row">
                        <div class="col-md-12 col-xs-12">
                            <font class="fs-11 m-l-5">Showing {{__SHOWING_FROM}} to {{__SHOWING_TO}} of {{__TOTAL_ENTRIES}} entries</font>
                            <div class="box-tools pull-right" *ngIf="__TOTAL_ENTRIES > 0">
                                <ul class="pagination margin0 m-r-5">
                                    <li (click)="onDirectButton(1)" *ngIf="__PAGE_NUMBER != 1" [class]="__CLASSNORMAL"><a>First</a></li>
                                    <li (click)="onPreviousButton()" *ngIf="__PAGE_NUMBER != 1" [class]="__CLASSNORMAL"><a>&laquo;</a></li>
                                    <ng-container *ngFor="let item of __DISPLAYPAGE; let i = index;">
                                        <li (click)="onDirectButton(item)" [class]="__PAGE_NUMBER == (item) ? __CLASSACTIVE : __CLASSNORMAL"><a>{{item}}</a></li>
                                    </ng-container>
                                    <li (click)="onNextButton()" *ngIf="__PAGE_NUMBER != __TOTAL_PAGES" [class]="__CLASSNORMAL"><a>&raquo;</a></li>
                                    <li (click)="onDirectButton(__TOTAL_PAGES)" *ngIf="__PAGE_NUMBER != __TOTAL_PAGES" [class]="__CLASSNORMAL"><a>Last</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="__TAKEACTIONMODAL" role="dialog">
                        <div class="modal-dialog" style="width: 30%;">

                            <div class="modal-content">
                                <div class="modal-header popupColor">
                                    <button type="button" class="close popupClose" data-dismiss="modal">&times;</button>
                                    <h4 class="modal-title">
                                        Add Remark for {{__ACTION_NAME}}
                                    </h4>
                                </div>
                                <div class="modal-body padding0">
                                    <form role="form" method="post" name="createClient">

                                        <div class="form-group col-sm-12 m-b-0" style="background-color: white!important;">
                                            <label class="fs-12">Enter Remark</label>
                                            <input name="__ACTION_REMARK" id="__ACTION_REMARK" [(ngModel)]="__ACTION_REMARK" required type="text" class="form-control border-radious-5 fs-12" />
                                        </div>

                                        <div class="col-md-12 col-xs-6 modal-footer" style="background-color: white!important;">
                                            <button type="button" data-dismiss="modal" class="btn btn-danger" id="__ADD_REMARK_BTN" (click)="onActionClick()">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>