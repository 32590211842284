import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { CommonlistService } from '../services/commonlist.service';

@Injectable({
  providedIn: 'root'
})
export class CryptojsService {
  PAGINATION =
    [
      {
        NAME: "10 Entries",
        VALUE: 10
      },
      {
        NAME: "25 Entries",
        VALUE: 25
      },
      {
        NAME: "50 Entries",
        VALUE: 50
      },
      {
        NAME: "100 Entries",
        VALUE: 100
      },
      {
        NAME: "500 Entries",
        VALUE: 500
      },
      {
        NAME: "1000 Entries",
        VALUE: 1000
      }
    ]

  __LOTUS_GAMES = [
    {
      GID: 56767,
      NAME: "Live Teenpatti",
      VIRTUAL: false,
      IMG: "images/HOTSPORTS/ads/ximg32149.jpeg"
    },
    {
      GID: 56768,
      NAME: "Teenpatti T20",
      VIRTUAL: false,
      IMG: "images/HOTSPORTS/ads/ximg45866.jpeg"
    },
    {
      GID: 87564,
      NAME: "Andar Bahar",
      VIRTUAL: false,
      IMG: "images/HOTSPORTS/ads/ximg32255.jpeg"
    },
    {
      GID: 67564,
      NAME: "Poker",
      VIRTUAL: false,
      IMG: "images/HOTSPORTS/ads/ximg32211.jpeg"
    },
    {
      GID: 98790,
      NAME: "Dragon Tiger",
      VIRTUAL: false,
      IMG: "images/HOTSPORTS/ads/ximg32169.jpeg"
    },
    {
      GID: 98789,
      NAME: "7 Up & Down",
      VIRTUAL: false,
      IMG: "images/HOTSPORTS/ads/ximg32151.jpeg"
    },
    {
      GID: 56967,
      NAME: "32 Card Casino",
      VIRTUAL: false,
      IMG: "images/HOTSPORTS/ads/ximg56265.jpeg"
    },
    {
      GID: 98791,
      NAME: "Amar Akbar Anthony",
      VIRTUAL: false,
      IMG: "images/HOTSPORTS/ads/ximg56271.jpeg"
    },
    {
      GID: 98788,
      NAME: "Roulette",
      VIRTUAL: false,
      IMG: "images/HOTSPORTS/ads/roulette-icon.jpg"
    },
    {
      GID: 98566,
      NAME: "SicBO",
      VIRTUAL: false,
      IMG: "images/HOTSPORTS/ads/SicBOIcon.jpg"
    },
    {
      GID: 67600,
      NAME: "Teenpatti Muflis",
      VIRTUAL: false,
      IMG: "images/HOTSPORTS/ads/TeenpattiMuflisIcon.jpg"
    },
    {
      GID: 92038,
      NAME: "Baccarat",
      VIRTUAL: false,
      IMG: "images/HOTSPORTS/ads/baccarat-banner-icon.jpg"
    },
    {
      GID: 67567,
      NAME: "Poker 2020",
      VIRTUAL: false,
      IMG: "images/HOTSPORTS/ads/Poker2020Icon.jpg"
    },
    {
      GID: 67660,
      NAME: "Teenpatti 2 Cards",
      VIRTUAL: false,
      IMG: "images/HOTSPORTS/ads/Teenpatti2cards.jpg"
    },
    {
      GID: 67620,
      NAME: "Queen Race",
      VIRTUAL: false,
      IMG: "images/HOTSPORTS/ads/QueenRace.jpg"
    },
    {
      GID: 67630,
      NAME: "TeenPatti Test",
      VIRTUAL: false,
      IMG: "images/HOTSPORTS/ads/TeenPattiTest.jpg"
    },
    /* VIRTUAL */
    // {
    //   GID : 67566,
    //   NAME : "6 Player Poker",
    //   VIRTUAL : true,
    //   IMG : "images/HOTSPORTS/ads/ximg49921.jpeg"
    // },
    // {
    //   GID : 56766,
    //   NAME : "1 Day TeenPatti",
    //   VIRTUAL : true,
    //   IMG : "images/HOTSPORTS/ads/Teenpatti.png"
    // },
    // {
    //   GID : 87565,
    //   NAME : "Andar Bahar",
    //   VIRTUAL : true,
    //   IMG : "images/HOTSPORTS/ads/ximg50402.jpeg"
    // },
    // {
    //   GID : 56769,
    //   NAME : "TeenPatti 2020",
    //   VIRTUAL : true,
    //   IMG : "images/HOTSPORTS/ads/ximg50403.jpeg"
    // },
    // {
    //   GID : 56969,
    //   NAME : "High Low",
    //   VIRTUAL : true,
    //   IMG : "images/HOTSPORTS/ads/ximg50404.jpeg"
    // },
    // {
    //   GID : 67563,
    //   NAME : "Poker",
    //   VIRTUAL : true,
    //   IMG : "images/HOTSPORTS/ads/ximg50442.jpeg"
    // },
    // {
    //   GID : 92036,
    //   NAME : "Matka",
    //   VIRTUAL : true,
    //   IMG : "images/HOTSPORTS/ads/ximg50443.jpeg"
    // },
    // {
    //   GID : 98793,
    //   NAME : "7 Up & Down",
    //   VIRTUAL : true,
    //   IMG : "images/HOTSPORTS/ads/ximg50444.jpeg"
    // },
    // {
    //   GID : 56966,
    //   NAME : "32 Cards Casino",
    //   VIRTUAL : true,
    //   IMG : "images/HOTSPORTS/ads/ximg50445.jpeg"
    // }
  ]

  __EZUGI_GAMES = [
    {
      GID: 228002,
      NAME: "32 Cards",
      IMG: "./images/ezugi/32 Cards - 400x200.png"
    },
    {
      GID: 228000,
      NAME: "Andar Bahar",
      IMG: "./images/ezugi/Andar Bahar - 400x200.png"
    },
    {
      GID: 100,
      NAME: "Baccarat",
      IMG: "./images/ezugi/Baccarat - 400x200.png"
    },
    {
      GID: 602000,
      NAME: "Bet On Numbers",
      IMG: "./images/ezugi/BON - 400x200.png"
    },
    {
      GID: 227101,
      NAME: "Bet on TeenPatti",
      IMG: "./images/ezugi/Bet on Teen Patti_400x200.png"
    },
    {
      GID: 51,
      NAME: "Blackjack",
      IMG: "./images/ezugi/Blackjack - 400x200.png"
    },
    {
      GID: 507000,
      NAME: "Casino Holdem",
      IMG: "./images/ezugi/Casino Holdem - 400x200.png"
    },
    {
      GID: 45100,
      NAME: "Cricket War",
      IMG: "./images/ezugi/Cricket War - 400x200.png"
    },
    {
      GID: 150,
      NAME: "Dragon Tiger",
      IMG: "./images/ezugi/Dragon Tiger - 400x200.png"
    },
    {
      GID: 228001,
      NAME: "Lucky 7",
      IMG: "./images/ezugi/Lucky 7 - 400x200.png"
    },
    {
      GID: 227103,
      NAME: "One Day Teenpatti",
      IMG: "./images/ezugi/ODTPC_400x200.png"
    },
    {
      GID: 224000,
      NAME: "Ultimate Sic Bo",
      IMG: "./images/ezugi/Ultimate Sic Bo_400x200.png"
    },
    {
      GID: 7100,
      NAME: "Teen Patti",
      IMG: "./images/ezugi/Teen Patti_400x200.png"
    },
    {
      GID: 5001,
      NAME: "Auto Roulette",
      IMG: "./images/ezugi/Auto Roulette_500x160.png"
    },
    {
      GID: 221002,
      NAME: "Auto Speed Roulette",
      IMG: "./images/ezugi/Auto Speed Roulette - 220 x 145.png"
    },
    {
      GID: 205,
      NAME: "Blackjack Bucuresti",
      IMG: "./images/ezugi/400x200_BJ_ro.png"
    },
    {
      GID: 204,
      NAME: "Blackjack Gold",
      IMG: "./images/ezugi/Blackjack Gold - 400x200.png"
    },
    {
      GID: 227,
      NAME: "Blackjack Salon Prive",
      IMG: "./images/ezugi/400x200_BJ_SALON.png"
    },
    {
      GID: 101,
      NAME: "Baccarat Cricket",
      IMG: "./images/ezugi/baccarat cricket - 220x145.png"
    },
    {
      GID: 221003,
      NAME: "Diamond Roulette",
      IMG: "./images/ezugi/Diamond Roulette.png"
    },
    {
      GID: 1000,
      NAME: "Italian Roulette",
      IMG: "./images/ezugi/Italian Roulette - 400x200.png"
    },
    {
      GID: 221005,
      NAME: "Namaste Roulette",
      IMG: "./images/ezugi/Namaste Roulette - 400x200.png"
    },
    {
      GID: 501000,
      NAME: "Turkce Roulette",
      IMG: "./images/ezugi/Turkce Roulette - 400x200.png"
    },
    {
      GID: 102,
      NAME: "Fortune Baccarat",
      IMG: "./images/ezugi/FortuneBaccarat.jpg"
    },
    {
      GID: 43100,
      NAME: "Fiesta Baccarat",
      IMG: "./images/ezugi/Baccarat_Fiesta.jpg"
    },
    {
      GID: 120,
      NAME: "KnockOut Baccarat",
      IMG: "./images/ezugi/BaccaratKnockOut.jpg"
    },
    {
      GID: 225,
      NAME: "Diamond VIP Blackjack",
      IMG: "./images/ezugi/Blakjack_DV.jpg"
    },
    {
      GID: 224,
      NAME: "VIP Blackjack With Surrender",
      IMG: "./images/ezugi/Blackjack_With_S.jpg"
    },
    {
      GID: 227103,
      NAME: "One Day TeenPatti",
      IMG: "./images/ezugi/OneDayTeenPatti.jpg"
    },
    {
      GID: 601000,
      NAME: "Ruletka Russia",
      IMG: "./images/ezugi/RuletkaRussia.jpg"
    },
    {
      GID: 431000,
      NAME: "Ruletka Del Sol",
      IMG: "./images/ezugi/RouletteDelSol.png"
    },
    {
      GID: 328000,
      NAME: "OTT Andar Bahar",
      IMG: "./images/ezugi/OTT_Andar_Bahar.jpg"
    },
    {
      GID: 170,
      NAME: "No Commision Baccarat",
      IMG: "./images/ezugi/Bacarrat_no_Camission.jpg"
    },
    {
      GID: 130,
      NAME: "Baccarat Super Six",
      IMG: "./images/ezugi/BaccaratSuper6.jpg"
    },
    {
      GID: 227100,
      NAME: "Teen Patti 3 Card",
      IMG: "./images/ezugi/Teenpatti3Cards.jpg"
    },
    {
      GID: 425,
      NAME: "White Russia Blackjack",
      IMG: "./images/ezugi/Blackjack_Wihte_Russian.jpg"
    },
    {
      GID: 4151,
      NAME: "Blackjack Mambo",
      IMG: "./images/ezugi/Blackjack_Mambo.jpg"
    },
    {
      GID: 221004,
      NAME: "Prestige Auto Roulette",
      IMG: "./images/ezugi/Prestige Auto Roulette - 400x200.png"
    },
    {
      GID: 611006,
      NAME: "Oracle Blaze Roulette",
      IMG: "./images/ezugi/OracleRulet.jpg"
    },
    {
      GID: 611004,
      NAME: "Portomaso Real Casino Roulett",
      IMG: "./images/ezugi/PortomasoCasino.jpg"
    },
    {
      GID: 426,
      NAME: "Black Russian Blackjack",
      IMG: "./images/ezugi/BJ_BLACK_RUSSIAN.jpg"
    }
  ]

  __EVOLUTION_GAMES = [
    {
      GID: 1000012,
      NAME: "Baccarat",
      IMG: "./images/ezugi/Baccarat - 400x200.png"
    },
    {
      GID: 1000030,
      NAME: "Blackjack",
      IMG: "./images/ezugi/Blackjack - 400x200.png"
    },
    {
      GID: 1000075,
      NAME: "Casino Holdem",
      IMG: "./images/ezugi/CasinoHoldemByEvolution.jpg"
    },
    {
      GID: 1000074,
      NAME: "Dragon Tiger",
      IMG: "./images/ezugi/Dragon Tiger - 400x200.png"
    },
    {
      GID: 1000110,
      NAME: "Ultimate Sic Bo",
      IMG: "./images/ezugi/Ultimate Sic Bo_400x200.png"
    },
    {
      GID: 1000123,
      NAME: "Auto Roulette",
      IMG: "./images/ezugi/Auto Roulette_500x160.png"
    },
    {
      GID: 1000103,
      NAME: "Auto Speed Roulette",
      IMG: "./images/ezugi/Auto Speed Roulette - 220 x 145.png"
    },
    {
      GID: 1000068,
      NAME: "Blackjack Salon Prive",
      IMG: "./images/ezugi/400x200_BJ_SALON.png"
    },
    {
      GID: 1000077,
      NAME: "Dream Catcher",
      IMG: "./images/ezugi/dream_catcher.jpg"
    },
    {
      GID: 1000352,
      NAME: "Cash or Crash",
      IMG: "./images/ezugi/cash-or-crash.jpg"
    },
    {
      GID: 1000148,
      NAME: "Crazy Time",
      IMG: "./images/ezugi/crazy-time-casino.jpg"
    },
    {
      GID: 1000092,
      NAME: "Lightning Roulette",
      IMG: "./images/ezugi/lightning_roulette.jpg"
    },
    {
      GID: 1000076,
      NAME: "Lightning Dice",
      IMG: "./images/ezugi/evolution_lightning_dice.jpg"
    },
    {
      GID: 1000112,
      NAME: "Football studio",
      IMG: "./images/ezugi/football-studio-screen.jpg"
    },
    {
      GID: 1000141,
      NAME: "Mega Ball",
      IMG: "./images/ezugi/Mega-Ball-Live-Casino.jpg"
    },
    {
      GID: 1100022,
      NAME: "Gonzos Treasure Hunt",
      IMG: "./images/ezugi/Gonzos-Treasure-Hunt.jpg"
    },
    {
      GID: 1000073,
      NAME: "2 Hand Casino Holdem",
      IMG: "./images/ezugi/2-hand-casino-hold-em.jpg"
    }
  ]

  __PAGESIZE = 10;
  __PAGESIZE_CLIENT_LISTING = 10;
  constructor(private CommonlistService: CommonlistService) { }

  KeyIV = ["!", "$", "@", "^", "#", "*", "~"];
  KEYVI(e, r) {
    const k1 = []; const k2 = []; let t = (r * Math.random() * r).toString(); e = e.filter(it => { return this.KeyIV.indexOf(it) < 0; }); for (var w = e.length - 1; w >= 0; w--) { if (e[0] == 'e' && e[1] == 'v') { if (w >= e.length / 2) k1.push(e[w]); else k2.push(e[w]); } else if (e[0] == '3' && e[1] == 'D' && e[e.length - 2] == 'w' && e[e.length - 1] == '5') { if (w >= e.length / 2) k1.push(e[w]); else k2.push(e[w]); } else if (e[1] == 'z' && e[0] == 'M' && e[e.length - 2] == '3' && e[e.length - 1] == 'L') { if (w >= e.length / 2) k1.push(e[w]); else k2.push(e[w]); } else if (e[0] == 'y' && e[1] == 'L' && e[e.length - 2] == 't' && e[e.length - 1] == 'e') { if (w >= e.length / 2) k1.push(e[w]); else k2.push(e[w]); } else if (e[1] == 'n' && e[0] == 'S' && e[e.length - 2] == 'r' && e[e.length - 1] == '7') { if (w >= e.length / 2) k1.push(e[w]); else k2.push(e[w]); } else if (e[0] == 'K' && e[1] == 'm' && e[e.length - 2] == 'B' && e[e.length - 1] == 'n') { if (w >= e.length / 2) k1.push(e[w]); else k2.push(e[w]); } r++; if (w > 0) { t += t; } else { t = k1.join("") + "" + k2.reverse().join(""); } } return t.substring(2, e.length / 2) + "" + t.substring((e.length / 2) + 2);
  }

  encryptOrderFunction(OrderInformation) {
    var key = CryptoJS.enc.Utf8.parse(this.KEYVI(this.CommonlistService.DecryptKKey, 0));
    var iv = CryptoJS.enc.Utf8.parse(this.KEYVI(this.CommonlistService.DecryptKKey, 0));

    var encrypt = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(OrderInformation), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    return encrypt;
  }

  DecryptOrderFunction(cipherText) {
    var key = CryptoJS.enc.Utf8.parse(this.KEYVI(this.CommonlistService.DecryptKKey, 0));
    var iv = CryptoJS.enc.Utf8.parse(this.KEYVI(this.CommonlistService.DecryptKKey, 0));

    var encrypt = CryptoJS.AES.decrypt(cipherText, key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    // console.log(encrypt.toString(CryptoJS.enc.Utf8));
    return encrypt.toString(CryptoJS.enc.Utf8);
  }

  encryptUserFunction(OrderInformation) {
    var key = CryptoJS.enc.Utf8.parse(this.KEYVI(this.CommonlistService.DecryptUserKey, parseInt(this.decryptData("U2FsdGVkX1+RjstrQVrRqCYGla0R1aXvj62qqT3ZWIA="))));
    var iv = CryptoJS.enc.Utf8.parse(this.KEYVI(this.CommonlistService.DecryptUserKey, parseInt(this.decryptData("U2FsdGVkX1+RjstrQVrRqCYGla0R1aXvj62qqT3ZWIA="))));

    var encrypt = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(OrderInformation), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    return encrypt;
  }

  DecryptUserFunction(cipherText) {
    var key = CryptoJS.enc.Utf8.parse(this.KEYVI(this.CommonlistService.DecryptUserKey, parseInt(this.decryptData("U2FsdGVkX1+RjstrQVrRqCYGla0R1aXvj62qqT3ZWIA="))));
    var iv = CryptoJS.enc.Utf8.parse(this.KEYVI(this.CommonlistService.DecryptUserKey, parseInt(this.decryptData("U2FsdGVkX1+RjstrQVrRqCYGla0R1aXvj62qqT3ZWIA="))));

    var encrypt = CryptoJS.AES.decrypt(cipherText, key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    // console.log(encrypt.toString(CryptoJS.enc.Utf8));
    return encrypt.toString(CryptoJS.enc.Utf8);
  }

  DecryptOrderFunctionU(cipherText, ciph) {
    var key = CryptoJS.enc.Utf8.parse(this.KEYVI(this.CommonlistService.DecOKey, parseInt(this.decryptData(ciph))));
    var iv = CryptoJS.enc.Utf8.parse(this.KEYVI(this.CommonlistService.DecOKey, parseInt(this.decryptData(ciph))));

    var encrypt = CryptoJS.AES.decrypt(cipherText, key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    // console.log(encrypt.toString(CryptoJS.enc.Utf8));
    return encrypt.toString(CryptoJS.enc.Utf8);
  }

  encryptData(data) {
    try {
      if (data != null || data != "")
        return CryptoJS.AES.encrypt(JSON.stringify(data), this.KEYVI(this.CommonlistService.DecryptKKey, 0)).toString();
      else
        return "";
    }
    catch (e) {
      console.log(e);
    }
  }

  encryptDataURL(data) {
    try {
      return CryptoJS.AES.encrypt(data, this.KEYVI(this.CommonlistService.DecryptKKey, 0)).toString();
    }
    catch (e) {
      console.log(e);
    }
  }

  encryptSocketFunction(OrderInformation) {
    var key = CryptoJS.enc.Utf8.parse(this.KEYVI(this.CommonlistService.DecryptSocket, 2));
    var iv = CryptoJS.enc.Utf8.parse(this.KEYVI(this.CommonlistService.DecryptSocket, 2));

    var encrypt = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(OrderInformation), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    return encrypt;
  }

  decryptSocketFunction(cipherText) {
    var key = CryptoJS.enc.Utf8.parse(this.KEYVI(this.CommonlistService.DecryptSocket, 2));
    var iv = CryptoJS.enc.Utf8.parse(this.KEYVI(this.CommonlistService.DecryptSocket, 2));

    var encrypt = CryptoJS.AES.decrypt(cipherText, key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    return encrypt.toString(CryptoJS.enc.Utf8);
  }

  decryptData(data) {
    try {
      if (data != null && data != "") {
        const bytes = CryptoJS.AES.decrypt(data, this.KEYVI(this.CommonlistService.DecryptKKey, 0));
        if (bytes.toString()) {
          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
        return data;
      }
      else
        return "";
    }
    catch (e) {
      console.log(e);
    }
  }
  
  decryptAES256(password, key, iv) {
    let _key = CryptoJS.enc.Utf8.parse(key);
    let _iv = CryptoJS.enc.Utf8.parse(iv);
    let decrypted = CryptoJS.AES.decrypt(
      password, _key,
      {
        iv: _iv,
        format: CryptoJS.format.Hex,
        mode: CryptoJS.mode.CTR,
        padding: CryptoJS.pad.NoPadding
      }).toString(CryptoJS.enc.Utf8);
    return decrypted;
  }

  encryptAES256(password, key, iv) {
    let _key = CryptoJS.enc.Utf8.parse(key);
    let _iv = CryptoJS.enc.Utf8.parse(iv);
    let encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(password), _key,
      {
        iv: _iv,
        format: CryptoJS.format.Hex,
        mode: CryptoJS.mode.CTR,
        padding: CryptoJS.pad.NoPadding
      }).toString();
    return encrypted;
  }
  
  decryptDataURL(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, this.KEYVI(this.CommonlistService.DecryptKKey, 0));
      if (bytes.toString()) {
        return bytes.toString(CryptoJS.enc.Utf8);
      }
      return data;
    }
    catch (e) {
      console.log(e);
    }
  }
}
