import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChipsinoutService } from '../services/chipsinout.service';
import { AppComponent } from '../app.component';
import { ButtonstackrangeService } from '../services/buttonstackrange.service';
import { CheckmobileService } from '../services/checkmobile.service';
import { SignalRService } from '../services/signal-r.service';
import { UserdetailsService } from '../services/userdetails.service';
import { CryptojsService } from '../services/cryptojs.service';
import { Subscription } from 'rxjs';
import { LoginService } from '../services/login.service';
import { RjNotifierService } from '../services/rj-notifier.service';
import { WebsocketService } from '../services/websocket.service';

@Component({
  selector: 'app-headermenu-mobile',
  templateUrl: './headermenu-mobile.component.html',
  styleUrls: ['./headermenu-mobile.component.css']
})
export class HeadermenuMobileComponent
{
  // Session_User = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
  userTypeShow = 3;
  UserType_Check : any;
  CurrentBalance_Temp : any;
  MarqueeMessage = "Welcome...";
  CurrentMainBalance = 0;
  CurrentOwnChips = 0;
  CurrentCreditChips = 0;
  CurrentExplosure = 0;
  CurrentRisk = 0;
  CurrentPL = 0;
  UserName_DB : any;
  UserID_DB : any;
  UserType = 0;

  ButtonValues : any;
  button0 : any;
  button1 : any;
  button2 : any;
  button3 : any;
  button4 : any;
  button5 : any;

  CurrentMarketID : any;
  CurrentMarketName : any;

  isMobileView = false;

  // CHANGE PASSWORD
  NewPassword = "";
  ConfirmPassword = "";

  isBetAllow = false;

  PageSizeForAll = 200;
  CurrentDatePageSizeForAll = 5000;
 
  isEnterTableView = 0;
  private __ALLINONESUBSCRIPTION : Subscription = new Subscription();

  constructor(private RjNotifierService : RjNotifierService, private SignalrService : WebsocketService, private LoginService : LoginService, private CryptojsService : CryptojsService, private UserdetailsService : UserdetailsService, private SignalRService : SignalRService, private CheckmobileService : CheckmobileService, private ButtonstackrangeService : ButtonstackrangeService, private AppComponent : AppComponent, private ChipsinoutService : ChipsinoutService, private router:Router)
  {
    this.isMobileView = this.CheckmobileService.isMobileView;

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnMatched().subscribe(
      (data) =>
      {
        if(this.UserType == 3)
        this.getCurrentBalance();
      })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalrService.getOnMessage().subscribe(
        (value) => {
          this.mH(value);
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnMatchedDiamond().subscribe(
      (data) =>
      {
        if(this.UserType == 3)
        this.getCurrentBalance();
      })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnLotusBalanceRefresh().subscribe(
      (data) =>
      {
        if(this.UserType == 3)
        this.getCurrentBalance();
      })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.LoginService.getAdminAccess().subscribe(
      (message) =>
      {
        this.checkAccess();
      })
    );
  } 

  
  IS_ACCESS = false;
  checkAccess()
  {
    if(sessionStorage.getItem('_ACCESS') === null)
    {
      this.IS_ACCESS = false;
    }
    else
      this.IS_ACCESS = true;
  }

  PREVIOUS_ORDER_NO = "";
  DELAY = 0;
  mH(msg) {
    let data: any;
    let dta: any;
    try {
      data = JSON.parse(msg.data);
      dta = this.CryptojsService.decryptSocketFunction(data.errormsg);
      if (data.errorcode == "0") {
        if (dta.split('=')[0].trim().toLowerCase() == 'delay') {
          this.DELAY = parseInt(dta.split('=')[1].trim());
        }
        else {
          try {
            let mdata = JSON.parse(dta);
            let mcode = mdata.pkHeader['shMessageCode'];
            if (mcode == 8000 || mcode == 0) {
              if (this.PREVIOUS_ORDER_NO != mdata.lnOrderNo) {
                this.PREVIOUS_ORDER_NO = mdata.lnOrderNo;

                // setTimeout(() => {
                this.getCurrentBalance();
                this.DELAY = 0;
                // }, this.DELAY);
              }
            }
          }
          catch { }
        }
      }
      else if (data.errorcode == "1") {
        if (dta == 0) {
          this.sendNotification('error', 'You will be disconnected');
          setTimeout(() => {
            this.Logout();
          }, 1000);
        }
      }
    }
    catch {
    }
  }
  
  __ISADDBALANCE = 0;
  ngOnInit()
  {
    if(sessionStorage.getItem('user') !== null)
    {
      // console.log("sessionStorage.user : " + sessionStorage.user);
      var Session_User = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
      this.UserName_DB = (Session_User.UserName.length > 7 ? Session_User.UserName.substr(0, 7) + "..." : Session_User.UserName);
      this.UserType =  Session_User.UserType;
      this.UserID_DB = Session_User.UserId;
      this.__ISADDBALANCE = Session_User.inDelay;

      if(this.UserType == 4)
      this.checkAccess();
      
      this.getCurrentBalance();
      
      switch (Session_User.UserType)
      {
        case 0:
          this.UserType_Check = "ADMIN";
          break;

        case 1:
          this.UserType_Check = "SUPER";
          break;

        case 2:
          this.UserType_Check = "MASTER";
          break;

        case 3:
          this.UserType_Check = "CLIENT";
          break;

        case 4:
          this.UserType_Check = "Hyper";
          break;

        case 5:
          this.UserType_Check = "SUB-SUPER-1";
          break;

        default:
          break;
      }
     
      this.isMobileView = this.CheckmobileService.isMobileView;
      this.ButtonstackrangeService.ngOnInit();
    }
    else
      this.Logout();

    this.checkStackButtons();
  }

  ngOnDestroy()
  {
    this.__ALLINONESUBSCRIPTION.unsubscribe();
  }

  onHomeClick()
  {
    this.SignalRService.changeOnHomeButtonClick(1);
  }
  
  onDynamicRouterClick(__ROUTER)
  {
    this.router.navigateByUrl('/RefreshComponent', {skipLocationChange: true}).then(()=>
    this.router.navigate([__ROUTER]));
  }
  onTransactionClick()
  {
    this.router.navigate(['/manage/payments/deposit']);
  }
  setServerMessage(data)
  {
    if(data != "")
      this.MarqueeMessage = data;
  }
  
  HideAllNotification()
  {
    this.RjNotifierService.hideNotifier();
  }

  sendNotification(Type, Message)
  {
    this.RjNotifierService.showNotifier(Type, Message);
  }

  ChangePassword()
  {
    if(this.NewPassword == "")
    {
      this.sendNotification('error', "Please enter New Password.");
    }
    else
    {
      if(this.NewPassword == this.ConfirmPassword)
      {
        this.UserdetailsService.changePasswordSelf(this.UserID_DB, this.NewPassword).subscribe(
        data1 =>
        {
          let data = this.CryptojsService.DecryptOrderFunction(data1);

          if(data != "1")
          {
            this.sendNotification("error", data);
            return false;
          }

          this.sendNotification('success', "Password has been changed Successfully.");
          this.ConfirmPassword = this.NewPassword = "";
          // console.log("Resp@Password : " + JSON.stringify(data));
        },
        error=>
        {
          // console.log("error@Password : " + JSON.stringify(error));
        });
      }
      else
      {
        this.sendNotification('error', "New and Confirm password must be same.");
      }
    }
  }

  getCurrentBalance()
  {
    this.ChipsinoutService.getMyCurrentBalance(this.UserID_DB).subscribe(
    data1 =>
    {
      let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));

      // console.log("Error@getCurrentBalance : " + JSON.stringify(data));
      this.CurrentBalance_Temp = data.deBalance;
      this.CurrentMainBalance = data.deBalance;
      // this.CurrentOwnChips = data.deChips;
      this.CurrentOwnChips = data.deBalance;
      this.CurrentCreditChips = data.deCreditChips;
      this.CurrentPL = this.CurrentOwnChips - this.CurrentCreditChips;
      this.CurrentExplosure = data.deLiability + data.deRisk;
      this.CurrentRisk = data.deRisk;
    },    
    error => 
    {
      // console.log("Error@getCurrentBalance : " + JSON.stringify(error) + " : " + error.StatusCode);
      return false;
    });
  }

  Logout() {
    // if(this.SignalRService.connection.state == 1)
    // this.SignalRService.BCast.invoke("LogoutClientMulti", this.UserID_DB).done(() => { });
    // else
    // {
    //   this.SignalRService.connection.start(this.SignalRService.Transport).done(() =>
    //   {
    //     this.SignalRService.BCast.invoke("LogoutClientMulti", this.UserID_DB).done(() => { });
    //   });
    // }
    
    this.LoginService.logoutAPI().subscribe(
    data =>
    {
      // console.log("Resp@logoutAPI : " + JSON.stringify(data));
      delete sessionStorage.user;
      this.AppComponent.checkLogin();
    },
    error =>
    {
      // console.log("Error@logoutAPI : " + error);
      delete sessionStorage.user;
      this.AppComponent.checkLogin();
    });

    if(!this.isMobileView)
      this.router.navigate(['/login'],); 
    else
      this.router.navigate(['/login_mobile'],);
  }

  checkStackButtons()
  {
    if(this.ButtonstackrangeService.ButtonValues == undefined)
    {
      setTimeout(() => {
        this.checkStackButtons();
      }, 100);
      return false;
    }
    this.getButtonStack();
  }
  
  getButtonStack()
  {
    this.ButtonValues = this.ButtonstackrangeService.ButtonValues;

    this.button0 = this.ButtonValues.split(',')[0];
    this.button1 = this.ButtonValues.split(',')[1];
    this.button2 = this.ButtonValues.split(',')[2];
    this.button3 = this.ButtonValues.split(',')[3];
    this.button4 = this.ButtonValues.split(',')[4];
    this.button5 = this.ButtonValues.split(',')[5];
  }

  SaveStacks()
  {
    this.ButtonstackrangeService.SaveButtonStackRange(this.button0, this.button1, this.button2, this.button3, this.button4, this.button5).subscribe(    
    data1 => 
    {
      let data = this.CryptojsService.DecryptOrderFunction(data1);
      if(data == null)
      {
        this.sendNotification("success", "Stacks has been saved Successfully.");
        this.ButtonstackrangeService.setButtonStackRange();
      }
      // console.log("Response@SaveButtonStackRange : " + data);
    },    
    error => 
    {    
      // console.log("error@SaveButtonStackRange : " + error);  
    }); 
  }
}
