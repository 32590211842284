import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatchtableService } from '../services/matchtable.service';
import { SignalRService } from '../services/signal-r.service';
import { CheckmobileService } from '../services/checkmobile.service';
import { SportsshowhideService } from '../services/sportsshowhide.service';
import { CryptojsService } from '../services/cryptojs.service';
import { LoginService } from '../services/login.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mainhomeview-mobile',
  templateUrl: './mainhomeview-mobile.component.html',
  styleUrls: ['./mainhomeview-mobile.component.css']
})
export class MainhomeviewMobileComponent {
  __SPORTSID: any;
  UserID: any;

  model: any = {

  };

  InPlayMatchList: any;
  InPlayMatchListLength: any;
  MainInPlayMatchList: any;
  isInPlayFancy = 0;
  isInPlayLine = 0;

  FavouriteMatchList: any;
  AllMatchList: any;

  FilteredSubMatchList = [];
  FilteredSubMatchListLength = 0;

  NewGameTimer: any;

  isMobileView = false;

  CricketShowStatus = false;
  TennisShowStatus = false;
  SoccerShowStatus = false;
  HorseRacingShowStatus = false;

  selectedWhichTab = -1;
  activeMain = "activeMain";
  activeTabPan = "active tab-pane";
  TabPan = "tab-pane";
  url: any

  __EVOLUTION = this.SignalRService.__EVOLUTION;
  __EZUGI = this.SignalRService.__EZUGI;
  __VIVOGAMING = this.SignalRService.__VIVOGAMING;
  __BETGAMES = this.SignalRService.__BETGAMES;

  private __ALLINONESUBSCRIPTION: Subscription = new Subscription();

  TotalLimitedMatch = 2;

  constructor(private activatedRoute: ActivatedRoute, private LoginService: LoginService, private CryptojsService: CryptojsService, private SportsshowhideService: SportsshowhideService, private CheckmobileService: CheckmobileService, private SignalRService: SignalRService, private router: Router, private MatchtableService: MatchtableService) {
    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnNewMaster().subscribe(
        (message) => {
          setTimeout(() => {
            this.refreshMarketList(message);
          }, 2000);
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnShowHideMatch().subscribe(
        (message) => {
          if (message.STATUS == true || message.STATUS == "D")
            this.showHideMatch(message.SID);
          else {
            // console.log(data);
            setTimeout(() => {
              this.getMatchListNew();
            }, 2000);
          }
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SignalRService.getOnShowHideSport().subscribe(
        (message) => {
          this.setShowHideSports(message.SID, message.PID, message.STATUS);
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.SportsshowhideService.getOnSportsShowHideShowStatus().subscribe(
        (message) => {
          this.getHidedSports();
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.MatchtableService.getOnInPlayMatches().subscribe(
        (message) => {
          this.checkInPlayMatches();
        })
    );

    this.__ALLINONESUBSCRIPTION.add(
      this.MatchtableService.getOnResetMatchList().subscribe(
        (message) => {
          this.getMatchListNew();
        })
    );
    this.__ALLINONESUBSCRIPTION.add(
      this.LoginService.getBenners().subscribe(
        (message) => {
          this.getBanners();
        })
    );
    this.getBanners();
  }

  __ACTIVE_S = "item active";
  __INACTIVE_S = "item";
  __BANNERS = [];
  getBanners() {
    this.__BANNERS = this.LoginService.__BANNERS;
  }

  goToUnauthorize() {
    this.router.navigate(["/Unauthorize"]);
  }

  CricketLoop = 0;
  TennisLoop = 0;
  SoccerLoop = 0;
  getMatchListWinner() {
    if (document.getElementById("__MATCHTABLELOADER1")) {
      document.getElementById("__MATCHTABLELOADER1").style.height = "100%";
    }
    if (this.MatchtableService.CurrentMatchList == undefined) {
      setTimeout(() => {
        this.getMatchListWinner();
      }, 100);
      return false;
    }

    setTimeout(() => {
      if (document.getElementById("__MATCHTABLELOADER1")) {
        document.getElementById("__MATCHTABLELOADER1").style.height = "0%";
      }
    }, 10);

    this.InPlayMatchList = this.MatchtableService.InPlayMatchList;
    this.InPlayMatchListLength = this.MatchtableService.InPlayMatchList.length;
    this.MainInPlayMatchList = this.MatchtableService.InPlayMatchList;

    this.AllMatchList = this.MatchtableService.CurrentMatchList;

    this.FilteredSubMatchList = this.InPlayMatchList.filter(({ stMarketName }) => {
      return stMarketName == "Winner"
    });

    this.FilteredSubMatchListLength = this.FilteredSubMatchList.length;

    if (this.SignalRService.__CURRENTODDS == undefined)
      return false;

    let __ODDSDATA = this.SignalRService.__CURRENTODDS;
    setTimeout(() => {
      // for (let i = 0; i < __ODDSDATA.length; i++) {
      //   this.setFrontRates(__ODDSDATA[i]);
      // }
    }, 10);
  }
  __PREVIOUSTAB = 2;
  getMatchList(__SPORTSID) {
    if (document.getElementById("__MATCHTABLELOADER1")) {
      document.getElementById("__MATCHTABLELOADER1").style.height = "100%";
    }
    if (this.MatchtableService.CurrentMatchList == undefined) {
      // if(document.getElementById("WaitingGameLoader"))
      // {
      //   document.getElementById("WaitingGameLoader").style.height = "100%";
      // }
      // if(document.getElementById("WaitingGames"))
      // {
      //   document.getElementById("WaitingGames").style.display = "none";
      // }
      setTimeout(() => {
        this.getMatchList(__SPORTSID);
      }, 100);
      return false;
    }

    this.FilteredSubMatchList = [];
    this.FilteredSubMatchListLength = 0;
    if (this.__FRONT_RATE_TIMER) {
      clearTimeout(this.__FRONT_RATE_TIMER);
      this.__FRONT_RATE_TIMER = null;
    }

    setTimeout(() => {
      if (document.getElementById("__MATCHTABLELOADER1")) {
        document.getElementById("__MATCHTABLELOADER1").style.height = "0%";
      }
    }, 10);

    if (document.getElementById("__INPLAYLIST_" + this.__PREVIOUSTAB)) {
      document.getElementById("__INPLAYLIST_" + this.__PREVIOUSTAB).className = "";
    }
    this.__PREVIOUSTAB = __SPORTSID;
    if (document.getElementById("__INPLAYLIST_" + __SPORTSID)) {
      document.getElementById("__INPLAYLIST_" + __SPORTSID).className = "active";
    }

    // this.MatchtableService.InPlayMatchList = this.MatchtableService.CurrentMatchList.filter(({ stMarketID, stSportsID, btMarketStatus, stMarketName }) => {
    //   return stMarketID != null && stMarketID.split(".")[0] == "1" && stSportsID != null && (((stSportsID != "7" && stSportsID != "4339") && (stMarketName == 'Winner' || stMarketName == 'Match Odds')) || (stSportsID == "7" || stSportsID == "4339"))
    // })
    this.InPlayMatchList = this.MatchtableService.InPlayMatchList;
    this.InPlayMatchListLength = this.MatchtableService.InPlayMatchList.length;
    this.MainInPlayMatchList = this.MatchtableService.InPlayMatchList;

    this.AllMatchList = this.MatchtableService.CurrentMatchList;

    this.__SPORTSID = __SPORTSID;

    this.FilteredSubMatchList = this.InPlayMatchList.filter(({ stSportsID, stMarketName }) => {
      return stSportsID == this.__SPORTSID && stMarketName != "Winner"
    });

    this.checkMasterFancy();
    this.FilteredSubMatchListLength = this.FilteredSubMatchList.length;
    this.MatchtableService.getRequestList(this.FilteredSubMatchList);

    // if (this.__FRONT_RATE_TIMER) {
    //   clearTimeout(this.__FRONT_RATE_TIMER);
    //   this.__FRONT_RATE_TIMER = null;
    // }
    // this.getFrontRate();

    // if (this.SignalRService.__CURRENTODDS == undefined)
    //   return false;

    setTimeout(() => {
      // let __ODDSDATA = this.SignalRService.__CURRENTODDS;
      // if (__ODDSDATA != undefined) {
      //   this.setFrontRate(false);
      // }
      // else {
      //   if (this.__FRONT_RATE_TIMER) {
      //     clearTimeout(this.__FRONT_RATE_TIMER);
      //     this.__FRONT_RATE_TIMER = null;
      //   }
      //   // this.__FRONT_M = false;
      //   this.getFrontRate();
      // }
      this.setM2MForAll(this.ALLM2M);
    }, 200);
  }
  setFrontRate(data) {
    for (let i = 0; i < this.SignalRService.__CURRENTODDS.length; i++) {
      this.setFrontRates(this.SignalRService.__CURRENTODDS[i]);

      if (data == false && i == this.SignalRService.__CURRENTODDS.length - 1) {
        setTimeout(() => {
          if (this.__FRONT_RATE_TIMER) {
            clearTimeout(this.__FRONT_RATE_TIMER);
            this.__FRONT_RATE_TIMER = null;
          }
          // this.__FRONT_M = false;
          this.getFrontRate();
        }, 500);
      }
    }
  }

  IsMarketAvailable(MarketID, data) {
    return data.filter(({ stMarketID }) => {
      return stMarketID == MarketID
    })
  }

  deleteCurrentMatch(MrktID, data) {
    let i = 0;
    for (let result of data) {
      if (result.stMarketID == MrktID) {
        break;
      }
      i++;
    }

    var LocalData = data;

    delete LocalData[i];

    var filtered = LocalData.filter(function (el) {
      return el != null;
    });
    i = 0;
    return filtered;
  }

  getMatchListNew() {
    this.InPlayMatchList = this.MatchtableService.InPlayMatchList;
    this.InPlayMatchListLength = this.MatchtableService.InPlayMatchList.length;
    this.MainInPlayMatchList = this.MatchtableService.InPlayMatchList;

    this.AllMatchList = this.MatchtableService.CurrentMatchList;

    let __NEWMATCHES = this.InPlayMatchList.filter(({ stSportsID, stMarketName }) => {
      return stSportsID == this.__SPORTSID && stMarketName != "Winner"
    });

    if (__NEWMATCHES.length > 0) {
      for (let d of this.FilteredSubMatchList) {
        let __REMOVED = this.IsMarketAvailable(d.stMarketID, __NEWMATCHES);
        if (__REMOVED.length == 0) {
          let filtered = this.deleteCurrentMatch(d.stMarketID, this.FilteredSubMatchList);
          this.FilteredSubMatchList = (filtered.length > 0 ? filtered : []);
          this.FilteredSubMatchListLength = this.FilteredSubMatchList.length;
        }
      }

      setTimeout(() => {
        for (let d of __NEWMATCHES) {
          let __ADDED = this.IsMarketAvailable(d.stMarketID, this.FilteredSubMatchList);
          if (__ADDED.length == 0) {
            this.FilteredSubMatchList.push(d);
            this.FilteredSubMatchListLength = this.FilteredSubMatchList.length;
          }
        }
      }, 1000);
    }
    else {
      this.FilteredSubMatchList = [];
      this.FilteredSubMatchListLength = this.FilteredSubMatchList.length;
    }

    this.checkMasterFancy();

    this.LoginService.getM2MForAllMaster(this.UserID).subscribe(
      data1 => {
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));
        // console.log("getM2MForAllMaster => ");
        // console.log(data);
        if (data.length > 0)
          this.setM2MForAll(data)
      },
      error => {
        // console.log("Error@getMatchList : " + error.message);
      });

    if (this.SignalRService.__CURRENTODDS == undefined)
      return false;

    let __ODDSDATA = this.SignalRService.__CURRENTODDS;
    setTimeout(() => {
      // for (let i = 0; i < __ODDSDATA.length; i++) {
      //   this.setFrontRates(__ODDSDATA[i]);
      // }
    }, 10);
  }

  checkMasterFancy() {
    let __MATCHES = this.MatchtableService.CurrentMatchList;
    let i = 0;
    for (let d of this.FilteredSubMatchList) {
      let __CHECK = __MATCHES.filter(({ stSportsName, stEventID, stMarketName }) => {
        return (stSportsName.split("_")[0].toLowerCase().match("fancy") || stMarketName.toLowerCase().match("goal") || stMarketName.toLowerCase().match("chance") || stMarketName.toLowerCase().match("winner")) && stEventID == this.FilteredSubMatchList[i].stEventID
      });

      this.FilteredSubMatchList[i]['isFancy'] = (__CHECK.length > 0 ? 1 : 0);
      __CHECK = [];
      i++;
    }
  }

  onCasinoClick(__GID) {
    let __ROUTER = (!this.CheckmobileService.isMobileView ? "lotuscasino" : "lotuscasino_Mobile")


    if (__ROUTER != "")
      this.onDynamicRouterClickNew(__ROUTER, __GID)
  }

  onDynamicRouterClickNew(__ROUTER, __PARAM) {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
      this.router.navigate([__ROUTER], { queryParams: { GID: this.CryptojsService.encryptData(__PARAM) } }));
  }

  onEzugiButtonClick(__ROUTER, __PARAM, __PARAM1) {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
      this.router.navigate([__ROUTER], { queryParams: { system: this.CryptojsService.encryptData(__PARAM), pc: this.CryptojsService.encryptData(__PARAM1) } }));
  }

  setM2MForAll(data) {
    // console.log("SETM2M", this.ALLM2M)
    for (let m2mData of this.ALLM2M) {
      this.ALLM2ME[m2mData.stEventId] = m2mData.stValue.toFixed(2);
    }
  }

  // setM2MForAll(data) {
  //   if (this.MatchtableService.CurrentMatchList == undefined) {
  //     setTimeout(() => {
  //       this.setM2MForAll(data);
  //     }, 100);
  //     return false;
  //   }

  //   setTimeout(() => {
  //     for (let m2mData of data) {
  //       let CurrentEvent = m2mData.stEventId;

  //       let CurrentMatch = this.InPlayMatchList.filter(({ stEventID, stMarketID }) => {
  //         return stEventID == CurrentEvent || stMarketID == CurrentEvent
  //       });

  //       if (CurrentMatch.length > 0) {
  //         if (document.getElementById('InPlaym2m_' + CurrentEvent)) {
  //           document.getElementById('InPlaym2m_' + CurrentEvent).innerHTML = m2mData.stValue.toFixed(2);
  //           if (m2mData.stValue < 0) {
  //             document.getElementById('InPlaym2m_' + CurrentEvent).classList.remove("htTnxO");
  //             document.getElementById('InPlaym2m_' + CurrentEvent).classList.add("ksWbmr");
  //           }
  //           else {
  //             document.getElementById('InPlaym2m_' + CurrentEvent).classList.remove("ksWbmr");
  //             document.getElementById('InPlaym2m_' + CurrentEvent).classList.add("htTnxO");
  //           }
  //         }
  //       }
  //     }
  //   }, 1000);
  // }

  ngOnInit() {
    this.isMobileView = this.CheckmobileService.isMobileView;
    this.activatedRoute.queryParams.subscribe(params => {
      this.selectedWhichTab = (params['params'] == undefined ? 0 : parseInt(this.CryptojsService.DecryptOrderFunction(params['params'])));
      // console.log("this.selectedWhichTab : " + this.selectedWhichTab)
    });

    if (sessionStorage.getItem('user') === null) {
      this.Logout();
      return false;
    }

    if (!this.isMobileView)
      this.router.navigate(["/Home"]);

    window.scroll(0, 0);
    var SessionUser = JSON.parse(this.CryptojsService.decryptData(sessionStorage.user));
    this.UserID = SessionUser.UserId;
    this.__SPORTSID = "4";

    if (this.selectedWhichTab != 1)
      this.getMatchList(this.__SPORTSID);
    else
      this.getMatchListWinner();

    this.getM2MForAll();

    this.getHidedSports();
    this.getFrontRate();
  }
  __FRONT_RATE_TIMER: any;
  getFrontRate() {
    if (sessionStorage.getItem("user") == undefined || sessionStorage.getItem("user") == null) {
      return false;
    }

    this.SignalRService.getFrontRate();
    this.__FRONT_RATE_TIMER = setTimeout(() => {
      this.getFrontRate();
    }, this.SignalRService.__FRONT_TIME);

    if (this.MatchtableService.CurrentMatchList == null) {
      setTimeout(() => {
        this.getFrontRate();
      }, 100);
      return false;
    }

    if (this.FilteredSubMatchList == null || this.FilteredSubMatchList == undefined) {
      setTimeout(() => {
        this.getFrontRate();
      }, 100);
      return false;
    }

    if(window.location.hash.split(this.CryptojsService.decryptData('U2FsdGVkX19QfMa3QnNU/Ypq92x4XiBM+WiKo6SbGbI='))[0].indexOf(this.CryptojsService.decryptData("U2FsdGVkX18yRHpGpejjR0YYPSNR0Eska3KEngAUiaw=")) >= 0) {
      let _MID = "";
      // for (let m of this.MatchtableService.CurrentMatchList) {
      for (let m of this.FilteredSubMatchList) {
        if (m.stMarketName == "Match Odds" || m.stMarketName == "Winner") {
          if (_MID == "") _MID = m.stMarketID;
          else _MID = _MID + "," + m.stMarketID;
        }
      }
      if (_MID == "") {
        if (this.__FRONT_RATE_TIMER) {
          clearTimeout(this.__FRONT_RATE_TIMER);
          this.__FRONT_RATE_TIMER = null;
        }

        setTimeout(() => {
          this.getFrontRate();
        }, 100);
        return false;
      }
      else {
        // this.LoginService.getRateFromJSON(_MID).subscribe(
        this.LoginService.getMasterSJSON(this.__SPORTSID).subscribe(
          (data1: any) => {
            // let data = JSON.parse(this.CryptojsService.DecryptOrderFunctionU(data1, "U2FsdGVkX18LyQo6FkAa5M9ec8JxMhgBmHG1bx21XiE="));
            let data = JSON.parse(this.CryptojsService.decryptAES256(data1, "0V27WqAzmrZRSL2A0V27WqAzmrZRSL2A","0V27WqAzmrZRSL2A"));
            // console.log("FRONT LIVE", data);
            
            this.MatchtableService.InPlayMatchList = data;

            for (let i = 0; i < data.length; i++) {
              if (data[i] && data[i].stMarketID) {
                if (document.getElementById('CricketTRInPlay_' + data[i].stMarketID)) {
                  this.setFrontRates(data[i]); //this.setFrontRate(); 
                }
                
                let stC = this.SignalRService.__CURRENTODDS.findIndex(m => {
                  return m.stMarketID == data[i].stMarketID
                })
                if (stC >= 0) {
                  this.SignalRService.__CURRENTODDS[stC] = data[i];
                }

                let stI = this.MatchtableService.CurrentMatchList.findIndex(m => {
                  return m.stMarketID == data[i].stMarketID
                })
                if (stI >= 0) {
                  this.MatchtableService.CurrentMatchList[stI] = data[i];
                }

                if (i == data.length - 1) {
                  this.setOdds();
                }

                this.LoginService.onChangeDetect();

              }
            }
          },
          error => {
          });
      }
    }
    else {
      if (this.__FRONT_RATE_TIMER) {
        clearTimeout(this.__FRONT_RATE_TIMER);
        this.__FRONT_RATE_TIMER = null;
      }
    }
  }

  setOdds() {
    if (this.SignalRService.__CURRENTODDS == undefined || this.SignalRService.__CURRENTODDS == null) {
      // if (document.getElementById("loaderLoad")) {
      //   document.getElementById("loaderLoad").className = "loaderLoad";
      // }
      return false;
    }
    if (this.SignalRService.__CURRENTODDS.length <= 0) {
      // if (document.getElementById("loaderLoad")) {
      //   document.getElementById("loaderLoad").className = "loaderLoad";
      // }
      setTimeout(() => {
        this.setOdds();
      }, 200);
      return false;
    }

    setTimeout(() => {
      let __ODDSDATA = this.SignalRService.__CURRENTODDS;
      // if (document.getElementById("loaderLoad")) {
      //   document.getElementById("loaderLoad").className = "loaderLoad";
      // }
      for (let i = 0; i < __ODDSDATA.length; i++) {
        this.setFrontRates(__ODDSDATA[i]);
      }
    }, 500);
  }

  __EZUGI_GAMES = this.CryptojsService.__EZUGI_GAMES;
  __EVOLUTION_GAMES = this.CryptojsService.__EVOLUTION_GAMES;
  __LOTUS_GAMES = this.CryptojsService.__LOTUS_GAMES;

  onDynamicRouterEzugiClick(__ROUTER, __PARAM) {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
      this.router.navigate([__ROUTER], { queryParams: { GID: this.CryptojsService.encryptData(__PARAM) } }));
  }

  onDynamicRouterClick(__ROUTER) {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
      this.router.navigate([__ROUTER]));
  }

  onMatchListMobileClick(param) {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/MatchView_mobile'], { queryParams: { SportsID: param } }));
  }

  setShowHideSports(data, ParentID, Status) {
    switch (data) {
      case "4":
        this.CricketShowStatus = Status;
        this.reSetInPlayMatches();
        break;

      case "2":
        this.TennisShowStatus = Status;
        this.reSetInPlayMatches();
        break;

      case "1":
        this.SoccerShowStatus = Status;
        this.reSetInPlayMatches();
        break;

      case "7":
        this.HorseRacingShowStatus = Status;
        this.reSetInPlayMatches();
    }
  }


  checkInPlayMatches() {
    if (this.MatchtableService.isMainHomeRefreshMobile == 1) {
      this.MatchtableService.isMainHomeRefreshMobile = 0;
      setTimeout(() => {
        this.getMatchListNew();
      }, 2000);
    }
  }

  ngAfterViewInit() {
    if (this.selectedWhichTab != -1)
      this.setNavigationMenu(this.selectedWhichTab);
  }

  setFrontRates(data) {
    if (data && data.submaster) {
      let depth = data.submaster;

      if(data.btMarketStatus == 3 || data.btMarketStatus == 2) {
        for (var d = 0; d < depth.length; d++) {
          if (document.getElementById('__SUSPEND_' + depth[d].stInstrumentID))
            document.getElementById('__SUSPEND_' + depth[d].stInstrumentID).style.display = "flex";
          
          if (document.getElementById("__BACK_P_INPLAY_" + depth[d].stInstrumentID))
            document.getElementById("__BACK_P_INPLAY_" + depth[d].stInstrumentID).innerHTML = "-";

          if (document.getElementById("__LAY_P_INPLAY_" + depth[d].stInstrumentID))
            document.getElementById("__LAY_P_INPLAY_" + depth[d].stInstrumentID).innerHTML = "-";
        }
      }
      else {        
        for (var d = 0; d < depth.length; d++) {
          if (document.getElementById("__BACK_P_INPLAY_" + depth[d].stInstrumentID)) {
            if(depth[d].rates.Back) {
              for(var b=0; b < depth[d].rates.Back.length; b++) {
                if(depth[d].rates.Back[0].Price.toFixed == "0.00") {
                  if (document.getElementById('__SUSPEND_' + depth[d].stInstrumentID))
                    document.getElementById('__SUSPEND_' + depth[d].stInstrumentID).style.display = "flex";
                  
                  document.getElementById("__BACK_P_INPLAY_" + depth[d].stInstrumentID).innerHTML = "-"; 
                }
                else {
                  if (document.getElementById('__SUSPEND_' + depth[d].stInstrumentID))
                    document.getElementById('__SUSPEND_' + depth[d].stInstrumentID).style.display = "none";

                  document.getElementById("__BACK_P_INPLAY_" + depth[d].stInstrumentID).innerHTML = depth[d].rates.Back[0].Price.toFixed(2);
                }
              }
            }
          }

          if (document.getElementById("__LAY_P_INPLAY_" + depth[d].stInstrumentID)) {
            if(depth[d].rates.Lay) {
              for(var b=0; b < depth[d].rates.Lay.length; b++) {
                if(depth[d].rates.Lay[0].Price.toFixed == "0.00") {
                  if (document.getElementById('__SUSPEND_' + depth[d].stInstrumentID))
                    document.getElementById('__SUSPEND_' + depth[d].stInstrumentID).style.display = "flex";
                  
                  document.getElementById("__LAY_P_INPLAY_" + depth[d].stInstrumentID).innerHTML = "-"; 
                }
                else {
                  if (document.getElementById('__SUSPEND_' + depth[d].stInstrumentID))
                    document.getElementById('__SUSPEND_' + depth[d].stInstrumentID).style.display = "none";

                  document.getElementById("__LAY_P_INPLAY_" + depth[d].stInstrumentID).innerHTML = depth[d].rates.Lay[0].Price.toFixed(2);
                }
              }
            }
          }
        }
      }      
    }
  }
  AnimationDuration = 50;
  RateAnimation(__ELEMENT) {
    if (__ELEMENT)
      __ELEMENT.classList.add("Rate-animation");
    setTimeout(() => {
      if (__ELEMENT)
        __ELEMENT.classList.remove("Rate-animation");
    }, this.AnimationDuration);
  }

  onChangeCasinoTabs(WhichTab) {
    this.router.navigate(['/iframecasino'], { queryParams: { GameID: WhichTab } });
  }

  setNavigationMenu(WhichTab) {

  }

  onChangeMainMenu(WhichTab) {
    for (let i = 0; i < 3; i++) {
      if (document.getElementById("ScrollMainTabs" + i))
        document.getElementById("ScrollMainTabs" + i).className = "";
    }

    if (document.getElementById("ScrollMainTabs" + WhichTab))
      document.getElementById("ScrollMainTabs" + WhichTab).className = "activeMain";
  }

  ALLM2M = [];
  ALLM2ME = [];
  getM2MForAll() {
    // console.log("REQ > " + formatDate(new Date(), 'HH:mm:ss', 'en'));
    this.LoginService.getM2MForAllMaster(this.UserID).subscribe(
      data1 => {
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));
        // console.log("RESP > " + formatDate(new Date(), 'HH:mm:ss', 'en'), data);

        this.ALLM2M = data;
        if (data.length > 0) {
          this.setM2MForAll(data)
        }
      },
      error => {
      });
  }

  onChangeSubMenu(WhichTab) {
    for (let i = 0; i < 2; i++) {
      if (document.getElementById("ScrollSubTabs" + i))
        document.getElementById("ScrollSubTabs" + i).className = "";
    }

    if (document.getElementById("ScrollSubTabs" + WhichTab))
      document.getElementById("ScrollSubTabs" + WhichTab).className = "activeMain";
  }

  onChangeNavigationTabs(WhichTab) {
    for (let i = 0; i < 7; i++) {
      if (document.getElementById("ScrollTabs" + i))
        document.getElementById("ScrollTabs" + i).className = "";
    }

    if (document.getElementById("ScrollTabs" + WhichTab))
      document.getElementById("ScrollTabs" + WhichTab).className = "active";
  }

  reSetInPlayMatches() {
    if (this.CricketShowStatus) {
      this.InPlayMatchList = this.MainInPlayMatchList.filter(({ stSportsID }) => {
        return stSportsID != null && stSportsID != "4"
      })
    }
    else {
      this.InPlayMatchList = this.MainInPlayMatchList;
    }

    if (this.InPlayMatchList == undefined)
      return false;

    if (this.TennisShowStatus) {
      this.InPlayMatchList = this.InPlayMatchList.filter(({ stSportsID }) => {
        return stSportsID != null && stSportsID != "2"
      })
    }

    if (this.SoccerShowStatus) {
      this.InPlayMatchList = this.InPlayMatchList.filter(({ stSportsID }) => {
        return stSportsID != null && stSportsID != "1"
      })
    }

    if (this.HorseRacingShowStatus) {
      this.InPlayMatchList = this.InPlayMatchList.filter(({ stSportsID }) => {
        return stSportsID != null && stSportsID != "7"
      })
    }

    this.InPlayMatchListLength = this.InPlayMatchList;
  }

  filterSportsWiseMatch(__SPORTSID) {
    if (this.InPlayMatchList == [])
      return false;
    return this.InPlayMatchList.filter(({ stSportsID }) => {
      return stSportsID != null && stSportsID == __SPORTSID
    })
  }

  getHidedSports() {
    if (this.CricketShowStatus != this.SportsshowhideService.CricketShowStatus
      || this.TennisShowStatus != this.SportsshowhideService.TennisShowStatus
      || this.SoccerShowStatus != this.SportsshowhideService.SoccerShowStatus
      || this.HorseRacingShowStatus != this.SportsshowhideService.HorseRacingShowStatus) {
      setTimeout(() => {
        this.reSetInPlayMatches();
      }, 50);
    }
    this.CricketShowStatus = this.SportsshowhideService.CricketShowStatus;

    this.TennisShowStatus = this.SportsshowhideService.TennisShowStatus;

    this.SoccerShowStatus = this.SportsshowhideService.SoccerShowStatus;

    this.HorseRacingShowStatus = this.SportsshowhideService.HorseRacingShowStatus;
  }

  showHideMatch(data) {
    if (data != "") {
      if (document.getElementById("CricketTR_" + data)) {
        document.getElementById("CricketTR_" + data).style.display = "none";
      }
    }
    setTimeout(() => {
      this.reSetInPlayMatches();
    }, 2000);
  }

  onLotusGameButtonClick() {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/lotuscasino_Mobile']));
  }

  changeNavigation(EventID, stSportsID, stMarketID) {
    if (this.__FRONT_RATE_TIMER) {
      clearTimeout(this.__FRONT_RATE_TIMER);
      this.__FRONT_RATE_TIMER = null;
    }
    this.router.navigate(['/Match:TableView_mobile'], { queryParams: { EventsID: EventID, SportsID: stSportsID, MarketID: stMarketID } });
  }
  Logout() {
    delete sessionStorage.user;

    this.isMobileView = this.CheckmobileService.isMobileView;
    if (!this.isMobileView)
      this.router.navigate(['/login']);
    else
      this.router.navigate(['/login_mobile']);
  }

  ngOnDestroy() {
    if (this.__FRONT_RATE_TIMER) {
      clearTimeout(this.__FRONT_RATE_TIMER);
      this.__FRONT_RATE_TIMER = null;
    }
    this.__ALLINONESUBSCRIPTION.unsubscribe();
  }
  refreshMarketList(result) {
    this.getMatchListNew();
    return false;
  }
  trackByFn(index, item) {
    return index; // or item.id
  }

  __PREVIOUSTOPTAB = 0;

  onWorldCasinoClick(__PARAM, __TAB_NO) {
    if (this.__PREVIOUSTOPTAB != -1) {
      if (document.getElementById("__TOPBARTABS_" + this.__PREVIOUSTOPTAB)) {
        document.getElementById("__TOPBARTABS_" + this.__PREVIOUSTOPTAB).className = "hoverPointer";
      }
    }
    // let __TAB_NO = (__NEXTTAB == 1 ? (__PARAM == 0 ? 1 : 2) : (__PARAM == 0 ? 0 : 3));
    this.__PREVIOUSTOPTAB = __TAB_NO;
    if (document.getElementById("__TOPBARTABS_" + __TAB_NO)) {
      document.getElementById("__TOPBARTABS_" + __TAB_NO).className = "hoverPointer router-link-active";
    }
    sessionStorage.setItem("__PREVIOUSTOPTAB", this.__PREVIOUSTOPTAB.toString());

    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/iframecasino'], { queryParams: { GameID: this.CryptojsService.encryptData(__PARAM) } }));
  }
}
