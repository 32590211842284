import { Component, OnInit } from '@angular/core';
import { CryptojsService } from '../services/cryptojs.service';
import { LoginService } from '../services/login.service';
import { RjNotifierService } from '../services/rj-notifier.service';


@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.css']
})
export class BankDetailsComponent implements OnInit {
  constructor(private RjNotifierService: RjNotifierService, private CryptojsService: CryptojsService, private LoginService: LoginService) { }

  ngOnInit() {
    this.getPaymentmethod();
  }

  BankName = "";
  AccountHolder = "";
  AccountNumber = "";
  IfscCode = "";
  UpiId = "";
  ImgUpload = "";

  __BANKLIST = [];

  __BANNERS = [];
  __TYPES = ['image/jpg', 'image/jpeg', 'image/png'];
  __WIDTH = 214;//500;
  __HEIGHT = 218; //500;
  __MAX_SIZE = 1500;
  __ERROR_SIZE = "";
  __ERROR_WIDTH = "";
  __FILE: any;

  IMG_WIDTH = 0;
  IMG_HEIGHT = 0;
  IMG_SIZE = 0;
  IMG_TYPE = "";

  // AddDeteils()
  // {
  //   if(this.BankName == "")
  //   {
  //     this.sendNotification("error", "Please enter BankName.");
  //     return false;
  //   }
  //   if(this.AccountHolder == "")
  //   {
  //     this.sendNotification("error", "Please enter AccountHolder.");
  //     return false;
  //   }
  //   if(this.AccountNumber == "")
  //   {
  //     this.sendNotification("error", "Please enter AccountNumber.");
  //     return false;
  //   }
  //   if(this.IfscCode == "")
  //   {
  //     this.sendNotification("error", "Please enter IfscCode.");
  //     return false;
  //   }
  //   if(this.UpiId == "")
  //   {
  //     this.sendNotification("error", "Please enter UpiId.");
  //     return false;
  //   }
  //   // if(this.ImgUpload == "")
  //   // {
  //   //   this.sendNotification("error", "Please Upload Image.");
  //   //   return false;
  //   // }

  //   this.LoginService.addpaymentmethod(this.ImgUpload,this.AccountHolder, this.AccountNumber, this.IfscCode,this.BankName,this.UpiId).subscribe(
  //     data1 =>
  //     {

  //       let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));
  //        console.log(data)
  //       if(data.status != 200)
  //       {
  //         this.sendNotification("error", data.message);
  //         return false;
  //       }
  //       this.sendNotification("success", data.message);

  //     },
  //     error =>
  //     {
  //       this.sendNotification("error", "Something went wrong in Add Patment Details. Please try again.");
  //     })

  // }

  __UPLOADEDFILE: File | null = null;

  ImgUploadePayment(__ID, __SID) {
    if (this.__P_ID == "") {
      return false;
    }
    if (this.__P_ACT == -1) {
      return false;
    }
    if (this.__UPLOADEDFILE == undefined || this.__UPLOADEDFILE == null) {
      this.sendNotification("warning", "Select proper Image")
      return false;
    }
    // if (this.__MAX_SIZE < this.IMG_SIZE) {
    //   let __MSG = "Banner size Must be smaller than " + this.__MAX_SIZE + "kb. Selected Banner size is : " + this.IMG_SIZE + "KB";
    //   this.sendNotification("error", __MSG);
    // }

    // if (this.IMG_WIDTH != this.__WIDTH || this.IMG_HEIGHT != this.__HEIGHT) {
    //   let __MSG = "File width Must be " + this.__WIDTH + " & height mus be " + this.__HEIGHT;
    //   this.sendNotification("error", __MSG);
    // }

    let __IMAGENAME = this.BankName;  //this.__BANNERS[1].stImage.split("/")[this.__BANNERS[1].stImage.split("/").length - 1];
    let __NEW_NAME = this.AccountHolder + '.jpg'; //this.LoginService.makeRandom(10) + Date.now().toString() + "." + __IMAGENAME.split(".")[1];

    this.LoginService.uploadImagePayment(this.__UPLOADEDFILE, this.__P_ID, 806, __IMAGENAME, __NEW_NAME, this.AccountHolder, this.AccountNumber, this.IfscCode, this.BankName, this.UpiId, this.__P_ACT).subscribe(
      data1 => {
        let data = this.CryptojsService.DecryptOrderFunction(data1);
        // if (data != "[]")
        //   this.sendNotification("warning", data);
        // else
        if (data != '"Success"')
          return false;

        this.sendNotification("success", "Bank Details has been changed Successfully.");

        this.LoginService.setBannersFromApi();
        setTimeout(() => {
          this.getPaymentmethod()
        }, 500);
      },
      error => {
        this.sendNotification("error", "Something went wrong. Please try again.");
      });
  }

  HideAllNotification() {
    this.RjNotifierService.hideNotifier();
  }

  sendNotification(Type, Message) {
    this.RjNotifierService.showNotifier(Type, Message);
  }

  handleFileInput(__EVENT: FileList) {
    this.__UPLOADEDFILE = __EVENT.item(0);

    this.__FILE = __EVENT;
    let image: any = __EVENT[0]

    this.IMG_TYPE = image.type;
    this.IMG_SIZE = parseInt(this.formatBytes(image.size));

    if (!this.__TYPES.includes(this.IMG_TYPE)) {
      let __MSG = "Banner type Must be .jpg, .jpeg extensions.";
      this.sendNotification("error", __MSG);
    }

    if (this.__MAX_SIZE < this.IMG_SIZE) {
      let __MSG = "Banner size Must be smaller than " + this.__MAX_SIZE + "kb. Selected Banner size is : " + this.IMG_SIZE + "KB";
      this.sendNotification("error", __MSG);
    }
    let fr = new FileReader();

    this.IMG_WIDTH = 0;
    this.IMG_HEIGHT = 0;
    fr.onload = () => {
      var img = new Image();
      img.onload = () => {
        this.IMG_WIDTH = img.width;
        this.IMG_HEIGHT = img.height;
      };
      img.src = fr.result.toString();
    };

    // setTimeout(() => {
    //   if (this.IMG_WIDTH != this.__WIDTH || this.IMG_HEIGHT != this.__HEIGHT) {
    //     let __MSG = "File width Must be " + this.__WIDTH + " & height mus be " + this.__HEIGHT;
    //     this.sendNotification("error", __MSG);
    //   }
    // }, 1000);

    fr.readAsDataURL(image);
  }

  formatBytes(a, b = 2) {
    if (0 === a) return "0 Bytes"; const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024)); return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  }

  __P_ID = "";
  __P_QRCODE_IMAGE = ""; //"../images/qrcode.png";
  __P_ACCOUNT_HOLDER = ""; //"FINNACLE ENTERPRISES";
  __P_BANK_NAME = ""; //"IDFC BANK";
  __P_IFSC_CODE = ""; //"IDFB0040152";
  __P_BANK_NUMBER = ""; //"10089669622";z
  __P_UPI_ID = ""; //"finnacleenterprises@okicici";
  // __PHONE_PAY = "9667170122";
  __P_ACT = -1;
  getPaymentmethod() {
    this.__BANKLIST = [];

    this.__P_ID = "";
    this.__P_QRCODE_IMAGE = "";
    this.__P_ACCOUNT_HOLDER = this.AccountHolder = "";
    this.__P_BANK_NAME = this.BankName = "";
    this.__P_IFSC_CODE = this.IfscCode = "";
    this.__P_BANK_NUMBER = this.AccountNumber = "";
    this.__P_UPI_ID = this.UpiId = "";
    this.__P_ACT = -1;

    this.LoginService.getPaymentApi().subscribe(
      data1 => {
        let data = JSON.parse(this.CryptojsService.DecryptOrderFunction(data1));
        console.log('image', data)
        if (data.length == 0) {
          return false;
        }
        this.__BANKLIST = data;
      },
      error => {
      });
  }

  getAccount(__DATA) {
    this.__P_ID = __DATA.id;
    this.__P_QRCODE_IMAGE = __DATA.stImage;
    this.__P_ACCOUNT_HOLDER = this.AccountHolder = __DATA.Accountholder;
    this.__P_BANK_NAME = this.BankName = __DATA.bankname;
    this.__P_IFSC_CODE = this.IfscCode = __DATA.ifsccode;
    this.__P_BANK_NUMBER = this.AccountNumber = __DATA.banknumber;
    this.__P_UPI_ID = this.UpiId = __DATA.bttype;
    this.__P_ACT = __DATA.ac;
  }

}
