import { Injectable } from '@angular/core';
// import { CommonlistService } from './commonlist.service';

@Injectable({
  providedIn: 'root'
})
export class RjNotifierService {

  constructor() { }

  __HIDE_NOTIFIER : any;
  __AUTO_HIDE = 4000;
  showNotifier(Type, Message)
  {
    this.hideNotifier();
    setTimeout(() =>
    {
      let __CLASS = "";
      switch(Type.toLowerCase())
      {
        case "success" :
          __CLASS = "success-notification";
          break;
        case "warning" :
          __CLASS = "warning-notification";
          break;
        case "error" :
          __CLASS = "error-notification";
          break;
        case "back" :
          __CLASS = "back-notification";
          break;
        case "lay" :
          __CLASS = "lay-notification";
          break;
        default :
          __CLASS = "default-notification";
          break;
      }
      if(document.getElementById("__NOTIFIER"))
      {
        document.getElementById("__NOTIFIER").classList.add(__CLASS, "show-notification");
        document.getElementById("__NOTIFIER").innerHTML = Message;
      }
      this.__HIDE_NOTIFIER = setTimeout(() =>
      {
        this.hideNotifier();
      }, this.__AUTO_HIDE);
    }, 100);
  }

  hideNotifier()
  {
    if(this.__HIDE_NOTIFIER)
    {
      clearTimeout(this.__HIDE_NOTIFIER);
      this.__HIDE_NOTIFIER = null;
    }
    if(document.getElementById("__NOTIFIER"))
    {
      document.getElementById("__NOTIFIER").className = "default-notification";
    }
  }
}
