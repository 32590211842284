import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { SafePipe } from './safe.pipe';
import { CookieService } from 'ngx-cookie-service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoginComponent } from './login/login.component';
import { LoginMobileComponent } from './login-mobile/login-mobile.component';

import { HeadermenuComponent } from './headermenu/headermenu.component';
import { HeadermenuMobileComponent } from './headermenu-mobile/headermenu-mobile.component';

import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { SidemenuMobileComponent } from './sidemenu-mobile/sidemenu-mobile.component';

import { MainhomeviewComponent } from './mainhomeview/mainhomeview.component';
import { MainhomeviewMobileComponent } from './mainhomeview-mobile/mainhomeview-mobile.component';

import { RefreshComponent } from './refresh/refresh.component';
import { ManageDepositPendingComponent } from './manage-deposit-pending/manage-deposit-pending.component';
import { PayuComponent } from './payu/payu.component';
import { BankDetailsComponent } from './bank-details/bank-details.component';

const customNotifierOptions: NotifierOptions =
{
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 4000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: false,
    stacking: 1
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 100,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 100,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 100,
      easing: 'ease'
    },
    overlap: 150
  }
}

@NgModule({
  declarations: [
    SafePipe,
    AppComponent,
    LoginComponent,
    HeadermenuComponent,
    SidemenuComponent,
    MainhomeviewComponent,
    RefreshComponent,
    LoginMobileComponent,
    MainhomeviewMobileComponent,
    HeadermenuMobileComponent,
    SidemenuMobileComponent,
    ManageDepositPendingComponent,
    PayuComponent,
    BankDetailsComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NotifierModule.withConfig(customNotifierOptions),
    BrowserAnimationsModule,
    // environment.production ? ServiceWorkerModule.register('/ngsw-worker.js') : [],
    ServiceWorkerModule.register('safety-worker.js', { enabled: environment.production }),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, CookieService, HeadermenuComponent, SidemenuComponent, AppComponent, RefreshComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
