<div class="row">
  <!-- <div class="col-md-9 p-r-0 home-content"> -->
  <div class="col-md-12 home-content">
    <div id="carousel-example-generic" class="carousel slide" data-ride="carousel"
      style="box-shadow: rgba(0, 0, 0, 0.2) -2px -1px 6px;">
      <div class="carousel-inner">
        <div *ngFor="let banners of __BANNERS; let isFirst = first;" [class]="isFirst ? __ACTIVE_S : __INACTIVE_S">
          <div class="carousel-item">
            <img src="{{banners.stImage}}" style="width: 100%;" alt="slider" />
          </div>
          <div class="carousel-caption"></div>
        </div>
      </div>
      <a *ngIf="__BANNERS.length > 0" class="left carousel-control" href="#carousel-example-generic" data-slide="prev">
        <span class="fa fa-angle-left"></span>
      </a>
      <a *ngIf="__BANNERS.length > 0" class="right carousel-control" href="#carousel-example-generic" data-slide="next">
        <span class="fa fa-angle-right"></span>
      </a>
    </div>

    <div class="scroll-menu-main-page m-t-5" *ngIf="selectedWhichTab != 1">
      <a *ngIf="!CricketShowStatus" (click)="getMatchList('-1', 0);" id="__HomeTabs_0">
        <img src="/images/inplay.png" width="20px">
        IN-PLAY
      </a>
      <a *ngIf="!CricketShowStatus" (click)="getMatchList('4', 1);" id="__HomeTabs_1" class="active">
        <img src="/images/cricket-sidebar.png" width="20px">
        CRICKET
      </a>
      <a *ngIf="!TennisShowStatus" (click)="getMatchList('2', 2);" id="__HomeTabs_2">
        <img src="/images/tennis-sidebar.png" width="20px">
        TENNIS
      </a>
      <a *ngIf="!SoccerShowStatus" (click)="getMatchList('1', 3);" id="__HomeTabs_3">
        <img src="/images/football-sidebar.png" width="20px">
        SOCCER
      </a>
      <a *ngIf="!HorseRacingShowStatus" (click)="getMatchList('7', 4);" id="__HomeTabs_4">
        <img src="/images/horseracing-sidebar.png" width="20px">
        HORSE RACING
      </a>
    </div>

    <div class="sc-fjdhpX sAjab m-t-5">
      <div class="sc-epnACN bGHpJS">
        <div class="sc-bwCtUz sc-hrWEMg ipbjCZ">
          <div class="sc-bXGyLb sc-lkqHmb sc-eLExRp edXhoF">Game</div>
          <div class="sc-bXGyLb sc-lkqHmb sc-krvtoX litKqN">
            <span>1</span>
            <span>X</span>
            <span>2</span>
          </div>
        </div>
        <div class="sc-sPYgB dqbEeb" *ngIf="FilteredSubMatchListLength == 0">No real-time records found</div>
        <ng-container *ngFor="let d of FilteredSubMatchList; let i = index;">
          <div *ngIf="__SPORTSID == '-1' && d.btMarketStatus == 1" id="CricketTRInPlay_{{d.stMarketID}}" class="sc-hXRMBi gVVkIY">
            <div class="sc-bwCtUz sc-gwVKww cXWrKs">
              <div class="sc-bXGyLb sc-cpmLhU beoeno">
                <div class="sc-emmjRN cpsDzQ">
                  <div class="sc-eerKOB ldkwXi">
                    <div class="sc-eilVRo ehEWqk" (click)="changeNavigation(d.stEventID, d.stSportsID, d.stMarketID)">
                      <b *ngIf="d.btMarketStatus == 1" class="live-list m-r-5">Live</b>{{d.stEventName}}<span
                        *ngIf="d.btMarketStatus != 1"> / {{d.dtStartDate | date:'dd MMM, HH:mm'}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sc-bXGyLb sc-dymIpo llHxpRJ">
                <div class="sc-RefOD dbkUcG">
                  <!-- <div class="list-tv-bm-f-icon" *ngIf="d.btMarketStatus == 1 && d.stSportsID != 4">
                      <img src="./images/tv-white.png" width="20px" class="m-r-2"/>
                      <span *ngIf="d.stSportsID == 1 || d.stSportsID == 2" class="list-f-color">F</span>
                  </div> -->
                  <div class="list-tv-bm-f-icon">
                    <img *ngIf="d.btMarketStatus == 1" src="./images/tv-white.png" width="20px" class="m-r-2" />
                    <span *ngIf="d.btMarketStatus == 1 && d.stSportsID == 4" class="list-bm-color m-r-2">BM</span>
                    <span *ngIf="d.isFancy && d.isFancy == 1" class="list-f-color">F</span>
                  </div>
                  <!-- <div *ngIf="__SPORTSID != '-1'" class="list-tv-bm-f-icon" *ngIf="d.btMarketStatus == 1">
                      <img src="./images/tv-white.png" width="20px" class="m-r-2"/>
                      <span *ngIf="__SPORTSID == '4'" class="list-bm-color m-r-2">BM</span>
                      <span *ngIf="__SPORTSID == '4' || __SPORTSID == '1' || __SPORTSID == '2'" class="list-f-color">F</span>
                  </div> -->
                </div>
              </div>
              <div class="sc-bXGyLb sc-dymIpo llHxp">
                <div class="sc-RefOD dbkUcG">
                  <!-- <div class="sc-ibxdXY bvjTkI" *ngIf="d.stSportsID != 7 && d.stSportsID != 4339"
                    id="InPlaym2m_{{d.stEventID}}">0.00</div>
                  <div class="sc-ibxdXY bvjTkI" *ngIf="d.stSportsID == 7 || d.stSportsID == 4339"
                    id="InPlaym2m_{{d.stMarketID}}">0.00</div> -->
                  <ng-container *ngIf="d.stSportsID != 7 && d.stSportsID != 4339">
                    <ng-container *ngIf="ALLM2ME[d.stEventID] == undefined">
                      <div class="sc-ibxdXY bvjTkI" id="InPlaym2m_{{d.stEventID}}">0.00</div>
                    </ng-container>
                    <ng-container *ngIf="ALLM2ME[d.stEventID] != undefined">
                      <div *ngIf="ALLM2ME[d.stEventID] < 0" class="sc-ibxdXY bvjTkI ksWbmr"
                        id="InPlaym2m_{{d.stEventID}}">
                        {{ALLM2ME[d.stEventID]}}</div>
                      <div *ngIf="ALLM2ME[d.stEventID] >= 0" class="sc-ibxdXY bvjTkI htTnxO"
                        id="InPlaym2m_{{d.stEventID}}">
                        {{ALLM2ME[d.stEventID]}}</div>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="d.stSportsID == 7 || d.stSportsID == 4339">
                    <ng-container *ngIf="ALLM2ME[d.stMarketID] == undefined">
                      <div class="sc-ibxdXY bvjTkI" id="InPlaym2m_{{d.stMarketID}}">0.00</div>
                    </ng-container>
                    <ng-container *ngIf="ALLM2ME[d.stMarketID] != undefined">
                      <div *ngIf="ALLM2ME[d.stMarketID] < 0" class="sc-ibxdXY bvjTkI ksWbmr"
                        id="InPlaym2m_{{d.stMarketID}}">
                        {{ALLM2ME[d.stMarketID]}}</div>
                      <div *ngIf="ALLM2ME[d.stMarketID] >= 0" class="sc-ibxdXY bvjTkI htTnxO"
                        id="InPlaym2m_{{d.stMarketID}}">
                        {{ALLM2ME[d.stMarketID]}}</div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
              <div class="sc-bXGyLb sc-bnXvFD vUVLn" *ngIf="d.submaster.length > 2">
                <div *ngIf="d.stSportsID == 7 || d.stSportsID == 4339" class="sc-iQNlJl kEIzqlHorse"
                  id="__SUSPEND_{{d.submaster[0].stInstrumentID}}">BET OPEN</div>
                <div *ngIf="d.stSportsID != 7 && d.stSportsID != 4339" class="sc-iQNlJl kEIzql" [style.display]="d.btMarketStatus == 0 || d.btMarketStatus == 1 ? 'none' : 'flex'"
                  id="__SUSPEND_{{d.submaster[0].stInstrumentID}}">SUSPENDED</div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jOBoYn" id="__BACK_INPLAY_{{d.submaster[0].stInstrumentID}}">
                    <div type="BACK" id="__BACK_P_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[0].rates ? d.submaster[0].rates.Back[0].Price : '-'}}
                    </div>
                    <!-- <div type="BACK" id="__BACK_S_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                  <div type="LAY" class="sc-cmthru bEnOkM" id="__LAY_INPLAY_{{d.submaster[0].stInstrumentID}}">
                    <div type="LAY" id="__LAY_P_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[0].rates ? d.submaster[0].rates.Lay[0].Price : '-'}}
                    </div>
                    <!-- <div type="LAY" id="__LAY_S_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                </div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jOBoYn" id="__BACK_INPLAY_{{d.submaster[2].stInstrumentID}}">
                    <div type="BACK" id="__BACK_P_INPLAY_{{d.submaster[2].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[2].rates ? d.submaster[2].rates.Back[0].Price : '-'}}
                    </div>
                    <!-- <div type="BACK" id="__BACK_S_INPLAY_{{d.submaster[2].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                  <div type="LAY" class="sc-cmthru bEnOkM" id="__LAY_INPLAY_{{d.submaster[2].stInstrumentID}}">
                    <div type="LAY" id="__LAY_P_INPLAY_{{d.submaster[2].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[2].rates ? d.submaster[2].rates.Lay[0].Price : '-'}}
                    </div>
                    <!-- <div type="LAY" id="__LAY_S_INPLAY_{{d.submaster[2].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                </div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jOBoYn" id="__BACK_INPLAY_{{d.submaster[1].stInstrumentID}}">
                    <div type="BACK" id="__BACK_P_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[1].rates ? d.submaster[1].rates.Back[0].Price : '-'}}
                    </div>
                    <!-- <div type="BACK" id="__BACK_S_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                  <div type="LAY" class="sc-cmthru bEnOkM" id="__LAY_INPLAY_{{d.submaster[1].stInstrumentID}}">
                    <div type="LAY" id="__LAY_P_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[1].rates ? d.submaster[1].rates.Lay[0].Price : '-'}}
                    </div>
                    <!-- <div type="LAY" id="__LAY_S_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                </div>
              </div>
              <div class="sc-bXGyLb sc-bnXvFD vUVLn" *ngIf="d.submaster.length == 2">
                <div *ngIf="d.stSportsID == 7 || d.stSportsID == 4339" class="sc-iQNlJl kEIzqlHorse"
                  id="__SUSPEND_{{d.submaster[0].stInstrumentID}}">BET OPEN</div>
                <div *ngIf="d.stSportsID != 7 && d.stSportsID != 4339" class="sc-iQNlJl kEIzql" [style.display]="d.btMarketStatus == 0 || d.btMarketStatus == 1 ? 'none' : 'flex'"
                  id="__SUSPEND_{{d.submaster[0].stInstrumentID}}">SUSPENDED</div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jOBoYn">
                    <div type="BACK" id="__BACK_P_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[0].rates ? d.submaster[0].rates.Back[0].Price : '-'}}
                    </div>
                    <!-- <div type="BACK" id="__BACK_S_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                  <div type="LAY" class="sc-cmthru bEnOkM">
                    <div type="LAY" id="__LAY_P_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[0].rates ? d.submaster[0].rates.Lay[0].Price : '-'}}
                    </div>
                    <!-- <div type="LAY" id="__LAY_S_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                </div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jVWUZo">
                    <div type="BACK" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                  <div type="LAY" class="sc-cmthru jVWUZo">
                    <div type="LAY" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                </div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jOBoYn">
                    <div type="BACK" id="__BACK_P_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[1].rates ? d.submaster[1].rates.Back[0].Price : '-'}}
                    </div>
                    <!-- <div type="BACK" id="__BACK_S_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                  <div type="LAY" class="sc-cmthru bEnOkM">
                    <div type="LAY" id="__LAY_P_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[1].rates ? d.submaster[1].rates.Lay[0].Price : '-'}}
                    </div>
                    <!-- <div type="LAY" id="__LAY_S_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                </div>
              </div>
              <div class="sc-bXGyLb sc-bnXvFD vUVLn" *ngIf="d.submaster.length == 1">
                <div *ngIf="d.stSportsID == 7 || d.stSportsID == 4339" class="sc-iQNlJl kEIzqlHorse"
                  id="__SUSPEND_{{d.submaster[0].stInstrumentID}}">BET OPEN</div>
                <div *ngIf="d.stSportsID != 7 && d.stSportsID != 4339" class="sc-iQNlJl kEIzql" [style.display]="d.btMarketStatus == 0 || d.btMarketStatus == 1 ? 'none' : 'flex'"
                  id="__SUSPEND_{{d.submaster[0].stInstrumentID}}">SUSPENDED</div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jOBoYn">
                    <div type="BACK" id="__BACK_P_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-iuJeZd hkqSUv">-
                    </div>
                    <!-- <div type="BACK" id="__BACK_S_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                  <div type="LAY" class="sc-cmthru bEnOkM">
                    <div type="LAY" id="__LAY_P_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-iuJeZd hkqSUv">-
                    </div>
                    <!-- <div type="LAY" id="__LAY_S_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                </div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jVWUZo">
                    <div type="BACK" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                  <div type="LAY" class="sc-cmthru jVWUZo">
                    <div type="LAY" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                </div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jVWUZo">
                    <div type="BACK" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                  <div type="LAY" class="sc-cmthru jVWUZo">
                    <div type="LAY" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                </div>
              </div>
              <div class="sc-bXGyLb sc-bnXvFD vUVLn" *ngIf="d.submaster.length == 0">
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jVWUZo">
                    <div type="BACK" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                  <div type="LAY" class="sc-cmthru jVWUZo">
                    <div type="LAY" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                </div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jVWUZo">
                    <div type="BACK" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                  <div type="LAY" class="sc-cmthru jVWUZo">
                    <div type="LAY" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                </div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jVWUZo">
                    <div type="BACK" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                  <div type="LAY" class="sc-cmthru jVWUZo">
                    <div type="LAY" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div *ngIf="__SPORTSID != '-1' && __SPORTSID == d.stSportsID" id="CricketTRInPlay_{{d.stMarketID}}" class="sc-hXRMBi gVVkIY">
            <div class="sc-bwCtUz sc-gwVKww cXWrKs">
              <div class="sc-bXGyLb sc-cpmLhU beoeno">
                <div class="sc-emmjRN cpsDzQ">
                  <div class="sc-eerKOB ldkwXi">
                    <div class="sc-eilVRo ehEWqk" (click)="changeNavigation(d.stEventID, d.stSportsID, d.stMarketID)">
                      <b *ngIf="d.btMarketStatus == 1" class="live-list m-r-5">Live</b>{{d.stEventName}}<span
                        *ngIf="d.btMarketStatus != 1"> / {{d.dtStartDate | date:'dd MMM, HH:mm'}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sc-bXGyLb sc-dymIpo llHxpRJ">
                <div class="sc-RefOD dbkUcG">
                  <!-- <div class="list-tv-bm-f-icon" *ngIf="d.btMarketStatus == 1 && d.stSportsID != 4">
                      <img src="./images/tv-white.png" width="20px" class="m-r-2"/>
                      <span *ngIf="d.stSportsID == 1 || d.stSportsID == 2" class="list-f-color">F</span>
                  </div> -->
                  <div class="list-tv-bm-f-icon">
                    <img *ngIf="d.btMarketStatus == 1" src="./images/tv-white.png" width="20px" class="m-r-2" />
                    <span *ngIf="d.btMarketStatus == 1 && d.stSportsID == 4" class="list-bm-color m-r-2">BM</span>
                    <span *ngIf="d.isFancy && d.isFancy == 1" class="list-f-color">F</span>
                  </div>
                  <!-- <div *ngIf="__SPORTSID != '-1'" class="list-tv-bm-f-icon" *ngIf="d.btMarketStatus == 1">
                      <img src="./images/tv-white.png" width="20px" class="m-r-2"/>
                      <span *ngIf="__SPORTSID == '4'" class="list-bm-color m-r-2">BM</span>
                      <span *ngIf="__SPORTSID == '4' || __SPORTSID == '1' || __SPORTSID == '2'" class="list-f-color">F</span>
                  </div> -->
                </div>
              </div>
              <div class="sc-bXGyLb sc-dymIpo llHxp">
                <div class="sc-RefOD dbkUcG">
                  <ng-container *ngIf="d.stSportsID != 7 && d.stSportsID != 4339">
                    <ng-container *ngIf="ALLM2ME[d.stEventID] == undefined">
                      <div class="sc-ibxdXY bvjTkI" id="InPlaym2m_{{d.stEventID}}">0.00</div>
                    </ng-container>
                    <ng-container *ngIf="ALLM2ME[d.stEventID] != undefined">
                      <div *ngIf="ALLM2ME[d.stEventID] < 0" class="sc-ibxdXY bvjTkI ksWbmr"
                        id="InPlaym2m_{{d.stEventID}}">
                        {{ALLM2ME[d.stEventID]}}</div>
                      <div *ngIf="ALLM2ME[d.stEventID] >= 0" class="sc-ibxdXY bvjTkI htTnxO"
                        id="InPlaym2m_{{d.stEventID}}">
                        {{ALLM2ME[d.stEventID]}}</div>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="d.stSportsID == 7 || d.stSportsID == 4339">
                    <ng-container *ngIf="ALLM2ME[d.stMarketID] == undefined">
                      <div class="sc-ibxdXY bvjTkI" id="InPlaym2m_{{d.stMarketID}}">0.00</div>
                    </ng-container>
                    <ng-container *ngIf="ALLM2ME[d.stMarketID] != undefined">
                      <div *ngIf="ALLM2ME[d.stMarketID] < 0" class="sc-ibxdXY bvjTkI ksWbmr"
                        id="InPlaym2m_{{d.stMarketID}}">
                        {{ALLM2ME[d.stMarketID]}}</div>
                      <div *ngIf="ALLM2ME[d.stMarketID] >= 0" class="sc-ibxdXY bvjTkI htTnxO"
                        id="InPlaym2m_{{d.stMarketID}}">
                        {{ALLM2ME[d.stMarketID]}}</div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>

              <div class="sc-bXGyLb sc-bnXvFD vUVLn" *ngIf="d.submaster.length > 2">
                <div *ngIf="d.stSportsID == 7 || d.stSportsID == 4339" class="sc-iQNlJl kEIzqlHorse"
                  id="__SUSPEND_{{d.submaster[0].stInstrumentID}}">BET OPEN</div>
                <div *ngIf="d.stSportsID != 7 && d.stSportsID != 4339" class="sc-iQNlJl kEIzql" [style.display]="d.btMarketStatus == 0 || d.btMarketStatus == 1 ? 'none' : 'flex'"
                  id="__SUSPEND_{{d.submaster[0].stInstrumentID}}">SUSPENDED</div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jOBoYn" id="__BACK_INPLAY_{{d.submaster[0].stInstrumentID}}">
                    <div type="BACK" id="__BACK_P_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[0].rates ? d.submaster[0].rates.Back[0].Price : '-'}}
                    </div>
                    <!-- <div type="BACK" id="__BACK_S_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                  <div type="LAY" class="sc-cmthru bEnOkM" id="__LAY_INPLAY_{{d.submaster[0].stInstrumentID}}">
                    <div type="LAY" id="__LAY_P_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[0].rates ? d.submaster[0].rates.Lay[0].Price : '-'}}
                    </div>
                    <!-- <div type="LAY" id="__LAY_S_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                </div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jOBoYn" id="__BACK_INPLAY_{{d.submaster[2].stInstrumentID}}">
                    <div type="BACK" id="__BACK_P_INPLAY_{{d.submaster[2].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[2].rates ? d.submaster[2].rates.Back[0].Price : '-'}}
                    </div>
                    <!-- <div type="BACK" id="__BACK_S_INPLAY_{{d.submaster[2].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                  <div type="LAY" class="sc-cmthru bEnOkM" id="__LAY_INPLAY_{{d.submaster[2].stInstrumentID}}">
                    <div type="LAY" id="__LAY_P_INPLAY_{{d.submaster[2].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[2].rates.Lay[0] ? d.submaster[2].rates.Lay[0].Price : '-'}}
                    </div>
                    <!-- <div type="LAY" id="__LAY_S_INPLAY_{{d.submaster[2].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                </div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jOBoYn" id="__BACK_INPLAY_{{d.submaster[1].stInstrumentID}}">
                    <div type="BACK" id="__BACK_P_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[1].rates.Back[0] ? d.submaster[1].rates.Back[0].Price : '-'}}
                    </div>
                    <!-- <div type="BACK" id="__BACK_S_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                  <div type="LAY" class="sc-cmthru bEnOkM" id="__LAY_INPLAY_{{d.submaster[1].stInstrumentID}}">
                    <div type="LAY" id="__LAY_P_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[1].rates.Lay[0]? d.submaster[1].rates.Lay[0].Price : '-'}}
                    </div>
                    <!-- <div type="LAY" id="__LAY_S_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                </div>
              </div>

              <div class="sc-bXGyLb sc-bnXvFD vUVLn" *ngIf="d.submaster.length == 2">
                <div *ngIf="d.stSportsID == 7 || d.stSportsID == 4339" class="sc-iQNlJl kEIzqlHorse"
                  id="__SUSPEND_{{d.submaster[0].stInstrumentID}}">BET OPEN</div>
                <div *ngIf="d.stSportsID != 7 && d.stSportsID != 4339" class="sc-iQNlJl kEIzql" [style.display]="d.btMarketStatus == 0 || d.btMarketStatus == 1 ? 'none' : 'flex'"
                  id="__SUSPEND_{{d.submaster[0].stInstrumentID}}">SUSPENDED</div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jOBoYn">
                    <div type="BACK" id="__BACK_P_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[0].rates ? d.submaster[0].rates.Back[0].Price : '-'}}
                    </div>
                    <!-- <div type="BACK" id="__BACK_S_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                  <div type="LAY" class="sc-cmthru bEnOkM">
                    <div type="LAY" id="__LAY_P_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[0].rates ? d.submaster[0].rates.Lay[0].Price : '-'}}
                    </div>
                    <!-- <div type="LAY" id="__LAY_S_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                </div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jVWUZo">
                    <div type="BACK" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                  <div type="LAY" class="sc-cmthru jVWUZo">
                    <div type="LAY" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                </div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jOBoYn">
                    <div type="BACK" id="__BACK_P_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[1].rates ? d.submaster[1].rates.Back[0].Price : '-'}}
                    </div>
                    <!-- <div type="BACK" id="__BACK_S_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                  <div type="LAY" class="sc-cmthru bEnOkM">
                    <div type="LAY" id="__LAY_P_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-iuJeZd hkqSUv">{{d.submaster[1].rates ? d.submaster[1].rates.Lay[0].Price : '-'}}
                    </div>
                    <!-- <div type="LAY" id="__LAY_S_INPLAY_{{d.submaster[1].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                </div>
              </div>

              <div class="sc-bXGyLb sc-bnXvFD vUVLn" *ngIf="d.submaster.length == 1">
                <div *ngIf="d.stSportsID == 7 || d.stSportsID == 4339" class="sc-iQNlJl kEIzqlHorse"
                  id="__SUSPEND_{{d.submaster[0].stInstrumentID}}">BET OPEN</div>
                <div *ngIf="d.stSportsID != 7 && d.stSportsID != 4339" class="sc-iQNlJl kEIzql" [style.display]="d.btMarketStatus == 0 || d.btMarketStatus == 1 ? 'none' : 'flex'"
                  id="__SUSPEND_{{d.submaster[0].stInstrumentID}}">SUSPENDED</div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jOBoYn">
                    <div type="BACK" id="__BACK_P_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-iuJeZd hkqSUv">-
                    </div>
                    <!-- <div type="BACK" id="__BACK_S_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                  <div type="LAY" class="sc-cmthru bEnOkM">
                    <div type="LAY" id="__LAY_P_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-iuJeZd hkqSUv">-
                    </div>
                    <!-- <div type="LAY" id="__LAY_S_INPLAY_{{d.submaster[0].stInstrumentID}}" class="sc-esOvli bspoOb">0</div> -->
                  </div>
                </div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jVWUZo">
                    <div type="BACK" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                  <div type="LAY" class="sc-cmthru jVWUZo">
                    <div type="LAY" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                </div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jVWUZo">
                    <div type="BACK" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                  <div type="LAY" class="sc-cmthru jVWUZo">
                    <div type="LAY" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                </div>
              </div>

              <div class="sc-bXGyLb sc-bnXvFD vUVLn" *ngIf="d.submaster.length == 0">
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jVWUZo">
                    <div type="BACK" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                  <div type="LAY" class="sc-cmthru jVWUZo">
                    <div type="LAY" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                </div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jVWUZo">
                    <div type="BACK" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                  <div type="LAY" class="sc-cmthru jVWUZo">
                    <div type="LAY" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                </div>
                <div class="sc-jzgbtB cDeqWm">
                  <div type="BACK" class="sc-cmthru jVWUZo">
                    <div type="BACK" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                  <div type="LAY" class="sc-cmthru jVWUZo">
                    <div type="LAY" class="sc-iuJeZd hkqSUvTS">-</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- <div class="col-md-3 p-l-5">
    <div id="right-sidebar-id" class="right-sidebar">
      <div class="home-casiono-icons d-none-mobile">
        <div dir="ltr" class="slick-slider slick-initialized slick-vertical">

          <div id="carousel-pager" class="carousel slide " data-ride="carousel" data-interval="3000">
            <div class="carousel-inner vertical">

              <ng-container *ngFor="let d of __LOTUS_GAMES; let i = index;">
                <div [class]="i == 0 ? 'active item' : 'item'">
                  <div class="casino-banner-item-slider login-hover" [attr.data-id]="d.GID">
                    <a>
                      <img src="{{d.IMG}}" class="img-fluid">
                      <span *ngIf="d.VIRTUAL" class="casino-text-name">(Virtual)</span>
                      <div role="button" tabindex="0">Play</div>
                    </a>
                  </div>
                </div>
              </ng-container>
              <div class="item">
                <div class="casino-banner-item-slider login-hover" data-id="ezugi">
                  <a>
                    <img src="images/HOTSPORTS/ads/ezugi.jpg" class="img-fluid">
                    <div role="button" tabindex="0">Play</div>
                  </a>
                </div>
              </div>
              <div class="item">
                <div class="casino-banner-item-slider login-hover" data-id="evolution">
                  <a>
                    <img src="images/HOTSPORTS/ads/evolution.jpg" class="img-fluid">
                    <div role="button" tabindex="0">Play</div>
                  </a>
                </div>
              </div>

            </div>
            <a class="left carousel-control" href="#carousel-pager" role="button" data-slide="prev">
                <span class="glyphicon glyphicon-chevron-up" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="right carousel-control" href="#carousel-pager" role="button" data-slide="next">
                <span class="glyphicon glyphicon-chevron-down" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
   
        </div>
      </div>
    </div>
  </div> -->
</div>