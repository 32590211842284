<aside class="main-sidebar">
    <section class="sidebar">
        <div class="text-center">
            <a (click)="onHomeClick()" class="hoverPointer logo">
                <img src="images/HOTSPORTS/header-logo.png" style="width: 9vw;" alt="" />
            </a>
        </div>

        <div class="left-sidebar">
            <!-- <div class="sidebarScrollDiv"> -->
            <ul *ngIf="UserType == 4"
                [class]="!IS_ACCESS ? 'sidebar-menu fs-09vw enabled-access' : 'sidebar-menu fs-09vw disabled-access'"
                data-widget="tree">
                <li>
                    <a *ngIf="!IS_ACCESS" data-toggle="modal" data-target="#_ENABLE_ACCESS" class="hoverPointer">
                        <i class="fa fa-lock m-r-10"></i>
                        <span>Enable Access</span>
                    </a>
                    <a *ngIf="IS_ACCESS" (click)="disableAccess()" class="hoverPointer">
                        <i class="fa fa-unlock m-r-10"></i>
                        <span>Disable Access</span>
                    </a>
                </li>
            </ul>
            <ul *ngIf="UserID == 1651" data-widget="tree" class="sidebar-menu fs-09vw">
                <li>
                    <span style="color: #fff;">Payment On - off</span>
                    <span class="dropdown">
                        <a class="dropdown-toggle btn btn-danger padding1-6 fs-12" data-toggle="dropdown" href="#"
                            style="float: right;">
                            Action <span class="caret"></span>
                        </a>
                        <ul class="dropdown-menu fs-12 margin0 padding0">
                            <li (click)="onActionOnOffClick('on')" class="Drop-down-border">
                                <a role="menuitem" tabindex="-1">On</a>
                            </li>
                            <li (click)="onActionOnOffClick('off')" class="Drop-down-border">
                                <a role="menuitem" tabindex="-1">Off</a>
                            </li>
                        </ul>
                    </span>
                </li>
            </ul>
            <ul *ngIf="IS_ACCESS && UserID == 1651 || UserID == 1652" class="sidebar-menu hoverPointer"
                data-widget="tree">
                <li class="header">
                    <a (click)="onDynamicRouterClick('/manage/bank')">
                        Bank Account
                    </a>
                </li>
            </ul>
            <ul *ngIf="UserID == 1651 || UserID == 1652" class="sidebar-menu hoverPointer" data-widget="tree">
                <li class="header" data-toggle="collapse" href="#collapsePaymentsNavTab">
                    <a>
                        Manage Payments
                        <span class="pull-right-container">
                            <i class="fa fa-fw fa-chevron-down"></i>
                            <i class="fa fa-fw fa-chevron-right"></i>
                        </span>
                    </a>
                </li>
            </ul>
            <div *ngIf="UserID == 1651 || UserID == 1652" id="collapsePaymentsNavTab"
                class="panel-collapse collapse in">
                <ul class="sidebar-menu fs-09vw" data-widget="tree">

                    <li>
                        <a (click)="onDynamicRouterClick('/manage/payments/deposit')" class="hoverPointer">
                            <i class="fa fa-history m-r-10"></i>
                            <span>Deposit History</span>
                        </a>
                    </li>
                    <!-- <li>
                        <a (click)="onDynamicRouterClick('/manage/payments/deposit-pending')" class="hoverPointer">
                            <i class="fa fa-history m-r-10"></i>
                            <span>Deposit Pending History</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="onDynamicRouterClick('manage/manual/payments/deposit')" class="hoverPointer">
                            <i class="fa fa-history m-r-10"></i>
                            <span>Deposit Bouns Report</span>
                        </a>
                    </li> -->
                    <li>
                        <a (click)="onDynamicRouterClick('/manage/payments/withdraw')" class="hoverPointer">
                            <i class="fa fa-history m-r-10"></i>
                            <span>Withdraw Requests</span>
                        </a>
                    </li>

                </ul>
            </div>
            <ul class="sidebar-menu fs-09vw" data-widget="tree">
                <li>
                    <a data-toggle="modal" data-target="#RulesAllInOneModal" class="hoverPointer">
                        <i class="fa fa-file-text m-r-10"></i>
                        <span>Rules</span>
                    </a>
                </li>
            </ul>
            <!-- <ul *ngIf="UserID == 1651 || UserID == 1652" class="sidebar-menu fs-09vw" data-widget="tree">
                <li>
                    <a (click)="onDynamicRouterClick('/report/gainers')" class="hoverPointer">
                        <i class="fa fa-thumbs-up m-r-10"></i>
                        <span>Top Gainers</span>
                    </a>
                </li>
            </ul>
            <ul *ngIf="UserID == 1651 || UserID == 1652" class="sidebar-menu fs-09vw" data-widget="tree">
                <li>
                    <a (click)="onDynamicRouterClick('/report/losers')" class="hoverPointer">
                        <i class="fa fa-thumbs-down m-r-10"></i>
                        <span>Top Losers</span>
                    </a>
                </li>
            </ul> -->
            <ul *ngIf="UserType != 3" class="sidebar-menu hoverPointer" data-widget="tree">
                <li class="header" data-toggle="collapse" href="#collapseOthersNavTab">
                    <a>
                        Others
                        <span class="pull-right-container">
                            <i class="fa fa-fw fa-chevron-down"></i>
                            <i class="fa fa-fw fa-chevron-right"></i>
                        </span>
                    </a>
                </li>
            </ul>
            <div *ngIf="UserType != 3" id="collapseOthersNavTab" class="panel-collapse collapse in">
                <ul class="sidebar-menu fs-09vw" data-widget="tree">


                    <li *ngIf="(IS_ACCESS && UserType == 4) || UserType == 0">
                        <a (click)="onDynamicRouterClick('/change-banner')" class="hoverPointer">
                            <i class="fa fa-picture-o m-r-10"></i>
                            <span>Change Banners</span>
                        </a>
                    </li>
                    <li *ngIf="(IS_ACCESS && UserType == 4) || UserType == 0">
                        <a (click)="onDynamicRouterClick('/eventslimits')" class="hoverPointer">
                            <i class="fa fa-cogs m-r-10"></i>
                            <span>Match Setting</span>
                        </a>
                    </li>
                    <li *ngIf="UserType == 0">
                        <a data-toggle="collapse" href="#collapseMasters"
                            class="hoverPointer sidetoggleclass collapsed">
                            <i class="fa fa-cogs m-r-10 color-blue"></i>
                            <span>Masters</span>
                        </a>
                        <div id="collapseMasters" class="panel-collapse collapse background-theme">
                            <ul class="fs-09vw list-style-hide p-l-20" data-widget="tree">
                                <li>
                                    <a (click)="onDynamicRouterClick('/report/settlement-history')"
                                        class="hoverPointer">
                                        <div class="white-space-inherit padding5">Settlement Entry</div>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="onDynamicRouterClick('/eventslimits')" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Match Setting</div>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="onDynamicRouterClick('/closingapp')" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Set Match Result</div>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="onDynamicRouterClick('/report/user-general-setting')"
                                        class="hoverPointer">
                                        <div class="white-space-inherit padding5">User General</div>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="onDynamicRouterClick('/stream/data')" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Set Tv Stream</div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li *ngIf="UserType != 3">
                        <a data-toggle="collapse" href="#collapseListing"
                            class="hoverPointer sidetoggleclass collapsed">
                            <i class="fa fa-users m-r-10 color-blue"></i>
                            <span>Client Listing</span>
                        </a>
                        <div id="collapseListing" class="panel-collapse collapse background-theme">
                            <ul *ngIf="UserType == 0" class="fs-09vw list-style-hide p-l-20" data-widget="tree">
                                <li>
                                    <a (click)="onDynamicClientListingClick(0)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Admin</div>
                                    </a>
                                </li>
                                <!-- <li>
                                    <a (click)="onDynamicClientListingClick(4)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Hyper</div>
                                    </a>
                                </li> -->
                                <!-- <li>
                                    <a (click)="onDynamicClientListingClick(5)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Sub-Super-1</div>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="onDynamicClientListingClick(1)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Super</div>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="onDynamicClientListingClick(2)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Master</div>
                                    </a>
                                </li> -->
                                <li>
                                    <a (click)="onDynamicClientListingClick(3)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Client</div>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="onDynamicClientListingRegisteredClick(3)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Registered Clients</div>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="onDynamicRouterClick('/client-online/data')" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Online Clients</div>
                                    </a>
                                </li>
                            </ul>
                            <ul *ngIf="UserType == 4" class="fs-09vw list-style-hide p-l-20" data-widget="tree">
                                <li>
                                    <a (click)="onDynamicClientListingClick(4)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Hyper</div>
                                    </a>
                                </li>
                                <!-- <li>
                                    <a (click)="onDynamicClientListingClick(5)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Sub-Super-1</div>
                                    </a>
                                </li> -->
                                <!-- <li>
                                    <a (click)="onDynamicClientListingClick(1)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Super</div>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="onDynamicClientListingClick(2)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Master</div>
                                    </a>
                                </li> -->
                                <li>
                                    <a (click)="onDynamicClientListingClick(3)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Client</div>
                                    </a>
                                </li>
                                <li *ngIf="UserID == 1651 || UserID == 1652">
                                    <a (click)="onDynamicClientListingRegisteredClick(3)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Registered Clients</div>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="onDynamicRouterClick('/client-online/data')" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Online Clients</div>
                                    </a>
                                </li>
                            </ul>
                            <ul *ngIf="UserType == 5" class="fs-09vw list-style-hide p-l-20" data-widget="tree">
                                <!-- <li>
                                    <a (click)="onDynamicClientListingClick(5)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Sub-Super-1</div>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="onDynamicClientListingClick(1)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Super</div>
                                    </a>
                                </li> -->
                                <!-- <li>
                                    <a (click)="onDynamicClientListingClick(2)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Master</div>
                                    </a>
                                </li> -->
                                <li>
                                    <a (click)="onDynamicClientListingClick(3)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Client</div>
                                    </a>
                                </li>
                                <li *ngIf="UserID == 1651 || UserID == 1652">
                                    <a (click)="onDynamicClientListingRegisteredClick(3)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Registered Clients</div>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="onDynamicRouterClick('/client-online/data')" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Online Clients</div>
                                    </a>
                                </li>
                            </ul>
                            <ul *ngIf="UserType == 1" class="fs-09vw list-style-hide p-l-20" data-widget="tree">
                                <li>
                                    <a (click)="onDynamicClientListingClick(1)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Super</div>
                                    </a>
                                </li>
                                <!-- <li>
                                    <a (click)="onDynamicClientListingClick(2)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Master</div>
                                    </a>
                                </li> -->
                                <li>
                                    <a (click)="onDynamicClientListingClick(3)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Client</div>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="onDynamicRouterClick('/client-online/data')" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Online Clients</div>
                                    </a>
                                </li>
                            </ul>
                            <ul *ngIf="UserType == 2" class="fs-09vw list-style-hide p-l-20" data-widget="tree">
                                <li>
                                    <a (click)="onDynamicClientListingClick(2)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Master</div>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="onDynamicClientListingClick(3)" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Client</div>
                                    </a>
                                </li>
                                <li>
                                    <a (click)="onDynamicRouterClick('/client-online/data')" class="hoverPointer">
                                        <div class="white-space-inherit padding5">Online Clients</div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            <ul class="sidebar-menu hoverPointer" data-widget="tree">
                <li class="header" data-toggle="collapse" href="#collapseSportsNavTab">
                    <a>
                        All Sports
                        <span class="pull-right-container">
                            <i class="fa fa-fw fa-chevron-down"></i>
                            <i class="fa fa-fw fa-chevron-right"></i>
                        </span>
                    </a>
                </li>
            </ul>

            <div id="collapseSportsNavTab" class="panel-collapse collapse in">

                <ul class="sidebar-menu" data-widget="tree">
                    <li class="treeview fs-09vw" id="__SIDE_TOGGLE_0">
                        <a class="hoverPointer">
                            <img src="/images/inplay.png" alt="icon" class="m-r-10" style="width:1vw">
                            <span>In-Play</span>
                            <span class="pull-right-container">
                                <small class="label pull-right bg-Color-black text-color-theme"
                                    id="TotalInPlayMatches">({{TotalInPlayMatches}})</small>
                            </span>
                        </a>
                        <ul id="collapsedata0" class="treeview-menu" *ngIf="TotalInPlayMatches > 0">
                            <li *ngFor="let data of InPlayMatchList">
                                <a (click)="changeNavigation(data.stEventID, data.stSportsID, data.stMarketID)"
                                    class="hoverPointer">
                                    <div class="white-space-inherit padding5 fs-09vw">{{data.stEventName}}</div>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="treeview fs-09vw" id="__SIDE_TOGGLE_1" *ngIf="!CricketShowStatus">
                        <a class="hoverPointer">
                            <img src="/images/cricket-sidebar.png" alt="icon" class="m-r-10" style="width:1vw">
                            <span>Cricket</span>
                            <span class="pull-right-container">
                                <small class="label pull-right bg-Color-black text-color-theme"
                                    id="totalCricketMatches">({{TotalCricketMatches}})</small>
                            </span>
                        </a>
                        <ul id="collapsedata1" class="treeview-menu" *ngIf="TotalCricketMatches > 0">
                            <li *ngFor="let data of CricketMatchList" id="__SIDE_TOGGLE__{{data.stCompititionID}}"
                                class="treeview">
                                <a class="hoverPointer">
                                    <div class="white-space-inherit padding5">{{data.stCompititionName}}</div>
                                </a>
                                <ul id="collapsedata_{{data.stCompititionID}}" class="treeview-menu"
                                    *ngIf="data.eventsList.length > 0 && data.eventsList">
                                    <li *ngFor="let d of data.eventsList" id="__ACTIVE_MATCH_{{d.stMarketID}}">
                                        <a (click)="changeNavigation(d.stEventID, d.stSportsID, d.stMarketID)"
                                            class="hoverPointer">
                                            <div class="white-space-inherit padding5">{{d.stEventName}}</div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li class="treeview fs-09vw" id="__SIDE_TOGGLE_2" *ngIf="!TennisShowStatus">
                        <a class="hoverPointer">
                            <img src="/images/tennis-sidebar.png" alt="icon" class="m-r-10" style="width:1vw">
                            <span>Tennis</span>
                            <span class="pull-right-container">
                                <small class="label pull-right bg-Color-black text-color-theme"
                                    id="TotalTennisMatches">({{TotalTennisMatches}})</small>
                            </span>
                        </a>
                        <ul id="collapsedata2" class="treeview-menu" *ngIf="TotalTennisMatches > 0">
                            <li *ngFor="let data of TennisMatchList" id="__SIDE_TOGGLE__{{data.stCompititionID}}"
                                class="treeview">
                                <a class="hoverPointer">
                                    <div class="white-space-inherit padding5">{{data.stCompititionName}}</div>
                                </a>
                                <ul id="collapsedata_{{data.stCompititionID}}" class="treeview-menu"
                                    *ngIf="data.eventsList.length > 0 && data.eventsList">
                                    <li *ngFor="let d of data.eventsList" id="__ACTIVE_MATCH_{{d.stMarketID}}">
                                        <a (click)="changeNavigation(d.stEventID, d.stSportsID, d.stMarketID)"
                                            class="hoverPointer">
                                            <div class="white-space-inherit padding5">{{d.stEventName}}</div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li class="treeview fs-09vw" id="__SIDE_TOGGLE_3" *ngIf="!SoccerShowStatus">
                        <a class="hoverPointer">
                            <img src="/images/football-sidebar.png" alt="icon" class="m-r-10" style="width:1vw">
                            <span>Soccer</span>
                            <span class="pull-right-container">
                                <small class="label pull-right bg-Color-black text-color-theme"
                                    id="TotalSoccerMatches">({{TotalSoccerMatches}})</small>
                            </span>
                        </a>
                        <ul id="collapsedata3" class="treeview-menu" *ngIf="TotalSoccerMatches > 0">
                            <li *ngFor="let data of SoccerMatchList" id="__SIDE_TOGGLE__{{data.stCompititionID}}"
                                class="treeview">
                                <a class="hoverPointer">
                                    <div class="white-space-inherit padding5">{{data.stCompititionName}}</div>
                                </a>
                                <ul id="collapsedata_{{data.stCompititionID}}" class="treeview-menu"
                                    *ngIf="data.eventsList.length > 0 && data.eventsList">
                                    <li *ngFor="let d of data.eventsList" id="__ACTIVE_MATCH_{{d.stMarketID}}">
                                        <a (click)="changeNavigation(d.stEventID, d.stSportsID, d.stMarketID)"
                                            class="hoverPointer">
                                            <div class="white-space-inherit padding5">{{d.stEventName}}</div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li class="treeview fs-09vw" id="__SIDE_TOGGLE_4" *ngIf="!HorseRacingShowStatus">
                        <a class="hoverPointer">
                            <img src="/images/horseracing-sidebar.png" alt="icon" class="m-r-10" style="width:1vw">
                            <span>Horse Racing</span>
                            <span class="pull-right-container">
                                <small class="label pull-right bg-Color-black text-color-theme"
                                    id="totalHorseMatches">({{TotalHorseRacingMatches}})</small>
                            </span>
                        </a>
                        <ul id="collapsedata4" class="treeview-menu" *ngIf="TotalHorseRacingMatches > 0">
                            <li *ngFor="let data of HorseMatchList" id="__ACTIVE_MATCH_{{data.stMarketID}}">
                                <a (click)="changeNavigation(data.stEventID, data.stSportsID, data.stMarketID)"
                                    class="hoverPointer">
                                    <div class="white-space-inherit padding5">{{data.stEventName}}</div>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</aside>